import React, { Suspense, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import Snackbar from '@material-ui/core/Snackbar';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import LinearProgress from '@material-ui/core/LinearProgress';

import { makeStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router-dom';

import LookupInsertComp from 'components/common/LookupInsertComp';
import AlertNotificationComp from 'components/custom/AlertNotificationComp';

//date
import moment from 'moment';

//Api
import axios from 'axios';

//Redux imports
import { useSelector, useDispatch } from 'react-redux';
import * as actionType from 'redux/actions/actionType';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
	},
	operatoraddcontainer: {
		'& .MuiPaper-root': {
			width: '100%',
			padding: 10,
			margin: 10,
		},
	},
	formcontainer: {
		'& .MuiFormControl-root': {
			width: '100%',
		},
		'& .MuiGrid-container': {
			marginBottom: 20,
		},
	},
	updatebutton: {
		width: '100%',
	},
}));

function AddOperatorComp(props) {
	const classes = useStyles();

	const dispatch = useDispatch();

	const token = useSelector((state) => state.userReducer.token);

	const operatorinitialstate = {
		OperatorName: null,
		OperatorTypeId: 1,
		Address: null,
		CityId: null,
		CountyId: null,
		StateNameId: null,
		ZipcodeId: null,
		GovtOperatorId: null,
		inceptiondate: null,
		closuredate: null,
		WebsiteAddress: null,
		ContactNumber: null,
		ContactEmail: null,
		Company1Id: null,
		Company1Percentage: null,
		Company2Id: null,
		Company2Percentage: null,
		Company3Id: null,
		Company3Percentage: null,
	};

	const [operatorvalues, setOperatorValues] = React.useState(operatorinitialstate);

	const [operators, setOperators] = React.useState([]);

	const [status, setStatusBase] = React.useState({
		show: false,
		message: '',
		variant: 'error',
	});

	const fnShowErrorMessage = () => {
		setStatusBase({ show: false, message: '', variant: 'error' });
	};

	const fnSetValues = (key, keyid, keyvalue, keyidvalue) => {
		setOperatorValues({ ...operatorvalues, [keyid]: keyidvalue });
	};

	const fnHandleChange = (prop) => (event) => {
		setOperatorValues({ ...operatorvalues, [prop]: event.target.value });
	};

	const fnHandleInceptionDateChange = (date) => {
		if (date !== null) {
			let modifieddate = moment(date);
			setOperatorValues({ ...operatorvalues, inceptiondate: modifieddate.format('YYYY-MM-DD') });
		} else {
			setOperatorValues({ ...operatorvalues, inceptiondate: null });
		}
	};

	const fnHandleClosureDateChange = (date) => {
		if (date !== null) {
			let modifieddate = moment(date);
			setOperatorValues({ ...operatorvalues, closuredate: modifieddate.format('YYYY-MM-DD') });
		} else {
			setOperatorValues({ ...operatorvalues, closuredate: null });
		}
	};

	const fnAddOperator = () => {
		// console.log(JSON.stringify(operatorvalues));

		var insertvalues = [];
		var keys = Object.keys(operatorvalues);
		keys.forEach((element) => {
			if (operatorvalues[element] === null) {
				insertvalues.push('NULL');
			} else {
				insertvalues.push('"' + operatorvalues[element] + '"');
			}
		});

		// console.log(insertvalues.toString(','));

		const data = {
			inserttype: 'operator',
			insertvalues: insertvalues.toString(','),
		};

		const options = {
			headers: {
				authorization: token ? `Bearer ${token}` : '',
			},
		};

		axios.post('/api/insertdatarecord', data, options).then(
			(response) => {
				if (response.data[0].status === 'error') {
					setStatusBase({
						show: true,
						message: 'Error while inserting operator record',
						variant: 'error',
					});
				} else if (response.data[0].status === 'inserted') {
					const tempoperators = [
						...operators,
						{
							OperatorName: operatorvalues.OperatorName,
							OperatorId: response.data[0].insertId,
						},
					];

					dispatch({
						type: actionType.ADD_OPERATORS,
						payload: {
							OperatorName: operatorvalues,
						},
					});

					setStatusBase({ show: true, message: 'Record inserted successfully', variant: 'info' });

					window.location.reload();
				} else {
					setStatusBase({ show: true, message: 'Error while inserting record', variant: 'info' });
				}
			},
			(error) => {
				if (error.request.status === 500) {
					setStatusBase({ show: true, message: 'Time out', variant: 'error' });
					props.history.push('/login');
				} else {
					setStatusBase({
						show: true,
						message: 'Error while inserting operator data',
						variant: 'error',
					});
				}
			}
		);
	};

	var reduxvalues = useSelector((state) => state.operatorsReducer.OperatorName);

	useEffect(() => {
		if (reduxvalues !== undefined && reduxvalues.length > 0) {
			// console.log('dbtype ' + dbtype);
			// console.log('effect' + reduxvalues);

			setOperators(reduxvalues);
		} else {
			// fnFetchLookupValues();
		}
	}, []);

	return (
		<>
			<Grid className={classes.operatoraddcontainer} container>
				<Paper>
					<Typography variant="subtitle1">Add Operator Data</Typography>
					<ValidatorForm>
						<>
							<Grid container direction="column" className={classes.formcontainer}>
								<Grid item>
									<Grid container direction="row" justify="flex-start" alignItems="center">
										<Grid item xs="4">
											<Autocomplete
												id="operatornameId"
												freeSolo
												size="small"
												options={operators.map((option) => option.OperatorName)}
												renderInput={(params) => (
													<TextField
														{...params}
														label="Operator Name"
														margin="normal"
														variant="outlined"
														value={operatorvalues.OperatorName}
														onChange={fnHandleChange('OperatorName')}
													/>
												)}
											/>
										</Grid>
										<Grid item xs="1"></Grid>
										<Grid item xs="4">
											<LookupInsertComp
												fnSetValues={fnSetValues}
												lookuptype="OperatorType"
												lookupid="OperatorTypeId"
											></LookupInsertComp>
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									<Grid container direction="row" justify="space-between" alignItems="center">
										<Grid item xs="3">
											<TextField
												label="Address"
												id="operatoraddressId"
												value={operatorvalues.Address}
												onChange={fnHandleChange('Address')}
												variant="outlined"
												size="small"
											/>
										</Grid>
										<Grid item xs="1"></Grid>
										<Grid item xs="2">
											<LookupInsertComp
												fnSetValues={fnSetValues}
												lookuptype="CityName"
												lookupid="CityId"
											></LookupInsertComp>
										</Grid>
										<Grid item xs="1"></Grid>
										<Grid item xs="2">
											<LookupInsertComp
												fnSetValues={fnSetValues}
												lookuptype="CountyName"
												lookupid="CountyId"
											></LookupInsertComp>
										</Grid>
										<Grid item xs="1"></Grid>
										<Grid item xs="2">
											<LookupInsertComp
												fnSetValues={fnSetValues}
												lookuptype="StateName"
												lookupid="StateNameId"
											></LookupInsertComp>
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									<Grid container direction="row" justify="space-between" alignItems="center">
										<Grid item xs="3">
											<LookupInsertComp
												fnSetValues={fnSetValues}
												lookuptype="Zipcode"
												lookupid="ZipcodeId"
											></LookupInsertComp>
										</Grid>
										<Grid item xs="1"></Grid>
										<Grid item xs="2">
											<TextField
												label="Govt Operator Id"
												id="govtoperatorId"
												onChange={fnHandleChange('GovtOperatorId')}
												value={operatorvalues.GovtOperatorId}
												variant="outlined"
												size="small"
											/>
										</Grid>
										<Grid item xs="1"></Grid>
										<Grid item xs="2">
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<KeyboardDatePicker
													disableToolbar
													format="yyyy/MM/dd"
													margin="normal"
													id="inceptiondateId"
													label="Inception Date"
													variant="outlined"
													value={operatorvalues.inceptiondate}
													onChange={fnHandleInceptionDateChange}
													KeyboardButtonProps={{
														'aria-label': 'Inception Date',
													}}
												/>
											</MuiPickersUtilsProvider>
										</Grid>
										<Grid item xs="1"></Grid>
										<Grid item xs="2">
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<KeyboardDatePicker
													disableToolbar
													format="yyyy/MM/dd"
													margin="normal"
													id="closuredateId"
													label="Closure Date"
													variant="outlined"
													value={operatorvalues.closuredate}
													onChange={fnHandleClosureDateChange}
													KeyboardButtonProps={{
														'aria-label': 'Closure Date',
													}}
												/>
											</MuiPickersUtilsProvider>
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									<Grid container direction="row" justify="space-between" alignItems="center">
										<Grid item xs="3">
											<TextField
												label="Website Address"
												id="websiteaddressId"
												onChange={fnHandleChange('WebsiteAddress')}
												value={operatorvalues.WebsiteAddress}
												variant="outlined"
												size="small"
											/>
										</Grid>
										<Grid item xs="1"></Grid>
										<Grid item xs="2">
											<TextField
												label="Contact Number"
												onChange={fnHandleChange('ContactNumber')}
												value={operatorvalues.ContactNumber}
												id="contactnumberId"
												variant="outlined"
												size="small"
											/>
										</Grid>
										<Grid item xs="1"></Grid>
										<Grid item xs="5">
											<TextField
												label="Contact Email"
												id="contactemailId"
												onChange={fnHandleChange('ContactEmail')}
												value={operatorvalues.ContactEmail}
												variant="outlined"
												size="small"
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									<Grid container direction="row" justify="space-between" alignItems="center">
										<Grid item xs="2">
											<LookupInsertComp
												fnSetValues={fnSetValues}
												lookuptype="Company1"
												lookupid="Company1Id"
											></LookupInsertComp>
										</Grid>
										<Grid item xs="1">
											<TextField
												label="Percentage"
												id="company1percentageId"
												type="number"
												placeholder="0.00"
												variant="outlined"
												onChange={fnHandleChange('Company1Percentage')}
												value={operatorvalues.Company1Percentage}
												size="small"
											/>
										</Grid>
										<Grid item xs="1"></Grid>
										<Grid item xs="2">
											<LookupInsertComp
												fnSetValues={fnSetValues}
												lookuptype="Company2"
												lookupid="Company2Id"
											></LookupInsertComp>
										</Grid>
										<Grid item xs="1">
											<TextField
												label="Percentage"
												id="company2percentageId"
												type="number"
												placeholder="0.00"
												variant="outlined"
												onChange={fnHandleChange('Company2Percentage')}
												value={operatorvalues.Company2Percentage}
												size="small"
											/>
										</Grid>
										<Grid item xs="1"></Grid>
										<Grid item xs="2">
											<LookupInsertComp
												fnSetValues={fnSetValues}
												lookuptype="Company3"
												lookupid="Company3Id"
											></LookupInsertComp>
										</Grid>
										<Grid item xs="1">
											<TextField
												label="Percentage"
												id="company3percentageId"
												type="number"
												placeholder="0.00"
												variant="outlined"
												onChange={fnHandleChange('Company3Percentage')}
												value={operatorvalues.Company3Percentage}
												size="small"
											/>
										</Grid>
										<Grid item xs="1"></Grid>
									</Grid>
								</Grid>
								<Grid item>
									<Button
										type="button"
										color="primary"
										variant="contained"
										className={classes.updatebutton}
										disabled={
											operatorvalues.OperatorName === null ||
											operatorvalues.OperatorName === undefined
										}
										onClick={() => fnAddOperator()}
									>
										Add Operator
									</Button>
								</Grid>
							</Grid>
							{/* <Grid container direction="column" justify="center" alignItems="stretch">
								<Grid item>
									<LookupInsertComp
										fnSetValues={fnSetValues}
										lookuptype="CityName"
										lookupid="CityId"
									></LookupInsertComp>
									<Autocomplete
										id="operatornameId"
										freeSolo
										// options={top100Films.map((option) => option.title)}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Operator Name"
												margin="normal"
												variant="outlined"
											/>
										)}
									/>
								</Grid>
								<Grid item></Grid>
								<Grid item>
									<Button
										type="submit"
										color="primary"
										variant="contained"
										className={classes.loginbutton}
									>
										Add Operator
									</Button>
								</Grid>
							</Grid>
						 */}
						</>
					</ValidatorForm>
				</Paper>
			</Grid>
			<Snackbar
				autoHideDuration={6000}
				open={status.show}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={() => fnShowErrorMessage()}
			>
				<AlertNotificationComp
					variant={status.variant}
					onClose={() => fnShowErrorMessage()}
					message={status.message}
				></AlertNotificationComp>
			</Snackbar>
		</>
	);
}

export default withRouter(AddOperatorComp);
