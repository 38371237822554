import * as React from "react";
import ReactDOM from "react-dom";
import { ClearCacheProvider, useClearCache } from "react-clear-cache";
import "./index.css";

import * as serviceWorker from "./serviceWorker";

import routing from "./routing/routing";

ReactDOM.render(
  <ClearCacheProvider duration={5000}>{routing}</ClearCacheProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
