import React, { Suspense, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import Snackbar from "@material-ui/core/Snackbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import LinearProgress from "@material-ui/core/LinearProgress";

import { makeStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";

import LookupInsertComp from "components/common/LookupInsertComp";
import AlertNotificationComp from "components/custom/AlertNotificationComp";

//date
import moment from "moment";

//linq
import linq from "linq";

//Api
import axios from "axios";

//Redux imports
import { useSelector, useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import { plantstatus } from "lookuptables/global";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },
  operatoraddcontainer: {
    "& .MuiPaper-root": {
      width: "100%",
      padding: 10,
      margin: 10,
    },
  },
  formcontainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-container": {
      // marginBottom: 10,
    },
  },
  updatebutton: {
    width: "100%",
  },
}));

function AddCompressorStationComp(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const [refineries, setCompressorStations] = React.useState([]);

  const citylookupvalues = useSelector(
    (state) => state.citynamesReducer.CityName
  );
  const countylookupvalues = useSelector(
    (state) => state.countynamesReducer.CountyName
  );
  const statelookupvalues = useSelector(
    (state) => state.statenamesReducer.StateName
  );
  const zipcodelookupvalues = useSelector(
    (state) => state.zipcodesReducer.Zipcode
  );
  const operatorlookupvalues = useSelector(
    (state) => state.operatorsReducer.OperatorName
  );
  const basinlookupvalues = useSelector(
    (state) => state.basinsReducer.BasinName
  );
  const [pipelinenames, setPipelineNames] = React.useState([]);

  const [statecounties, setStateCounties] = React.useState([]);

  const [selectedcity, setSelectedCity] = React.useState({});
  const [selectedcounty, setSelectedCounty] = React.useState({});
  const [selectedstate, setSelectedState] = React.useState({});
  const [selectedzipcode, setSelectedZipcode] = React.useState({});
  const [selectedbasin, setSelectedBasin] = React.useState({});
  const [selectedowner, setSelectedOwner] = React.useState({});
  const [selectedoperator, setSelectedOperator] = React.useState({});
  const [selectedStatus, setSelectedStatus] = React.useState({});
  const [selectedcompressorstationtype, setSelectedCompressorStationType] =
    React.useState({});

  const [pipelinelocationcode, setPipelineLocationCode] = React.useState("");
  const [selectedpipeline, setSelectedPipeline] = React.useState({});

  const compressorstationinitialstate = {
    CompressorStationName: "",
    Address: "",
    CityName: null,
    CityId: null,
    CountyName: null,
    CountyId: null,
    StateName: null,
    StateNameId: null,
    Zipcode: null,
    ZipcodeId: null,
    Basin: null,
    BasinId: null,
    OperatorName: null,
    OperatorId: null,
    Owner: null,
    OwnerId: null,
    Latitude: "",
    Longitude: "",
    Manufacturer: "",
    AssociationStatus: "",
    LifeCycleStatus: "",
    InstallationDate: null,
    InserviceDate: null,
    TotalPower: "",
    NumberOfEngines: "",
    NumberOfTurbines: "",
    NumberOfMotors: "",
    RegulatoryType: "",
    Notes: "",
    MaopDesign: "",
    MaximumOperatingPressure: "",
    MinimumOperatingPressure: "",
    EmissionLastReportedYear: "",
    EmissionGHGRPID: "",
    EquivalentCO2Tons: "",
    key: 0,
  };

  const [compressorstationvalues, setCompressorStationValues] = React.useState(
    compressorstationinitialstate
  );

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "error",
  });

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "error" });
  };

  const fnHandleChange = (prop) => (event) => {
    if (prop === "PipelineLocationCode") {
      setPipelineLocationCode(event.target.value);
    } else {
      setCompressorStationValues({
        ...compressorstationvalues,
        [prop]: event.target.value,
      });
    }
  };

  const fnOnChange = (newvalue, type, typeid) => {
    if (newvalue !== null && newvalue !== undefined) {
      if (type === "Owner" || type === "Operator") {
        setCompressorStationValues({
          ...compressorstationvalues,
          [type]: newvalue["OperatorName"],
          [typeid]: newvalue["OperatorId"],
        });
      } else {
        setCompressorStationValues({
          ...compressorstationvalues,
          [type]: newvalue[type],
          [typeid]: newvalue[typeid],
        });
      }

      switch (type) {
        case "CityName":
          setSelectedCity(newvalue);
          break;
        case "CountyName":
          setSelectedCounty(newvalue);
          break;
        case "StateName":
          setSelectedState(newvalue);
          setSelectedCounty({});
          var filtercounties = linq
            .from(countylookupvalues)
            .where((a) => a.StateNameId == newvalue.StateNameId)
            .toArray();
          setStateCounties(filtercounties);
          break;
        case "Zipcode":
          setSelectedZipcode(newvalue);
          break;
        case "Operator":
          setSelectedOperator(newvalue);
          break;
        case "Owner":
          setSelectedOwner(newvalue);
          break;
        case "BasinName":
          setSelectedBasin(newvalue);
          break;
        case "Status":
          setSelectedStatus(newvalue);
      }
    }
  };

  const fnInsertCompressorStationData = () => {
    var compressorstationkeys = [
      "CompressorStationName",
      "Address",
      "CityId",
      "StateNameId",
      "ZipcodeId",
      "StatusId",
      "CountyId",
      "BasinId",
      "Latitude",
      "Longitude",
      "OperatorId",
      "OwnerId",
      "Manufacturer",
      "AssociationStatus",
      "LifeCycleStatus",
      "InstallationDate",
      "InserviceDate",
      "TotalPower",
      "NumberOfEngines",
      "NumberOfTurbines",
      "NumberOfMotors",
      "RegulatoryType",
      "Notes",
      "MaopDesign",
      "MaximumOperatingPressure",
      "MinimumOperatingPressure",

      "EmissionLastReportedYear",
      "EmissionGHGRPID",
      "EquivalentCO2Tons",
    ];

    var insertvalues = [];
    compressorstationkeys.forEach((element) => {
      if (
        compressorstationvalues[element] !== undefined &&
        compressorstationvalues[element] !== null &&
        compressorstationvalues[element] !== ""
      ) {
        if (element.indexOf("Date") > -1) {
          insertvalues.push(
            element +
              " = '" +
              moment(compressorstationvalues[element]).format("YYYY-MM-DD") +
              "'"
          );
        } else {
          if (typeof compressorstationvalues[element] === "string") {
            insertvalues.push("'" + compressorstationvalues[element] + "'");
          } else {
            insertvalues.push(compressorstationvalues[element]);
          }
        }
      } else {
        insertvalues.push("NULL");
      }
    });

    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      inserttype: "Compressor Station",
      insertvalues: insertvalues.join(","),
      facname: compressorstationvalues.CompressorStationName,
      factype: "Compressor Station",
      operator:
        compressorstationvalues.Operator === null
          ? ""
          : compressorstationvalues.Operator,
      owner:
        compressorstationvalues.Owner === null
          ? ""
          : compressorstationvalues.Owner,
      latitude: compressorstationvalues.Latitude,
      longitude: compressorstationvalues.Longitude,
      pipelinelocationcode:
        pipelinelocationcode !== "" ? pipelinelocationcode : null,
      pipelineheaderid:
        selectedpipeline.PipelineHeaderId !== undefined
          ? selectedpipeline.PipelineHeaderId
          : null,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/insertdatarecord", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error while inserting compressorstation record",
            variant: "error",
          });
        } else if (response.data[0].status === "inserted") {
          setStatusBase({
            show: true,
            message: "Record inserted successfully",
            variant: "info",
          });

          window.location.reload();
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting record",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting compressorstation data",
            variant: "error",
          });
        }
      }
    );
  };

  const fetchCompressorStations = () => {
    const query = {
      columnname: "CompressorStationName",
      tablename: "CompressorStation",
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchsubjectuniquevalues", query, options).then(
      (response) => {
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "no records") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            setCompressorStations(response.data[0].data);
          }
        }
      },
      (error) => {
        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
        }
      }
    );
  };

  const fnFetchUniqueValues = () => {
    const data = {
      fetchtype: "PipelineName",
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchuniquevalues", data, options).then(
      (response) => {
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "no records") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            setPipelineNames(response.data[0].data);
          }
        }
      },
      (error) => {
        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
        }
      }
    );
  };

  const fnHandleInstallationDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setCompressorStationValues({
        ...compressorstationvalues,
        InstallationDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setCompressorStationValues({
        ...compressorstationvalues,
        InstallationDate: null,
      });
    }
  };

  const fnHandleInserviceDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setCompressorStationValues({
        ...compressorstationvalues,
        InserviceDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setCompressorStationValues({
        ...compressorstationvalues,
        InserviceDate: null,
      });
    }
  };

  useEffect(() => {
    fetchCompressorStations();
    fnFetchUniqueValues();
  }, []);

  return (
    <>
      <Grid className={classes.operatoraddcontainer} container>
        <Paper>
          <Typography variant="subtitle1">
            Add CompressorStation Data
          </Typography>
          <ValidatorForm>
            {refineries.length > 0 ? (
              <>
                <Grid
                  container
                  spacing="3"
                  direction="column"
                  className={classes.formcontainer}
                >
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <TextField
                          label="CompressorStation Name"
                          id="compressorstationnameId"
                          value={compressorstationvalues.CompressorStationName}
                          onChange={fnHandleChange("CompressorStationName")}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={
                            compressorstationvalues.CompressorStationName === ""
                          }
                        />
                      </Grid>
                      <Grid item xs="2"></Grid>
                      <Grid item xs="4">
                        <TextField
                          label="Address"
                          id="addressId"
                          value={compressorstationvalues.Address}
                          onChange={fnHandleChange("Address")}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        {/* {JSON.stringify(operatorvalues.City)} */}

                        <Autocomplete
                          size="small"
                          options={citylookupvalues}
                          getOptionLabel={(option) =>
                            option.CityName === undefined ? "" : option.CityName
                          }
                          value={selectedcity}
                          onChange={(event, newValue) => {
                            if (newValue != null) {
                              fnOnChange(newValue, "CityName", "CityId");
                            } else {
                              setSelectedCity({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select City"}
                              placeholder="City"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={statelookupvalues}
                          getOptionLabel={(option) =>
                            option.StateName === undefined
                              ? ""
                              : option.StateName
                          }
                          value={selectedstate}
                          onChange={(event, newValue) => {
                            if (newValue != null) {
                              fnOnChange(newValue, "StateName", "StateNameId");
                            } else {
                              setSelectedState({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select State"}
                              placeholder="State"
                              error={selectedstate.StateName === undefined}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={statecounties}
                          getOptionLabel={(option) =>
                            option.CountyName === undefined
                              ? ""
                              : option.CountyName
                          }
                          value={selectedcounty}
                          onChange={(event, newValue) => {
                            fnOnChange(newValue, "CountyName", "CountyId");if (newValue != null) {
                              fnOnChange(newValue, "CountyName", "CountyId");
                            } else {
                              setSelectedCounty({});
                            }
                          }}
                          disabled={
                            statecounties.length === 0 ||
                            selectedstate.StateName === undefined
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select County"}
                              placeholder="County"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={zipcodelookupvalues}
                          getOptionLabel={(option) =>
                            option.Zipcode === undefined ? "" : option.Zipcode
                          }
                          value={selectedzipcode}
                          onChange={(event, newValue) => {
                            if (newValue != null) {
                              fnOnChange(newValue, "Zipcode", "ZipcodeId");
                            } else {
                              setSelectedZipcode({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Zipcode"}
                              placeholder="Zipcode"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={basinlookupvalues}
                          getOptionLabel={(option) =>
                            option.BasinName === undefined
                              ? ""
                              : option.BasinName
                          }
                          value={selectedbasin}
                          onChange={(event, newValue) => {
                            if (newValue != null) {
                              fnOnChange(newValue, "BasinName", "BasinId");
                            } else {
                              setSelectedBasin({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Basin"}
                              placeholder="Basin"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={operatorlookupvalues}
                          getOptionLabel={(option) =>
                            option.OperatorName === undefined
                              ? ""
                              : option.OperatorName
                          }
                          value={selectedoperator}
                          onChange={(event, newValue) => {
                            if (newValue != null) {
                              fnOnChange(newValue, "Operator", "OperatorId");
                            } else {
                              setSelectedOperator({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Operator"}
                              placeholder="Operator"
                              error={
                                selectedoperator.OperatorName === undefined
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={operatorlookupvalues}
                          getOptionLabel={(option) =>
                            option.OperatorName === undefined
                              ? ""
                              : option.OperatorName
                          }
                          value={selectedowner}
                          onChange={(event, newValue) => {
                            if (newValue != null) {
                              fnOnChange(newValue, "Owner", "OwnerId");
                            } else {
                              setSelectedOwner({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Owner"}
                              placeholder="Owner"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="3"></Grid>

                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={plantstatus}
                          getOptionLabel={(option) =>
                            option.Status === undefined ? "" : option.Status
                          }
                          value={selectedStatus}
                          onChange={(event, newValue) => {
                            if (newValue != null) {
                              fnOnChange(newValue, "Status", "StatusId");
                            } else {
                              setSelectedStatus({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Status"}
                              placeholder="Status"
                              error={selectedStatus.Status === undefined}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="2">
                        <TextField
                          label="Latitude"
                          id="latitudeId"
                          onChange={fnHandleChange("Latitude")}
                          value={compressorstationvalues.Latitude}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={compressorstationvalues.Latitude === ""}
                        />
                      </Grid>
                      <Grid item xs="2">
                        <TextField
                          label="Longitude"
                          id="longitudeId"
                          onChange={fnHandleChange("Longitude")}
                          value={compressorstationvalues.Longitude}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={compressorstationvalues.Longitude === ""}
                        />
                      </Grid>
                      <Grid item xs="2">
                        <Link
                          href={
                            "https://www.google.com/maps/search/?api=1&query=" +
                            compressorstationvalues.Latitude +
                            "," +
                            compressorstationvalues.Longitude
                          }
                          to="Google Maps"
                          target="_blank"
                        >
                          Google Maps Link
                        </Link>
                      </Grid>
                      <Grid item xs="3">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            format="yyyy/MM/dd"
                            margin="normal"
                            id="installationdateId"
                            label="Installation Date"
                            variant="outlined"
                            value={compressorstationvalues.InstallationDate}
                            onChange={fnHandleInstallationDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "Installation Date",
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs="3">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            format="yyyy/MM/dd"
                            margin="normal"
                            id="inservicedateId"
                            label="Inservice Date"
                            variant="outlined"
                            value={compressorstationvalues.InserviceDate}
                            onChange={fnHandleInserviceDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "Inservice Date",
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <TextField
                          label="Manufacturer"
                          id="manufacturerId"
                          onChange={fnHandleChange("Manufacturer")}
                          value={compressorstationvalues.Manufacturer}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="AssociationStatus"
                          id="associationstatusId"
                          onChange={fnHandleChange("AssociationStatus")}
                          value={compressorstationvalues.AssociationStatus}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="LifeCycleStatus"
                          id="lifeCyclestatusId"
                          onChange={fnHandleChange("LifeCycleStatus")}
                          value={compressorstationvalues.LifeCycleStatus}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="TotalPower"
                          id="totalpowerId"
                          onChange={fnHandleChange("TotalPower")}
                          value={compressorstationvalues.TotalPower}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <TextField
                          label="NumberOfEngines"
                          id="numberofenginesId"
                          onChange={fnHandleChange("NumberOfEngines")}
                          value={compressorstationvalues.NumberOfEngines}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="NumberOfTurbines"
                          id="numberofturbinesId"
                          onChange={fnHandleChange("NumberOfTurbines")}
                          value={compressorstationvalues.NumberOfTurbines}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="NumberOfMotors"
                          id="numberofmotorsId"
                          onChange={fnHandleChange("NumberOfMotors")}
                          value={compressorstationvalues.NumberOfMotors}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="RegulatoryType"
                          id="regulatorytypeId"
                          onChange={fnHandleChange("RegulatoryType")}
                          value={compressorstationvalues.RegulatoryType}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <TextField
                          label="Notes"
                          id="notesId"
                          onChange={fnHandleChange("Notes")}
                          value={compressorstationvalues.Notes}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="MaopDesign"
                          id="maopdesignId"
                          onChange={fnHandleChange("MaopDesign")}
                          value={compressorstationvalues.MaopDesign}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="MaximumOperatingPressure"
                          id="maximumoperatingpressureId"
                          onChange={fnHandleChange("MaximumOperatingPressure")}
                          value={
                            compressorstationvalues.MaximumOperatingPressure
                          }
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="MinimumOperatingPressure"
                          id="minimumoperatingpressureId"
                          onChange={fnHandleChange("MinimumOperatingPressure")}
                          value={
                            compressorstationvalues.MinimumOperatingPressure
                          }
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <TextField
                          label="EmissionLastReportedYear"
                          id="EmissionLastReportedYearId"
                          value={
                            compressorstationvalues.EmissionLastReportedYear
                          }
                          onChange={fnHandleChange("EmissionLastReportedYear")}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="EmissionGHGRPID"
                          id="EmissionGHGRPIDId"
                          value={compressorstationvalues.EmissionGHGRPID}
                          onChange={fnHandleChange("EmissionGHGRPID")}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="EquivalentCO2Tons"
                          id="EquivalentCO2TonsId"
                          value={compressorstationvalues.EquivalentCO2Tons}
                          onChange={fnHandleChange("EquivalentCO2Tons")}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="2">
                        <TextField
                          label="PipelineLocationCode"
                          id="PipelineLocationCodeId"
                          onChange={fnHandleChange("PipelineLocationCode")}
                          value={pipelinelocationcode}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={pipelinenames}
                          value={selectedpipeline}
                          getOptionLabel={(option) =>
                            option.PipelineName === undefined
                              ? ""
                              : option.PipelineName
                          }
                          disabled={pipelinelocationcode === ""}
                          onChange={(event, newValue) => {
                            if (newValue === null) {
                              setSelectedPipeline({});
                            } else {
                              setSelectedPipeline(newValue);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Search By Pipeline Name"
                              placeholder="Pipelines"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="7"></Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Grid item>
                        <Button
                          type="button"
                          color="primary"
                          variant="contained"
                          className={classes.updatebutton}
                          disabled={
                            compressorstationvalues.Latitude === "" ||
                            compressorstationvalues.CompressorStationName ===
                              "" ||
                            compressorstationvalues.Longitude === "" ||
                            compressorstationvalues.StateName === null ||
                            compressorstationvalues.Status === null ||
                            (pipelinelocationcode !== "" &&
                              selectedpipeline.PipelineHeaderId === undefined)
                          }
                          onClick={() => fnInsertCompressorStationData()}
                        >
                          Insert CompressorStation
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </ValidatorForm>
        </Paper>
      </Grid>
      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(AddCompressorStationComp);
