import React, { Suspense, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import DateFnsUtils from '@date-io/date-fns';
import Snackbar from '@material-ui/core/Snackbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import LinearProgress from '@material-ui/core/LinearProgress';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router-dom';

import LookupInsertComp from 'components/common/LookupInsertComp';
import AlertNotificationComp from 'components/custom/AlertNotificationComp';

import MUIDataTable from 'mui-datatables';

//linq
import linq from 'linq';

//date
import moment from 'moment';

//Api
import axios from 'axios';

//Redux imports
import { useSelector, useDispatch } from 'react-redux';
import * as actionType from 'redux/actions/actionType';

import { deviceterminaltypes } from 'lookuptables/global';

const BootstrapInput = withStyles((theme) => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(1),
		},
	},
	input: {
		borderRadius: 4,
		width: '350px',
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #ced4da',
		fontSize: 16,
		padding: '10px 26px 10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		'&:focus': {
			borderRadius: 4,
			borderColor: '#80bdff',
			boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
		},
	},
}))(InputBase);

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
	},
	operatoraddcontainer: {
		'& .MuiPaper-root': {
			width: '100%',
			padding: 10,
			margin: 10,
		},
	},
	formcontainer: {
		'& .MuiFormControl-root': {
			width: '100%',
		},
		'& .MuiGrid-container': {
			// marginBottom: 10,
		},
	},
	updatebutton: {
		width: '100%',
	},
	margin: {
		margin: theme.spacing(1),
	},
}));

function AddPipelineInterconnectsComp(props) {
	const classes = useStyles();

	const dispatch = useDispatch();

	const token = useSelector((state) => state.userReducer.token);

	const [frompipelines, setFromPipelines] = React.useState([]);
	const [frompipelinesections, setFromPipelineSections] = React.useState([]);

	const [topipelines, setToPipelines] = React.useState([]);
	const [topipelinesections, setToPipelineSections] = React.useState([]);

	const [selectedfrompipelineheader, setSelectedFromPipelineHeader] = React.useState({});
	const [selectedfrompipelinesection, setSelectedFromPipelineSection] = React.useState({});

	const [selectedtopipelineheader, setSelectedToPipelineHeader] = React.useState({});
	const [selectedtopipelinesection, setSelectedToPipelineSection] = React.useState({});

	const [pipelineinterconnects, setPipelineInterconnects] = React.useState({});

	const interconnectscolumns = [
		{
			label: 'Id',
			name: 'PipelineInterconnectId',
			options: {
				filter: true,
			},
		},
		{
			name: 'InterconnectName',
			options: {
				filter: true,
			},
		},
		{
			name: 'FlowPoints',
			options: {
				filter: true,
			},
		},
		{
			name: 'FromPipelineName',
			options: {
				filter: true,
			},
		},
		{
			name: 'FromPipelineSectionName',
			options: {
				filter: true,
			},
		},
		{
			name: 'ToPipelineName',
			options: {
				filter: true,
			},
		},
		{
			name: 'ToPipelineSectionName',
			options: {
				filter: true,
			},
		},
		{
			name: 'Delete',
			options: {
				filter: true,
				sort: false,
				empty: true,
				customBodyRender: (value, tableMeta, updateValue) => {
					
					return (
						<Button
							type="button"
							color="secondary"
							size="small"
							variant="contained"
							onClick={() => fnDeletePipelineInterconnect(tableMeta.rowData[0])}
						>
							Delete
						</Button>
					);
				},
			},
		},
	];

	const options = {
		print: false,
		selectableRows: 'none',
	};

	const pipelineinterconnectinitialstate = {
		InterconnectName: '',
		FromPipelineName: null,
		FromPipelineId: null,
		FromPipelineSectionName: null,
		FromPipelineSectionId: null,
		ToPipelineName: null,
		ToPipelineId: null,
		ToPipelineSectionName: null,
		ToPipelineSectionId: null,
		FlowPoints: 'Delivery',
		key: 0,
	};

	const [pipelineinterconnectvalues, setPipelineInterconnectValues] = React.useState(
		pipelineinterconnectinitialstate
	);

	const [status, setStatusBase] = React.useState({
		show: false,
		message: '',
		variant: 'error',
	});

	const fnDeletePipelineInterconnect = (id) => {
		dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

		const data = {
			facilityid: id,
		};

		const options = {
			headers: {
				authorization: token ? `Bearer ${token}` : '',
			},
		};

		axios.post('/api/deletepipelineinterconnect', data, options).then(
			(response) => {
				dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

				if (response.data[0].status.code === undefined) {
					if (response.data[0].status === 'error') {
						setStatusBase({ show: true, message: 'Error while deleting record', variant: 'error' });
					} else if (response.data[0].status === 'success') {
						setStatusBase({
							show: true,
							message: 'Interconnect deleted.',
							variant: 'info',
						});

						fnFetchInterConnects(selectedfrompipelineheader.FromPipelineId);
					}
				} else {
					setStatusBase({
						show: true,
						message: 'Error while deleting record. Please try again.',
						variant: 'error',
					});
				}
			},
			(error) => {
				dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

				if (error.request.status === 500) {
					setStatusBase({ show: true, message: 'Time out', variant: 'error' });
					props.history.push('/login');
				} else {
					setStatusBase({
						show: true,
						message: 'Error while deleting facility',
						variant: 'error',
					});
				}
			}
		);
	};

	const fnShowErrorMessage = () => {
		setStatusBase({ show: false, message: '', variant: 'error' });
	};

	const fnHandleChange = (prop) => (event) => {
		setPipelineInterconnectValues({ ...pipelineinterconnectvalues, [prop]: event.target.value });
	};

	const fnOnChange = (newvalue, type, typeid) => {
		if (newvalue !== null && newvalue !== undefined) {
			setPipelineInterconnectValues({
				...pipelineinterconnectvalues,
				[type]: newvalue[type],
				[typeid]: newvalue[typeid],
			});

			switch (type) {
				case 'FromPipelineSectionName':
					setSelectedFromPipelineSection(newvalue);

					break;
				case 'ToPipelineSectionName':
					setSelectedToPipelineSection(newvalue);

					break;
				case 'FromPipelineName':
					setSelectedFromPipelineHeader(newvalue);
					// setSectionCounties([]);
					setSelectedFromPipelineSection({});
					fnFetchPipelineSectionData(newvalue[typeid], type);
					fnFetchInterConnects(newvalue.FromPipelineId);
					break;
				case 'ToPipelineName':
					setSelectedToPipelineHeader(newvalue);
					// setSectionCounties([]);
					setSelectedToPipelineSection({});
					fnFetchPipelineSectionData(newvalue[typeid], type);

					break;
			}
		}
	};

	const fnFetchInterConnects = (pipelineid) => {
		dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

		const data = {
			fetchtype: 'pipelineinterconnects',
			fetchquery: pipelineid,
		};

		const options = {
			headers: {
				authorization: token ? `Bearer ${token}` : '',
			},
		};

		axios.post('/api/fetchindividualdata', data, options).then(
			(response) => {
				dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

				if (response.data[0].status.code === undefined) {
					if (response.data[0].status === 'error') {
						// setStatusBase({ show: true, message: 'Error while fetching records', variant: 'error' });
					} else if (response.data[0].status == 'no records found') {
						// setStatusBase({ show: true, message: 'Pipeline section counties data not found', variant: 'info' });
					} else if (response.data[0].data.length > 0) {
						// setInterconnectsColumns(Object.keys(response.data[0].data[0]));
						setPipelineInterconnects(response.data[0].data);

						// scrollToRef(pipelinesectiondataRef);
					}
				} else {
					setStatusBase({
						show: true,
						message: 'Error while fetching pipeline section counties data. Please try again.',
						variant: 'error',
					});
				}
			},
			(error) => {
				dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

				if (error.request.status === 500) {
					setStatusBase({ show: true, message: 'Time out', variant: 'error' });
					props.history.push('/login');
				} else {
					setStatusBase({
						show: true,
						message: 'Error while fetching pipeline section data',
						variant: 'error',
					});
				}
			}
		);
	};

	const fnInsertPipelineInterconnect = () => {
		var pipelineinterconnectkeys = [
			'InterconnectName',
			'FromPipelineId',
			'FromPipelineSectionId',
			'ToPipelineId',
			'ToPipelineSectionId',
			'FlowPoints',
		];

		var insertvalues = [];
		pipelineinterconnectkeys.forEach((element) => {
			if (
				pipelineinterconnectvalues[element] !== undefined &&
				pipelineinterconnectvalues[element] !== null &&
				pipelineinterconnectvalues[element] !== ''
			) {
				if (element.indexOf('Date') > -1) {
					insertvalues.push(
						element + " = '" + moment(pipelineinterconnectvalues[element]).format('YYYY-MM-DD') + "'"
					);
				} else {
					if (typeof pipelineinterconnectvalues[element] === 'string') {
						insertvalues.push("'" + pipelineinterconnectvalues[element] + "'");
					} else {
						insertvalues.push(pipelineinterconnectvalues[element]);
					}
				}
			} else {
				insertvalues.push('NULL');
			}
		});

		dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

		const data = {
			inserttype: 'PipelineInterconnects',
			insertvalues: insertvalues.join(','),
		};

		const options = {
			headers: {
				authorization: token ? `Bearer ${token}` : '',
			},
		};

		axios.post('/api/insertdatarecord', data, options).then(
			(response) => {
				dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

				if (response.data[0].status === 'error') {
					setStatusBase({
						show: true,
						message: 'Error while inserting pipelinecounties record',
						variant: 'error',
					});
				} else if (response.data[0].status === 'inserted') {
					setStatusBase({ show: true, message: 'Record inserted successfully', variant: 'info' });
					fnFetchInterConnects(pipelineinterconnectvalues.FromPipelineId);
					// window.location.reload();
				} else {
					setStatusBase({ show: true, message: 'Error while inserting record', variant: 'error' });
				}
			},
			(error) => {
				dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

				if (error.request.status === 500) {
					setStatusBase({ show: true, message: 'Time out', variant: 'error' });
					props.history.push('/login');
				} else {
					setStatusBase({
						show: true,
						message: 'Error while inserting pipelinecounties data',
						variant: 'error',
					});
				}
			}
		);
	};

	const fnFetchPipelineSectionData = (id, type) => {
		dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

		const data = {
			fetchtype: 'pipelinesections',
			fetchquery: id,
		};

		const options = {
			headers: {
				authorization: token ? `Bearer ${token}` : '',
			},
		};

		axios.post('/api/fetchindividualdata', data, options).then(
			(response) => {
				dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

				if (response.data[0].status.code === undefined) {
					if (response.data[0].status === 'error') {
						setStatusBase({ show: true, message: 'Error while fetching records', variant: 'error' });
					} else if (response.data[0].status == 'no records found') {
						setStatusBase({ show: true, message: 'Pipeline section data not found', variant: 'info' });
					} else if (response.data[0].data.length > 0) {
						var data = [];
						if (type === 'FromPipelineName') {
							response.data[0].data.forEach((element) => {
								data.push({
									FromPipelineSectionName: element['PipelineSectionName'],
									FromPipelineSectionId: element['key'],
								});
							});

							setFromPipelineSections(data);
						} else {
							response.data[0].data.forEach((element) => {
								data.push({
									ToPipelineSectionName: element['PipelineSectionName'],
									ToPipelineSectionId: element['key'],
								});
							});
							setToPipelineSections(data);
						}

						// scrollToRef(pipelinesectiondataRef);
					}
				} else {
					setStatusBase({
						show: true,
						message: 'Error while fetching pipeline section data. Please try again.',
						variant: 'error',
					});
				}
			},
			(error) => {
				dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

				if (error.request.status === 500) {
					setStatusBase({ show: true, message: 'Time out', variant: 'error' });
					props.history.push('/login');
				} else {
					setStatusBase({
						show: true,
						message: 'Error while fetching pipeline section data',
						variant: 'error',
					});
				}
			}
		);
	};

	const fnFetchLookupValues = () => {
		const data = {
			fetchtype: 'PipelineName',
		};

		const options = {
			headers: {
				authorization: token ? `Bearer ${token}` : '',
			},
		};

		axios.post('/api/fetchuniquevalues', data, options).then(
			(response) => {
				// console.log(response.data[0]);
				if (response.data[0].status === 'error') {
				} else if (response.data[0].status === 'success') {
					if (response.data[0].data.length > 0) {
						var fromdata = [];
						var todata = [];
						response.data[0].data.forEach((element) => {
							fromdata.push({
								FromPipelineName: element['PipelineName'],
								FromPipelineId: element['PipelineHeaderId'],
							});

							todata.push({
								ToPipelineName: element['PipelineName'],
								ToPipelineId: element['PipelineHeaderId'],
							});
						});

						setFromPipelines(fromdata);
						setToPipelines(todata);
					} else {
					}
				}
			},
			(error) => {}
		);
	};

	useEffect(() => {
		// fetchTableData('PipelineSectionName', 'PipelineSection');
		fnFetchLookupValues();
	}, []);

	return (
		<>
			<Grid className={classes.operatoraddcontainer} container>
				<Paper>
					<Typography variant="subtitle1">Add PipelineInterconnect Data</Typography>
					<ValidatorForm>
						{frompipelines.length > 0 ? (
							<>
								<Grid container spacing="3" direction="column" className={classes.formcontainer}>
									<Grid item>
										<Grid
											container
											spacing="3"
											direction="row"
											justify="flex-start"
											alignItems="center"
										>
											<Grid item xs="3">
												<TextField
													label="InterconnectName"
													id="InterconnectNameId"
													onChange={fnHandleChange('InterconnectName')}
													value={pipelineinterconnectvalues.InterconnectName}
													variant="outlined"
													size="small"
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</Grid>
											<Grid item xs="3">
												<Autocomplete
													size="small"
													options={frompipelines}
													getOptionLabel={(option) =>
														option.FromPipelineName === undefined
															? ''
															: option.FromPipelineName
													}
													value={selectedfrompipelineheader}
													onChange={(event, newValue) => {
														fnOnChange(newValue, 'FromPipelineName', 'FromPipelineId');
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															variant="outlined"
															label={'Select From Pipeline'}
															placeholder="From Pipeline"
															error={
																selectedfrompipelineheader.FromPipelineName ===
																undefined
															}
														/>
													)}
												/>
											</Grid>
											<Grid item xs="3">
												<Autocomplete
													size="small"
													options={frompipelinesections}
													getOptionLabel={(option) =>
														option.FromPipelineSectionName === undefined
															? ''
															: option.FromPipelineSectionName
													}
													value={selectedfrompipelinesection}
													onChange={(event, newValue) => {
														fnOnChange(
															newValue,
															'FromPipelineSectionName',
															'FromPipelineSectionId'
														);
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															variant="outlined"
															label={'Select From Pipeline Section'}
															placeholder="From Pipeline Section"
															error={
																selectedfrompipelinesection.FromPipelineSectionName ===
																undefined
															}
														/>
													)}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										<Grid
											container
											spacing="3"
											direction="row"
											justify="flex-start"
											alignItems="center"
										>
											<Grid item xs="3">
												<Autocomplete
													size="small"
													options={topipelines}
													getOptionLabel={(option) =>
														option.ToPipelineName === undefined ? '' : option.ToPipelineName
													}
													value={selectedtopipelineheader}
													onChange={(event, newValue) => {
														fnOnChange(newValue, 'ToPipelineName', 'ToPipelineId');
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															variant="outlined"
															label={'Select To Pipeline'}
															placeholder="To Pipeline"
															error={
																selectedtopipelineheader.ToPipelineName === undefined
															}
														/>
													)}
												/>
											</Grid>
											<Grid item xs="3">
												<Autocomplete
													size="small"
													options={topipelinesections}
													getOptionLabel={(option) =>
														option.ToPipelineSectionName === undefined
															? ''
															: option.ToPipelineSectionName
													}
													value={selectedtopipelinesection}
													onChange={(event, newValue) => {
														fnOnChange(
															newValue,
															'ToPipelineSectionName',
															'ToPipelineSectionId'
														);
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															variant="outlined"
															label={'Select To Pipeline Section'}
															placeholder="To Pipeline Section"
															error={
																selectedtopipelinesection.ToPipelineSectionName ===
																undefined
															}
														/>
													)}
												/>
											</Grid>

											<Grid item xs="3">
												<FormControl>
													<Select
														labelId="demo-customized-select-label"
														id="demo-customized-select"
														value={pipelineinterconnectvalues.FlowPoints}
														onChange={fnHandleChange('FlowPoints')}
														input={<BootstrapInput />}
													>
														<MenuItem value={''} disabled>
															Select Flow Point
														</MenuItem>
														<MenuItem value={'Delivery'}>Delivery</MenuItem>
														<MenuItem value={'Receiver'}>Receiver</MenuItem>
														<MenuItem value={'Both'}>Both</MenuItem>
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										<Grid container direction="row" justify="flex-end" alignItems="center">
											<Grid item>
												<Button
													type="button"
													color="primary"
													variant="contained"
													className={classes.updatebutton}
													disabled={
														pipelineinterconnectvalues.InterconnectName === '' ||
														pipelineinterconnectvalues.FromPipelineName === null ||
														pipelineinterconnectvalues.FromPipelineSectionName === '' ||
														pipelineinterconnectvalues.ToPipelineName === null ||
														pipelineinterconnectvalues.ToPipelineSectionName === ''
													}
													onClick={() => fnInsertPipelineInterconnect()}
												>
													Insert PipelineInterconnect
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</>
						) : null}
					</ValidatorForm>
				</Paper>

				<Grid container>
					<Grid xs={12} style={{ maxWidth: '98.7%' }} item>
						{pipelineinterconnects.length > 0 ? (
							<>
								<MUIDataTable
									title={selectedfrompipelineheader.FromPipelineName + ' Interconnects Data'}
									data={pipelineinterconnects}
									columns={interconnectscolumns}
									options={options}
								/>
							</>
						) : null}
					</Grid>
				</Grid>
			</Grid>
			<Snackbar
				autoHideDuration={6000}
				open={status.show}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={() => fnShowErrorMessage()}
			>
				<AlertNotificationComp
					variant={status.variant}
					onClose={() => fnShowErrorMessage()}
					message={status.message}
				></AlertNotificationComp>
			</Snackbar>
		</>
	);
}

export default withRouter(AddPipelineInterconnectsComp);
