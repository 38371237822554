import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const useStyles = makeStyles((theme) => ({
	fullheight: {
		height: '100%',
	},
}));

export default function DDGroupButtonComp(props) {
	const classes = useStyles();

	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);

	const handleMenuItemClick = (event, index) => {
		props.fnDownloadGeoJSON(props.param, event.currentTarget.textContent);
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	return (
		<>
			<Button
				ref={anchorRef}
				color="primary"
				size="small"
				aria-controls={open ? 'split-button-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-label="select merge strategy"
				aria-haspopup="menu"
				onClick={handleToggle}
			>
				{props.title}
			</Button>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				style={{ zIndex: 1 }}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: 'center top',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu">
									<MenuItem key="overallfacilities" onClick={(event) => handleMenuItemClick(event)}>
										Overall Facilities
									</MenuItem>
									<MenuItem key="pipelineassemblies" onClick={(event) => handleMenuItemClick(event)}>
										Pipeline Assembly
									</MenuItem>
									<MenuItem key="wells" onClick={(event) => handleMenuItemClick(event)}>
										Wells_Louisiana
									</MenuItem>
									<MenuItem key="wells" onClick={(event) => handleMenuItemClick(event)}>
										Wells_Utah
									</MenuItem>
									<MenuItem key="wells" onClick={(event) => handleMenuItemClick(event)}>
										Wells_Wyoming
									</MenuItem>
									{/* <MenuItem key="gasstorage" onClick={(event) => handleMenuItemClick(event)}>
										Gas Storage
									</MenuItem>
									<MenuItem key="gasplant" onClick={(event) => handleMenuItemClick(event)}>
										Gas Plant
									</MenuItem>
									<MenuItem key="terminal" onClick={(event) => handleMenuItemClick(event)}>
										Terminal
									</MenuItem>
									<MenuItem key="refinery" onClick={(event) => handleMenuItemClick(event)}>
										Refinery
									</MenuItem>
									<MenuItem key="liquefaction" onClick={(event) => handleMenuItemClick(event)}>
										Liquefaction
									</MenuItem>
									<MenuItem key="compressorstation" onClick={(event) => handleMenuItemClick(event)}>
										Compressor Station
									</MenuItem>
									<MenuItem key="pumpingstation" onClick={(event) => handleMenuItemClick(event)}>
										Pumping Station
									</MenuItem> */}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
}
