import * as actionType from 'redux/actions/actionType';

let initialState = {
	CommodityHandled: [],
};

function commodityhandledReducer(state = initialState, action) {
	switch (action.type) {
		case actionType.ADD_COMMODITYHANDLED:
			state.CommodityHandled = action.payload.CommodityHandled;
			return state;
		default:
			return state;
	}
}

export default commodityhandledReducer;
