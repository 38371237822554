import * as actionType from 'redux/actions/actionType';

let initialState = {
	OperatorName: [],
};

function operatorsReducer(state = initialState, action) {
	switch (action.type) {
		case actionType.ADD_OPERATORS:
			state.OperatorName = action.payload.OperatorName;
			// console.log(action.payload.OperatorName);
			return state;

		default:
			return state;
	}
}

export default operatorsReducer;
