import React, { useEffect, useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import AlertNotificationComp from "components/custom/AlertNotificationComp";

import { customizedDateFormat } from "constants/utils";

import { plantstatus } from "lookuptables/global";

//date
import moment from "moment";

//linq
import linq from "linq";

//Redux imports
import { useSelector } from "react-redux";

//Api
import axios from "axios";

//Redux imports
import { useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import { withRouter } from "react-router-dom";

import { regulatorytypes,lengthmilestype } from "lookuptables/global";

const useStyles = makeStyles((theme) => ({
  operatordatacontainer: {
    margin: 10,
    width: "100%",
    "& .MuiPaper-root": {
      width: "100%",
    },
  },
  formcontainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-container": {
      marginBottom: 3.5,
    },
  },

  operatorlist: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    maxHeight: 700,
    minHeight: 700,
  },
  operatordata: {
    padding: 10,
    height: 700,
    maxHeight: 700,
  },
  updatebutton: {
    width: "200px",
  },
}));

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

function PipelineUpdateComp(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const operatorlookupvalues = useSelector(
    (state) => state.operatorsReducer.OperatorName
  );
  const commodityhandledlookupvalues = useSelector(
    (state) => state.commodityhandledReducer.CommodityHandled
  );

  const pipelinedataRef = useRef(null);

  const [selectedindex, setSelectedIndex] = React.useState("");

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "error",
  });

  const [selectedowner, setSelectedOwner] = React.useState({});
  const [selectedoperator, setSelectedOperator] = React.useState({});
  const [regulatorytype, setRegulatoryType] = React.useState({});
  const [selectedcommodityhandled, setSelectedCommodityHandled] =
    React.useState({});

  const pipelineinitialstate = {
    PipelineName: "",
    AssetType: "",
    IsConnected: 0,
    RegulatoryType: null,
    RegulatoryTypeId: null,
    PipesurfaceArea: "",
    InstallationDate: null,
    InserviceDate: null,
    InstallationMethod: "",
    OperatorName: null,
    OperatorId: null,
    Owner: null,
    OwnerId: null,
    PipelineCapacityBPD: "",
    PipelineCapacityBCFD: "",
    NominalDiameterInch: "",
    ReportedLengthMiles: "",
    LengthMilesType:"",
    Manufacturer: "",
    CommodityHandled: null,
    CommodityHandledId: null,
    DateRetired: null,
    DesignClass: "",
    PressureUnits: "",
    PipeSurfaceAreaUnits: "",
    PipeVolumeUnits: "",
    WallThickness: "",
    TSP_FERC_CID: "",
    key: 0,
  };

  const [pipelinevalues, setPipelineValues] = React.useState({});

  const [pipelinedata, setPipelineData] = React.useState([]);

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "info" });
  };

  const fnHandleChange = (prop) => (event) => {
    setPipelineValues({ ...pipelinevalues, [prop]: event.target.value });
  };

  const fnHandleCheckChange = (prop) => (event) => {
    setPipelineValues({
      ...pipelinevalues,
      IsConnected: event.target.checked ? 1 : 0,
    });
  };

  const fnOnChange = (newvalue, type, typeid) => {
    if (newvalue !== null && newvalue !== undefined) {
      if(type === "LengthMilesType"){
        setPipelineValues({
          ...pipelinevalues,
          LengthMilesType: newvalue
        });
      } else if (type === "Owner" || type === "Operator") {
        setPipelineValues({
          ...pipelinevalues,
          [type]: newvalue["OperatorName"],
          [typeid]: newvalue["OperatorId"],
        });
      } else {
        setPipelineValues({
          ...pipelinevalues,
          [type]: newvalue[type],
          [typeid]: newvalue[typeid],
        });
      }

      switch (type) {
        case "Operator":
          setSelectedOperator(newvalue);
          break;
        case "Owner":
          setSelectedOwner(newvalue);
          break;

        case "CommodityHandled":
          setSelectedCommodityHandled(newvalue);
          break;
        case "RegulatoryType":
          setRegulatoryType(newvalue);
          break;
      }
    }
  };

  const fnFetchPipelineData = () => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      fetchtype: "pipelines",
      fetchquery: props.searchpipelinequery,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchindividualdata", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while fetching records",
              variant: "error",
            });
          } else if (response.data[0].status == "no records found") {
            setStatusBase({
              show: true,
              message: "Pipeline data not found",
              variant: "info",
            });
          } else if (response.data[0].data.length > 0) {
            setPipelineData(response.data[0].data);

            scrollToRef(pipelinedataRef);
          }
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching pipeline data. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching pipeline data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnLoadPipelineData = (id) => {
    // setSelectedIndex(index);

    fnResetStateValues();

    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      fetchtype: "pipelineheader",
      fetchid: id,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchsinglerecord", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while fetching record",
              variant: "error",
            });
          } else if (response.data[0].status == "no record found") {
            setStatusBase({
              show: true,
              message: "Operator data not found",
              variant: "info",
            });
          } else if (response.data[0].data !== undefined) {
            var resultobj = JSON.parse(JSON.stringify(pipelineinitialstate));

            Object.keys(response.data[0].data).forEach((element) => {
              if (
                response.data[0].data[element] !== null &&
                response.data[0].data[element] !== undefined
              ) {
                if (element === "OperatorName") {
                  var selectedvalue = linq
                    .from(operatorlookupvalues)
                    .where(
                      (a) => a.OperatorName == response.data[0].data[element]
                    )
                    .toArray();

                  setSelectedOperator({
                    OperatorName: selectedvalue[0].OperatorName,
                    OperatorId: selectedvalue[0].OperatorId,
                  });

                  resultobj.OperatorId = selectedvalue[0].OperatorId;
                }

                if (element === "Owner") {
                  var selectedvalue = linq
                    .from(operatorlookupvalues)
                    .where(
                      (a) => a.OperatorName == response.data[0].data[element]
                    )
                    .toArray();

                  setSelectedOwner({
                    OperatorName: selectedvalue[0].OperatorName,
                    OperatorId: selectedvalue[0].OperatorId,
                  });

                  resultobj.OwnerId = selectedvalue[0].OperatorId;
                }

                if (element === "RegulatoryType") {
                  var selectedvalue = linq
                    .from(regulatorytypes)
                    .where(
                      (a) => a.RegulatoryType == response.data[0].data[element]
                    )
                    .toArray();

                  setRegulatoryType({
                    RegulatoryType: selectedvalue[0].RegulatoryType,
                    RegulatoryTypeId: selectedvalue[0].RegulatoryTypeId,
                  });

                  resultobj.RegulatoryTypeId =
                    selectedvalue[0].RegulatoryTypeId;
                }

                if (element === "CommodityHandled") {
                  var selectedvalue = linq
                    .from(commodityhandledlookupvalues)
                    .where(
                      (a) =>
                        a.CommodityHandled == response.data[0].data[element]
                    )
                    .toArray();

                  setSelectedCommodityHandled({
                    CommodityHandled: selectedvalue[0].CommodityHandled,
                    CommodityHandledId: selectedvalue[0].CommodityHandledId,
                  });

                  resultobj.CommodityHandledId =
                    selectedvalue[0].CommodityHandledId;
                }

                if (element === "IsConnected") {
                  resultobj.IsConnected =
                    response.data[0].data[element].data[0];
                } else {
                  resultobj[element] = response.data[0].data[element];
                }
              }
            });

            setPipelineValues(resultobj);
          }
        } else {
          setStatusBase({
            show: true,
            message:
              "Error while fetching rerminal header data. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching pipeline data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnResetStateValues = () => {
    // let cloneobj = JSON.parse(JSON.stringify(operatorinitialstate));

    // setOperatorValues(cloneobj);

    setSelectedCommodityHandled({});

    setSelectedOperator({});
    setSelectedOwner({});
  };

  const fnUpdatePipelineData = () => {
    var pipelinekeys = [
      "AssetType",
      "IsConnected",
      "RegulatoryTypeId",
      "PipesurfaceArea",
      "InstallationDate",
      "InserviceDate",
      "InstallationMethod",
      "OperatorId",
      "OwnerId",
      "PipelineCapacityBPD",
      "PipelineCapacityBCFD",
      "NominalDiameterInch",
      "ReportedLengthMiles",
      "LengthMilesType",
      "Manufacturer",
      "DateRetired",
      "DesignClass",
      "PressureUnits",
      "PipeSurfaceAreaUnits",
      "PipeVolumeUnits",
      "WallThickness",
      "TSP_FERC_CID",
    ];

    var updatevalues = [];
    pipelinekeys.forEach((element) => {
      if (
        pipelinevalues[element] !== undefined &&
        pipelinevalues[element] !== null &&
        pipelinevalues[element] !== ""
      ) {
        if (element.indexOf("Date") > -1) {
          updatevalues.push(
            element +
              " = '" +
              moment(pipelinevalues[element]).format("YYYY-MM-DD") +
              "'"
          );
        } else {
          if (typeof pipelinevalues[element] === "string") {
            updatevalues.push(element + " = '" + pipelinevalues[element] + "'");
          } else {
            updatevalues.push(element + " = " + pipelinevalues[element]);
          }
        }
      } else {
        updatevalues.push(element + " = null");
      }
    });

    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      tablename: "Pipeline",
      query: updatevalues.join(","),
      key: pipelinevalues.key,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/updateheaderdata", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while updating",
              variant: "error",
            });
          } else if (response.data[0].status === "success") {
            setStatusBase({
              show: true,
              message: "Data updated successfully",
              variant: "info",
            });
          }
        } else {
          setStatusBase({
            show: true,
            message: "Error while updating pipeline data. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while updating pipeline data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnHandleInstallationDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setPipelineValues({
        ...pipelinevalues,
        InstallationDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setPipelineValues({ ...pipelinevalues, InstallationDate: null });
    }
  };

  const fnHandleInserviceDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setPipelineValues({
        ...pipelinevalues,
        InserviceDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setPipelineValues({ ...pipelinevalues, InserviceDate: null });
    }
  };

  const fnHandleDateRetiredChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setPipelineValues({
        ...pipelinevalues,
        DateRetired: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setPipelineValues({ ...pipelinevalues, DateRetired: null });
    }
  };

  useEffect(() => {
    if (props.id > 0) {
      fnLoadPipelineData(props.id);
    }
  }, [props.id]);

  return (
    <>
      <Grid className={classes.operatordatacontainer} container>
        <Typography variant="title">Pipeline Data</Typography>
        {/* {JSON.stringify(gasplantvalues)} */}
        {pipelinevalues.key > 0 ? (
          <Grid
            container
            spacing="3"
            direction="column"
            className={classes.formcontainer}
          >
            <Grid item>
              <Grid
                container
                spacing="3"
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs="3">
                  <TextField
                    label="Pipeline Name"
                    id="pipelinenameId"
                    value={pipelinevalues.PipelineName}
                    onChange={fnHandleChange("PipelineName")}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // error={pipelinevalues.PipelineName === ''}
                    disabled
                  />
                </Grid>
                <Grid item xs="3">
                  <Autocomplete
                    size="small"
                    options={operatorlookupvalues}
                    getOptionLabel={(option) =>
                      option.OperatorName === undefined
                        ? ""
                        : option.OperatorName
                    }
                    value={selectedoperator}
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        fnOnChange(newValue, "Operator", "OperatorId");
                      } else {
                        setSelectedOperator({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select Operator"}
                        placeholder="Operator"
                        error={selectedoperator.OperatorName === undefined}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs="3">
                  <Autocomplete
                    size="small"
                    options={operatorlookupvalues}
                    getOptionLabel={(option) =>
                      option.OperatorName === undefined
                        ? ""
                        : option.OperatorName
                    }
                    value={selectedowner}
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        fnOnChange(newValue, "Owner", "OwnerId");
                      } else {
                        setSelectedOwner({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select Owner"}
                        placeholder="Owner"
                        error={selectedowner.OperatorName === undefined}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs="3">
                  {/* {JSON.stringify(operatorvalues.City)} */}
                  {/* <Autocomplete
										size="small"
										options={commodityhandledlookupvalues}
										getOptionLabel={(option) =>
											option.CommodityHandled === undefined ? '' : option.CommodityHandled
										}
										value={selectedcommodityhandled}
										onChange={(event, newValue) => {
											fnOnChange(newValue, 'CommodityHandled', 'CommodityHandledId');
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"
												label={'Select CommodityHandled'}
												placeholder="CommodityHandled"
												error={selectedcommodityhandled.CommodityHandled === undefined}
											/>
										)}
									/> */}
                  <TextField
                    label="CommodityHandled"
                    id="CommodityHandledId"
                    value={pipelinevalues.CommodityHandled}
                    onChange={fnHandleChange("CommodityHandled")}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // error={pipelinevalues.PipelineName === ''}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing="3"
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs="3">
                  <TextField
                    label="AssetType"
                    id="assettypeId"
                    value={pipelinevalues.AssetType}
                    onChange={fnHandleChange("AssetType")}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinevalues.AssetType === ""}
                  />
                </Grid>

                <Grid item xs="3">
                  <TextField
                    label="InstallationMethod"
                    id="installationmethodId"
                    value={pipelinevalues.InstallationMethod}
                    onChange={fnHandleChange("InstallationMethod")}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinevalues.InstallationMethod === ""}
                  />
                </Grid>
                <Grid item xs="3">
                  {/* <TextField
										label="RegulatoryType"
										id="regulatorytypeId"
										value={pipelinevalues.RegulatoryType}
										onChange={fnHandleChange('RegulatoryType')}
										variant="outlined"
										size="small"
										InputLabelProps={{
											shrink: true,
										}}
										error={pipelinevalues.RegulatoryType === ''}
									/> */}
                  <Autocomplete
                    size="small"
                    options={regulatorytypes}
                    getOptionLabel={(option) =>
                      option.RegulatoryType === undefined
                        ? ""
                        : option.RegulatoryType
                    }
                    value={regulatorytype}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        fnOnChange(
                          newValue,
                          "RegulatoryType",
                          "RegulatoryTypeId"
                        );
                      } else {
                        setRegulatoryType({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select RegulatoryType"}
                        placeholder="RegulatoryType"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing="3"
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs="3">
                  <TextField
                    label="PipesurfaceArea"
                    id="pipesurfaceareaId"
                    value={pipelinevalues.PipesurfaceArea}
                    onChange={fnHandleChange("PipesurfaceArea")}
                    variant="outlined"
                    type="number"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinevalues.PipesurfaceArea === ""}
                  />
                </Grid>
                <Grid item xs="3">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      format="yyyy/MM/dd"
                      margin="normal"
                      id="installationdateId"
                      label="Installation Date"
                      variant="outlined"
                      value={pipelinevalues.InstallationDate}
                      onChange={fnHandleInstallationDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "Installation Date",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={pipelinevalues.InstallationDate === undefined}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs="3">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      format="yyyy/MM/dd"
                      margin="normal"
                      id="inservicedateId"
                      label="Inservice Date"
                      variant="outlined"
                      value={pipelinevalues.InserviceDate}
                      onChange={fnHandleInserviceDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "Inservice Date",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={pipelinevalues.InserviceDate === undefined}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs="3">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          pipelinevalues.IsConnected === 0 ? false : true
                        }
                        onChange={fnHandleCheckChange("IsConnected")}
                        name="IsConnected"
                        color="primary"
                      />
                    }
                    label="IsConnected"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                spacing="3"
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                
                <Grid item xs="3">
                  <TextField
                    label="NominalDiameterInch"
                    id="NominalDiameterInchId"
                    value={pipelinevalues.NominalDiameterInch}
                    onChange={fnHandleChange("NominalDiameterInch")}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinevalues.NominalDiameterInch === ""}
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="engthMiles"
                    id="LengthMilesId"
                    value={pipelinevalues.ReportedLengthMiles}
                    onChange={fnHandleChange("ReportedLengthMiles")}
                    variant="outlined"
                    type="number"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinevalues.ReportedLengthMiles === ""}
                  />
                </Grid>
                <Grid item xs="3">
                  
                <Autocomplete
								size="small"
								options={lengthmilestype}
								getOptionLabel={(option) => option}
								value={pipelinevalues.LengthMilesType}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    fnOnChange(
                      newValue,
                      "LengthMilesType",
                      "LengthMilesTypeId"
                    );
                  } 
                }}

								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										label={'Select LengthMilesType'}
										placeholder="Select LengthMilesType"

									/>
								)}
							/>
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="Manufacturer"
                    id="manufacturerId"
                    value={pipelinevalues.Manufacturer}
                    onChange={fnHandleChange("Manufacturer")}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinevalues.Manufacturer === ""}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing="3"
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs="3">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      format="yyyy/MM/dd"
                      margin="normal"
                      id="dateretiredId"
                      label="DateRetired"
                      variant="outlined"
                      value={pipelinevalues.DateRetired}
                      onChange={fnHandleDateRetiredChange}
                      KeyboardButtonProps={{
                        "aria-label": "DateRetired",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={pipelinevalues.DateRetired === undefined}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="DesignClass"
                    id="designclassId"
                    value={pipelinevalues.DesignClass}
                    onChange={fnHandleChange("DesignClass")}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinevalues.DesignClass === ""}
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="PressureUnits"
                    id="pressureunitsId"
                    value={pipelinevalues.PressureUnits}
                    onChange={fnHandleChange("PressureUnits")}
                    variant="outlined"
                    type="number"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinevalues.PressureUnits === ""}
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="LengthUnits"
                    id="lengthunitsId"
                    value={pipelinevalues.LengthUnits}
                    onChange={fnHandleChange("LengthUnits")}
                    variant="outlined"
                    type="number"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinevalues.LengthUnits === ""}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing="3"
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs="3"></Grid>
                <Grid item xs="3">
                  <TextField
                    label="PipeSurfaceAreaUnits"
                    id="pipesurfaceareaunitsId"
                    value={pipelinevalues.PipeSurfaceAreaUnits}
                    onChange={fnHandleChange("PipeSurfaceAreaUnits")}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinevalues.PipeSurfaceAreaUnits === ""}
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="PipeVolumeUnits"
                    id="pipevolumeunitsId"
                    value={pipelinevalues.PipeVolumeUnits}
                    onChange={fnHandleChange("PipeVolumeUnits")}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinevalues.PipeVolumeUnits === ""}
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="WallThickness"
                    id="wallthicknessId"
                    value={pipelinevalues.WallThickness}
                    onChange={fnHandleChange("WallThickness")}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinevalues.WallThickness === ""}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing="3"
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs="3">
                  <TextField
                    label="TSP_FERC_CID"
                    id="TSP_FERC_CIDId"
                    onChange={fnHandleChange("TSP_FERC_CID")}
                    value={pipelinevalues.TSP_FERC_CID}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinevalues.TSP_FERC_CID === ""}
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="PipelineCapacityBPD"
                    id="PipelineCapacityBPD"
                    onChange={fnHandleChange("PipelineCapacityBPD")}
                    value={pipelinevalues.PipelineCapacityBPD}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={
                      pipelinevalues.CommodityHandled === "Natural Gas" ||
                      pipelinevalues.CommodityHandled === "LNG" ||
                      pipelinevalues.CommodityHandled === "LPG"
                    }
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="PipelineCapacityBCFD"
                    id="PipelineCapacityBCFDId"
                    onChange={fnHandleChange("PipelineCapacityBCFD")}
                    value={pipelinevalues.PipelineCapacityBCFD}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={
                      pipelinevalues.CommodityHandled === "Crude Oil" ||
                      pipelinevalues.CommodityHandled === "Refined Products" ||
                      pipelinevalues.CommodityHandled === "Chemicals"
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    className={classes.updatebutton}
                    onClick={() => fnUpdatePipelineData()}
                  >
                    Update Pipeline
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>

      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(PipelineUpdateComp);
