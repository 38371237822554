import * as actionType from 'redux/actions/actionType';

let initialState = {
	searchvalue: '',
	searchtype: [],
	searchquery: {},
};

function searchReducer(state = initialState, action) {
	switch (action.type) {
		case actionType.ADD_SEARCHDETAILS:
			state.searchtype = action.payload.searchtype;
			state.searchvalue = action.payload.searchvalue;
			state.searchquery = action.payload.searchquery;
			return state;

		default:
			return state;
	}
}

export default searchReducer;
