import { Theme } from '@material-ui/core';
import { naveentheme } from './globaltheme';

export function getThemeByName(theme) {
	return themeMap[theme];
}

const themeMap = {
	naveentheme: naveentheme,
};
