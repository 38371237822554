import React, { Suspense, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import Snackbar from "@material-ui/core/Snackbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import LinearProgress from "@material-ui/core/LinearProgress";

import { makeStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";

import LookupInsertComp from "components/common/LookupInsertComp";
import AlertNotificationComp from "components/custom/AlertNotificationComp";

//date
import moment from "moment";

//Api
import axios from "axios";

//Redux imports
import { useSelector, useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import { plantstatus } from "lookuptables/global";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },
  operatoraddcontainer: {
    "& .MuiPaper-root": {
      width: "100%",
      padding: 10,
      margin: 10,
    },
  },
  formcontainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-container": {
      // marginBottom: 10,
    },
  },
  updatebutton: {
    width: "100%",
  },
}));

function AddCompressorStationCostComp(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const [cstations, setCStations] = React.useState([]);
  const [costtypes, setCostTypes] = React.useState([]);
  const [costunits, setCostUnits] = React.useState([]);
  const [currency, setCurrency] = React.useState([]);

  const [selectedcstation, setSelectedCStation] = React.useState({});
  const [selectedcostunit, setSelectedCostUnit] = React.useState({});
  const [selectedcosttype, setSelectedCostType] = React.useState({});
  const [selectedcurrency, setSelectedCurrency] = React.useState({});

  const costinitialstate = {
    CompressorStationId: null,
    CompressorStationName: null,
    CostUnit: null,
    CostUnitId: null,
    CostType: null,
    CostTypeId: null,
    Currency: null,
    CurrencyId: null,
    FinalCost: "",
    EstimatedCost: "",
    ReportedDate: null,
    key: 0,
  };

  const [costvalues, setCostValues] = React.useState(costinitialstate);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "error",
  });

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "error" });
  };

  const fnHandleChange = (prop) => (event) => {
    setCostValues({ ...costvalues, [prop]: event.target.value });
  };

  const fnHandleReportedDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setCostValues({
        ...costvalues,
        ReportedDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setCostValues({ ...costvalues, ReportedDate: null });
    }
  };

  const fnOnChange = (newvalue, type, typeid) => {
    if (newvalue !== null && newvalue !== undefined) {
      setCostValues({
        ...costvalues,
        [type]: newvalue[type],
        [typeid]: newvalue[typeid],
      });

      switch (type) {
        case "CompressorStationName":
          setSelectedCStation(newvalue);
          break;

        case "CostUnit":
          setSelectedCostUnit(newvalue);
          break;
        case "CostType":
          setSelectedCostType(newvalue);
          break;
        case "Currency":
          setSelectedCurrency(newvalue);
          break;
      }
    }
  };

  const fnInsertTerminalCostData = () => {
    var costkeys = [
      "CompressorStationId",
      "CostUnitId",
      "CurrencyId",
      "CostTypeId",
      "ReportedDate",
      "FinalCost",
      "EstimatedCost",
    ];

    var insertvalues = [];
    costkeys.forEach((element) => {
      if (
        costvalues[element] !== undefined &&
        costvalues[element] !== null &&
        costvalues[element] !== ""
      ) {
        if (typeof costvalues[element] === "string") {
          insertvalues.push("'" + costvalues[element] + "'");
        } else {
          insertvalues.push(costvalues[element]);
        }
      } else {
        insertvalues.push("NULL");
      }
    });

    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      inserttype: "CompressorStationCost",
      insertvalues: insertvalues.join(","),
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/insertdatarecord", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error while inserting compressor station cost record",
            variant: "error",
          });
        } else if (response.data[0].status === "inserted") {
          setStatusBase({
            show: true,
            message: "Record inserted successfully",
            variant: "info",
          });

          window.location.reload();
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting record",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting compressor station cost",
            variant: "error",
          });
        }
      }
    );
  };

  const fnFetchLookupValues = (fetchtype) => {
    const data = {
      fetchtype: fetchtype,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchuniquevalues", data, options).then(
      (response) => {
        // console.log(response.data[0]);
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            var data = [];

            if (fetchtype === "CompressorStationName") {
              response.data[0].data.forEach((element) => {
                data.push({
                  CompressorStationName: element["CompressorStationName"],
                  CompressorStationId: element["CompressorStationId"],
                });
              });

              setCStations(data);
            }

            if (fetchtype === "CostType") {
              response.data[0].data.forEach((element) => {
                data.push({
                  CostType: element["CostType"],
                  CostTypeId: element["CostTypeId"],
                });
              });

              setCostTypes(data);
            }

            if (fetchtype === "CostUnit") {
              response.data[0].data.forEach((element) => {
                data.push({
                  CostUnit: element["CostUnit"],
                  CostUnitId: element["CostUnitId"],
                });
              });

              setCostUnits(data);
            }

            if (fetchtype === "Currency") {
              response.data[0].data.forEach((element) => {
                data.push({
                  Currency: element["Currency"],
                  CurrencyId: element["CurrencyId"],
                });
              });

              setCurrency(data);
            }
          } else {
          }
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    fnFetchLookupValues("CompressorStationName");
    fnFetchLookupValues("CostUnit");
    fnFetchLookupValues("CostType");
    fnFetchLookupValues("Currency");
  }, []);

  return (
    <>
      <Grid className={classes.operatoraddcontainer} container>
        <Paper>
          <Typography variant="subtitle1">
            Add Compressor Station Cost Data
          </Typography>
          <ValidatorForm>
            {cstations.length > 0 ? (
              <>
                <Grid
                  container
                  spacing="3"
                  direction="column"
                  className={classes.formcontainer}
                >
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={cstations}
                          getOptionLabel={(option) =>
                            option.CompressorStationName === undefined
                              ? ""
                              : option.CompressorStationName
                          }
                          value={selectedcstation}
                          onChange={(event, newValue) => {
                            if (newValue != null) {
                              fnOnChange(
                                newValue,
                                "CompressorStationName",
                                "CompressorStationId"
                              );
                            } else {
                              setSelectedCStation({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Compressor Station"}
                              placeholder="Compressor Station"
                              error={
                                selectedcstation.CompressorStationId ===
                                undefined
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="2">
                        <TextField
                          label="Final Cost"
                          id="finalcostId"
                          onChange={fnHandleChange("FinalCost")}
                          value={costvalues.FinalCost}
                          variant="outlined"
                          size="small"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="2">
                        <TextField
                          label="Estimated Cost"
                          id="estimatedcostId"
                          onChange={fnHandleChange("EstimatedCost")}
                          value={costvalues.EstimatedCost}
                          variant="outlined"
                          size="small"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="2">
                        <Autocomplete
                          size="small"
                          options={costunits}
                          getOptionLabel={(option) =>
                            option.CostUnit === undefined ? "" : option.CostUnit
                          }
                          value={selectedcostunit}
                          onChange={(event, newValue) => {
                            if (newValue != null) {
                              fnOnChange(newValue, "CostUnit", "CostUnitId");
                            } else {
                              setSelectedCostUnit({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Cost Unit"}
                              placeholder="CostUnit"
                              error={selectedcostunit.CostUnit === undefined}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="2">
                        <Autocomplete
                          size="small"
                          options={costtypes}
                          getOptionLabel={(option) =>
                            option.CostType === undefined ? "" : option.CostType
                          }
                          value={selectedcosttype}
                          onChange={(event, newValue) => {
                            if (newValue != null) {
                              fnOnChange(newValue, "CostType", "CostTypeId");
                            } else {
                              setSelectedCostType({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Cost Type"}
                              placeholder="CostType"
                              error={selectedcosttype.CostType === undefined}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={currency}
                          getOptionLabel={(option) =>
                            option.Currency === undefined ? "" : option.Currency
                          }
                          value={selectedcurrency}
                          onChange={(event, newValue) => {
                            if (newValue != null) {
                              fnOnChange(newValue, "Currency", "CurrencyId");
                            } else {
                              setSelectedCurrency({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Currency"}
                              placeholder="Currency"
                              error={selectedcurrency.Currency === undefined}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs="3">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            format="yyyy/MM/dd"
                            margin="normal"
                            id="reporteddateId"
                            label="Reported Date"
                            variant="outlined"
                            value={costvalues.ReportedDate}
                            onChange={fnHandleReportedDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "Reported Date",
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs="3"></Grid>
                      <Grid item xs="3"></Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Grid item>
                        <Button
                          type="button"
                          color="primary"
                          variant="contained"
                          className={classes.updatebutton}
                          disabled={costvalues.TerminalName === ""}
                          onClick={() => fnInsertTerminalCostData()}
                        >
                          Insert Terminal Cost
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </ValidatorForm>
        </Paper>
      </Grid>
      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(AddCompressorStationCostComp);
