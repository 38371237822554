import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import 'antd/dist/antd.css';

import { Table } from 'antd';

import AlertNotificationComp from 'components/custom/AlertNotificationComp';

//linq
import linq from 'linq';

//Redux imports
import { useSelector } from 'react-redux';

//Api
import axios from 'axios';

//Redux imports
import { useDispatch } from 'react-redux';
import * as actionType from 'redux/actions/actionType';

import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({}));

function LookupInsertComp(props) {
	const token = useSelector((state) => state.userReducer.token);

	const [status, setStatusBase] = React.useState({
		show: false,
		message: '',
		variant: 'error',
	});

	const mappingReducers = {
		OperatorName: 'operatorsReducer',
		CityName: 'citynamesReducer',
		CountyName: 'countynamesReducer',
		StateName: 'statenamesReducer',
		OperatorType: 'operatortypesReducer',
		Zipcode: 'zipcodesReducer',
	};

	const [values, setValues] = React.useState([]);
	const [selectedvalue, setSelectedValue] = React.useState();

	var dbtype =
		props.lookuptype === 'Company1' || props.lookuptype === 'Company2' || props.lookuptype === 'Company3'
			? 'OperatorName'
			: props.lookuptype;

	// console.log('dbtype ' + dbtype);

	var reduxvalues = useSelector((state) => state[mappingReducers[dbtype]][dbtype]);

	const fnOnChange = (value) => {
		// console.log(`selected ${value}`);

		setSelectedValue(value);

		props.fnSetValues(props.lookuptype, props.lookupid, value[props.lookuptype], value[props.lookupid]);
	};

	const fnShowErrorMessage = () => {
		setStatusBase({ show: false, message: '', variant: 'info' });
	};

	const fnFetchLookupValues = () => {
		const data = {
			fetchtype: dbtype,
		};

		const options = {
			headers: {
				authorization: token ? `Bearer ${token}` : '',
			},
		};

		axios.post('/api/fetchuniquevalues', data, options).then(
			(response) => {
				if (response.data[0].status === 'error') {
					setStatusBase({
						show: true,
						message: `Error while fetching ${props.lookuptype}`,
						variant: 'error',
					});
				} else if (response.data[0].data.length > 0) {
					if (
						props.lookuptype === 'Company1' ||
						props.lookuptype === 'Company2' ||
						props.lookuptype === 'Company3'
					) {
						var data = [];
						response.data[0].data.forEach((element) => {
							data.push({
								[props.lookuptype]: element.OperatorName,
								[props.lookupid]: element.OperatorId,
							});
						});
						setValues(data);
					} else {
						setValues(response.data[0].data);
					}
				} else {
					setStatusBase({ show: true, message: `${props.lookuptype}s not found`, variant: 'error' });
				}
			},
			(error) => {
				if (error.request.status === 500) {
					setStatusBase({ show: true, message: 'Time out', variant: 'error' });
					props.history.push('/login');
				} else {
					setStatusBase({
						show: true,
						message: `Error while fetching ${props.lookuptype}s`,
						variant: 'error',
					});
				}
			}
		);
	};

	useEffect(() => {
		if (reduxvalues !== undefined && reduxvalues.length > 0) {
			// console.log('dbtype ' + dbtype);
			// console.log('effect' + reduxvalues);

			if (props.lookuptype === 'Company1' || props.lookuptype === 'Company2' || props.lookuptype === 'Company3') {
				var data = [];
				reduxvalues.forEach((element) => {
					data.push({
						[props.lookuptype]: element.OperatorName,
						[props.lookupid]: element.OperatorId,
					});
				});
				setValues(data);
			} else {
				setValues(reduxvalues);
			}
		} else {
			fnFetchLookupValues();
		}
	}, []);

	useEffect(() => {
		if (values.length > 0 && props.lookupvalue !== undefined && props.lookupvalue !== null) {
			var selectedvalue = linq
				.from(values)
				.where((a) => a[props.lookuptype] == props.lookupvalue)
				.toArray();

			setSelectedValue(selectedvalue[0]);
		}
	}, [values]);

	// useEffect(() => {
	// 	if (values.length > 0 && props.lookupvalue !== undefined && props.lookupvalue !== null) {
	// 		var selectedvalue = linq
	// 			.from(values)
	// 			.where((a) => a[props.lookuptype] == props.lookupvalue)
	// 			.toArray();

	// 		setSelectedValue(selectedvalue[0]);
	// 	}
	// }, [props.lookupvalue]);

	return (
		<>
			{values.length > 0 ? (
				<Grid>
					<Autocomplete
						size="small"
						options={values}
						getOptionLabel={(option) => (typeof option === 'string' ? option : option[props.lookuptype])}
						value={selectedvalue}
						onChange={(event, newValue) => {
							fnOnChange(newValue);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								label={'Select ' + [props.lookuptype]}
								placeholder={[props.lookuptype]}
							/>
						)}
					/>
				</Grid>
			) : null}

			<Snackbar
				autoHideDuration={6000}
				open={status.show}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={() => fnShowErrorMessage()}
			>
				<AlertNotificationComp
					variant={status.variant}
					onClose={() => fnShowErrorMessage()}
					message={status.message}
				></AlertNotificationComp>
			</Snackbar>
		</>
	);
}

export default withRouter(LookupInsertComp);
