import * as actionType from 'redux/actions/actionType';

let initialState = {
	CountyName: [],
};

function countynamesReducer(state = initialState, action) {
	switch (action.type) {
		case actionType.ADD_COUNTYNAMES:
			state.CountyName = action.payload.CountyName;

			return state;

		default:
			return state;
	}
}

export default countynamesReducer;
