import React, { Suspense, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import Snackbar from "@material-ui/core/Snackbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import LinearProgress from "@material-ui/core/LinearProgress";

import { makeStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";

import LookupInsertComp from "components/common/LookupInsertComp";
import AlertNotificationComp from "components/custom/AlertNotificationComp";

//date
import moment from "moment";

//Api
import axios from "axios";

//Redux imports
import { useSelector, useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import { plantstatus } from "lookuptables/global";
import { monthsvalues } from "../../lookuptables/global";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },
  operatoraddcontainer: {
    "& .MuiPaper-root": {
      width: "100%",
      padding: 10,
      margin: 10,
    },
  },
  formcontainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-container": {
      // marginBottom: 10,
    },
  },
  updatebutton: {
    width: "100%",
  },
}));

function AddLiquefactionUnitComp(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const [liquefactions, setLiquefactions] = React.useState([]);

  const citylookupvalues = useSelector(
    (state) => state.citynamesReducer.CityName
  );
  const countylookupvalues = useSelector(
    (state) => state.countynamesReducer.CountyName
  );
  const statelookupvalues = useSelector(
    (state) => state.statenamesReducer.StateName
  );
  const zipcodelookupvalues = useSelector(
    (state) => state.zipcodesReducer.Zipcode
  );
  const operatorlookupvalues = useSelector(
    (state) => state.operatorsReducer.OperatorName
  );
  const basinlookupvalues = useSelector(
    (state) => state.basinsReducer.BasinName
  );

  const [selectedcity, setSelectedCity] = React.useState({});
  const [selectedcounty, setSelectedCounty] = React.useState({});
  const [selectedstate, setSelectedState] = React.useState({});
  const [selectedzipcode, setSelectedZipcode] = React.useState({});
  const [selectedbasin, setSelectedBasin] = React.useState({});
  const [selectedowner, setSelectedOwner] = React.useState({});
  const [selectedoperator, setSelectedOperator] = React.useState({});
  const [selectedStatus, setSelectedStatus] = React.useState({});
  const [selectedliquefaction, setSelectedLiquefaction] = React.useState({});

  const liquefactioninitialstate = {
    LiquefactionPlantUnit: "",
    LiquefactionPlantHeaderId: null,
    LiquefactionPlantName: null,
    BaseloadCapacityBCF: "",
    PeakloadCapacityBCF: "",
    CommissioningYear: "",
    CommissioningMonth: "",
    ProjectType: "",
    DOEExportQtyFTACountriesBCF: "",
    DocketNumberFTACountries: "",
    DOEExportQtyNonFTACountriesBCF: "",
    DocketNumberNonFTACountries: "",
    FERCAuthorizedExportQtyBCF: "",
    FERCDocketNumber: "",
    StatusId: null,
    Status: null,
    key: 0,
  };

  const [liquefactionvalues, setLiquefactionValues] = React.useState(
    liquefactioninitialstate
  );

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "error",
  });

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "error" });
  };

  const fnHandleChange = (prop) => (event) => {
    setLiquefactionValues({
      ...liquefactionvalues,
      [prop]: event.target.value,
    });
  };

  const fnHandleFTChange = (prop) => (event) => {
    if (event.target.outerText === undefined) {
      setLiquefactionValues({
        ...liquefactionvalues,
        [prop]: "",
      });
    } else {
      setLiquefactionValues({
        ...liquefactionvalues,
        [prop]: event.target.outerText,
      });
    }
  };

  const fnOnChange = (newvalue, type, typeid) => {
    if (newvalue !== null && newvalue !== undefined) {
      setLiquefactionValues({
        ...liquefactionvalues,
        [type]: newvalue[type],
        [typeid]: newvalue[typeid],
      });

      switch (type) {
        case "LiquefactionPlantName":
          setSelectedLiquefaction(newvalue);
          break;

        case "Status":
          setSelectedStatus(newvalue);
          break;
      }
    }
  };

  const fnInsertLiquefactionData = () => {
    var liquefactionkeys = [
      "LiquefactionPlantUnit",
      "LiquefactionPlantHeaderId",
      "BaseloadCapacityBCF",
      "PeakloadCapacityBCF",
      "CommissioningYear",
      "CommissioningMonth",
      "StatusId",
      "ProjectType",
      "DOEExportQtyFTACountriesBCF",
      "DocketNumberFTACountries",
      "DOEExportQtyNonFTACountriesBCF",
      "DocketNumberNonFTACountries",
      "FERCAuthorizedExportQtyBCF",
      "FERCDocketNumber",
    ];

    var insertvalues = [];
    liquefactionkeys.forEach((element) => {
      if (
        liquefactionvalues[element] !== undefined &&
        liquefactionvalues[element] !== null &&
        liquefactionvalues[element] !== ""
      ) {
        if (element.indexOf("Date") > -1) {
          insertvalues.push(
            element +
              " = '" +
              moment(liquefactionvalues[element]).format("YYYY-MM-DD") +
              "'"
          );
        } else {
          if (typeof liquefactionvalues[element] === "string") {
            insertvalues.push("'" + liquefactionvalues[element] + "'");
          } else {
            insertvalues.push(liquefactionvalues[element]);
          }
        }
      } else {
        insertvalues.push("NULL");
      }
    });

    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      inserttype: "LiquefactionUnit",
      insertvalues: insertvalues.join(","),
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/insertdatarecord", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error while inserting liquefaction record",
            variant: "error",
          });
        } else if (response.data[0].status === "inserted") {
          setStatusBase({
            show: true,
            message: "Record inserted successfully",
            variant: "info",
          });

          window.location.reload();
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting record",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting liquefaction unit data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnFetchLookupValues = (fetchtype) => {
    const data = {
      fetchtype: fetchtype,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchuniquevalues", data, options).then(
      (response) => {
        // console.log(response.data[0]);
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            var data = [];

            if (fetchtype === "LiquefactionName") {
              response.data[0].data.forEach((element) => {
                data.push({
                  LiquefactionPlantName: element["LiquefactionPlantName"],
                  LiquefactionPlantHeaderId:
                    element["LiquefactionPlantHeaderId"],
                });
              });

              setLiquefactions(data);
            }
          } else {
          }
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    fnFetchLookupValues("LiquefactionName");
  }, []);

  return (
    <>
      <Grid className={classes.operatoraddcontainer} container>
        <Paper>
          <Typography variant="subtitle1">
            Add Liquefaction Unit Data
          </Typography>
          {/* {JSON.stringify(liquefactionvalues)} */}
          <ValidatorForm>
            {liquefactions.length > 0 ? (
              <>
                <Grid
                  container
                  spacing="3"
                  direction="column"
                  className={classes.formcontainer}
                >
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <TextField
                          label="Liquefaction Plant Unit"
                          id="liquefactionplantunitId"
                          onChange={fnHandleChange("LiquefactionPlantUnit")}
                          value={liquefactionvalues.LiquefactionPlantUnit}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={
                            liquefactionvalues.LiquefactionPlantUnit === ""
                          }
                        />
                      </Grid>
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={liquefactions}
                          getOptionLabel={(option) =>
                            option.LiquefactionPlantName === undefined
                              ? ""
                              : option.LiquefactionPlantName
                          }
                          value={selectedliquefaction}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              fnOnChange(
                                newValue,
                                "LiquefactionPlantName",
                                "LiquefactionPlantHeaderId"
                              );
                            } else {
                              setSelectedLiquefaction({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Plant"}
                              placeholder="Liquefaction Plant"
                              error={
                                selectedliquefaction.LiquefactionPlantName ===
                                undefined
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="2">
                        <TextField
                          label="Baseload Capacity BCF"
                          id="baseloadcapacitybcfId"
                          onChange={fnHandleChange("BaseloadCapacityBCF")}
                          value={liquefactionvalues.BaseloadCapacityBCF}
                          variant="outlined"
                          type="number"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="2">
                        <TextField
                          label="Peakload Capacity BCF"
                          id="peakloadcapacitybcfId"
                          onChange={fnHandleChange("PeakloadCapacityBCF")}
                          value={liquefactionvalues.PeakloadCapacityBCF}
                          variant="outlined"
                          type="number"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="2">
                        <TextField
                          label="Commissioning Year"
                          id="commissioningyearId"
                          onChange={fnHandleChange("CommissioningYear")}
                          value={liquefactionvalues.CommissioningYear}
                          variant="outlined"
                          size="small"
                          type="number"
                          inputProps={{ maxLength: 4, minLength: 4 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={liquefactionvalues.CommissioningYear === ""}
                        />
                      </Grid>

                      {/* <Grid item xs="2">
                        <TextField
                          label="Commissioning Month"
                          id="commissioningmonthId"
                          onChange={fnHandleChange("CommissioningMonth")}
                          value={liquefactionvalues.CommissioningMonth}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={liquefactionvalues.CommissioningMonth === ""}
                        />
                      </Grid> */}
                      <Grid item xs="2">
                        <Autocomplete
                          size="small"
                          options={monthsvalues}
                          getOptionLabel={(option) => option}
                          value={liquefactionvalues.CommissioningMonth}
                          onChange={fnHandleFTChange("CommissioningMonth")}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Month"}
                              placeholder="Commissioning Month"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="2">
                        <Autocomplete
                          size="small"
                          options={plantstatus}
                          getOptionLabel={(option) =>
                            option.Status === undefined ? "" : option.Status
                          }
                          value={selectedStatus}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              fnOnChange(newValue, "Status", "StatusId");
                            } else {
                              setSelectedStatus({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Status"}
                              placeholder="Status"
                              error={selectedStatus.Status === undefined}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="Project Type"
                          id="projecttypeId"
                          onChange={fnHandleChange("ProjectType")}
                          value={liquefactionvalues.ProjectType}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="2">
                        <TextField
                          label="DOE Export Qty FTA Countries BCF"
                          id="doeexportqtyftacountriesbcfId"
                          onChange={fnHandleChange(
                            "DOEExportQtyFTACountriesBCF"
                          )}
                          value={liquefactionvalues.DOEExportQtyFTACountriesBCF}
                          variant="outlined"
                          size="small"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="2">
                        <TextField
                          label="Docket Number FTA Countries"
                          id="docketnumberftacountriesId"
                          onChange={fnHandleChange("DocketNumberFTACountries")}
                          value={liquefactionvalues.DocketNumberFTACountries}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="2">
                        <TextField
                          label="DOE Export Qty Non FTA Countries BCF"
                          id="doeexportqtynonftacountriesbcfId"
                          onChange={fnHandleChange(
                            "DOEExportQtyNonFTACountriesBCF"
                          )}
                          value={
                            liquefactionvalues.DOEExportQtyNonFTACountriesBCF
                          }
                          variant="outlined"
                          type="number"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="2">
                        <TextField
                          label="Docket Number Non FTA Countries"
                          id="docketnumbernonftacountriesId"
                          onChange={fnHandleChange(
                            "DocketNumberNonFTACountries"
                          )}
                          value={liquefactionvalues.DocketNumberNonFTACountries}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs="2">
                        <TextField
                          label="FERC Authorized Export Qty BCF"
                          id="fercauthorizedexportqtybcfId"
                          onChange={fnHandleChange(
                            "FERCAuthorizedExportQtyBCF"
                          )}
                          value={liquefactionvalues.FERCAuthorizedExportQtyBCF}
                          variant="outlined"
                          size="small"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="2">
                        <TextField
                          label="FERC Docket Number"
                          id="fercdocketnumberId"
                          onChange={fnHandleChange("FERCDocketNumber")}
                          value={liquefactionvalues.FERCDocketNumber}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Grid item>
                        <Button
                          type="button"
                          color="primary"
                          variant="contained"
                          className={classes.updatebutton}
                          disabled={
                            liquefactionvalues.LiquefactionPlantUnit === "" ||
                            liquefactionvalues.LiquefactionPlantName === "" ||
                            liquefactionvalues.CommissioningYear === "" ||
                            liquefactionvalues.CommissioningMonth === "" ||
                            liquefactionvalues.Status === null
                          }
                          onClick={() => fnInsertLiquefactionData()}
                        >
                          Insert Liquefaction Unit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </ValidatorForm>
        </Paper>
      </Grid>
      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(AddLiquefactionUnitComp);
