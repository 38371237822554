import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useClearCache } from "react-clear-cache";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

//Images
import LoginImage from "assets/images/multiply.png";

//Redux imports
import { useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

//Api
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  login: {
    top: 50,
    padding: theme.spacing(3, 2),
    width: 400,
  },
  loginbutton: {
    boxShadow: "none",
    width: "100%",
    marginTop: "30px",
    marginBottom: "20px",
  },
  loginimage: {
    height: 60,
    width: 158,
    backgroundSize: "contain",
  },
  moreinfo: {
    fontSize: "small",
    color: "#b2bec3",
    marginTop: "50px",
  },
}));

export default function LoginComp(props) {
  const classes = useStyles();

  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  const [values, setValues] = React.useState({
    username: "",
    password: "",
    error: "",
  });

  const dispatch = useDispatch();

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const fnForgotPassword = () => {
    props.history.push("/forgotpassword");
  };

  const fnUserLogin = (info) => {
    const userDetails = {
      username: info.variables.username,
      password: info.variables.password,
    };

    axios.post("/api/signinuser", userDetails).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "auth error") {
          setValues({ ...values, error: "Invalid Authentication." });
        } else if (response.data[0].status === "success") {
          dispatch({
            type: actionType.ADD_USERDETAILS,
            payload: {
              username: userDetails.username,
              token: response.data[0].token,
              userrole: response.data[0].userrole,
              usercode: response.data[0].usercode,
            },
          });

          props.history.push("/home");
        }
      },
      (error) => {
        setValues({
          ...values,
          error: "Server is not available. Please contact admin.",
        });
      }
    );
  };

  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }

    dispatch({
      type: actionType.SET_THEME,
      payload: { theme: "naveentheme" },
    });
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

    window.localStorage.clear();
  }, []);

  return (
    <>
      <Grid
        container
        direction="column"
        justify="space-around"
        alignItems="center"
      >
        <Grid item>
          <Paper className={classes.login}>
            <ValidatorForm
              onSubmit={() => {
                dispatch({
                  type: actionType.LOADINGSPINNER,
                  payload: { loading: true },
                });

                fnUserLogin({
                  variables: {
                    username: values.username,
                    password: values.password,
                  },
                });
              }}
              onError={(errors) => console.log(errors)}
            >
              <>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="stretch"
                >
                  <Grid item>
                    <CardMedia
                      image={LoginImage}
                      className={classes.loginimage}
                    ></CardMedia>
                  </Grid>
                  <Grid item>
                    <TextValidator
                      autoFocus
                      margin="normal"
                      fullWidth
                      label="User Name"
                      onChange={handleChange("username")}
                      name="username"
                      value={values.username || ""}
                      validators={["required"]}
                      errorMessages={["Required"]}
                    />
                  </Grid>
                  <Grid item>
                    <TextValidator
                      margin="normal"
                      fullWidth
                      label="Password"
                      onChange={handleChange("password")}
                      name="password"
                      type="password"
                      value={values.password || ""}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      inputProps={{
                        maxLength: 25,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      className={classes.loginbutton}
                    >
                      Login
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button size="small" onClick={() => fnForgotPassword()}>
                      Forgot Password?
                    </Button>
                  </Grid>
                  <Grid container xs={12} justify="flex-start">
                    <Typography color="error">{values.error}</Typography>
                  </Grid>
                </Grid>
              </>
            </ValidatorForm>
          </Paper>
        </Grid>
        <Grid item>
          <Grid
            container
            className={classes.moreinfo}
            direction="column"
            justify="space-around"
            alignItems="center"
          >
            <Grid item>AURA Informatica LLP.</Grid>
            <Grid item>v1.8.8</Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
