import React from "react";

import Grid from "@material-ui/core/Grid";

// import HomeComp from 'components/user/HomeComp';
import HeaderComp from "components/custom/HeaderComp";
import TariffComp from "components/tariff/TariffComp";

const homeStyles = {
  home: {
    height: "100%",
  },
};

export default class Tariff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <HeaderComp></HeaderComp>
        </div>
        <div>
          <TariffComp></TariffComp>
        </div>
      </React.Fragment>
    );
  }
}
