import * as actionType from 'redux/actions/actionType';

let initialState = {
	history: [],
};

function navigationReducer(state = initialState, action) {
	switch (action.type) {
		case actionType.ADD_NAVIGATION:
			state.history = state.history.concat(action.payload.history);
			return state;
		case actionType.REMOVE_NAVIGATION:
			state.history.splice(state.history.indexOf(action.payload.history) + 1);
			return state;
		default:
			return state;
	}
}

export default navigationReducer;
