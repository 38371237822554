import React, { Suspense, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import Snackbar from "@material-ui/core/Snackbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import LinearProgress from "@material-ui/core/LinearProgress";

import { makeStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";

import LookupInsertComp from "components/common/LookupInsertComp";
import AlertNotificationComp from "components/custom/AlertNotificationComp";

//date
import moment from "moment";

//Api
import axios from "axios";

//linq
import linq from "linq";

//Redux imports
import { useSelector, useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import { assettypes } from "lookuptables/global";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },
  operatoraddcontainer: {
    "& .MuiPaper-root": {
      width: "100%",
      padding: 10,
      margin: 10,
    },
  },
  formcontainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-container": {
      // marginBottom: 10,
    },
  },
  updatebutton: {
    width: "100%",
  },
}));

function AddPipelineAssemblyComp(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const [refineries, setRefineries] = React.useState([]);

  const citylookupvalues = useSelector(
    (state) => state.citynamesReducer.CityName
  );
  const countylookupvalues = useSelector(
    (state) => state.countynamesReducer.CountyName
  );
  const statelookupvalues = useSelector(
    (state) => state.statenamesReducer.StateName
  );
  const operatorlookupvalues = useSelector(
    (state) => state.operatorsReducer.OperatorName
  );
  const basinlookupvalues = useSelector(
    (state) => state.basinsReducer.BasinName
  );

  const [statecounties, setStateCounties] = React.useState([]);

  const [pipelinenames, setPipelineNames] = React.useState([]);

  const [selectedcity, setSelectedCity] = React.useState({});
  const [selectedcounty, setSelectedCounty] = React.useState({});
  const [selectedstate, setSelectedState] = React.useState({});
  const [selectedbasin, setSelectedBasin] = React.useState({});
  const [selectedowner, setSelectedOwner] = React.useState({});
  const [selectedoperator, setSelectedOperator] = React.useState({});
  const [selectedassettype, setSelectedAssetType] = React.useState({});

  const pipelineassemblyinitialstate = {
    PipelineAssemblyName: "",
    CityName: null,
    CityId: null,
    CountyName: null,
    CountyId: null,
    StateName: null,
    StateNameId: null,
    Basin: null,
    BasinId: null,
    Operator: null,
    OperatorId: null,
    Owner: null,
    OwnerId: null,
    AssetType: null,
    AssetTypeId: null,
    Latitude: "",
    Longitude: "",
    LifecycleStatus: "",
    RegulatoryType: "",
    InserviceDate: null,
    InstallationDate: null,

    key: 0,
  };

  const [pipelineassemblyvalues, setPipelineAssemblyValues] = React.useState(
    pipelineassemblyinitialstate
  );

  const [pipelinelocationcode, setPipelineLocationCode] = React.useState("");
  const [selectedpipeline, setSelectedPipeline] = React.useState({});

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "error",
  });

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "error" });
  };

  const fnHandleChange = (prop) => (event) => {
    if (prop === "PipelineLocationCode") {
      setPipelineLocationCode(event.target.value);
    } else {
      setPipelineAssemblyValues({
        ...pipelineassemblyvalues,
        [prop]: event.target.value,
      });
    }
  };

  const fnHandleInstallationDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setPipelineAssemblyValues({
        ...pipelineassemblyvalues,
        InstallationDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setPipelineAssemblyValues({
        ...pipelineassemblyvalues,
        InstallationDate: null,
      });
    }
  };

  const fnHandleInserviceDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setPipelineAssemblyValues({
        ...pipelineassemblyvalues,
        InserviceDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setPipelineAssemblyValues({
        ...pipelineassemblyvalues,
        InserviceDate: null,
      });
    }
  };

  const fnOnChange = (newvalue, type, typeid) => {
    if (newvalue !== null && newvalue !== undefined) {
      if (type === "Owner" || type === "Operator") {
        setPipelineAssemblyValues({
          ...pipelineassemblyvalues,
          [type]: newvalue["OperatorName"],
          [typeid]: newvalue["OperatorId"],
        });
      } else {
        setPipelineAssemblyValues({
          ...pipelineassemblyvalues,
          [type]: newvalue[type],
          [typeid]: newvalue[typeid],
        });
      }

      switch (type) {
        case "CityName":
          setSelectedCity(newvalue);
          break;
        case "CountyName":
          setSelectedCounty(newvalue);
          break;
        case "StateName":
          setSelectedState(newvalue);
          setSelectedCounty({});
          var filtercounties = linq
            .from(countylookupvalues)
            .where((a) => a.StateNameId == newvalue.StateNameId)
            .toArray();

          setStateCounties(filtercounties);
          break;

        case "Operator":
          setSelectedOperator(newvalue);
          break;
        case "Owner":
          setSelectedOwner(newvalue);
          break;
        case "BasinName":
          setSelectedBasin(newvalue);
          break;
        case "AssetType":
          setSelectedAssetType(newvalue);
          break;
      }
    }
  };

  const fnInsertPipelineAssemblyData = () => {
    var pipelineassemblykeys = [
      "PipelineAssemblyName",
      "AssetTypeId",
      "Latitude",
      "Longitude",
      "Elevation",
      "StateNameId",
      "CountyId",
      "BasinId",
      "CityId",
      "OwnerId",
      "OperatorId",
      "LifecycleStatus",
      "RegulatoryType",
      "InserviceDate",
      "InstallationDate",
    ];

    var insertvalues = [];
    pipelineassemblykeys.forEach((element) => {
      if (
        pipelineassemblyvalues[element] !== undefined &&
        pipelineassemblyvalues[element] !== null &&
        pipelineassemblyvalues[element] !== ""
      ) {
        if (element.indexOf("Date") > -1) {
          insertvalues.push(
            element +
              " = '" +
              moment(pipelineassemblyvalues[element]).format("YYYY-MM-DD") +
              "'"
          );
        } else {
          if (typeof pipelineassemblyvalues[element] === "string") {
            insertvalues.push("'" + pipelineassemblyvalues[element] + "'");
          } else {
            insertvalues.push(pipelineassemblyvalues[element]);
          }
        }
      } else {
        insertvalues.push("NULL");
      }
    });

    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      inserttype: "PipelineAssembly",
      insertvalues: insertvalues.join(","),
      facname: pipelineassemblyvalues.PipelineAssemblyName,
      factype: pipelineassemblyvalues.AssetType,
      operator:
        pipelineassemblyvalues.Operator === null
          ? ""
          : pipelineassemblyvalues.Operator,
      owner:
        pipelineassemblyvalues.Owner === null
          ? ""
          : pipelineassemblyvalues.Owner,
      latitude: pipelineassemblyvalues.Latitude,
      longitude: pipelineassemblyvalues.Longitude,
      pipelinelocationcode:
        pipelinelocationcode !== "" ? pipelinelocationcode : null,
      pipelineheaderid:
        selectedpipeline.PipelineHeaderId !== undefined
          ? selectedpipeline.PipelineHeaderId
          : null,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/insertdatarecord", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: `Error while inserting pipelineassembly record : ${response.data[0].message}`,
            variant: "error",
          });
        } else if (response.data[0].status === "inserted") {
          if (
            pipelinelocationcode !== "" &&
            selectedpipeline.PipelineHeaderId !== undefined
          ) {
            fnInsertPLC(response.data[0].insertId);
          } else {
            setStatusBase({
              show: true,
              message: "Record inserted successfully",
              variant: "info",
            });
            window.location.reload();
          }
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting record",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting pipelineassembly data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnInsertPLC = (insertid) => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      pipelinelocationcode: pipelinelocationcode,
      factype: pipelineassemblyvalues.AssetType,
      facid: insertid,
      pipelineheaderid: selectedpipeline.PipelineHeaderId,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/insertpipelinelocationcode", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: response.data[0].message,
              variant: "error",
            });

            
          } else if (response.data[0].status === "inserted") {
            setStatusBase({
              show: true,
              message: "Data inserted successfully",
              variant: "info",
            });

            window.location.reload();
          }
        } else {
          setStatusBase({
            show: true,
            message:
              "Error while inserting pipeline location code. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching pipelineassembly data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnFetchUniqueValues = () => {
    const data = {
      fetchtype: "PipelineName",
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchuniquevalues", data, options).then(
      (response) => {
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "no records") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            setPipelineNames(response.data[0].data);
          }
        }
      },
      (error) => {
        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
        }
      }
    );
  };

  useEffect(() => {
    fnFetchUniqueValues();
  }, []);

  return (
    <>
      <Grid className={classes.operatoraddcontainer} container>
        <Paper>
          <Typography variant="subtitle1">
            Add Pipeline Assembly Data
          </Typography>
          <ValidatorForm>
            <>
              <Grid
                container
                spacing="3"
                direction="column"
                className={classes.formcontainer}
              >
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs="5">
                      <Autocomplete
                        id="pipelineassemblynameId"
                        freeSolo
                        size="small"
                        options={refineries}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="PipelineAssembly Name"
                            margin="normal"
                            variant="outlined"
                            value={pipelineassemblyvalues.PipelineAssemblyName}
                            onChange={fnHandleChange("PipelineAssemblyName")}
                            error={
                              pipelineassemblyvalues.PipelineAssemblyName === ""
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="4">
                      <Autocomplete
                        size="small"
                        options={assettypes}
                        getOptionLabel={(option) =>
                          option.AssetType === undefined ? "" : option.AssetType
                        }
                        value={selectedassettype}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            fnOnChange(newValue, "AssetType", "AssetTypeId");
                          } else {
                            setSelectedAssetType({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select AssetType"}
                            placeholder="AssetType"
                            error={selectedassettype.AssetType === undefined}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      {/* {JSON.stringify(operatorvalues.City)} */}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={statelookupvalues}
                        getOptionLabel={(option) =>
                          option.StateName === undefined ? "" : option.StateName
                        }
                        value={selectedstate}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "StateName", "StateNameId");
                          } else {
                            setSelectedState({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select State"}
                            placeholder="State"
                            error={selectedstate.StateName === undefined}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={statecounties}
                        getOptionLabel={(option) =>
                          option.CountyName === undefined
                            ? ""
                            : option.CountyName
                        }
                        value={selectedcounty}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "CountyName", "CountyId");
                          } else {
                            setSelectedCounty({});
                          }
                        }}
                        disabled={
                          statecounties.length === 0 ||
                          selectedstate.StateName === undefined
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select County"}
                            placeholder="County"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={citylookupvalues}
                        getOptionLabel={(option) =>
                          option.CityName === undefined ? "" : option.CityName
                        }
                        value={selectedcity}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "CityName", "CityId");
                          } else {
                            setSelectedCity({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select City"}
                            placeholder="City"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={basinlookupvalues}
                        getOptionLabel={(option) =>
                          option.BasinName === undefined ? "" : option.BasinName
                        }
                        value={selectedbasin}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "BasinName", "BasinId");
                          } else {
                            setSelectedBasin({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select Basin"}
                            placeholder="Basin"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={operatorlookupvalues}
                        getOptionLabel={(option) =>
                          option.OperatorName === undefined
                            ? ""
                            : option.OperatorName
                        }
                        value={selectedoperator}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "Operator", "OperatorId");
                          } else {
                            setSelectedOperator({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select Operator"}
                            placeholder="Operator"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={operatorlookupvalues}
                        getOptionLabel={(option) =>
                          option.OperatorName === undefined
                            ? ""
                            : option.OperatorName
                        }
                        value={selectedowner}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "Owner", "OwnerId");
                          } else {
                            setSelectedOwner({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select Owner"}
                            placeholder="Owner"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          format="yyyy/MM/dd"
                          margin="normal"
                          id="inservicedateId"
                          label="Inservice Date"
                          variant="outlined"
                          value={pipelineassemblyvalues.InserviceDate}
                          onChange={fnHandleInserviceDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "Inservice Date",
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs="3">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          format="yyyy/MM/dd"
                          margin="normal"
                          id="installationdateId"
                          label="Installation Date"
                          variant="outlined"
                          value={pipelineassemblyvalues.InstallationDate}
                          onChange={fnHandleInstallationDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "Installation Date",
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="2">
                      <TextField
                        label="Latitude"
                        id="latitudeId"
                        onChange={fnHandleChange("Latitude")}
                        value={pipelineassemblyvalues.Latitude}
                        variant="outlined"
                        size="small"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={pipelineassemblyvalues.Latitude === ""}
                      />
                    </Grid>
                    <Grid item xs="2">
                      <TextField
                        label="Longitude"
                        id="longitudeId"
                        onChange={fnHandleChange("Longitude")}
                        value={pipelineassemblyvalues.Longitude}
                        variant="outlined"
                        size="small"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={pipelineassemblyvalues.Longitude === ""}
                      />
                    </Grid>

                    <Grid item xs="3">
                      <TextField
                        label="LifecycleStatus"
                        id="LifecycleStatusId"
                        onChange={fnHandleChange("LifecycleStatus")}
                        value={pipelineassemblyvalues.LifecycleStatus}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="RegulatoryType"
                        id="RegulatoryTypeId"
                        onChange={fnHandleChange("RegulatoryType")}
                        value={pipelineassemblyvalues.RegulatoryType}
                        variant="outlined"
                        size="small"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="2">
                      <TextField
                        label="PipelineLocationCode"
                        id="PipelineLocationCodeId"
                        onChange={fnHandleChange("PipelineLocationCode")}
                        value={pipelinelocationcode}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={pipelinenames}
                        value={selectedpipeline}
                        getOptionLabel={(option) =>
                          option.PipelineName === undefined
                            ? ""
                            : option.PipelineName
                        }
                        disabled={pipelinelocationcode === ""}
                        onChange={(event, newValue) => {
                          if (newValue === null) {
                            setSelectedPipeline({});
                          } else {
                            setSelectedPipeline(newValue);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Search By Pipeline Name"
                            placeholder="Pipelines"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="7"></Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid item>
                      <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        className={classes.updatebutton}
                        disabled={
                          pipelineassemblyvalues.Latitude === "" ||
                          pipelineassemblyvalues.PipelineAssemblyName === "" ||
                          pipelineassemblyvalues.Longitude === "" ||
                          pipelineassemblyvalues.StateName === null ||
                          (pipelinelocationcode !== "" &&
                            selectedpipeline.PipelineHeaderId === undefined)
                        }
                        onClick={() => fnInsertPipelineAssemblyData()}
                      >
                        Insert PipelineAssembly
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          </ValidatorForm>
        </Paper>
      </Grid>
      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(AddPipelineAssemblyComp);
