import React, { Suspense, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import Snackbar from "@material-ui/core/Snackbar";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import { Space, Table } from "antd";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import MUIDataTable from "mui-datatables";

import LinearProgress from "@material-ui/core/LinearProgress";

import { makeStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";

import LookupInsertComp from "components/common/LookupInsertComp";
import AlertNotificationComp from "components/custom/AlertNotificationComp";
import EditTariffDetailComp from "./EditTariffDetailComp";

//date
import moment from "moment";

//linq
import linq from "linq";

//Api
import axios from "axios";

//Redux imports
import { useSelector, useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import {
  tariffstatus,
  tariffratetype,
  tarifffueltype,
} from "lookuptables/global";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },
  operatoraddcontainer: {
    "& .MuiPaper-root": {
      width: "100%",
      padding: 10,
      margin: 10,
    },
  },
  formcontainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-container": {
      // marginBottom: 10,
    },
  },
  updatebutton: {
    width: "100%",
  },
  operatortable: {
    width: "100%",
    "& .ant-table-title": {
      textTransform: "capitalize",
    },
    "& .editable-row .ant-form-item-explain": {
      position: "absolute",
      top: "100%",
    },
  },
  operatordatacontainer: {
    margin: 10,
    width: "98.5%",
    "& .MuiPaper-root": {
      width: "100%",
    },
  },
}));

function AddTariffComp(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "error",
  });

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "error" });
  };

  const [pipelines, setPipelines] = React.useState([]);
  const [existingtariffs, setExistingTariffs] = React.useState([]);
  const [originpipelinesections, setOriginPipelineSections] = React.useState(
    []
  );
  const [destinationpipelinesections, setDestinationPipelineSections] =
    React.useState([]);
  const [originpipelinefacilities, setOriginPipelineFacilities] =
    React.useState([]);
  const [destinationpipelinefacilities, setDestinationPipelineFacilities] =
    React.useState([]);

  const [existingtariffdata, setExistingTariffData] = React.useState([]);

  const acselectedtariffpipelines = [];
  const [selectedtariffpipelines, setSelectedTariffPipelines] = React.useState([
    ...acselectedtariffpipelines,
  ]);

  const [tariffpipelinesections, setTariffPipelineSections] = React.useState(
    []
  );

  const actariffsections = [];
  const [selectedtariffsections, setSelectedTariffSections] = React.useState([
    ...actariffsections,
  ]);

  const tariffnodeinitialstate = {
    OriginPipelineHeaderId: null,
    PointOfOrigin: "",
    OriginFacilityId: "",
    OriginFacilityType: "",
    DestinationPipelineHeaderId: null,
    PointOfDestination: "",
    DestinationFacilityId: "",
    DestinationFacilityType: "",
  };

  const tariffcolumns = [
    {
      title: "LiquidTariffNumber",
      dataIndex: "LiquidTariffNumber",
    },
    {
      title: "LiquidFuelType",
      dataIndex: "LiquidFuelType",
    },
    {
      title: "EffectiveDate",
      dataIndex: "EffectiveDate",
    },
    {
      title: "EndDate",
      dataIndex: "EndDate",
    },
    {
      title: "LiquidTariffStatus",
      dataIndex: "LiquidTariffStatus",
    },
    {
      title: "LiquidTariffRateType",
      dataIndex: "LiquidTariffRateType",
    },
    {
      title: "TermYear",
      dataIndex: "TermYear",
    },
    {
      title: "BPDMin",
      dataIndex: "BPDMin",
    },
    {
      title: "BPDMax",
      dataIndex: "BPDMax",
    },
    {
      title: "AcreageDedicationMinAcres",
      dataIndex: "AcreageDedicationMinAcres",
    },
    {
      title: "AcreageDedicationMaxAcres",
      dataIndex: "AcreageDedicationMaxAcres",
    },
    {
      title: "LiquidRateCentsPerBbl",
      dataIndex: "LiquidRateCentsPerBbl",
    },
    {
      title: "SurchargeCentsPerBbl",
      dataIndex: "SurchargeCentsPerBbl",
    },
    {
      title: "Edit",
      key: "action",
      render: (_, record, index) => {
        return (
          <Button
            size="small"
            onClick={() => fnEditTariffDetails(record)}
            color="primary"
          >
            Edit
          </Button>
        );
      },
    },
  ];

  const [tariffnodevalues, setTariffNodeValues] = React.useState(
    tariffnodeinitialstate
  );

  const tariffdetailinitialstate = {
    LiquidTariffNumber: "",
    EffectiveDate: null,
    EndDate: null,
    LiquidTariffStatusId: "",
    LiquidTariffStatus: "",
    LiquidTariffRateTypeId: "",
    LiquidTariffRateType: "",
    TermYear: "",
    BPDMin: "",
    BPDMax: "",
    AcreageDedicationMinAcres: "",
    AcreageDedicationMaxAcres: "",
    LiquidRateCentsPerBbl: "",
    SurchargeCentsPerBbl: "",
    LiquidTariffFuelTypeId: "",
    LiquidTariffFuelType: "",
  };

  const options = {
    print: false,
    selectableRows: "none",
  };

  const tariffdetailcolumns = [
    {
      name: "LiquidTariffNumber",
      options: {
        filter: true,
      },
    },
    {
      name: "LiquidTariffFuelType",
      options: {
        filter: true,
      },
    },
    {
      name: "EffectiveDate",
      options: {
        filter: true,
      },
    },
    {
      name: "EndDate",
      options: {
        filter: true,
      },
    },

    {
      name: "LiquidTariffStatus",
      options: {
        filter: true,
      },
    },
    {
      name: "LiquidTariffRateType",
      options: {
        filter: true,
      },
    },
    {
      name: "TermYear",
      options: {
        filter: true,
      },
    },
    {
      name: "BPDMin",
      options: {
        filter: true,
      },
    },
    {
      name: "BPDMax",
      options: {
        filter: true,
      },
    },
    {
      name: "AcreageDedicationMinAcres",
      options: {
        filter: true,
      },
    },
    {
      name: "AcreageDedicationMaxAcres",
      options: {
        filter: true,
      },
    },
    {
      name: "LiquidRateCentsPerBbl",
      options: {
        filter: true,
      },
    },
    {
      name: "SurchargeCentsPerBbl",
      options: {
        filter: true,
      },
    },
    {
      name: "Delete",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              type="button"
              color="secondary"
              size="small"
              variant="contained"
              onClick={() => fnDeleteTariffDetail(tableMeta)}
            >
              Delete
            </Button>
          );
        },
      },
    },
  ];

  const [tariffdetailvalues, setTariffDetailValues] = React.useState(
    tariffdetailinitialstate
  );

  const [tariffdetails, setTariffDetails] = React.useState([]);

  const fnFetchPipelineSectionData = (pipelineid, position) => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      fetchtype: "pipelinesections",
      fetchquery: pipelineid,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchindividualdata", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while fetching records",
              variant: "error",
            });
          } else if (response.data[0].status == "no records found") {
            setStatusBase({
              show: true,
              message: "Pipeline section data not found",
              variant: "info",
            });
          } else if (response.data[0].data.length > 0) {
            if (position === "Origin") {
              setOriginPipelineSections(response.data[0].data);
            } else {
              setDestinationPipelineSections(response.data[0].data);
            }

            // scrollToRef(pipelinesectiondataRef);
          }
        } else {
          setStatusBase({
            show: true,
            message:
              "Error while fetching pipeline section data. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching pipeline section data",
            variant: "error",
          });
        }
      }
    );
  };

  const [selectedoriginpipelineheader, setSelectedOriginPipelineHeader] =
    React.useState({});

  const [selectedexistingtariff, setSelectedExistingTariff] = React.useState(
    {}
  );

  const [selectedexistingtariffdetail, setSelectedExistingTariffDetail] =
    React.useState({});

  const [
    selecteddestinationpipelineheader,
    setSelectedDestinationPipelineHeader,
  ] = React.useState({});

  const acoriginsections = [];
  const [selectedoriginsections, setSelectedOriginSections] = React.useState([
    ...acoriginsections,
  ]);

  const acdestinationsections = [];
  const [selecteddestinationsections, setSelectedDestinationSections] =
    React.useState([...acdestinationsections]);

  const [selectedjointtariffs, setSelectedJointTariffs] = React.useState({});

  const [selectedpipelineoriginfacility, setSelectedPipelineOriginFacility] =
    React.useState({});

  const [
    selectedpipelinedestinationfacility,
    setSelectedPipelineDestinationFacility,
  ] = React.useState({});

  const [selectedtariffstatus, setSelectedTariffStatus] = React.useState({});

  const [selectedtariffratetype, setSelectedTariffRateType] = React.useState(
    {}
  );

  const [selectedtarifffueltype, setSelectedTariffFuelType] = React.useState(
    {}
  );

  const fnOnChange = (newvalue, type, typeid) => {
    if (newvalue !== null && newvalue !== undefined) {
      switch (type) {
        case "ExistingTariffNode":
          setSelectedExistingTariff(newvalue);
          break;
        case "ExistingTariffDetail":
          setSelectedExistingTariffDetail(newvalue);
          fnFetchExistingTariffDetails(newvalue.LiquidTariffNodeId);
          break;
        case "OriginPipelineName":
          setSelectedOriginPipelineHeader(newvalue);
          fnFetchPipelineFacilities(newvalue.PipelineHeaderId, "Origin");
          // fnFetchPipelineSectionData(newvalue.PipelineHeaderId, "Origin");
          setTariffNodeValues({
            ...tariffnodevalues,
            ["OriginPipelineHeaderId"]: newvalue.PipelineHeaderId,
          });

          break;
        case "DestinationPipelineName":
          setSelectedDestinationPipelineHeader(newvalue);
          fnFetchPipelineFacilities(newvalue.PipelineHeaderId, "Destination");
          // fnFetchPipelineSectionData(newvalue.PipelineHeaderId, "Destination");
          setTariffNodeValues({
            ...tariffnodevalues,
            ["DestinationPipelineHeaderId"]: newvalue.PipelineHeaderId,
          });

          break;
        case "OriginTerminal":
          if (
            newvalue.CountyId === null ||
            newvalue.StateNameId === null ||
            newvalue.Latitude === null ||
            newvalue.Longitude === null
          ) {
            setStatusBase({
              show: true,
              message:
                "CountyName/StateName/Latitude/Longitude not found for selected Terminal",
              variant: "error",
            });
          } else {
            setSelectedPipelineOriginFacility(newvalue);

            var tariffnodes = tariffnodevalues;

            tariffnodes.OriginFacilityId = newvalue.TerminalId;
            tariffnodes.OriginFacilityType = newvalue.TerminalType;

            // tariffnodes.OriginLatitude = newvalue.Latitude;
            // tariffnodes.OriginLongitude = newvalue.Longitude;
            // tariffnodes.OriginCountyId = newvalue.CountyId;
            // tariffnodes.OriginStateNameId = newvalue.StateNameId;

            setTariffNodeValues(tariffnodes);
          }

          break;
        case "DestinationTerminal":
          if (
            newvalue.CountyId === null ||
            newvalue.StateNameId === null ||
            newvalue.Latitude === null ||
            newvalue.Longitude === null
          ) {
            setStatusBase({
              show: true,
              message:
                "CountyName/StateName/Latitude/Longitude not found for selected Terminal",
              variant: "error",
            });
          } else {
            setSelectedPipelineDestinationFacility(newvalue);

            var tariffnodes = tariffnodevalues;

            tariffnodes.DestinationFacilityId = newvalue.TerminalId;
            tariffnodes.DestinationFacilityType = newvalue.TerminalType;

            // tariffnodes.DestinationLatitude = newvalue.Latitude;
            // tariffnodes.DestinationLongitude = newvalue.Longitude;
            // tariffnodes.DestinationCountyId = newvalue.CountyId;
            // tariffnodes.DestinationStateNameId = newvalue.StateNameId;

            setTariffNodeValues(tariffnodes);
          }

          break;

        case "LiquidTariffStatus":
          setSelectedTariffStatus(newvalue);
          setTariffDetailValues({
            ...tariffdetailvalues,
            ["LiquidTariffStatusId"]: newvalue.LiquidTariffStatusId,
            ["LiquidTariffStatus"]: newvalue.LiquidTariffStatus,
          });
          break;
        case "LiquidTariffRateType":
          setSelectedTariffRateType(newvalue);
          setTariffDetailValues({
            ...tariffdetailvalues,
            ["LiquidTariffRateTypeId"]: newvalue.LiquidTariffRateTypeId,
            ["LiquidTariffRateType"]: newvalue.LiquidTariffRateType,
          });
          break;
        case "LiquidTariffFuelType":
          setSelectedTariffFuelType(newvalue);
          setTariffDetailValues({
            ...tariffdetailvalues,
            ["LiquidTariffFuelTypeId"]: newvalue.LiquidTariffFuelTypeId,
            ["LiquidTariffFuelType"]: newvalue.LiquidTariffFuelType,
          });
          break;
      }
    }
  };

  const [nodeeditable, setNodeEditable] = React.useState(false);
  const [nodedetails, setNodeDetails] = React.useState([]);
  const [editnode, setEditNode] = React.useState(false);
  const fnFetchExistingTariffNode = () => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });
    const data = {
      tariffnodeid: selectedexistingtariff.LiquidTariffNodeId,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchliquidtariffnode", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });
        // console.log(response.data[0]);
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            let node = response.data[0].data[0];

            setNodeDetails(response.data[0].data);

            let originpipeline = {
              PipelineHeaderId: node.OriginPipelineHeaderId,
              PipelineName: node.OriginPipelineName,
            };

            let destpipeline = {
              PipelineHeaderId: node.DestinationPipelineHeaderId,
              PipelineName: node.DestinationPipelineName,
            };

            fnFetchPipelineFacilities(node.OriginPipelineHeaderId, "Origin");
            // fnFetchPipelineSectionData(node.OriginPipelineHeaderId, "Origin");
            fnFetchPipelineFacilities(
              node.DestinationPipelineHeaderId,
              "Destination"
            );
            // fnFetchPipelineSectionData(
            //   node.DestinationPipelineHeaderId,
            //   "Destination"
            // );

            setNodeEditable(true);

            setSelectedOriginPipelineHeader(originpipeline);
            setSelectedDestinationPipelineHeader(destpipeline);

            var tariffnodes = tariffnodevalues;
            tariffnodes.OriginPipelineHeaderId = node.OriginPipelineHeaderId;
            tariffnodes.DestinationPipelineHeaderId =
              node.DestinationPipelineHeaderId;
            tariffnodes.PointOfOrigin = node.PointOfOrigin;
            tariffnodes.PointOfDestination = node.PointOfDestination;

            setTariffNodeValues(tariffnodes);

            setEditNode(true);

            let pipes = [];
            let secs = [];
            let opipelines = [];
            let osecs = [];

            response.data[0].data.forEach((n) => {
              if (secs.indexOf(n.PipelineSectionId) === -1 && n.PipelineSectionId !== null) {
                secs.push(n.PipelineSectionId);
                osecs.push({
                  key: n.PipelineSectionId,
                  PipelineSectionName: n.PipelineSectionName,
                });
              }

              if (pipes.indexOf(n.DestinationPipelineHeaderId) === -1) {
                pipes.push(n.DestinationPipelineHeaderId);

                opipelines.push({
                  PipelineName: n.DestinationPipelineName,
                  PipelineHeaderId: n.DestinationPipelineHeaderId,
                });
              }

              if (pipes.indexOf(n.OriginPipelineHeaderId) === -1) {
                pipes.push(n.OriginPipelineHeaderId);

                opipelines.push({
                  PipelineName: n.OriginPipelineName,
                  PipelineHeaderId: n.OriginPipelineHeaderId,
                });
              }
            });

            

            setSelectedTariffPipelines(opipelines);

            if (osecs.length > 0) {

              
              setSelectedTariffSections(osecs);
            }
          } else {
            setStatusBase({
              show: true,
              message: "Existing Node not found",
              variant: "error",
            });
          }
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });
      }
    );
  };

  useEffect(() => {
    if (editnode && selectedtariffpipelines.length > 0) {
      
      fnFetchSections();
    }
  }, [selectedtariffpipelines]);

  const fnUpdateExistingNode = () => {
    setEditNode(false);

    dispatch({
      type: actionType.LOADINGSPINNER,
      payload: { loading: true },
    });

    var tariffnodekeys = Object.keys(tariffnodeinitialstate);

    var updatevalues = [];
    tariffnodekeys.forEach((element) => {
      if (
        tariffnodevalues[element] !== undefined &&
        tariffnodevalues[element] !== null &&
        tariffnodevalues[element] !== ""
      ) {
        if (typeof tariffnodevalues[element] === "string") {
          updatevalues.push(element + " = '" + tariffnodevalues[element] + "'");
        } else {
          updatevalues.push(element + " = " + tariffnodevalues[element]);
        }
      } else {
        updatevalues.push(element + " = null");
      }
    });

    const data = {
      tablename: "LiquidTariffNode",
      query: updatevalues.join(","),
      key: selectedexistingtariff.LiquidTariffNodeId,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    

    axios.post("/api/updateheaderdata", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while updating",
              variant: "error",
            });
          } else if (response.data[0].status === "success") {
            fnUpdateSectionDetails(selectedexistingtariff.LiquidTariffNodeId);
          }
        } else {
          setStatusBase({
            show: true,
            message: "Error while updating tariff node data. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while updating tariff node data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnUpdateSectionDetails = (tariffnodeid) => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    var detailvalues = [];

    // if (selectedoriginsections.length > 0) {
    //   selectedoriginsections.forEach((section) => {
    //     detailvalues.push("(" + tariffnodeid + "," + section.key + ",'O')");
    //   });
    // }

    // if (selecteddestinationsections.length > 0) {
    //   selecteddestinationsections.forEach((section) => {
    //     detailvalues.push("(" + tariffnodeid + "," + section.key + ",'D')");
    //   });
    // }

    selectedtariffsections.forEach((section) => {
      detailvalues.push("(" + tariffnodeid + "," + section.key + ",'M')");
    });

    const data = {
      inserttype: "LiquidTariffSectionsUpdate",
      insertvalues: detailvalues.join(","),
      nodeid: tariffnodeid,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    

    axios.post("/api/insertdatarecord", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error while update tariff node sections",
            variant: "error",
          });
        } else if (response.data[0].status === "inserted") {
          setStatusBase({
            show: true,
            message: "Tariff Sections Updated successfully",
            variant: "info",
          });

          // window.location.reload();
        } else {
          setStatusBase({
            show: true,
            message: "Error while updating tariff sections",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while updating tariff node sections",
            variant: "error",
          });
        }
      }
    );
  };

  const fnResetNodeSelections = () => {
    setNodeEditable(false);
    setTariffNodeValues(tariffnodeinitialstate);

    setSelectedOriginPipelineHeader({});
    setSelectedDestinationPipelineHeader({});
    setOriginPipelineSections([]);
    setSelectedOriginSections([...acoriginsections]);
    setDestinationPipelineSections([]);
    setSelectedDestinationSections([...acdestinationsections]);
    setOriginPipelineFacilities([]);
    setDestinationPipelineFacilities([]);
    setSelectedPipelineOriginFacility({});
    setSelectedPipelineDestinationFacility({});
  };

  const fnFetchExistingTariffDetails = (nodeid) => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });
    const data = {
      tariffnodeid: nodeid,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchliquidtariffnodedetails", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });
        // console.log(response.data[0]);
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            setExistingTariffData(response.data[0].data);
          } else {
            setStatusBase({
              show: true,
              message: "Existing Tariff details not found",
              variant: "error",
            });
          }
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });
      }
    );
  };

  const fnFetchPipelineFacilities = (pipelineid, position) => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });
    const data = {
      pipelineid: pipelineid,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchpipelinefacilitiesbyid", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });
        // console.log(response.data[0]);
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            if (position === "Origin") {
              setOriginPipelineFacilities(response.data[0].data);
            } else {
              setDestinationPipelineFacilities(response.data[0].data);
            }
          } else {
            setStatusBase({
              show: true,
              message: "Pipeline facilities not found",
              variant: "error",
            });
          }
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });
      }
    );
  };

  const fnHandleChange = (prop) => (event) => {
    if (prop === "PointOfOrigin" || prop === "PointOfDestination") {
      setTariffNodeValues({
        ...tariffnodevalues,
        [prop]: event.target.value
          .toLowerCase()
          .replace(/\b(\w)/g, (s) => s.toUpperCase()),
      });
    } else {
      setTariffNodeValues({ ...tariffnodevalues, [prop]: event.target.value });
    }
  };

  const fnHandleDetailsChange = (prop) => (event) => {
    setTariffDetailValues({
      ...tariffdetailvalues,
      [prop]: event.target.value,
    });
  };

  const fnHandleEffectiveDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setTariffDetailValues({
        ...tariffdetailvalues,
        EffectiveDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setTariffDetailValues({
        ...tariffdetailvalues,
        EffectiveDate: null,
      });
    }
  };

  const fnHandleEndDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setTariffDetailValues({
        ...tariffdetailvalues,
        EndDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setTariffDetailValues({
        ...tariffdetailvalues,
        EndDate: null,
      });
    }
  };

  const fnAddTariffDetails = () => {
    setTariffDetails([...tariffdetails, tariffdetailvalues]);

    setTariffDetailValues(tariffdetailinitialstate);

    // setSelectedTariffStatus({});
    setSelectedTariffRateType({});
    setSelectedTariffStatus({});
    setSelectedTariffFuelType({});
  };

  const fnDeleteTariffDetail = (data) => {
    var refreshdata = tariffdetails.splice(data.rowIndex, 1);

    setTariffDetails([...tariffdetails]);
  };

  const fnInsertTariff = () => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    var tariffnodekeys = Object.keys(tariffnodevalues);

    var insertvalues = [];
    tariffnodekeys.forEach((element) => {
      if (
        tariffnodevalues[element] !== undefined &&
        tariffnodevalues[element] !== null &&
        tariffnodevalues[element] !== ""
      ) {
        if (typeof tariffnodevalues[element] === "string") {
          insertvalues.push("'" + tariffnodevalues[element] + "'");
        } else {
          insertvalues.push(tariffnodevalues[element]);
        }
      } else {
        insertvalues.push("NULL");
      }
    });

    const data = {
      inserttype: "LiquidTariffNodes",
      insertvalues: insertvalues.join(","),
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/insertdatarecord", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error while inserting tariff node record",
            variant: "error",
          });
        } else if (response.data[0].status === "inserted") {
          fnInsertSectionDetails(response.data[0].insertId);

          if (selectedexistingtariffdetail.LiquidTariffNodeId === undefined) {
            fnInsertTariffDetails(response.data[0].insertId);
          } else {
            fnInsertExistingTariffDetails(
              response.data[0].insertId,
              selectedexistingtariffdetail.LiquidTariffNodeId
            );
          }

          setSelectedOriginPipelineHeader({});
          setSelectedDestinationPipelineHeader({});
          setSelectedPipelineOriginFacility({});
          setSelectedPipelineDestinationFacility({});
          setSelectedTariffFuelType({});

          setTariffNodeValues(tariffnodeinitialstate);

          // window.location.reload();
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting tariff node record",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting tariff node data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnInsertExistingTariff = () => {
    if (selectedexistingtariffdetail.LiquidTariffNodeId === undefined) {
      fnInsertTariffDetails(selectedexistingtariff.LiquidTariffNodeId);
    } else {
      fnInsertExistingTariffDetails(
        selectedexistingtariff.LiquidTariffNodeId,
        selectedexistingtariffdetail.LiquidTariffNodeId
      );
    }

    setSelectedOriginPipelineHeader({});
    setSelectedDestinationPipelineHeader({});
    setSelectedPipelineOriginFacility({});
    setSelectedPipelineDestinationFacility({});

    setTariffNodeValues(tariffnodeinitialstate);
  };

  const fnInsertTariffDetails = (tariffnodeid) => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    var tariffdetailkeys = Object.keys(tariffdetailinitialstate);

    var detailvalues = [];

    tariffdetails.forEach((tariff) => {
      var insertvalues = [];
      tariffdetailkeys.forEach((element) => {
        if (
          element !== "LiquidTariffStatus" &&
          element !== "LiquidTariffRateType" &&
          element !== "LiquidTariffFuelType"
        ) {
          if (
            tariff[element] !== undefined &&
            tariff[element] !== null &&
            tariff[element] !== ""
          ) {
            if (element.indexOf("Date") > -1) {
              insertvalues.push(
                "'" + moment(tariff[element]).format("YYYY-MM-DD") + "'"
              );
            } else {
              if (typeof tariff[element] === "string") {
                insertvalues.push("'" + tariff[element] + "'");
              } else {
                insertvalues.push(tariff[element]);
              }
            }
          } else {
            insertvalues.push("NULL");
          }
        }
      });

      detailvalues.push(
        "(" + tariffnodeid + "," + insertvalues.join(",") + ")"
      );
    });

    const data = {
      inserttype: "LiquidTariffDetails",
      insertvalues: detailvalues.join(","),
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/insertdatarecord", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error while inserting tariff details record",
            variant: "error",
          });
        } else if (response.data[0].status === "inserted") {
          setStatusBase({
            show: true,
            message: "Tariff Details Inserted successfully",
            variant: "info",
          });

          setTariffDetails([]);

          // window.location.reload();
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting tariff details",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting tariffdetails data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnRefreshPipelineFacilities = () => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {};

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/refreshpipelinefacilities", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error while refreshing facilities",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while refreshing pipeline facilities",
            variant: "error",
          });
        }
      }
    );
  };
  const fnInsertExistingTariffDetails = (tariffnodeid, tariffdetailnodeid) => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      tariffnodeid: tariffnodeid,
      tariffdetailnodeid: tariffdetailnodeid,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/insertexistingtariffdetailbyid", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error while inserting tariff details record",
            variant: "error",
          });
        } else if (response.data[0].status === "inserted") {
          setStatusBase({
            show: true,
            message: "Tariff Details Inserted successfully",
            variant: "info",
          });

          setSelectedExistingTariffDetail({});

          // window.location.reload();
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting tariff details",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting tariffdetails data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnInsertSectionDetails = (tariffnodeid) => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    var detailvalues = [];

    // if (selectedoriginsections.length > 0) {
    //   selectedoriginsections.forEach((section) => {
    //     detailvalues.push("(" + tariffnodeid + "," + section.key + ",'O')");
    //   });
    // }

    // if (selecteddestinationsections.length > 0) {
    //   selecteddestinationsections.forEach((section) => {
    //     detailvalues.push("(" + tariffnodeid + "," + section.key + ",'D')");
    //   });
    // }

    selectedtariffsections.forEach((section) => {
      detailvalues.push("(" + tariffnodeid + "," + section.key + ",'M')");
    });

    const data = {
      inserttype: "LiquidTariffSections",
      insertvalues: detailvalues.join(","),
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/insertdatarecord", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error while inserting tariff details record",
            variant: "error",
          });
        } else if (response.data[0].status === "inserted") {
          setStatusBase({
            show: true,
            message: "Tariff Sections Inserted successfully",
            variant: "info",
          });

          setSelectedOriginSections([]);
          setSelectedDestinationSections([]);

          // window.location.reload();
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting tariff details",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting tariffdetails data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnFetchLookupValues = (fetchtype) => {
    const data = {
      fetchtype: fetchtype,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchuniquevalues", data, options).then(
      (response) => {
        // console.log(response.data[0]);
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            var data = [];

            if (fetchtype === "CrudePipelineName") {
              response.data[0].data.forEach((element) => {
                data.push({
                  PipelineName: element["PipelineName"],
                  PipelineHeaderId: element["PipelineHeaderId"],
                });
              });

              setPipelines(data);
            } else if (fetchtype === "LiquidTariffNodes") {
              response.data[0].data.forEach((element) => {
                data.push({
                  LiquidTariffNodeId: element["LiquidTariffNodeId"],
                  LiquidTariffNode: element["LiquidTariffNode"],
                });
              });

              setExistingTariffs(data);
            }
          } else {
          }
        }
      },
      (error) => {}
    );
  };

  const [editrecord, setEditRecord] = React.useState({});
  const fnEditTariffDetails = (record) => {
    setEditRecord(record);
  };

  const [detaileditdialog, setdetaileditDialog] = React.useState(false);
  const fnclosedetaileditdialog = () => {
    setdetaileditDialog(false);
  };

  const fnFetchSections = () => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    let mpipelines = [];

    selectedtariffpipelines.forEach((pipe) => {
      mpipelines.push(pipe.PipelineHeaderId);
    });

    

    const data = {
      fetchtype: "multiplepipelinesections",
      fetchquery: mpipelines.join(","),
    };

    

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchindividualdata", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while fetching records",
              variant: "error",
            });
          } else if (response.data[0].status == "no records found") {
            setStatusBase({
              show: true,
              message: "Pipeline section data not found",
              variant: "info",
            });
          } else if (response.data[0].data.length > 0) {
            setTariffPipelineSections(response.data[0].data);

            // scrollToRef(pipelinesectiondataRef);
          }
        } else {
          setStatusBase({
            show: true,
            message:
              "Error while fetching pipeline section data. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching pipeline section data",
            variant: "error",
          });
        }
      }
    );
  };

  useEffect(() => {
    fnFetchLookupValues("CrudePipelineName");
    fnFetchLookupValues("LiquidTariffNodes");
  }, []);

  useEffect(() => {
    if (nodeeditable && originpipelinefacilities.length > 0) {
      let originfac = linq
        .from(originpipelinefacilities)
        .where(
          (a) =>
            a.TerminalId === nodedetails[0].OriginFacilityId &&
            a.TerminalType === nodedetails[0].OriginFacilityType
        )
        .toArray();

      setSelectedPipelineOriginFacility(originfac[0]);
    }

    if (nodeeditable && destinationpipelinefacilities.length > 0) {
      let destfac = linq
        .from(destinationpipelinefacilities)
        .where(
          (a) =>
            a.TerminalId === nodedetails[0].DestinationFacilityId &&
            a.TerminalType === nodedetails[0].DestinationFacilityType
        )
        .toArray();

      setSelectedPipelineDestinationFacility(destfac[0]);
    }

    if (nodeeditable && nodedetails.length > 0) {
      let osections = [];
      let dsections = [];
      nodedetails.forEach((section) => {
        if (section.Type === "O") {
          osections.push({
            key: section.PipelineSectionId,
            PipelineSectionName: section.PipelineSectionName,
          });
        }

        if (section.Type === "D") {
          dsections.push({
            key: section.PipelineSectionId,
            PipelineSectionName: section.PipelineSectionName,
          });
        }
      });

      osections.length > 0 && setSelectedOriginSections(osections);

      dsections.length > 0 && setSelectedDestinationSections(dsections);
    }
  }, [
    nodeeditable,
    nodedetails,
    destinationpipelinefacilities,
    originpipelinefacilities,
  ]);

  useEffect(() => {
    if (Object.keys(editrecord).length > 0) {
      setdetaileditDialog(true);
    }
  }, [editrecord]);

  return (
    <>
      <Grid className={classes.operatoraddcontainer} container>
        <Paper>
          <Typography variant="subtitle1" style={{ marginBottom: "5px" }}>
            Tariff Data
          </Typography>
          {existingtariffs.length > 0 && (
            <>
              <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>
                Select Existing Tariff Nodes
              </Typography>

              <>
                <Grid
                  container
                  spacing="3"
                  direction="column"
                  className={classes.formcontainer}
                >
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={existingtariffs}
                          getOptionLabel={(option) =>
                            option.LiquidTariffNode === undefined
                              ? ""
                              : option.LiquidTariffNode
                          }
                          value={selectedexistingtariff}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              fnOnChange(
                                newValue,
                                "ExistingTariffNode",
                                "LiquidTariffNodeId"
                              );
                            } else {
                              setSelectedExistingTariff({});
                              fnResetNodeSelections();
                              setEditRecord(false);
                              setEditNode(false);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Existing Tariff Node"}
                              placeholder="Existing Tariff Nodes"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="2">
                        <Button
                          type="button"
                          color="primary"
                          variant="contained"
                          className={classes.updatebutton}
                          disabled={
                            Object.keys(selectedexistingtariff).length === 0
                          }
                          onClick={() => fnFetchExistingTariffNode()}
                        >
                          Edit Node Details
                        </Button>
                      </Grid>
                      <Grid item xs="2">
                        <Button
                          type="button"
                          color="primary"
                          variant="contained"
                          className={classes.updatebutton}
                          onClick={() => fnRefreshPipelineFacilities()}
                        >
                          Refresh Pipeline Facilities
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>

              <Divider
                style={{ marginBottom: "5px", marginTop: "15px" }}
                light
              />
            </>
          )}

          {(editnode ||
            selectedexistingtariff.LiquidTariffNodeId === undefined) && (
            <>
              <Typography variant="subtitle2" style={{ marginBottom: "5px" }}>
                Add Tariff Nodes
              </Typography>
              <ValidatorForm>
                <>
                  <Grid
                    container
                    spacing="3"
                    direction="column"
                    className={classes.formcontainer}
                  >
                    <Grid item>
                      <Grid
                        container
                        spacing="3"
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                      >
                        <Grid item xs="3">
                          <Autocomplete
                            size="small"
                            options={pipelines}
                            getOptionLabel={(option) =>
                              option.PipelineName === undefined
                                ? ""
                                : option.PipelineName
                            }
                            value={selectedoriginpipelineheader}
                            onChange={(event, newValue) => {
                              if (newValue !== null) {
                                fnOnChange(
                                  newValue,
                                  "OriginPipelineName",
                                  "PipelineHeaderId"
                                );
                              } else {
                                setSelectedOriginPipelineHeader({});
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={"Select Origin Pipeline"}
                                placeholder="Origin Pipeline"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs="3">
                          <TextField
                            label="PointOfOrigin"
                            id="PointOfOriginId"
                            onChange={fnHandleChange("PointOfOrigin")}
                            value={tariffnodevalues.PointOfOrigin}
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs="3">
                          <Autocomplete
                            size="small"
                            options={originpipelinefacilities}
                            getOptionLabel={(option) =>
                              option.Terminal === undefined
                                ? ""
                                : option.Terminal
                            }
                            value={selectedpipelineoriginfacility}
                            onChange={(event, newValue) => {
                              if (newValue !== null) {
                                fnOnChange(newValue, "OriginTerminal");
                              } else {
                                setSelectedPipelineOriginFacility({});
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={"Select Origin Facility"}
                                placeholder="Origin Facility"
                              />
                            )}
                          />
                        </Grid>
                        {/* <Grid item xs="3">
                          <Autocomplete
                            multiple
                            size="small"
                            options={originpipelinesections}
                            getOptionLabel={(option) =>
                              option.PipelineSectionName
                            }
                            value={selectedoriginsections}
                            defaultValue={selectedoriginsections}
                            onChange={(event, newValue) => {
                              if (newValue.length > 0) {
                                setSelectedOriginSections([
                                  ...acoriginsections,
                                  ...newValue.filter(
                                    (option) =>
                                      acoriginsections.indexOf(option) === -1
                                  ),
                                ]);
                              } else {
                                setSelectedOriginSections(
                                  ...acoriginsections,
                                  newValue
                                );
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={"Select Origin Sections"}
                                placeholder="Origin Sections"
                              />
                            )}
                          />
                        </Grid> */}
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        spacing="3"
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                      >
                        <Grid item xs="3">
                          <Autocomplete
                            size="small"
                            options={pipelines}
                            getOptionLabel={(option) =>
                              option.PipelineName === undefined
                                ? ""
                                : option.PipelineName
                            }
                            value={selecteddestinationpipelineheader}
                            onChange={(event, newValue) => {
                              if (newValue !== null) {
                                fnOnChange(
                                  newValue,
                                  "DestinationPipelineName",
                                  "PipelineHeaderId"
                                );
                              } else {
                                setSelectedDestinationPipelineHeader({});
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={"Select Destination Pipeline"}
                                placeholder="Destination Pipeline"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs="3">
                          <TextField
                            label="PointOfDestination"
                            id="PointOfDestinationId"
                            onChange={fnHandleChange("PointOfDestination")}
                            value={tariffnodevalues.PointOfDestination}
                            variant="outlined"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs="3">
                          <Autocomplete
                            size="small"
                            options={destinationpipelinefacilities}
                            getOptionLabel={(option) =>
                              option.Terminal === undefined
                                ? ""
                                : option.Terminal
                            }
                            value={selectedpipelinedestinationfacility}
                            onChange={(event, newValue) => {
                              if (newValue !== null) {
                                fnOnChange(newValue, "DestinationTerminal");
                              } else {
                                setSelectedPipelineDestinationFacility({});
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={"Select Destination Facility"}
                                placeholder="Destination Facility"
                              />
                            )}
                          />
                        </Grid>
                        {/* <Grid item xs="3">
                          <Autocomplete
                            multiple
                            size="small"
                            options={destinationpipelinesections}
                            getOptionLabel={(option) =>
                              option.PipelineSectionName
                            }
                            value={selecteddestinationsections}
                            defaultValue={selecteddestinationsections}
                            onChange={(event, newValue) => {
                              if (newValue.length > 0) {
                                setSelectedDestinationSections([
                                  ...acdestinationsections,
                                  ...newValue.filter(
                                    (option) =>
                                      acdestinationsections.indexOf(option) ===
                                      -1
                                  ),
                                ]);
                              } else {
                                setSelectedDestinationSections(
                                  ...acdestinationsections,
                                  newValue
                                );
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={"Select Destination Sections"}
                                placeholder="Destination Sections"
                              />
                            )}
                          />
                        </Grid> */}
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        spacing="3"
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                      >
                        <Grid item xs="3">
                          <Autocomplete
                            multiple
                            size="small"
                            options={pipelines}
                            getOptionLabel={(option) =>
                              option.PipelineName === undefined
                                ? ""
                                : option.PipelineName
                            }
                            value={selectedtariffpipelines}
                            onChange={(event, newValue) => {
                              
                              setSelectedTariffPipelines([
                                ...acselectedtariffpipelines,
                                ...newValue.filter(
                                  (option) =>
                                    acselectedtariffpipelines.indexOf(
                                      option
                                    ) === -1
                                ),
                              ]);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={"Select Tariff Pipelines"}
                                placeholder="Select Tariff Pipelines"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs="2">
                          <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            className={classes.updatebutton}
                            disabled={selectedtariffpipelines.length === 0}
                            onClick={() => fnFetchSections()}
                          >
                            Fetch Sections
                          </Button>
                        </Grid>

                        <Grid item xs="3">
                          {tariffpipelinesections.length > 0 && (
                            <Autocomplete
                              multiple
                              size="small"
                              options={tariffpipelinesections}
                              getOptionLabel={(option) =>
                                option.PipelineSectionName
                              }
                              value={selectedtariffsections}
                              defaultValue={selectedtariffsections}
                              disabled={tariffpipelinesections.length === 0}
                              onChange={(event, newValue) => {
                                if (newValue.length > 0) {
                                  setSelectedTariffSections([
                                    ...actariffsections,
                                    ...newValue.filter(
                                      (option) =>
                                        actariffsections.indexOf(option) === -1
                                    ),
                                  ]);
                                } else {
                                  setSelectedTariffSections(
                                    ...actariffsections,
                                    newValue
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label={"Select Tariff Sections"}
                                  placeholder="Tariff Sections"
                                />
                              )}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      {editnode && (
                        <Grid
                          container
                          spacing="3"
                          direction="row"
                          justify="flex-end"
                          alignItems="center"
                        >
                          <Grid item xs="3">
                            <Button
                              type="button"
                              color="primary"
                              variant="contained"
                              className={classes.updatebutton}
                              disabled={
                                selectedexistingtariff.LiquidTariffNodeId ===
                                undefined
                              }
                              onClick={() => fnUpdateExistingNode()}
                            >
                              Update Node Details
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              </ValidatorForm>

              <Divider
                style={{ marginBottom: "5px", marginTop: "15px" }}
                light
              />
            </>
          )}
          {!editnode && (
            <>
              <Typography
                variant="subtitle2"
                style={{ marginBottom: "5px", marginTop: "15px" }}
              >
                Add Tariff Details
                {/* {JSON.stringify(tariffdetailvalues)} */}
              </Typography>
              <Typography variant="subtitle1" style={{ marginBottom: "5px" }}>
                Tariff Data
              </Typography>
              {existingtariffs.length > 0 && (
                <>
                  <Typography
                    variant="subtitle2"
                    style={{ marginBottom: "5px" }}
                  >
                    Select Existing Tariff Details
                  </Typography>

                  <>
                    <Grid
                      container
                      spacing="3"
                      direction="column"
                      className={classes.formcontainer}
                    >
                      <Grid item>
                        <Grid
                          container
                          spacing="3"
                          direction="row"
                          justify="flex-start"
                          alignItems="center"
                        >
                          <Grid item xs="3">
                            <Autocomplete
                              size="small"
                              options={existingtariffs}
                              getOptionLabel={(option) =>
                                option.LiquidTariffNode === undefined
                                  ? ""
                                  : option.LiquidTariffNode
                              }
                              value={selectedexistingtariffdetail}
                              onChange={(event, newValue) => {
                                if (newValue !== null) {
                                  fnOnChange(
                                    newValue,
                                    "ExistingTariffDetail",
                                    "LiquidTariffNodeId"
                                  );
                                } else {
                                  setSelectedExistingTariffDetail({});
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label={"Select Existing Tariff Detail"}
                                  placeholder="Existing Tariff Detail"
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>

                  <Divider
                    style={{ marginBottom: "5px", marginTop: "15px" }}
                    light
                  />
                </>
              )}
              {selectedexistingtariffdetail.LiquidTariffNodeId ===
                undefined && (
                <>
                  <ValidatorForm>
                    <>
                      <Grid
                        container
                        spacing="3"
                        direction="column"
                        className={classes.formcontainer}
                      >
                        <Grid item>
                          <Grid
                            container
                            spacing="3"
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                          >
                            <Grid item xs="3">
                              <TextField
                                label="TariffNumber"
                                id="TariffNumberId"
                                onChange={fnHandleDetailsChange(
                                  "LiquidTariffNumber"
                                )}
                                value={tariffdetailvalues.LiquidTariffNumber}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs="3">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableToolbar
                                  format="yyyy/MM/dd"
                                  margin="normal"
                                  id="EffectiveDateId"
                                  label="EffectiveDate"
                                  variant="outlined"
                                  value={tariffdetailvalues.EffectiveDate}
                                  onChange={fnHandleEffectiveDateChange}
                                  KeyboardButtonProps={{
                                    "aria-label": "EffectiveDate",
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs="3">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableToolbar
                                  format="yyyy/MM/dd"
                                  margin="normal"
                                  id="EndDateId"
                                  label="EndDate"
                                  variant="outlined"
                                  value={tariffdetailvalues.EndDate}
                                  onChange={fnHandleEndDateChange}
                                  KeyboardButtonProps={{
                                    "aria-label": "EndDate",
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs="3">
                              <Autocomplete
                                size="small"
                                options={tariffstatus}
                                getOptionLabel={(option) =>
                                  option.LiquidTariffStatus === undefined
                                    ? ""
                                    : option.LiquidTariffStatus
                                }
                                value={selectedtariffstatus}
                                onChange={(event, newValue) => {
                                  if (newValue !== null) {
                                    fnOnChange(
                                      newValue,
                                      "LiquidTariffStatus",
                                      "LiquidTariffStatusId"
                                    );
                                  } else {
                                    setSelectedTariffStatus({});
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label={"Select TariffStatus"}
                                    placeholder="TariffStatus"
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            spacing="3"
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                          >
                            <Grid item xs="3">
                              <Autocomplete
                                size="small"
                                options={tariffratetype}
                                getOptionLabel={(option) =>
                                  option.LiquidTariffRateType === undefined
                                    ? ""
                                    : option.LiquidTariffRateType
                                }
                                value={selectedtariffratetype}
                                onChange={(event, newValue) => {
                                  if (newValue !== null) {
                                    fnOnChange(
                                      newValue,
                                      "LiquidTariffRateType",
                                      "LiquidTariffRateTypeId"
                                    );
                                  } else {
                                    setSelectedTariffRateType({});
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label={"Select TariffRateType"}
                                    placeholder="TariffRateType"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs="3">
                              <TextField
                                label="TermYear"
                                id="TermYearId"
                                onChange={fnHandleDetailsChange("TermYear")}
                                value={tariffdetailvalues.TermYear}
                                variant="outlined"
                                size="small"
                                type="number"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs="3">
                              <TextField
                                label="BPDMin"
                                id="BPDMinId"
                                onChange={fnHandleDetailsChange("BPDMin")}
                                value={tariffdetailvalues.BPDMin}
                                variant="outlined"
                                size="small"
                                type="number"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs="3">
                              <TextField
                                label="BPDMax"
                                id="BPDMaxId"
                                type="number"
                                onChange={fnHandleDetailsChange("BPDMax")}
                                value={tariffdetailvalues.BPDMax}
                                variant="outlined"
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            spacing="3"
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                          >
                            <Grid item xs="3">
                              <TextField
                                label="AcreageDedicationMinAcres"
                                id="AcreageDedicationMinAcresId"
                                onChange={fnHandleDetailsChange(
                                  "AcreageDedicationMinAcres"
                                )}
                                value={
                                  tariffdetailvalues.AcreageDedicationMinAcres
                                }
                                variant="outlined"
                                size="small"
                                type="number"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs="3">
                              <TextField
                                label="AcreageDedicationMaxAcres"
                                id="AcreageDedicationMaxAcresId"
                                onChange={fnHandleDetailsChange(
                                  "AcreageDedicationMaxAcres"
                                )}
                                value={
                                  tariffdetailvalues.AcreageDedicationMaxAcres
                                }
                                variant="outlined"
                                size="small"
                                type="number"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            spacing="3"
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                          >
                            <Grid item xs="3">
                              <TextField
                                label="LiquidRateCentsPerBbl"
                                id="LiquidRateCentsPerBblId"
                                onChange={fnHandleDetailsChange(
                                  "LiquidRateCentsPerBbl"
                                )}
                                value={tariffdetailvalues.LiquidRateCentsPerBbl}
                                variant="outlined"
                                size="small"
                                type="number"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs="3">
                              <TextField
                                label="SurchargeCentsPerBbl"
                                id="SurchargeCentsPerBblId"
                                onChange={fnHandleDetailsChange(
                                  "SurchargeCentsPerBbl"
                                )}
                                value={tariffdetailvalues.SurchargeCentsPerBbl}
                                variant="outlined"
                                size="small"
                                type="number"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs="3">
                              <Autocomplete
                                size="small"
                                options={tarifffueltype}
                                getOptionLabel={(option) =>
                                  option.LiquidTariffFuelType === undefined
                                    ? ""
                                    : option.LiquidTariffFuelType
                                }
                                value={selectedtarifffueltype}
                                onChange={(event, newValue) => {
                                  if (newValue !== null) {
                                    fnOnChange(
                                      newValue,
                                      "LiquidTariffFuelType",
                                      "LiquidTariffFuelTypeId"
                                    );
                                  } else {
                                    setSelectedTariffFuelType({});
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label={"Select TariffFuelType"}
                                    placeholder="TariffFuelType"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs="3">
                              <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                className={classes.updatebutton}
                                disabled={
                                  tariffdetailvalues.LiquidTariffRateTypeId ===
                                    "" ||
                                  tariffdetailvalues.LiquidTariffStatusId ===
                                    "" ||
                                  tariffdetailvalues.LiquidTariffNumber === ""
                                }
                                onClick={() => fnAddTariffDetails()}
                              >
                                Add Tariff
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  </ValidatorForm>
                  <Divider style={{ marginBottom: "5px", marginTop: "15px" }} />

                  <Grid
                    container
                    spacing="3"
                    direction="column"
                    className={classes.formcontainer}
                  >
                    <Grid item>
                      <Grid container>
                        <Grid xs={12} style={{ maxWidth: "98.7%" }} item>
                          {tariffdetails.length > 0 ? (
                            <>
                              <MUIDataTable
                                title="Tariff Details"
                                data={tariffdetails}
                                columns={tariffdetailcolumns}
                                options={options}
                              />
                            </>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              {selectedexistingtariffdetail.LiquidTariffNodeId !==
                undefined && (
                <>
                  <Paper>
                    <Table
                      columns={tariffcolumns}
                      dataSource={existingtariffdata}
                      className={classes.operatortable}
                      bordered
                      title={() => {
                        return `${existingtariffdata.length} Records Found`;
                      }}
                      // pagination={false}
                      size="middle"
                      scroll={{ x: 1500, y: 500 }}
                    />
                  </Paper>
                </>
              )}

              <Grid
                container
                spacing="3"
                direction="column"
                className={classes.formcontainer}
              >
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      {selectedexistingtariff.LiquidTariffNodeId ===
                      undefined ? (
                        <Button
                          type="button"
                          color="primary"
                          variant="contained"
                          className={classes.updatebutton}
                          disabled={
                            (tariffdetails.length === 0 &&
                              selectedexistingtariffdetail.LiquidTariffNodeId ===
                                undefined) ||
                            tariffnodevalues.PipelineHeaderId === null
                          }
                          onClick={() => fnInsertTariff()}
                        >
                          Insert Tariff Data
                        </Button>
                      ) : (
                        <Button
                          type="button"
                          color="primary"
                          variant="contained"
                          className={classes.updatebutton}
                          disabled={
                            tariffdetails.length === 0 &&
                            selectedexistingtariffdetail.LiquidTariffNodeId ===
                              undefined
                          }
                          onClick={() => fnInsertExistingTariff()}
                        >
                          Insert Tariff Details
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </Grid>
      <Dialog
        maxWidth="md"
        aria-labelledby="confirmation-dialog-title"
        open={detaileditdialog}
        onClose={fnclosedetaileditdialog}
      >
        <DialogContent dividers>
          <EditTariffDetailComp
            data={editrecord}
            fnclosedetaileditdialog={fnclosedetaileditdialog}
          ></EditTariffDetailComp>
        </DialogContent>
      </Dialog>
      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(AddTariffComp);
