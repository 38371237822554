import React, { useEffect, useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import AlertNotificationComp from "components/custom/AlertNotificationComp";

import { customizedDateFormat } from "constants/utils";

import { plantstatus } from "lookuptables/global";

//date
import moment from "moment";

//linq
import linq from "linq";

//Redux imports
import { useSelector } from "react-redux";

//Api
import axios from "axios";

//Redux imports
import { useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import { withRouter } from "react-router-dom";

import { regulatorytypes, lengthmilestype } from "lookuptables/global";
import ChangePipelineName from "../../screens/changepipelinename";

const useStyles = makeStyles((theme) => ({
  operatordatacontainer: {
    margin: 10,
    width: "100%",
    "& .MuiPaper-root": {
      width: "100%",
    },
  },
  formcontainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-container": {
      marginBottom: 3.5,
    },
  },

  operatorlist: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    maxHeight: 700,
    minHeight: 700,
  },
  operatordata: {
    padding: 10,
    height: 700,
    maxHeight: 700,
  },
  updatebutton: {
    width: "100%",
  },
}));

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

function ChangePipelineNameComp(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const pipelinedataRef = useRef(null);

  const [selectedindex, setSelectedIndex] = React.useState("");

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "error",
  });

  const [pipelinenames, setPipelineNames] = React.useState({});

  const [pipeline, setPipeline] = React.useState("");
  const [editpipeline, setEditPipeline] = React.useState("");

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "info" });
  };

  const fnFetchUniqueValues = (searchkey) => {
    var tablename = "Pipeline";

    const query = { columnname: searchkey, tablename: tablename };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchsubjectuniquevalues", query, options).then(
      (response) => {
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "no records") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            switch (searchkey) {
              case "PipelineName":
                setPipelineNames(response.data[0].data);
                break;
            }
          }
        }
      },
      (error) => {
        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
        }
      }
    );
  };

  const fnUpdatePipelineName = () => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      pipeline: pipeline,
      newpipeline: editpipeline,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/modifypipelinename", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error while modifing pipeline name",
            variant: "error",
          });
        }else if (response.data[0].status === "failed") {
          setStatusBase({
            show: true,
            message: "Error while modifing pipeline name in Postgre.",
            variant: "error",
          });
        }
        else if (response.data[0].status === "incomplete") {
          setStatusBase({
            show: true,
            message: "Updated only in postre database. Please contact admin.",
            variant: "error",
          });
        } else if (response.data[0].status === "updated") {
          setStatusBase({
            show: true,
            message: "Pipeline updated successfully",
            variant: "info",
          });

          window.location.reload();
        } else {
          setStatusBase({
            show: true,
            message: "Error while updating pipeline",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while updating pipeline",
            variant: "error",
          });
        }
      }
    );
  };

  useEffect(() => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

    var searchkeys = ["PipelineName"];

    searchkeys.forEach((element) => {
      fnFetchUniqueValues(element);
    });
  }, []);

  useEffect(() => {
    if (pipeline != "") {
      setEditPipeline("");
    }
  }, [pipeline]);

  return (
    <>
      <Grid style={{ padding: "10px" }} direction="column" container>
        <Grid item>
          <Typography variant="title">Change Pipeline Name</Typography>
        </Grid>
        <Grid item>
          {/* {JSON.stringify(gasplantvalues)} */}
          {pipelinenames.length > 0 ? (
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={6}>
                <Autocomplete
                  size="small"
                  id="pipelinelistId"
                  options={pipelinenames}
                  // getOptionLabel={(option) => option}

                  filterSelectedOptions
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setPipeline(newValue);
                    } else {
                      setPipeline({});
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Search & Select Pipeline"
                      placeholder="Pipelines"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Enter Pipeline Name"
                  id="editpiplineId"
                  value={editpipeline}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setEditPipeline(e.target.value);
                  }}
                  variant="outlined"
                  disabled={pipeline === ""}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  className={classes.updatebutton}
                  disabled={pipeline === "" || editpipeline === ""}
                  onClick={() => fnUpdatePipelineName()}
                >
                  Modify Pipeline Name
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(ChangePipelineNameComp);
