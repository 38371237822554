import React, { Suspense, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import Snackbar from "@material-ui/core/Snackbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputBase from "@material-ui/core/InputBase";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import LinearProgress from "@material-ui/core/LinearProgress";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";

import LookupInsertComp from "components/common/LookupInsertComp";
import AlertNotificationComp from "components/custom/AlertNotificationComp";

import MUIDataTable from "mui-datatables";

//linq
import linq from "linq";

//date
import moment from "moment";

//Api
import axios from "axios";

//Redux imports
import { useSelector, useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import { deviceterminaltypes } from "lookuptables/global";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    borderRadius: 4,
    width: "350px",
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },
  operatoraddcontainer: {
    "& .MuiPaper-root": {
      width: "100%",
      padding: 10,
      margin: 10,
    },
  },
  formcontainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-container": {
      // marginBottom: 10,
    },
  },
  updatebutton: {
    width: "100%",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

function UpdateGasPipelineLocationCodesComp(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "error",
  });

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "error" });
  };

  const [selectedPipeline, setSelectedPipeline] = React.useState({});
  const [pipelinenames, setPipelineNames] = React.useState([]);

  const fnFetchUniqueValues = (searchkey) => {
    var tablename = "pipelineflowlocationcodes";

    const query = { columnname: searchkey, tablename: tablename };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchsubjectuniquevalues", query, options).then(
      (response) => {
        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error While Fetching Data",
            variant: "error",
          });
        } else if (response.data[0].status === "no records") {
          setStatusBase({
            show: true,
            message: "No Records Found",
            variant: "info",
          });
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            setPipelineNames(response.data[0].data);
          }
        }
      },
      (error) => {
        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
        }
      }
    );
  };

  const [selectedLocation, setSelectedLocation] = React.useState({});
  const [locationdata, setLocationData] = React.useState([]);
  const fnFetchLocationDataForPipeline = (pipeline) => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const query = { PipelineHeaderId: pipeline.PipelineHeaderId };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchstcntylocationdata", query, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error While Fetching Data",
            variant: "error",
          });
        } else if (response.data[0].status === "no records") {
          setStatusBase({
            show: true,
            message: "No Records Found",
            variant: "info",
          });
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            setLocationData(response.data[0].data);
          }
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        setStatusBase({
          show: true,
          message: "Error While Fetching Data",
          variant: "error",
        });

        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
        }
      }
    );
  };

  const [selectedLocationCode, setSelectedLocationCode] = React.useState({});
  const [locationcodes, setLocationCodes] = React.useState([]);
  const fnFetchLocationCodes = (location) => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const query = {
      PipelineHeaderId: selectedPipeline.PipelineHeaderId,
      CountyId: location.CountyId,
      StateNameId: location.StateNameId,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchlocationcodesbylocation", query, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error While Fetching Data",
            variant: "error",
          });
        } else if (response.data[0].status === "no records") {
          setStatusBase({
            show: true,
            message: "No Records Found",
            variant: "info",
          });
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            setLocationCodes(response.data[0].data);
          }
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        setStatusBase({
          show: true,
          message: "Error While Fetching Data",
          variant: "error",
        });

        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
        }
      }
    );
  };

  const [selectedFacilityType, setSelectedFacilityType] = React.useState({});
  const [facilitytypes, setFacilityTypes] = React.useState([]);
  const fnFetchFacilityTypes = () => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const query = {};

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchfacilitytypes", query, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error While Fetching Data",
            variant: "error",
          });
        } else if (response.data[0].status === "no records") {
          setStatusBase({
            show: true,
            message: "No Records Found",
            variant: "info",
          });
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            setFacilityTypes(response.data[0].data);
          }
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        setStatusBase({
          show: true,
          message: "Error While Fetching Data",
          variant: "error",
        });

        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
        }
      }
    );
  };

  const [selectedFacility, setSelectedFacility] = React.useState({});
  const [facilities, setFacilities] = React.useState([]);
  const fnFetchFacilities = (facilitytype) => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const query = {
      facilitytype: facilitytype.FacilityType,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchfacilitiesbyfacilitytype", query, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error While Fetching Data",
            variant: "error",
          });
        } else if (response.data[0].status === "no records") {
          setStatusBase({
            show: true,
            message: "No Records Found",
            variant: "info",
          });
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            setFacilities(response.data[0].data);
          }
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        setStatusBase({
          show: true,
          message: "Error While Fetching Data",
          variant: "error",
        });

        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
        }
      }
    );
  };

  const fnUpdateLocationFacility = () => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const query = {
      LocationCodeId: selectedLocationCode.PipelineLocationCodeId,
      FacilityId: selectedFacility.FacilityId,
      FacilityType: selectedFacilityType.FacilityType,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/updatelocationcodefacility", query, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error While Updating Data",
            variant: "error",
          });
        } else if (response.data[0].status === "no records") {
          setStatusBase({
            show: true,
            message: "Update Failed",
            variant: "info",
          });
        } else if (response.data[0].status === "success") {
          setStatusBase({
            show: true,
            message: "Facility Data Updated",
            variant: "info",
          });

          setSelectedFacility({});
          setSelectedFacilityType({});
          setSelectedLocationCode({});

          setFacilities([]);
          setFacilityTypes([]);

        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        setStatusBase({
          show: true,
          message: "Error While Fetching Data",
          variant: "error",
        });

        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
        }
      }
    );
  };

  useEffect(() => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

    fnFetchUniqueValues("PipelineName,PipelineHeaderId");
  }, []);

  return (
    <>
      <Grid className={classes.operatoraddcontainer} container>
        <Paper>
          <Typography variant="subtitle1">
            Map Facility to Location Code
          </Typography>
          {pipelinenames.length > 0 ? (
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={6}>
                <Autocomplete
                  size="small"
                  id="pipelinelistId"
                  options={pipelinenames}
                  getOptionLabel={(option) =>
                    option.PipelineName === undefined ? "" : option.PipelineName
                  }
                  value={selectedPipeline}
                  onChange={(event, newValue) => {
                    if (newValue != null) {
                      setSelectedPipeline(newValue);

                      fnFetchLocationDataForPipeline(newValue);
                    } else {
                      setSelectedPipeline({});
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Search & Select Pipeline"
                      placeholder="Pipelines"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                {locationdata.length > 0 && (
                  <Autocomplete
                    size="small"
                    id="pipelinelistId"
                    options={locationdata}
                    getOptionLabel={(option) =>
                      option.StateName === undefined
                        ? ""
                        : `${option.StateName} - ${option.CountyName}`
                    }
                    value={selectedLocation}
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        setSelectedLocation(newValue);

                        fnFetchLocationCodes(newValue);
                      } else {
                        setSelectedLocation({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Search & Select Location"
                        placeholder="Location"
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {locationcodes.length > 0 && (
                  <Autocomplete
                    size="small"
                    id="pipelinelistId"
                    options={locationcodes}
                    getOptionLabel={(option) =>
                      option.PipelineLocationCode === undefined
                        ? ""
                        : option.PipelineLocationCode
                    }
                    value={selectedLocationCode}
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        setSelectedLocationCode(newValue);

                        fnFetchFacilityTypes();
                      } else {
                        setSelectedLocationCode({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Search & Select Location Code"
                        placeholder="Location Code"
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {selectedLocationCode.PipelineLocationCode != undefined && (
                  <>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                    >
                      <Grid row>
                        Location Code :{" "}
                        <b>{selectedLocationCode.PipelineLocationCode}</b>
                      </Grid>
                      <Grid row>
                        Location Actual Name :{" "}
                        <b>{selectedLocationCode.LocationActualName}</b>
                      </Grid>
                      <Grid row>
                        Zone :<b> {selectedLocationCode.LocationZone}</b>
                      </Grid>
                      <Grid row>
                        UpDownName : <b>{selectedLocationCode.UpDownName}</b>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={6}>
                {facilitytypes.length > 0 && (
                  <Autocomplete
                    size="small"
                    id="pipelinelistId"
                    options={facilitytypes}
                    getOptionLabel={(option) =>
                      option.FacilityType === undefined
                        ? ""
                        : option.FacilityType
                    }
                    value={selectedFacilityType}
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        setSelectedFacilityType(newValue);

                        fnFetchFacilities(newValue);
                      } else {
                        setSelectedFacilityType({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Search & Select Facility Type"
                        placeholder="Facility Type"
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {facilities.length > 0 && (
                  <Autocomplete
                    size="small"
                    id="facilitieslistId"
                    options={facilities}
                    getOptionLabel={(option) =>
                      option.FacilityName === undefined
                        ? ""
                        : option.FacilityName
                    }
                    value={selectedFacility}
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        setSelectedFacility(newValue);
                      } else {
                        setSelectedFacility({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Search & Select Facility"
                        placeholder="Facility"
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {locationcodes.length > 0 && (
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={() => fnUpdateLocationFacility()}
                    disabled={
                      selectedLocationCode.PipelineLocationCode === "" ||
                      selectedFacilityType.FacilityType === "" ||
                      selectedFacility.FacilityName === ""
                    }
                  >
                    Update Location Facility
                  </Button>
                )}
              </Grid>
            </Grid>
          ) : null}
        </Paper>
      </Grid>
      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(UpdateGasPipelineLocationCodesComp);
