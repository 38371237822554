import React, { Suspense } from 'react';

import Grid from '@material-ui/core/Grid';


import LinearProgress from '@material-ui/core/LinearProgress';

import { makeStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router-dom';

import PipelineSubComp from './subcomponents/PipelineSubComp';

const PipelineSearchComponent = React.lazy(
	() => new Promise((resolve, reject) => setTimeout(() => resolve(import('./subcomponents/PipelineSearchComp')), 100))
);

const PipelineUpdateComponent = React.lazy(
	() => new Promise((resolve, reject) => setTimeout(() => resolve(import('./subcomponents/PipelineUpdateComp')), 100))
);

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
	},
}));

function PipelineComp(props) {
	const classes = useStyles();

	const [searchpipelinequery, setSearchPipelineQuery] = React.useState('');

	

	return (
		<>
			<Grid container>
				<Suspense
					fallback={
						<>
							<LinearProgress />
						</>
					}
				>
					<PipelineSearchComponent setSearchPipelineQuery={setSearchPipelineQuery} />
				</Suspense>
			</Grid>
			<Grid container>
				{/* <Suspense
					fallback={
						<>
							<LinearProgress />
						</>
					}
				>
					<PipelineUpdateComponent searchpipelinequery={searchpipelinequery} />
				</Suspense> */}
				

				<PipelineSubComp searchpipelinequery={searchpipelinequery}></PipelineSubComp>
			</Grid>
		</>
	);
}

export default withRouter(PipelineComp);
