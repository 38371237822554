import React from 'react';

import Grid from '@material-ui/core/Grid';

// import HomeComp from 'components/user/HomeComp';
import HeaderComp from 'components/custom/HeaderComp';
import AddPipelineInterconnectsComp from 'components/pipeline/AddPipelineInterconnectsComp';

const homeStyles = {
	home: {
		height: '100%',
	},
};

export default class AddPipelineInterconnects extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<React.Fragment>
				<div>
					<HeaderComp></HeaderComp>
				</div>
				<div>
					<AddPipelineInterconnectsComp></AddPipelineInterconnectsComp>
				</div>
			</React.Fragment>
		);
	}
}
