import React from 'react';
import Grid from '@material-ui/core/Grid';

import ChangePasswordComp from 'components/user/ChangePasswordComp';
import HeaderComp from 'components/custom/HeaderComp';

export default class ChangePassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<React.Fragment>
				<Grid container direction="column">
					<Grid item>
						<HeaderComp></HeaderComp>
					</Grid>
					<Grid item align="center">
						<ChangePasswordComp history={this.props.history}></ChangePasswordComp>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	}
}
