import React, { useEffect, Suspense } from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

import "antd/dist/antd.css";

import AlertNotificationComp from "components/custom/AlertNotificationComp";

import ReactJson from "react-json-view";

//linq
import linq from "linq";

//Redux imports
import { useSelector } from "react-redux";

//Api
import axios from "axios";

//Router
import { withRouter } from "react-router-dom";

//Redux imports
import { useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";
import MUIDataTable from "mui-datatables";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    borderRadius: 4,
    width: "350px",
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  operatortable: {
    width: "100%",
    "& .ant-table-title": {
      textTransform: "capitalize",
    },
  },
  operatorsearchcontainer: {
    "& .MuiPaper-root": {
      width: "100%",
      padding: 10,
      margin: 10,
    },
  },
  searchcontrols: {
    "& .MuiTextField-root": {
      width: "80%",
      margin: 10,
    },
  },
  rowcontrols: {
    marginTop: 20,
  },
  addbutton: {
    marginLeft: 10,
  },
  fetchfacility: {
    marginTop: theme.spacing(1),
    width: "200px",
  },
}));

function UserWorkflowComp(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "info",
  });

  const [confirmdialog, setConfirmDialog] = React.useState(false);

  const [selecteduser, setSelectedUser] = React.useState({});
  const [selecteddate, setSelectedDate] = React.useState({});

  const options = {
    print: false,
    selectableRows: "none",
  };

  const workflowcolumns = [
    {
      label: "Id",
      name: "id",
      options: {
        filter: true,
      },
    },
    {
      name: "UserName",
      label: "User Name",
      options: {
        filter: true,
      },
    },
    {
      name: "URL",
      label: "URL",
      options: {
        filter: true,
      },
    },
    {
      name: "UserInput",
      label: "Input",
      options: {
        filter: true,
      },
    },
    {
      name: "recordedtime",
      label: "DateTime",
      options: {
        filter: true,
      },
    },
  ];

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "info" });
  };

  const [userdates, setUserDates] = React.useState([]);
  const fnFetchDates = (user) => {
    setUserDates([]);
    setSelectedDate({});
    setUserWorkflows([]);
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      username: user.UserName,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchdates", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while fetching records",
              variant: "error",
            });
          } else if (response.data[0].status == "no records found") {
            setStatusBase({
              show: true,
              message: "user data not found",
              variant: "info",
            });
          } else if (response.data[0].data.length > 0) {
            setUserDates(response.data[0].data);
          }
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching user data. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching facilities data",
            variant: "error",
          });
        }
      }
    );
  };

  const [users, setUsers] = React.useState([]);
  const fnFetchUsers = () => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {};

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchusers", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while fetching users",
              variant: "error",
            });
          } else if (response.data[0].status == "no users found") {
            setStatusBase({
              show: true,
              message: "users data not found",
              variant: "info",
            });
          } else if (response.data[0].data.length > 0) {
            setUsers(response.data[0].data);
          }
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching users data. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching facilities data",
            variant: "error",
          });
        }
      }
    );
  };

  const [userworkflows, setUserWorkflows] = React.useState([]);
  const fnFetchWorkflow = () => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      username: selecteduser.UserName,
      selecteddate: selecteddate.RecordedDate,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchworkflow", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while fetching data",
              variant: "error",
            });
          } else if (response.data[0].status == "no data found") {
            setStatusBase({
              show: true,
              message: "user data not found",
              variant: "info",
            });
          } else if (response.data[0].data.length > 0) {
            setUserWorkflows(response.data[0].data);
          }
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching user data. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching user data",
            variant: "error",
          });
        }
      }
    );
  };

  const handleCancel = () => {
    setConfirmDialog(false);
  };

  const handleOk = () => {
    setConfirmDialog(false);
  };

  useEffect(() => {
    fnFetchUsers();
  }, []);

  return (
    <>
      <Grid className={classes.operatorsearchcontainer} container>
        <Paper>
          <Typography variant="subtitle1">User Workflow</Typography>
          {/* {JSON.stringify(values)} */}
          <Grid container className={classes.searchcontrols}>
            <Grid item xs={12} className={classes.rowcontrols}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Grid item xs={3}>
                  <Autocomplete
                    size="small"
                    id="userslistId"
                    options={users}
                    getOptionLabel={(option) =>
                      option.UserNames === undefined ? "" : option.UserNames
                    }
                    value={selecteduser}
                    disabled={users.length === 0}
                    filterSelectedOptions
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        setSelectedUser(newValue);
                        fnFetchDates(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select User"
                        placeholder="Users"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    size="small"
                    id="dateslistId"
                    options={userdates}
                    getOptionLabel={(option) =>
                      option.RecordedDate === undefined
                        ? ""
                        : option.RecordedDate
                    }
                    disabled={userdates.length === 0}
                    filterSelectedOptions
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        setSelectedDate(newValue);
                        // fnOnFacilityChange(
                        //   newValue,
                        //   "Facilityname",
                        //   "FacilityId"
                        // );
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Date"
                        placeholder="Dates"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={() => fnFetchWorkflow()}
                    className={classes.fetchfacility}
                  >
                    Fetch Workflow
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {userworkflows.length > 0 && (
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid item>
                <MUIDataTable
                  title={selecteduser.UserNames + " Workflow Data"}
                  data={userworkflows}
                  columns={workflowcolumns}
                  options={options}
                />
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>

      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
      <Dialog
        maxWidth="sm"
        aria-labelledby="confirmation-dialog-title"
        open={confirmdialog}
        onClose={handleCancel}
      >
        <DialogContent dividers>Delete this facility?</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withRouter(UserWorkflowComp);
