import React, { useEffect, Suspense } from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

import "antd/dist/antd.css";

import AlertNotificationComp from "components/custom/AlertNotificationComp";

import ReactJson from "react-json-view";

//linq
import linq from "linq";

//Redux imports
import { useSelector } from "react-redux";

//Api
import axios from "axios";

//Router
import { withRouter } from "react-router-dom";

//Redux imports
import { useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
  },
  input: {
    borderRadius: 4,
    width: "350px",
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  operatortable: {
    width: "100%",
    "& .ant-table-title": {
      textTransform: "capitalize",
    },
  },
  operatorsearchcontainer: {
    "& .MuiPaper-root": {
      width: "100%",
      padding: 10,
      margin: 10,
    },
  },
  searchcontrols: {
    "& .MuiTextField-root": {
      width: "80%",
      margin: 10,
    },
  },
  rowcontrols: {
    marginTop: 20,
  },
  addbutton: {
    marginLeft: 10,
  },
  fetchfacility: {
    marginTop: theme.spacing(1),
    width: "200px",
  },
}));

function DeleteFacilityComp(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "info",
  });

  const [confirmdialog, setConfirmDialog] = React.useState(false);

  const [facility, setFacility] = React.useState({
    FacilityType: "",
    FacilityId: "",
  });

  const [facilities, setFacilities] = React.useState([]);
  const [selectedfacility, setSelectedFacility] = React.useState({});

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "info" });
  };

  const fnHandleChange = (prop) => (event) => {
    setFacility({ ...facility, [prop]: event.target.value });

    if (prop === "FacilityType") {
      fnFetchFacilities(event.target.value);
    }
  };

  const fnOnFacilityChange = (newvalue, type, typeid) => {
    if (newvalue !== null) {
      setFacility({ ...facility, ["FacilityId"]: newvalue.FacilityId });
    }
  };

  const fnFetchFacilities = (ftype) => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      facilitytype: ftype,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchfacilitiesbytype", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while fetching records",
              variant: "error",
            });
          } else if (response.data[0].status == "no records found") {
            setStatusBase({
              show: true,
              message: "Facilities data not found",
              variant: "info",
            });
          } else if (response.data[0].data.length > 0) {
            setFacilities(response.data[0].data);
          }
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching facilities data. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching facilities data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnFetchFacilityData = (fid) => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      facilitytype: facility.FacilityType,
      facilityid: facility.FacilityId,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchfacilitydetails", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while fetching records",
              variant: "error",
            });
          } else if (response.data[0].status == "no records found") {
            setStatusBase({
              show: true,
              message: "Facility data not found",
              variant: "info",
            });
          } else if (response.data[0].data.length > 0) {
            setSelectedFacility(response.data[0].data);
          }
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching facility data. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching facility data",
            variant: "error",
          });
        }
      }
    );
  };

  const handleCancel = () => {
    setConfirmDialog(false);
  };

  const handleOk = () => {
    setConfirmDialog(false);
    fnDeleteFacility();
  };

  const fnDeleteFacility = () => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      facilitytype: facility.FacilityType,
      facilityid: facility.FacilityId,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/deletefacility", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: `Error while deleting record - ${response.data[0].msg}`,
              variant: "error",
            });
          } else if (response.data[0].status === "exists") {
            setStatusBase({
              show: true,
              message:
                "Error, Facility is connected to Pipeline...Please contact admin",
              variant: "error",
            });
          } else if (response.data[0].status === "success") {
            setStatusBase({
              show: true,
              message: "Facility deleted.",
              variant: "info",
            });

            window.location.reload();
          }
        } else {
          setStatusBase({
            show: true,
            message: "Error while deleting record. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while deleting facility",
            variant: "error",
          });
        }
      }
    );
  };

  useEffect(() => {}, []);

  return (
    <>
      <Grid className={classes.operatorsearchcontainer} container>
        <Paper>
          <Typography variant="subtitle1">Search Facility</Typography>
          {/* {JSON.stringify(values)} */}
          <Grid container className={classes.searchcontrols}>
            <Grid item xs={12} className={classes.rowcontrols}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Grid item xs={3}>
                  <FormControl>
                    <InputLabel id="demo-customized-select-label">
                      Facility Type
                    </InputLabel>
                    <Select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      // value={age}
                      onChange={fnHandleChange("FacilityType")}
                      input={<BootstrapInput />}
                    >
                      <MenuItem value="">
                        <em>Select Facility Type</em>
                      </MenuItem>
                      <MenuItem value={"Gas Plant"}>Gas Plant</MenuItem>
                      <MenuItem value={"Gas Storage"}>Gas Storage</MenuItem>
                      <MenuItem value={"Refinery"}>Refinery</MenuItem>
                      <MenuItem value={"Terminal"}>Terminal</MenuItem>
                      <MenuItem value={"Liquefaction"}>Liquefaction</MenuItem>
                      <MenuItem value={"Compressor Station"}>
                        Compressor Station
                      </MenuItem>
                      <MenuItem value={"Pumping Station"}>
                        Pumping Station
                      </MenuItem>
                      <MenuItem value={"PipelineSystem"}>
                        PipelineSystem
                      </MenuItem>
                      <MenuItem value={"PipelineSection"}>
                        PipelineSection
                      </MenuItem>
                      <MenuItem value={"IndustrialPlant"}>
                        IndustrialPlant
                      </MenuItem>
                      <MenuItem value={"PowerPlant"}>PowerPlant</MenuItem>
                      <MenuItem value={"Regulatory Station"}>
                        Regulatory Station
                      </MenuItem>
                      <MenuItem value={"Metering Station Delivery"}>
                        Metering Station Delivery
                      </MenuItem>
                      <MenuItem value={"Metering Station Receipt"}>
                        Metering Station Receipt
                      </MenuItem>
                      <MenuItem value={"Metering Station Bidirectional"}>
                        Metering Station Bidirectional
                      </MenuItem>
                      <MenuItem value={"Rural Tap"}>Rural Tap</MenuItem>
                      <MenuItem value={"Field Gathering Station"}>
                        Field Gathering Station
                      </MenuItem>
                      <MenuItem value={"Gas Oil Seperation Point"}>
                        Gas Oil Seperation Point
                      </MenuItem>
                      <MenuItem value={"Tee Junction"}>Tee Junction</MenuItem>
                      <MenuItem value={"Dehydration Equipment"}>
                        Dehydration Equipment
                      </MenuItem>
                      <MenuItem value={"Tank"}>Tank</MenuItem>
                      <MenuItem value={"Town Border Station"}>
                        Town Border Station
                      </MenuItem>
                      <MenuItem value={"CO2 Production"}>
                        CO2 Production
                      </MenuItem>
                      <MenuItem value={"CO2Storage"}>
                        CO2 Storage
                      </MenuItem>
                      <MenuItem value={"LDC"}>LDC</MenuItem>
                      <MenuItem value={"CCUS"}>CCUS</MenuItem>
                      <MenuItem value={"Interconnect"}>Interconnect</MenuItem>
                      <MenuItem value={"Pooling Point"}>Pooling Point</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    size="small"
                    id="facilitieslistId"
                    options={facilities}
                    getOptionLabel={(option) =>
                      option.FacilityName === undefined
                        ? ""
                        : option.FacilityName
                    }
                    disabled={facilities.length === 0}
                    filterSelectedOptions
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        fnOnFacilityChange(
                          newValue,
                          "Facilityname",
                          "FacilityId"
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Search By Facility Name"
                        placeholder="facilities"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={() => fnFetchFacilityData()}
                    className={classes.fetchfacility}
                  >
                    Search Facility
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item>
              <ReactJson
                src={selectedfacility}
                displayDataTypes={false}
              ></ReactJson>
            </Grid>
            <Grid item>
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={() => setConfirmDialog(true)}
                className={classes.fetchfacility}
                disabled={Object.keys(selectedfacility).length === 0}
              >
                Delete Facility
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
      <Dialog
        maxWidth="sm"
        aria-labelledby="confirmation-dialog-title"
        open={confirmdialog}
        onClose={handleCancel}
      >
        <DialogContent dividers>Delete this facility?</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withRouter(DeleteFacilityComp);
