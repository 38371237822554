import * as actionType from 'redux/actions/actionType';

let initialState = {
	theme:'naveentheme'
};

function customizationReducer(state = initialState, action) {
	switch (action.type) {
		case actionType.SET_THEME:
			state.theme = action.payload.theme;
			return state;

		default:
			return state;
	}
}

export default customizationReducer;
