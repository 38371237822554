import * as actionType from 'redux/actions/actionType';

let initialState = {
	loading: false,
};

function spinnerReducer(state = initialState, action) {
	switch (action.type) {
		case actionType.LOADINGSPINNER:
			state.loading = action.payload.loading;
			return state;

		default:
			return state;
	}
}

export default spinnerReducer;
