import { createMuiTheme } from '@material-ui/core/styles';

export const naveentheme = createMuiTheme({
	typography: {
		fontFamily: [
			'-apple-system',
			'"Segoe UI"',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		fontSize: 14,
	},
	palette: {
		primary: {
			main: '#74b9ff',
			contrastText: '#ffffff',
			shadows: ['none'],
		},
		text: {
			primary: '#636e72',
		},
	},

	//     success:{

	//     },
	//     warning:{

	//     },
	//     error:{

	//     },
	//     text:{

	//     }
	// }
	shape: {
		borderRadius: 5,
	},
	// shadows: ['0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'],
	overrides: {
		MuiButton: {
			// Name of the styleSheet
			root: {
				// Name of the rule
				// color: 'white',
				boxShadow: 'none',

				textTransform: 'none',
				'&:hover': {
					backgroundColor: 'none',
					textDecoration: 'underline',
					'@media (hover: none)': {
						backgroundColor: 'none',
						textDecoration: 'underline',
					},
				},
			},
			outlined: {
				boxShadow: 'none',
				'&:hover': {
					backgroundColor: 'none',
					textDecoration: 'underline',
				},
			},
			contained: {
				minWidth: 150,
				boxShadow: 'none',
				'&:hover': {
					textDecoration: 'none',
				},
			},
			containedPrimary: {
				boxShadow: 'none',
				minWidth: 150,
				'&:hover': {
					backgroundColor: '#0984e3',
				},
			},
		},
	},
});
