import React, { Suspense, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import Snackbar from "@material-ui/core/Snackbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import LinearProgress from "@material-ui/core/LinearProgress";

import { makeStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";

import LookupInsertComp from "components/common/LookupInsertComp";
import AlertNotificationComp from "components/custom/AlertNotificationComp";

//date
import moment from "moment";

//Api
import axios from "axios";

//Redux imports
import { useSelector, useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import { plantstatus } from "lookuptables/global";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },
  operatoraddcontainer: {
    "& .MuiPaper-root": {
      width: "100%",
      padding: 10,
      margin: 10,
    },
  },
  formcontainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-container": {
      // marginBottom: 10,
    },
  },
  updatebutton: {
    width: "100%",
  },
}));

function AddPipelineComp(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const [pipelines, setPipelines] = React.useState([]);

  const operatorlookupvalues = useSelector(
    (state) => state.operatorsReducer.OperatorName
  );
  const refineryproductlookupvalues = useSelector(
    (state) => state.refineryproductReducer.RefineryProduct
  );

  const [selectedowner, setSelectedOwner] = React.useState({});
  const [selectedoperator, setSelectedOperator] = React.useState({});
  const [selectedrefineryproduct, setSelectedRefineryProduct] = React.useState(
    {}
  );

  const pipelineinitialstate = {
    PipelineName: "",
    AssetType: "",
    IsConnected: 0,
    RegulatoryType: null,
    RegulatoryTypeId: null,
    PipesurfaceArea: "",
    InstallationDate: null,
    InserviceDate: null,
    InstallationMethod: "",
    OperatorName: null,
    OperatorId: null,
    Owner: null,
    OwnerId: null,
    FromDeviceTerminalType: "",
    FromDeviceTerminal: "",
    ToDeviceTerminalType: "",
    ToDeviceTerminal: "",
    NominalDiameter: "",
    Measuredlength: "",
    Manufacturer: "",
    RefineryProduct: null,
    RefineryProductId: null,
    DateRetired: null,
    DesignClass: "",
    PressureUnits: "",
    LengthUnits: "",
    DiameterUnits: "",
    PipeSurfaceAreaUnits: "",
    PipeVolumeUnits: "",
    WallThickness: "",
    key: 0,
  };

  const [pipelinevalues, setPipelineValues] =
    React.useState(pipelineinitialstate);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "error",
  });

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "error" });
  };

  const fnHandleChange = (prop) => (event) => {
    setPipelineValues({ ...pipelinevalues, [prop]: event.target.value });
  };

  const fnOnChange = (newvalue, type, typeid) => {
    if (newvalue !== null && newvalue !== undefined) {
      if (type === "Owner" || type === "Operator") {
        setPipelineValues({
          ...pipelinevalues,
          [type]: newvalue["OperatorName"],
          [typeid]: newvalue["OperatorId"],
        });
      } else {
        setPipelineValues({
          ...pipelinevalues,
          [type]: newvalue[type],
          [typeid]: newvalue[typeid],
        });
      }

      switch (type) {
        case "Operator":
          setSelectedOperator(newvalue);
          break;
        case "Owner":
          setSelectedOwner(newvalue);
          break;
        case "RefineryProduct":
          setSelectedRefineryProduct(newvalue);
          break;
      }
    }
  };

  const fnInsertPipelineData = () => {
    var pipelinekeys = [
      "PipelineName",
      "AssetType",
      "IsConnected",
      "RegulatoryType",
      "RegulatoryTypeId",
      "PipesurfaceArea",
      "InstallationDate",
      "InserviceDate",
      "InstallationMethod",
      "OperatorName",
      "OperatorId",
      "Owner",
      "OwnerId",
      "FromDeviceTerminalType",
      "FromDeviceTerminal",
      "ToDeviceTerminalType",
      "ToDeviceTerminal",
      "NominalDiameter",
      "Measuredlength",
      "Manufacturer",
      "RefineryProduct",
      "RefineryProductId",
      "DateRetired",
      "DesignClass",
      "PressureUnits",
      "LengthUnits",
      "DiameterUnits",
      "PipeSurfaceAreaUnits",
      "PipeVolumeUnits",
      "WallThickness",
    ];

    var insertvalues = [];
    pipelinekeys.forEach((element) => {
      if (
        pipelinevalues[element] !== undefined &&
        pipelinevalues[element] !== null &&
        pipelinevalues[element] !== ""
      ) {
        if (element.indexOf("Date") > -1) {
          insertvalues.push(
            element +
              " = '" +
              moment(pipelinevalues[element]).format("YYYY-MM-DD") +
              "'"
          );
        } else {
          if (typeof pipelinevalues[element] === "string") {
            insertvalues.push("'" + pipelinevalues[element] + "'");
          } else {
            insertvalues.push(pipelinevalues[element]);
          }
        }
      } else {
        insertvalues.push("NULL");
      }
    });

    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      inserttype: "Pipeline",
      insertvalues: insertvalues.join(","),
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/insertdatarecord", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error while inserting pipeline record",
            variant: "error",
          });
        } else if (response.data[0].status === "inserted") {
          setStatusBase({
            show: true,
            message: "Record inserted successfully",
            variant: "info",
          });

          window.location.reload();
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting record",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting pipeline data",
            variant: "error",
          });
        }
      }
    );
  };

  const fetchPipelines = () => {
    const query = { columnname: "PipelineName", tablename: "Pipeline" };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchsubjectuniquevalues", query, options).then(
      (response) => {
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "no records") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            setPipelines(response.data[0].data);
          }
        }
      },
      (error) => {
        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
        }
      }
    );
  };

  const fnHandleCheckChange = (prop) => (event) => {
    setPipelineValues({
      ...pipelinevalues,
      [prop]: event.target.checked ? 1 : 0,
    });
  };

  const fnHandleInstallationDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setPipelineValues({
        ...pipelinevalues,
        InstallationDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setPipelineValues({ ...pipelinevalues, InstallationDate: null });
    }
  };

  const fnHandleInserviceDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setPipelineValues({
        ...pipelinevalues,
        InserviceDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setPipelineValues({ ...pipelinevalues, InserviceDate: null });
    }
  };

  const fnHandleDateRetiredChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setPipelineValues({
        ...pipelinevalues,
        DateRetired: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setPipelineValues({ ...pipelinevalues, DateRetired: null });
    }
  };

  useEffect(() => {
    fetchPipelines();
  }, []);

  return (
    <>
      <Grid className={classes.operatoraddcontainer} container>
        <Paper>
          <Typography variant="subtitle1">Add Pipeline Data</Typography>
          <ValidatorForm>
            {pipelines.length > 0 ? (
              <>
                <Grid
                  container
                  spacing="3"
                  direction="column"
                  className={classes.formcontainer}
                >
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <Autocomplete
                          id="pipelinenameId"
                          freeSolo
                          size="small"
                          options={pipelines}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Pipeline Name"
                              margin="normal"
                              variant="outlined"
                              value={pipelinevalues.PipelineName}
                              onChange={fnHandleChange("PipelineName")}
                              error={pipelinevalues.PipelineName === ""}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={operatorlookupvalues}
                          getOptionLabel={(option) =>
                            option.OperatorName === undefined
                              ? ""
                              : option.OperatorName
                          }
                          value={selectedoperator}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              fnOnChange(newValue, "Operator", "OperatorId");
                            } else {
                              setSelectedOperator({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Operator"}
                              placeholder="Operator"
                              error={
                                selectedoperator.OperatorName === undefined
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={operatorlookupvalues}
                          getOptionLabel={(option) =>
                            option.OperatorName === undefined
                              ? ""
                              : option.OperatorName
                          }
                          value={selectedowner}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              fnOnChange(newValue, "Owner", "OwnerId");
                            } else {
                              setSelectedOwner({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Owner"}
                              placeholder="Owner"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="3">
                        {/* {JSON.stringify(operatorvalues.City)} */}
                        <Autocomplete
                          size="small"
                          options={refineryproductlookupvalues}
                          getOptionLabel={(option) =>
                            option.RefineryProduct === undefined
                              ? ""
                              : option.RefineryProduct
                          }
                          value={selectedrefineryproduct}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              fnOnChange(
                                newValue,
                                "RefineryProduct",
                                "RefineryProductId"
                              );
                            } else {
                              setSelectedRefineryProduct({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select RefineryProduct"}
                              placeholder="RefineryProduct"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <TextField
                          label="AssetType"
                          id="assettypeId"
                          value={pipelinevalues.AssetType}
                          onChange={fnHandleChange("AssetType")}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs="3">
                        <TextField
                          label="InstallationMethod"
                          id="installationmethodId"
                          value={pipelinevalues.InstallationMethod}
                          onChange={fnHandleChange("InstallationMethod")}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        {/* <TextField
													label="RegulatoryType"
													id="regulatorytypeId"
													value={pipelinevalues.RegulatoryType}
													onChange={fnHandleChange('RegulatoryType')}
													variant="outlined"
													size="small"
													InputLabelProps={{
														shrink: true,
													}}
												/> */}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <TextField
                          label="PipesurfaceArea"
                          id="pipesurfaceareaId"
                          value={pipelinevalues.PipesurfaceArea}
                          onChange={fnHandleChange("PipesurfaceArea")}
                          variant="outlined"
                          type="number"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            format="yyyy/MM/dd"
                            margin="normal"
                            id="installationdateId"
                            label="Installation Date"
                            variant="outlined"
                            value={pipelinevalues.InstallationDate}
                            onChange={fnHandleInstallationDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "Installation Date",
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs="3">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            format="yyyy/MM/dd"
                            margin="normal"
                            id="inservicedateId"
                            label="Inservice Date"
                            variant="outlined"
                            value={pipelinevalues.InserviceDate}
                            onChange={fnHandleInserviceDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "Inservice Date",
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid item xs="3">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                pipelinevalues.IsConnected === 0 ? false : true
                              }
                              onChange={fnHandleCheckChange("IsConnected")}
                              name="IsConnected"
                              color="primary"
                            />
                          }
                          label="IsConnected"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <TextField
                          label="FromDeviceTerminalType"
                          id="fromdeviceterminaltypeId"
                          value={pipelinevalues.FromDeviceTerminalType}
                          onChange={fnHandleChange("FromDeviceTerminalType")}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="FromDeviceTerminal"
                          id="fromdeviceterminalId"
                          value={pipelinevalues.FromDeviceTerminal}
                          onChange={fnHandleChange("FromDeviceTerminal")}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="ToDeviceTerminalType"
                          id="todeviceterminaltypeId"
                          value={pipelinevalues.ToDeviceTerminalType}
                          onChange={fnHandleChange("ToDeviceTerminalType")}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs="3">
                        <TextField
                          label="ToDeviceTerminal"
                          id="todeviceterminalId"
                          value={pipelinevalues.ToDeviceTerminal}
                          onChange={fnHandleChange("ToDeviceTerminal")}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      
                      <Grid item xs="3">
                        <TextField
                          label="NominalDiameter"
                          id="nominaldiameterId"
                          value={pipelinevalues.NominalDiameter}
                          onChange={fnHandleChange("NominalDiameter")}
                          variant="outlined"
                          type="number"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="Measuredlength"
                          id="measuredlengthId"
                          value={pipelinevalues.Measuredlength}
                          onChange={fnHandleChange("Measuredlength")}
                          variant="outlined"
                          type="number"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="Manufacturer"
                          id="manufacturerId"
                          value={pipelinevalues.Manufacturer}
                          onChange={fnHandleChange("Manufacturer")}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            format="yyyy/MM/dd"
                            margin="normal"
                            id="dateretiredId"
                            label="DateRetired"
                            variant="outlined"
                            value={pipelinevalues.DateRetired}
                            onChange={fnHandleDateRetiredChange}
                            KeyboardButtonProps={{
                              "aria-label": "DateRetired",
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="DesignClass"
                          id="designclassId"
                          value={pipelinevalues.DesignClass}
                          onChange={fnHandleChange("DesignClass")}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="PressureUnits"
                          id="pressureunitsId"
                          value={pipelinevalues.PressureUnits}
                          onChange={fnHandleChange("PressureUnits")}
                          variant="outlined"
                          type="number"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="LengthUnits"
                          id="lengthunitsId"
                          value={pipelinevalues.LengthUnits}
                          onChange={fnHandleChange("LengthUnits")}
                          variant="outlined"
                          type="number"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <TextField
                          label="DiameterUnits"
                          id="diameterunitsId"
                          value={pipelinevalues.DiameterUnits}
                          onChange={fnHandleChange("DiameterUnits")}
                          variant="outlined"
                          type="number"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="PipeSurfaceAreaUnits"
                          id="pipesurfaceareaunitsId"
                          value={pipelinevalues.PipeSurfaceAreaUnits}
                          onChange={fnHandleChange("PipeSurfaceAreaUnits")}
                          variant="outlined"
                          size="small"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="PipeVolumeUnits"
                          id="pipevolumeunitsId"
                          value={pipelinevalues.PipeVolumeUnits}
                          onChange={fnHandleChange("PipeVolumeUnits")}
                          variant="outlined"
                          size="small"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="WallThickness"
                          id="wallthicknessId"
                          value={pipelinevalues.WallThickness}
                          onChange={fnHandleChange("WallThickness")}
                          variant="outlined"
                          size="small"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Grid item>
                        <Button
                          type="button"
                          color="primary"
                          variant="contained"
                          className={classes.updatebutton}
                          disabled={
                            pipelinevalues.Latitude === "" ||
                            pipelinevalues.PipelineName === "" ||
                            pipelinevalues.Longitude === "" ||
                            pipelinevalues.StateName === null ||
                            pipelinevalues.Status === null
                          }
                          onClick={() => fnInsertPipelineData()}
                        >
                          Insert Pipeline
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </ValidatorForm>
        </Paper>
      </Grid>
      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(AddPipelineComp);
