import React, { useEffect } from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import { getThemeByName } from './themecreator';

import { useSelector } from 'react-redux';

export const ThemeContext = React.createContext((themeName) => ({}));

function ThemeProvider(props) {
	const [themeName, _setThemeName] = React.useState('naveentheme');

	let savedtheme = useSelector((state) => state.customizationReducer.theme);

	let theme = getThemeByName(savedtheme === undefined ? themeName : savedtheme);

	let reusabletheme;

	const setThemeName = (themeName) => {
		localStorage.setItem('appTheme', 'naveentheme');

		reusabletheme = getThemeByName(themeName);

		_setThemeName(reusabletheme);
	};

	useEffect(() => {
		theme = getThemeByName(savedtheme);

		localStorage.setItem('appTheme', savedtheme);
	}, [savedtheme]);

	return (
		<ThemeContext.Provider value={setThemeName}>
			<MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
		</ThemeContext.Provider>
	);
}


export default ThemeProvider;