import React, { Suspense } from 'react';

import Grid from '@material-ui/core/Grid';

import LinearProgress from '@material-ui/core/LinearProgress';

import { makeStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router-dom';

const HydrogenPlantSearchComponent = React.lazy(
	() =>
		new Promise((resolve, reject) =>
			setTimeout(() => resolve(import('./subcomponents/HydrogenPlantSearchComp')), 100)
		)
);

const HydrogenPlantSubComp = React.lazy(
	() =>
		new Promise((resolve, reject) =>
			setTimeout(() => resolve(import('./subcomponents/HydrogenPlantSubComp')), 100)
		)
);

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
	},
}));

function HydrogenPlantComp(props) {
	const classes = useStyles();

	const [searchhydrogenplantquery, setSearchHydrogenPlantQuery] = React.useState('');

	return (
		<>
			<Grid container>
				<Suspense
					fallback={
						<>
							<LinearProgress />
						</>
					}
				>
					<HydrogenPlantSearchComponent
						setSearchHydrogenPlantQuery={setSearchHydrogenPlantQuery}
					/>
				</Suspense>
			</Grid>
			<Grid container>
				<Suspense
					fallback={
						<>
							<LinearProgress />
						</>
					}
				>
					<HydrogenPlantSubComp searchhydrogenplantquery={searchhydrogenplantquery} />
				</Suspense>
			</Grid>
		</>
	);
}

export default withRouter(HydrogenPlantComp);
