import React, { useEffect } from "react";

import { Breadcrumbs, Link, Typography } from "@material-ui/core";

import { withRouter, Link as RouterLink } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import * as actionType from "redux/actions/actionType";
// import AddGasPlantUnit from "../../screens/addgasplantunit";

function BreadcrumbRouterComp(props) {
  const dispatch = useDispatch();

  const { navhistory } = useSelector((state) => ({
    navhistory: state.navigationReducer.history,
  }));

  useEffect(() => {
    if (!navhistory.includes(props.history.location.pathname)) {
      dispatch({
        type: actionType.ADD_NAVIGATION,
        payload: { history: props.history.location.pathname },
      });
    } else {
      dispatch({
        type: actionType.REMOVE_NAVIGATION,
        payload: { history: props.history.location.pathname },
      });
    }

    window.onpopstate = (e) => {
      e.preventDefault();
      window.history.forward();
    };

    if (props.history.location.pathname === "/home") {
      resetProjectParams();
    }
  }, []);

  const breadcrumbNameMap = {
    "/home": "Home",
    "/operator": "Operators",
    "/addoperator": "AddOperator",
    "/changepassword": "ChangePassword",
    "/updateoperator": "UpdateOperator",
    "/gasplant": "GasPlants",
    "/addgasplant": "Add GasPlant",
    "/addgasplantunit":"Add Gas Plant Unit",
    "/gasstorage": "GasStorages",
    "/addgasstorage": "Add GasStorage",
    "/refinery": "Refineries",
    "/addrefinery": "Add Refinery",
    "/terminal": "Terminals",
    "/well": "Wells",
    "/pipeline": "Pipelines",
    "/addterminal": "Add Terminal",
    "/addwell": "Add Well",
    "/addterminalunit": "Add Terminal Unit",
    "/addterminaltransport": "Add Terminal Transport",
    "/addpipeline": "Add Pipeline",
    "/addpipelinecounties": "Add Pipeline Counties",
    "/addpipelineinterconnects": "Add Pipeline Interconnects",
    "/addcompressorstation": "Add Compressor Station",
    "/addpumpingstation": "Add Pumping Station",
    "/liquefaction": "Liquefactions",
    "/pipelineassembly": "Pipeline Assembly",
    "/compressorstation": "CompressorStations",
    "/industrialplant": "IndustrialPlants",
    "/powerplant": "PowerPlants",
    "/hydrogenplant": "HydrogenPlants",
    "/addhydrogendata": " Add Hydrogen Data",
    "/ccus": "CCUS",
    "/addccusdata":"Add CCUS Data",
    "/co2storage": "CO2Storages",
    "/tariff": "Crude Oil Tariff",
    "/pumpingstation": "PumpingStations",
    "/addliquefaction": "Add Liquefaction",
    "/addpipelineassembly": "Add Pipeline Assembly",
    "/addliquefactionunit": "Add Liquefaction Unit",
    "/searchfacility": "Search Facility",
    "/addgasstoragecost": "Add Gas Storage Cost",
    "/addpumpingstationcost": "Add Pumping Station Cost",
    "/addgasplantcost": "Add Gas Plant Cost",
    "/addrefinerycost": "Add Refinery Cost",
    "/addterminalcost": "Add Terminal Cost",
    "/addliquefactioncost": "Add Liquefaction Cost",
    "/addcompressorstationcost": "Add Compressor Station Cost",
    "/addindustrialplant": "Add IndustrialPlant",
    "/addpowerplant": "Add PowerPlant",
    "/addhydrogenplant": "Add HydrogenPlant",
    "/addccus": "Add CCUS",
    "/addco2storage": "Add CO2Storage",
    "/addco2storagedata":"Add CO2StorageData",
    "/addtariff": "Add Tariff",
    "/dataview": "Data View",
    "/replaceoperator": "Replace Operator",
    "/updateowner": "Update Owner",
  };

  const resetProjectParams = () => {
    // dispatch({ type: actionType.RESETPROJECT_STATE_PARAMS });
  };

  const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

  return (
    <React.Fragment>
      {props.history.location.pathname !== "/home" ? (
        <Breadcrumbs aria-label="breadcrumb">
          {navhistory.length > 0 &&
            navhistory.map((value, index) => {
              const last = index === navhistory.length - 1;

              const to = value;

              if (last) {
                return (
                  <Typography color="textPrimary" key={to}>
                    {breadcrumbNameMap[to]}
                  </Typography>
                );
              } else if (index === 0) {
                return (
                  <LinkRouter
                    href={to}
                    onClick={resetProjectParams}
                    color="inherit"
                    to={to}
                    key={to}
                  >
                    {breadcrumbNameMap[to]}
                  </LinkRouter>
                );
              } else {
                return (
                  <LinkRouter href={to} color="inherit" to={to} key={to}>
                    {breadcrumbNameMap[to]}
                  </LinkRouter>
                );
              }
            })}
        </Breadcrumbs>
      ) : null}
    </React.Fragment>
  );
}

export default withRouter(BreadcrumbRouterComp);
