import React, { Suspense, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import Snackbar from "@material-ui/core/Snackbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputBase from "@material-ui/core/InputBase";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import LinearProgress from "@material-ui/core/LinearProgress";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";

import LookupInsertComp from "components/common/LookupInsertComp";
import AlertNotificationComp from "components/custom/AlertNotificationComp";

//date
import moment from "moment";

//Api
import axios from "axios";

//Redux imports
import { useSelector, useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import { deviceterminaltypes } from "lookuptables/global";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },
  operatoraddcontainer: {
    "& .MuiPaper-root": {
      width: "100%",
      padding: 10,
      margin: 10,
    },
  },
  formcontainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-container": {
      // marginBottom: 10,
    },
  },
  updatebutton: {
    width: "100%",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

function AddPipelineSectionComp(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const [pipelinesections, setPipelineSections] = React.useState([]);
  const [pipelines, setPipelines] = React.useState([]);
  const [fromdeviceterminals, setFromDeviceTerminals] = React.useState([]);
  const [todeviceterminals, setToDeviceTerminals] = React.useState([]);

  const basinlookupvalues = useSelector(
    (state) => state.basinsReducer.BasinName
  );
  const statelookupvalues = useSelector(
    (state) => state.statenamesReducer.StateName
  );
  const countylookupvalues = useSelector(
    (state) => state.countynamesReducer.CountyName
  );

  const [selectedbasin, setSelectedBasin] = React.useState({});
  const [selectedstate, setSelectedState] = React.useState({});
  const [selectedcounty, setSelectedCounty] = React.useState({});
  const [selectedpipelineheader, setSelectedPipelineHeader] = React.useState(
    {}
  );

  const pipelinesectioninitialstate = {
    PipelineName: null,
    PipelineHeaderId: null,
    PipelineSectionName: "",
    SectionFromDeviceTerminalType: "",
    SectionFromDeviceTerminal: "",
    SectionToDeviceTerminalType: "",
    SectionToDeviceTerminal: "",
    SectionMeasuredLengthMiles: "",
    SectionPipesurfaceArea: "",
    SectionInstallationDate: null,
    SectionInservicedate: null,
    SectionNominalDiameter: "",
    SectionOperatingPressure: "",
    SectionLengthSource: "",
    SectionManufacturer: "",
    SectionPipevolume: "",
    SectionActualInternalDiameter: "",
    SectionDateRetired: null,
    CriticalIndicator: "",
    SectionStubbed: "",
    PressureUnits: "",
    LengthUnits: "",
    DiameterUnits: "",
    PipesurfaceAreaUnits: "",
    PipeVolumeUnits: "",
    WallThickness: "",
    CountyName: null,
    CountyId: null,
    StateName: null,
    StateNameId: null,
    Basin: null,
    BasinId: null,
    key: 0,
  };

  const [pipelinesectionvalues, setPipelineSectionValues] = React.useState(
    pipelinesectioninitialstate
  );

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "error",
  });

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "error" });
  };

  const tablenamemapping = {
    Refinery: "RefineryName",
    Terminal: "TerminalName",
    GasPlant: "GasPlantName",
    GasStorage: "GasStorageName",
    Liquefaction: "LiquefactionPlantName",
    CompressorStation: "CompressorStationName",
    PumpingStation: "PumpingStationName",
  };

  const fnHandleDDChange = (prop) => (event) => {
    if (prop === "SectionFromDeviceTerminalType") {
      fetchTableData(
        tablenamemapping[event.target.textContent],
        event.target.textContent,
        "SectionFromDeviceTerminalType"
      );
    } else if (prop === "SectionToDeviceTerminalType") {
      fetchTableData(
        tablenamemapping[event.target.textContent],
        event.target.textContent,
        "SectionToDeviceTerminalType"
      );
    }

    setPipelineSectionValues({
      ...pipelinesectionvalues,
      [prop]: event.target.textContent,
    });
  };

  const fnHandleChange = (prop) => (event) => {
    setPipelineSectionValues({
      ...pipelinesectionvalues,
      [prop]: event.target.value,
    });
  };

  const fnOnChange = (newvalue, type, typeid) => {
    if (newvalue !== null && newvalue !== undefined) {
      if (type === "Owner" || type === "Operator") {
        setPipelineSectionValues({
          ...pipelinesectionvalues,
          [type]: newvalue["OperatorName"],
          [typeid]: newvalue["OperatorId"],
        });
      } else {
        setPipelineSectionValues({
          ...pipelinesectionvalues,
          [type]: newvalue[type],
          [typeid]: newvalue[typeid],
        });
      }

      switch (type) {
        case "CountyName":
          setSelectedCounty(newvalue);
          break;
        case "StateName":
          setSelectedState(newvalue);
          break;
        case "PipelineName":
          setSelectedPipelineHeader(newvalue);
          break;
      }
    }
  };

  const fnInsertPipelineSectionData = () => {
    var pipelinesectionkeys = [
      "PipelineHeaderId",
      "PipelineSectionName",
      "SectionFromDeviceTerminalType",
      "SectionFromDeviceTerminal",
      "SectionToDeviceTerminalType",
      "SectionToDeviceTerminal",
      "SectionMeasuredLengthMiles",
      "SectionPipesurfaceArea",
      "SectionInstallationDate",
      "SectionInservicedate",
      "SectionNominalDiameter",
      "SectionOperatingPressure",
      "SectionLengthSource",
      "SectionManufacturer",
      "SectionPipevolume",
      "SectionActualInternalDiameter",
      "SectionDateRetired",
      "CriticalIndicator",
      "SectionStubbed",
      "PressureUnits",
      "LengthUnits",
      "DiameterUnits",
      "PipesurfaceAreaUnits",
      "PipeVolumeUnits",
      "WallThickness",
      "CountyId",
      "StateNameId",
      "BasinId",
    ];

    var insertvalues = [];
    pipelinesectionkeys.forEach((element) => {
      if (
        pipelinesectionvalues[element] !== undefined &&
        pipelinesectionvalues[element] !== null &&
        pipelinesectionvalues[element] !== ""
      ) {
        if (element.indexOf("Date") > -1) {
          insertvalues.push(
            element +
              " = '" +
              moment(pipelinesectionvalues[element]).format("YYYY-MM-DD") +
              "'"
          );
        } else {
          if (typeof pipelinesectionvalues[element] === "string") {
            insertvalues.push("'" + pipelinesectionvalues[element] + "'");
          } else {
            insertvalues.push(pipelinesectionvalues[element]);
          }
        }
      } else {
        insertvalues.push("NULL");
      }
    });

    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      inserttype: "PipelineSection",
      insertvalues: insertvalues.join(","),
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/insertdatarecord", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error while inserting pipelinesection record",
            variant: "error",
          });
        } else if (response.data[0].status === "inserted") {
          setStatusBase({
            show: true,
            message: "Record inserted successfully",
            variant: "info",
          });

          window.location.reload();
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting record",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting pipelinesection data",
            variant: "error",
          });
        }
      }
    );
  };

  const fetchTableData = (columnname, tablename, type) => {
    const query = { columnname: columnname, tablename: tablename };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchsubjectuniquevalues", query, options).then(
      (response) => {
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "no records") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            if (type === "PipelineSection") {
              setPipelineSections(response.data[0].data);
            } else if (type === "SectionFromDeviceTerminalType") {
              setFromDeviceTerminals(response.data[0].data);
            } else if (type === "SectionToDeviceTerminalType") {
              setToDeviceTerminals(response.data[0].data);
            }
          }
        }
      },
      (error) => {
        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
        }
      }
    );
  };

  const fnFetchLookupValues = () => {
    const data = {
      fetchtype: "PipelineName",
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchuniquevalues", data, options).then(
      (response) => {
        // console.log(response.data[0]);
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            var data = [];
            response.data[0].data.forEach((element) => {
              data.push({
                PipelineName: element["PipelineName"],
                PipelineHeaderId: element["PipelineHeaderId"],
              });
            });

            setPipelines(data);
          } else {
          }
        }
      },
      (error) => {}
    );
  };

  const fnHandleCheckChange = (prop) => (event) => {
    setPipelineSectionValues({
      ...pipelinesectionvalues,
      [prop]: event.target.checked ? 1 : 0,
    });
  };

  const fnHandleInstallationDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setPipelineSectionValues({
        ...pipelinesectionvalues,
        InstallationDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setPipelineSectionValues({
        ...pipelinesectionvalues,
        InstallationDate: null,
      });
    }
  };

  const fnHandleInserviceDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setPipelineSectionValues({
        ...pipelinesectionvalues,
        InserviceDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setPipelineSectionValues({
        ...pipelinesectionvalues,
        InserviceDate: null,
      });
    }
  };

  const fnHandleDateRetiredChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setPipelineSectionValues({
        ...pipelinesectionvalues,
        DateRetired: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setPipelineSectionValues({ ...pipelinesectionvalues, DateRetired: null });
    }
  };

  useEffect(() => {
    fetchTableData("PipelineSectionName", "PipelineSection", "PipelineSection");
    fnFetchLookupValues();
  }, []);

  return (
    <>
      <Grid className={classes.operatoraddcontainer} container>
        <Paper>
          <Typography variant="subtitle1">Add PipelineSection Data</Typography>
          <ValidatorForm>
            {pipelinesections.length > 0 ? (
              <>
                <Grid
                  container
                  spacing="3"
                  direction="column"
                  className={classes.formcontainer}
                >
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={pipelines}
                          getOptionLabel={(option) =>
                            option.PipelineName === undefined
                              ? ""
                              : option.PipelineName
                          }
                          value={selectedpipelineheader}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              fnOnChange(
                                newValue,
                                "PipelineName",
                                "PipelineHeaderId"
                              );
                            } else {
                              setSelectedPipelineHeader({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Pipeline"}
                              placeholder="Pipeline"
                              error={
                                selectedpipelineheader.PipelineName ===
                                undefined
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <Autocomplete
                          id="pipelinesectionnameId"
                          freeSolo
                          size="small"
                          options={pipelinesections}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Pipeline Section Name"
                              margin="normal"
                              variant="outlined"
                              value={pipelinesectionvalues.PipelineSectionName}
                              onChange={fnHandleChange("PipelineSectionName")}
                              error={
                                pipelinesectionvalues.PipelineSectionName === ""
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={countylookupvalues}
                          getOptionLabel={(option) =>
                            option.CountyName === undefined
                              ? ""
                              : option.CountyName
                          }
                          value={selectedcounty}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              fnOnChange(newValue, "CountyName", "CountyId");
                            } else {
                              setSelectedCounty({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select County"}
                              placeholder="County"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={statelookupvalues}
                          getOptionLabel={(option) =>
                            option.StateName === undefined
                              ? ""
                              : option.StateName
                          }
                          value={selectedstate}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              fnOnChange(newValue, "StateName", "StateNameId");
                            } else {
                              setSelectedState({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select State"}
                              placeholder="State"
                              error={selectedstate.StateName === undefined}
                            />
                          )}
                        />
                        {/* {JSON.stringify(operatorvalues.City)} */}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={basinlookupvalues}
                          getOptionLabel={(option) =>
                            option.BasinName === undefined
                              ? ""
                              : option.BasinName
                          }
                          value={selectedbasin}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              fnOnChange(newValue, "BasinName", "BasinId");
                            } else {
                              setSelectedBasin({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Basin"}
                              placeholder="Basin"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            format="yyyy/MM/dd"
                            margin="normal"
                            id="installationdateId"
                            label="Section Installation Date"
                            variant="outlined"
                            value={
                              pipelinesectionvalues.SectionInstallationDate
                            }
                            onChange={fnHandleInstallationDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "Section Installation Date",
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={
                              pipelinesectionvalues.SectionInstallationDate ===
                              undefined
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs="3">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            format="yyyy/MM/dd"
                            margin="normal"
                            id="inservicedateId"
                            label="Section Inservice Date"
                            variant="outlined"
                            value={pipelinesectionvalues.SectionInservicedate}
                            onChange={fnHandleInserviceDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "Section Inservice Date",
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={
                              pipelinesectionvalues.SectionInservicedate ===
                              undefined
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="SectionMeasuredLengthMiles"
                          id="sectionMeasuredlengthId"
                          onChange={fnHandleChange(
                            "SectionMeasuredLengthMiles"
                          )}
                          value={
                            pipelinesectionvalues.SectionMeasuredLengthMiles
                          }
                          variant="outlined"
                          size="small"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        {deviceterminaltypes.length > 0 ? (
                          <Autocomplete
                            size="small"
                            options={deviceterminaltypes}
                            getOptionLabel={(option) => option}
                            value={
                              pipelinesectionvalues.SectionFromDeviceTerminalType
                            }
                            onChange={fnHandleDDChange(
                              "SectionFromDeviceTerminalType"
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={"Select From Device Terminal Type"}
                                placeholder="Select From Device Terminal Type"
                                error={
                                  pipelinesectionvalues.SectionFromDeviceTerminalType ===
                                  ""
                                }
                              />
                            )}
                          />
                        ) : null}
                      </Grid>
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={fromdeviceterminals}
                          getOptionLabel={(option) => option}
                          value={
                            pipelinesectionvalues.SectionFromDeviceTerminal
                          }
                          onChange={fnHandleDDChange(
                            "SectionFromDeviceTerminal"
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select From Device Terminal"}
                              placeholder="Select From Device Terminal"
                              error={
                                pipelinesectionvalues.SectionFromDeviceTerminal ===
                                ""
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="3">
                        {deviceterminaltypes.length > 0 ? (
                          <Autocomplete
                            size="small"
                            options={deviceterminaltypes}
                            getOptionLabel={(option) => option}
                            value={
                              pipelinesectionvalues.SectionToDeviceTerminalType
                            }
                            onChange={fnHandleDDChange(
                              "SectionToDeviceTerminalType"
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={"Select To Device Terminal Type"}
                                placeholder="Select To Device Terminal Type"
                                error={
                                  pipelinesectionvalues.SectionToDeviceTerminalType ===
                                  ""
                                }
                              />
                            )}
                          />
                        ) : null}
                      </Grid>
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={todeviceterminals}
                          getOptionLabel={(option) => option}
                          value={pipelinesectionvalues.SectionToDeviceTerminal}
                          onChange={fnHandleDDChange("SectionToDeviceTerminal")}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select To Device Terminal"}
                              placeholder="Select To Device Terminal"
                              error={
                                pipelinesectionvalues.SectionToDeviceTerminal ===
                                ""
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <TextField
                          label="SectionPipesurfaceArea"
                          id="sectionpipesurfaceareaId"
                          onChange={fnHandleChange("SectionPipesurfaceArea")}
                          value={pipelinesectionvalues.SectionPipesurfaceArea}
                          variant="outlined"
                          size="small"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="SectionNominalDiameter"
                          id="sectionnominaldiameterId"
                          onChange={fnHandleChange("SectionNominalDiameter")}
                          value={pipelinesectionvalues.SectionNominalDiameter}
                          variant="outlined"
                          size="small"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="SectionOperatingPressure"
                          id="sectionoperatingpressureId"
                          onChange={fnHandleChange("SectionOperatingPressure")}
                          value={pipelinesectionvalues.SectionOperatingPressure}
                          variant="outlined"
                          size="small"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="SectionActualInternalDiameter"
                          id="sectionactualinternaldiameterId"
                          onChange={fnHandleChange(
                            "SectionActualInternalDiameter"
                          )}
                          value={
                            pipelinesectionvalues.SectionActualInternalDiameter
                          }
                          variant="outlined"
                          size="small"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <TextField
                          label="SectionLengthSource"
                          id="sectionlengthsourceId"
                          onChange={fnHandleChange("SectionLengthSource")}
                          value={pipelinesectionvalues.SectionLengthSource}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="SectionManufacturer"
                          id="sectionmanufacturerId"
                          onChange={fnHandleChange("SectionManufacturer")}
                          value={pipelinesectionvalues.SectionManufacturer}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="SectionPipevolume"
                          id="sectionpipevolumeId"
                          onChange={fnHandleChange("SectionPipevolume")}
                          value={pipelinesectionvalues.SectionPipevolume}
                          variant="outlined"
                          size="small"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            format="yyyy/MM/dd"
                            margin="normal"
                            id="sectiondateretiredId"
                            label="SectionDateRetired"
                            variant="outlined"
                            value={pipelinesectionvalues.SectionDateRetired}
                            onChange={fnHandleDateRetiredChange}
                            KeyboardButtonProps={{
                              "aria-label": "Section Date Retired",
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <TextField
                          label="CriticalIndicator"
                          id="criticalindicatorId"
                          onChange={fnHandleChange("CriticalIndicator")}
                          value={pipelinesectionvalues.CriticalIndicator}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="SectionStubbed"
                          id="sectionstubbedId"
                          onChange={fnHandleChange("SectionStubbed")}
                          value={pipelinesectionvalues.SectionStubbed}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="PressureUnits"
                          id="pressureunitsId"
                          onChange={fnHandleChange("PressureUnits")}
                          value={pipelinesectionvalues.PressureUnits}
                          variant="outlined"
                          size="small"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="LengthUnits"
                          id="lengthunitsId"
                          onChange={fnHandleChange("LengthUnits")}
                          value={pipelinesectionvalues.LengthUnits}
                          variant="outlined"
                          size="small"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <TextField
                          label="DiameterUnits"
                          id="diameterunitsId"
                          onChange={fnHandleChange("DiameterUnits")}
                          value={pipelinesectionvalues.DiameterUnits}
                          variant="outlined"
                          size="small"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="PipesurfaceAreaUnits"
                          id="pipesurfaceareaunitsId"
                          onChange={fnHandleChange("PipesurfaceAreaUnits")}
                          value={pipelinesectionvalues.PipesurfaceAreaUnits}
                          variant="outlined"
                          size="small"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="PipeVolumeUnits"
                          id="pipevolumeunitsId"
                          onChange={fnHandleChange("PipeVolumeUnits")}
                          value={pipelinesectionvalues.PipeVolumeUnits}
                          variant="outlined"
                          size="small"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="WallThickness"
                          id="wallthicknessId"
                          onChange={fnHandleChange("WallThickness")}
                          value={pipelinesectionvalues.WallThickness}
                          variant="outlined"
                          size="small"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Grid item>
                        <Button
                          type="button"
                          color="primary"
                          variant="contained"
                          className={classes.updatebutton}
                          disabled={
                            pipelinesectionvalues.PipelineName === null ||
                            pipelinesectionvalues.StateName === null ||
                            pipelinesectionvalues.PipelineSectionName === "" ||
                            pipelinesectionvalues.SectionFromDeviceTerminalType ===
                              "" ||
                            pipelinesectionvalues.SectionFromDeviceTerminal ===
                              "" ||
                            pipelinesectionvalues.SectionToDeviceTerminalType ===
                              null ||
                            pipelinesectionvalues.SectionToDeviceTerminal ===
                              null
                          }
                          onClick={() => fnInsertPipelineSectionData()}
                        >
                          Insert PipelineSection
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </ValidatorForm>
        </Paper>
      </Grid>
      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(AddPipelineSectionComp);
