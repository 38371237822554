import * as actionType from 'redux/actions/actionType';

let initialState = {
	RefineryProduct: [],
};

function refineryproductReducer(state = initialState, action) {
	switch (action.type) {
		case actionType.ADD_REFINERYPRODUCT:
			state.RefineryProduct = action.payload.RefineryProduct;
			return state;
		default:
			return state;
	}
}

export default refineryproductReducer;
