import React, { Suspense, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import Snackbar from "@material-ui/core/Snackbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputBase from "@material-ui/core/InputBase";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import LinearProgress from "@material-ui/core/LinearProgress";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";

import LookupInsertComp from "components/common/LookupInsertComp";
import AlertNotificationComp from "components/custom/AlertNotificationComp";

import MUIDataTable from "mui-datatables";

//linq
import linq from "linq";

//date
import moment from "moment";

//Api
import axios from "axios";

//Redux imports
import { useSelector, useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import { deviceterminaltypes } from "lookuptables/global";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },
  operatoraddcontainer: {
    "& .MuiPaper-root": {
      width: "100%",
      padding: 10,
      margin: 10,
    },
  },
  formcontainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-container": {
      // marginBottom: 10,
    },
  },
  updatebutton: {
    width: "100%",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

function AddPipelineCountiesComp(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const [pipelinesections, setPipelineSections] = React.useState([]);
  const [pipelines, setPipelines] = React.useState([]);

  const statelookupvalues = useSelector(
    (state) => state.statenamesReducer.StateName
  );
  const countylookupvalues = useSelector(
    (state) => state.countynamesReducer.CountyName
  );

  const [statecounties, setStateCounties] = React.useState([]);

  const [selectedstate, setSelectedState] = React.useState({});
  const [selectedcounty, setSelectedCounty] = React.useState({});
  const [selectedpipelineheader, setSelectedPipelineHeader] = React.useState(
    {}
  );
  const [selectedpipelinesection, setSelectedPipelineSection] = React.useState(
    {}
  );
  const [sectioncounties, setSectionCounties] = React.useState({});

  // const [sectioncountiescolumns, setSectionCountiesColumns] = React.useState([]);

  const sectioncountiescolumns = [
    {
      label: "Id",
      name: "PipelineCountiesId",
      options: {
        filter: true,
      },
    },
    {
      name: "PipelineName",
      options: {
        filter: true,
      },
    },
    {
      name: "PipelineSectionName",
      options: {
        filter: true,
      },
    },
    {
      name: "StateName",
      options: {
        filter: true,
      },
    },
    {
      name: "CountyName",
      options: {
        filter: true,
      },
    },
    {
      name: "DeliveryPoints",
      options: {
        filter: true,
      },
    },
    {
      name: "Delete",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              type="button"
              color="secondary"
              size="small"
              variant="contained"
              onClick={() => fnDeleteCounty(tableMeta.rowData[0])}
            >
              Delete
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    print: false,
    selectableRows: "none",
  };

  const pipelinecountiesinitialstate = {
    PipelineName: null,
    PipelineHeaderId: null,
    PipelineSectionName: null,
    PipelineSectionId: null,
    DeliveryPoints: 1,
    CountyName: null,
    CountyId: null,
    StateName: null,
    StateNameId: null,
    key: 0,
  };

  const [pipelinecountiesvalues, setPipelineCountiesValues] = React.useState(
    pipelinecountiesinitialstate
  );

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "error",
  });

  const fnDeleteCounty = (id) => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      facilityid: id,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/deletepipelinecounty", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while deleting record",
              variant: "error",
            });
          } else if (response.data[0].status === "success") {
            setStatusBase({
              show: true,
              message: "County deleted.",
              variant: "info",
            });

            fnFetchCounties(pipelinecountiesvalues.PipelineSectionId);
          }
        } else {
          setStatusBase({
            show: true,
            message: "Error while deleting record. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while deleting facility",
            variant: "error",
          });
        }
      }
    );
  };

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "error" });
  };

  const fnHandleChange = (prop) => (event) => {
    setPipelineCountiesValues({
      ...pipelinecountiesvalues,
      [prop]: event.target.value,
    });
  };

  const fnOnChange = (newvalue, type, typeid) => {
    if (newvalue !== null && newvalue !== undefined) {
      if (type === "PipelineSectionName") {
        setPipelineCountiesValues({
          ...pipelinecountiesvalues,
          [type]: newvalue[type],
          PipelineSectionId: newvalue.key,
        });
      } else {
        setPipelineCountiesValues({
          ...pipelinecountiesvalues,
          [type]: newvalue[type],
          [typeid]: newvalue[typeid],
        });
      }

      switch (type) {
        case "CountyName":
          setSelectedCounty(newvalue);

          break;
        case "StateName":
          setSelectedState(newvalue);
          setSelectedCounty({});
          var filtercounties = linq
            .from(countylookupvalues)
            .where((a) => a.StateNameId == newvalue.StateNameId)
            .toArray();

          setStateCounties(filtercounties);
          break;
        case "PipelineSectionName":
          setSelectedPipelineSection(newvalue);
          fnFetchCounties(newvalue.key);
          break;
        case "PipelineName":
          setSelectedPipelineHeader(newvalue);
          setSectionCounties([]);
          setSelectedPipelineSection({});
          fnFetchPipelineSectionData(newvalue[typeid]);
          break;
      }
    }
  };

  const fnFetchCounties = (sectionid) => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      fetchtype: "pipelinecounties",
      fetchquery: sectionid,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchindividualdata", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            // setStatusBase({ show: true, message: 'Error while fetching records', variant: 'error' });
          } else if (response.data[0].status == "no records found") {
            // setStatusBase({ show: true, message: 'Pipeline section counties data not found', variant: 'info' });
          } else if (response.data[0].data.length > 0) {
            // setSectionCountiesColumns(Object.keys(response.data[0].data[0]));
            setSectionCounties(response.data[0].data);

            // scrollToRef(pipelinesectiondataRef);
          }
        } else {
          setStatusBase({
            show: true,
            message:
              "Error while fetching pipeline section counties data. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching pipeline section data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnInsertPipelineCounties = () => {
    var pipelinesectionkeys = [
      "StateNameId",
      "CountyId",
      "PipelineHeaderId",
      "PipelineSectionId",
      "DeliveryPoints",
    ];

    var insertvalues = [];
    pipelinesectionkeys.forEach((element) => {
      if (
        pipelinecountiesvalues[element] !== undefined &&
        pipelinecountiesvalues[element] !== null &&
        pipelinecountiesvalues[element] !== ""
      ) {
        if (element.indexOf("Date") > -1) {
          insertvalues.push(
            element +
              " = '" +
              moment(pipelinecountiesvalues[element]).format("YYYY-MM-DD") +
              "'"
          );
        } else {
          if (typeof pipelinecountiesvalues[element] === "string") {
            insertvalues.push("'" + pipelinecountiesvalues[element] + "'");
          } else {
            insertvalues.push(pipelinecountiesvalues[element]);
          }
        }
      } else {
        insertvalues.push("NULL");
      }
    });

    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      inserttype: "PipelineCounties",
      insertvalues: insertvalues.join(","),
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/insertdatarecord", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error while inserting pipelinecounties record",
            variant: "error",
          });
        } else if (response.data[0].status === "inserted") {
          setStatusBase({
            show: true,
            message: "Record inserted successfully",
            variant: "info",
          });
          fnFetchCounties(pipelinecountiesvalues.PipelineSectionId);
          // window.location.reload();
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting record",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting pipelinecounties data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnFetchPipelineSectionData = (id) => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      fetchtype: "pipelinesections",
      fetchquery: id,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchindividualdata", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while fetching records",
              variant: "error",
            });
          } else if (response.data[0].status == "no records found") {
            setStatusBase({
              show: true,
              message: "Pipeline section data not found",
              variant: "info",
            });
          } else if (response.data[0].data.length > 0) {
            setPipelineSections(response.data[0].data);

            // scrollToRef(pipelinesectiondataRef);
          }
        } else {
          setStatusBase({
            show: true,
            message:
              "Error while fetching pipeline section data. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching pipeline section data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnFetchLookupValues = () => {
    const data = {
      fetchtype: "PipelineName",
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchuniquevalues", data, options).then(
      (response) => {
        // console.log(response.data[0]);
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            var data = [];
            response.data[0].data.forEach((element) => {
              data.push({
                PipelineName: element["PipelineName"],
                PipelineHeaderId: element["PipelineHeaderId"],
              });
            });

            setPipelines(data);
          } else {
          }
        }
      },
      (error) => {}
    );
  };

  const fnHandleCheckChange = (prop) => (event) => {
    setPipelineCountiesValues({
      ...pipelinecountiesvalues,
      [prop]: event.target.checked ? 1 : 0,
    });
  };

  useEffect(() => {
    // fetchTableData('PipelineSectionName', 'PipelineSection');
    fnFetchLookupValues();
  }, []);

  return (
    <>
      <Grid className={classes.operatoraddcontainer} container>
        <Paper>
          <Typography variant="subtitle1">Add PipelineCounties Data</Typography>
          <ValidatorForm>
            {pipelines.length > 0 ? (
              <>
                <Grid
                  container
                  spacing="3"
                  direction="column"
                  className={classes.formcontainer}
                >
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={pipelines}
                          getOptionLabel={(option) =>
                            option.PipelineName === undefined
                              ? ""
                              : option.PipelineName
                          }
                          value={selectedpipelineheader}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              fnOnChange(
                                newValue,
                                "PipelineName",
                                "PipelineHeaderId"
                              );
                            } else {
                              setSelectedPipelineHeader({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Pipeline"}
                              placeholder="Pipeline"
                              error={
                                selectedpipelineheader.PipelineName ===
                                undefined
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={pipelinesections}
                          getOptionLabel={(option) =>
                            option.PipelineSectionName === undefined
                              ? ""
                              : option.PipelineSectionName
                          }
                          value={selectedpipelinesection}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              fnOnChange(
                                newValue,
                                "PipelineSectionName",
                                "PipelineSectionNameId"
                              );
                            } else {
                              setSelectedPipelineSection({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Pipeline Section"}
                              placeholder="Pipeline Section"
                              error={
                                selectedpipelinesection.PipelineSectionName ===
                                undefined
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={statelookupvalues}
                          getOptionLabel={(option) =>
                            option.StateName === undefined
                              ? ""
                              : option.StateName
                          }
                          value={selectedstate}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              fnOnChange(newValue, "StateName", "StateNameId");
                            } else {
                              setSelectedState({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select State"}
                              placeholder="State"
                              error={selectedstate.StateName === undefined}
                            />
                          )}
                        />
                        {/* {JSON.stringify(operatorvalues.City)} */}
                      </Grid>
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={statecounties}
                          getOptionLabel={(option) =>
                            option.CountyName === undefined
                              ? ""
                              : option.CountyName
                          }
                          value={selectedcounty}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              fnOnChange(newValue, "CountyName", "CountyId");
                            } else {
                              setSelectedCounty({});
                            }
                          }}
                          disabled={
                            statecounties.length === 0 ||
                            selectedstate.StateName === undefined
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select County"}
                              placeholder="County"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                pipelinecountiesvalues.DeliveryPoints === 0
                                  ? false
                                  : true
                              }
                              onChange={fnHandleCheckChange("DeliveryPoints")}
                              name="DeliveryPoints"
                              color="primary"
                            />
                          }
                          label="DeliveryPoints"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Grid item>
                        <Button
                          type="button"
                          color="primary"
                          variant="contained"
                          className={classes.updatebutton}
                          disabled={
                            pipelinecountiesvalues.PipelineName === null ||
                            pipelinecountiesvalues.StateName === null ||
                            pipelinecountiesvalues.PipelineSectionName === "" ||
                            pipelinecountiesvalues.CountyName === ""
                          }
                          onClick={() => fnInsertPipelineCounties()}
                        >
                          Insert PipelineCounties
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </ValidatorForm>
        </Paper>

        <Grid container>
          <Grid xs={12} style={{ maxWidth: "98.7%" }} item>
            {sectioncounties.length > 0 ? (
              <>
                <MUIDataTable
                  title={
                    selectedpipelinesection.PipelineSectionName +
                    " Section Counties Data"
                  }
                  data={sectioncounties}
                  columns={sectioncountiescolumns}
                  options={options}
                />
              </>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(AddPipelineCountiesComp);
