import React, { Suspense } from 'react';

import Grid from '@material-ui/core/Grid';

import LinearProgress from '@material-ui/core/LinearProgress';

import { makeStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router-dom';

const WellSearchComponent = React.lazy(
	() =>
		new Promise((resolve, reject) => setTimeout(() => resolve(import('./subcomponents/WellSearchComp')), 100))
);

const WellUpdateComponent = React.lazy(
	() =>
		new Promise((resolve, reject) => setTimeout(() => resolve(import('./subcomponents/WellUpdateComp')), 100))
);

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
	},
}));

function WellComp(props) {
	const classes = useStyles();

	const [searchwellquery, setSearchWellQuery] = React.useState('');

	return (
		<>
			<Grid container>
				<Suspense
					fallback={
						<>
							<LinearProgress />
						</>
					}
				>
					<WellSearchComponent setSearchWellQuery={setSearchWellQuery} />
				</Suspense>
			</Grid>
			<Grid container>
				<Suspense
					fallback={
						<>
							<LinearProgress />
						</>
					}
				>
					<WellUpdateComponent searchwellquery={searchwellquery} />
				</Suspense>
			</Grid>
		</>
	);
}

export default withRouter(WellComp);
