import React from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
  HashRouter,
} from "react-router-dom";

import Login from "screens/login";
import Home from "screens/home";
import Operator from "screens/operator";
import AddOperator from "screens/addoperator";
import AddGasPlant from "screens/addgasplant";
import AddGasStorage from "screens/addgasstorage";
import AddRefinery from "screens/addrefinery";
import AddTerminal from "screens/addterminal";
import AddWell from "screens/addwell";
import AddTerminalUnit from "screens/addterminalunit";
import AddTerminalTransport from "screens/addterminaltransport";
import AddPipeline from "screens/addpipeline";
import AddPipelineSection from "screens/addpipelinesection";
import AddPipelineCounties from "screens/addpipelinecounties";
import MapLocationCodes from "screens/maplocationcodes";
import AddPipelineInterconnects from "screens/addpipelineinterconnects";
import ChangePipelineName from "screens/changepipelinename";
import AddCompressorStation from "screens/addcompressorstation";
import AddPumpingStation from "screens/addpumpingstation";
import AddLiquefaction from "screens/addliquefaction";
import AddPipelineAssembly from "screens/addpipelineassembly";
import AddLiquefactionUnit from "screens/addliquefactionunit";
import UpdateOperator from "screens/updateoperator";
import UpdateOwnerByOperator from "screens/updateownerbyoperator";
import UpdateOwner from "screens/updateowner";
import ReplaceOperator from "screens/replaceoperator";
import GasPlant from "screens/gasplant";
import GasPlantUnit from "screens/gasplantunit";
import GasStorage from "screens/gasstorage";
import Refinery from "screens/refinery";
import Terminal from "screens/terminal";
import Well from "screens/well";
import Pipeline from "screens/pipeline";
import DataView from "screens/dataview";
import CompressorStation from "screens/compressorstation";
import IndustrialPlant from "screens/industrialplant";
import PowerPlant from "screens/powerplant";
import Tariff from "screens/tariff";
import PumpingStation from "screens/pumpingstation";
import HydrogenPlant from "screens/hydrogenplant";
import CCUS from "screens/ccus";
import CO2Storage from "screens/co2storage";

import Liquefaction from "screens/liquefaction";
import PipelineAssembly from "screens/pipelineassembly";
import SearchFacility from "screens/searchfacility";
import DeleteFacility from "screens/deletefacility";
import ForgotPassword from "screens/forgotpassword";
import ChangePassword from "screens/changepassword";
import AddGasStorageCost from "screens/addgasstoragecost";
import AddGasPlantCost from "screens/addgasplantcost";
import AddLiquefactionCost from "screens/addliquefactioncost";
import AddRefineryCost from "screens/addrefinerycost";
import AddTerminalCost from "screens/addterminalcost";
import AddCompressorStationCost from "screens/addcompressorstationcost";
import AddPumpingStationCost from "screens/addpumpingstationcost";
import AddIndustrialPlant from "screens/addindustrialplant";
import AddPowerPlant from "screens/addpowerplant";
import AddHydrogenPlant from "screens/addhydrogenplant";
import AddCCUS from "screens/addccus";

import AddCO2Storage from "screens/addco2storage";
import AddTariff from "screens/addtariff";
import UpdateGasPipelineLocationCodes from "screens/updategaspipelineloccodes";
import history from "./history";

import LoadingSpinnerComp from "components/custom/LoadingSpinnerComp";

import PrivateRoute from "./PrivateRoute";

import store from "./store";

import { Provider } from "react-redux";

import ThemeProvider from "themes/themeprovider";
import UserWorkflow from "../screens/userworkflow";

const state = store.getState();
const routing = (
  <Provider store={store}>
    <LoadingSpinnerComp></LoadingSpinnerComp>
    <ThemeProvider>
      <HashRouter>
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/login" component={Login} />
            <Route path="/forgotpassword" component={ForgotPassword} />
            <PrivateRoute path="/home" component={Home}></PrivateRoute>
            <PrivateRoute path="/operator" component={Operator}></PrivateRoute>
            <PrivateRoute
              path="/addoperator"
              component={AddOperator}
            ></PrivateRoute>
            <PrivateRoute
              path="/updateoperator"
              component={UpdateOperator}
            ></PrivateRoute>
            <PrivateRoute
              path="/updateowner"
              component={UpdateOwner}
            ></PrivateRoute>
            <PrivateRoute
              path="/replaceoperator"
              component={ReplaceOperator}
            ></PrivateRoute>
            <PrivateRoute
              path="/updateownerbyoperator"
              component={UpdateOwnerByOperator}
            ></PrivateRoute>
            <PrivateRoute path="/gasplant" component={GasPlant}></PrivateRoute>
            <PrivateRoute
              path="/gasplantunit"
              component={GasPlantUnit}
            ></PrivateRoute>
            <PrivateRoute
              path="/gasstorage"
              component={GasStorage}
            ></PrivateRoute>
            <PrivateRoute path="/refinery" component={Refinery}></PrivateRoute>
            <PrivateRoute path="/terminal" component={Terminal}></PrivateRoute>
            <PrivateRoute path="/well" component={Well}></PrivateRoute>
            <PrivateRoute path="/pipeline" component={Pipeline}></PrivateRoute>
            <PrivateRoute path="/dataview" component={DataView}></PrivateRoute>
            <PrivateRoute
              path="/compressorstation"
              component={CompressorStation}
            ></PrivateRoute>
            <PrivateRoute
              path="/industrialplant"
              component={IndustrialPlant}
            ></PrivateRoute>
            <PrivateRoute
              path="/powerplant"
              component={PowerPlant}
            ></PrivateRoute>
            <PrivateRoute
              path="/hydrogenplant"
              component={HydrogenPlant}
            ></PrivateRoute>

            <PrivateRoute path="/ccus" component={CCUS}></PrivateRoute>

            <PrivateRoute
              path="/co2storage"
              component={CO2Storage}
            ></PrivateRoute>
            <PrivateRoute
              path="/addco2storage"
              component={AddCO2Storage}
            ></PrivateRoute>

            <PrivateRoute path="/tariff" component={Tariff}></PrivateRoute>
            <PrivateRoute
              path="/pumpingstation"
              component={PumpingStation}
            ></PrivateRoute>
            <PrivateRoute
              path="/liquefaction"
              component={Liquefaction}
            ></PrivateRoute>
            <PrivateRoute
              path="/pipelineassembly"
              component={PipelineAssembly}
            ></PrivateRoute>
            <PrivateRoute
              path="/addgasplant"
              component={AddGasPlant}
            ></PrivateRoute>

            <PrivateRoute
              path="/addgasstorage"
              component={AddGasStorage}
            ></PrivateRoute>
            <PrivateRoute
              path="/addrefinery"
              component={AddRefinery}
            ></PrivateRoute>
            <PrivateRoute
              path="/addterminal"
              component={AddTerminal}
            ></PrivateRoute>
            <PrivateRoute
              path="/addterminalunit"
              component={AddTerminalUnit}
            ></PrivateRoute>
            <PrivateRoute
              path="/addterminaltransport"
              component={AddTerminalTransport}
            ></PrivateRoute>
            <PrivateRoute path="/addwell" component={AddWell}></PrivateRoute>
            <PrivateRoute
              path="/addgasstoragecost"
              component={AddGasStorageCost}
            ></PrivateRoute>
            <PrivateRoute
              path="/addgasplantcost"
              component={AddGasPlantCost}
            ></PrivateRoute>
            <PrivateRoute
              path="/addliquefactioncost"
              component={AddLiquefactionCost}
            ></PrivateRoute>
            <PrivateRoute
              path="/addterminalcost"
              component={AddTerminalCost}
            ></PrivateRoute>
            <PrivateRoute
              path="/addrefinerycost"
              component={AddRefineryCost}
            ></PrivateRoute>
            <PrivateRoute
              path="/addcompressorstationcost"
              component={AddCompressorStationCost}
            ></PrivateRoute>
            <PrivateRoute
              path="/addpumpingstationcost"
              component={AddPumpingStationCost}
            ></PrivateRoute>
            <PrivateRoute
              path="/addpipeline"
              component={AddPipeline}
            ></PrivateRoute>
            <PrivateRoute
              path="/addpipelinesection"
              component={AddPipelineSection}
            ></PrivateRoute>
            <PrivateRoute
              path="/maplocationcodes"
              component={MapLocationCodes}
            ></PrivateRoute>
            <PrivateRoute
              path="/addpipelinecounties"
              component={AddPipelineCounties}
            ></PrivateRoute>
            <PrivateRoute
              path="/addpipelineinterconnects"
              component={AddPipelineInterconnects}
            ></PrivateRoute>
            <PrivateRoute
              path="/changepipelinename"
              component={ChangePipelineName}
            ></PrivateRoute>
            <PrivateRoute
              path="/addcompressorstation"
              component={AddCompressorStation}
            ></PrivateRoute>
            <PrivateRoute
              path="/addindustrialplant"
              component={AddIndustrialPlant}
            ></PrivateRoute>
            <PrivateRoute
              path="/addpowerplant"
              component={AddPowerPlant}
            ></PrivateRoute>
            <PrivateRoute
              path="/addhydrogenplant"
              component={AddHydrogenPlant}
            ></PrivateRoute>

            <PrivateRoute path="/addccus" component={AddCCUS}></PrivateRoute>
            <PrivateRoute
              path="/pipelinegasflowloccodes"
              component={UpdateGasPipelineLocationCodes}
            ></PrivateRoute>

            <PrivateRoute
              path="/addtariff"
              component={AddTariff}
            ></PrivateRoute>
            <PrivateRoute
              path="/addpumpingstation"
              component={AddPumpingStation}
            ></PrivateRoute>
            <PrivateRoute
              path="/addliquefaction"
              component={AddLiquefaction}
            ></PrivateRoute>
            <PrivateRoute
              path="/addpipelineassembly"
              component={AddPipelineAssembly}
            ></PrivateRoute>
            <PrivateRoute
              path="/addliquefactionunit"
              component={AddLiquefactionUnit}
            ></PrivateRoute>
            <PrivateRoute
              path="/searchfacility"
              component={SearchFacility}
            ></PrivateRoute>
            <PrivateRoute
              path="/deletefacilitycompnent"
              component={DeleteFacility}
            ></PrivateRoute>
            <PrivateRoute
              path="/userworkflow"
              component={UserWorkflow}
            ></PrivateRoute>
            {/* <PrivateRoute path="/home" component={Home}></PrivateRoute> */}
            <PrivateRoute
              path="/changepassword"
              component={ChangePassword}
            ></PrivateRoute>
          </Switch>
        </Router>
      </HashRouter>
    </ThemeProvider>
  </Provider>
);

export default routing;
