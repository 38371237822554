import * as actionType from 'redux/actions/actionType';

let initialState = {
	BasinName: [],
};

function basinsReducer(state = initialState, action) {
	switch (action.type) {
		case actionType.ADD_BASINS:
			state.BasinName = action.payload.BasinName;
			return state;

		default:
			return state;
	}
}

export default basinsReducer;
