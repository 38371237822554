import * as actionType from 'redux/actions/actionType';

let initialState = {
	StateName: [],
};

function statenamesReducer(state = initialState, action) {
	switch (action.type) {
		case actionType.ADD_STATENAMES:
			state.StateName = action.payload.StateName;
			return state;

		default:
			return state;
	}
}

export default statenamesReducer;
