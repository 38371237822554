import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Paper, Grid, Button, FormControlLabel } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import CardMedia from '@material-ui/core/CardMedia';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import AlertNotificationComp from 'components/custom/AlertNotificationComp';

//Images
import LoginImage from 'assets/images/multiply.png';

//Redux imports
import { useDispatch } from 'react-redux';
import * as actionType from 'redux/actions/actionType';

//Api
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
	login: {
		top: 50,
		padding: theme.spacing(3, 2),
		width: 400,
	},
	root: {
		padding: theme.spacing(3, 2),
		width: 450,
		marginTop: 50,
	},
	margin: {
		marginTop: theme.spacing(3),
	},
	loginbutton: {
		boxShadow: 'none',
		width: '100%',
		marginTop: '30px',
		marginBottom: '20px',
	},
	loginimage: {
		height: 60,
		width: 158,
		backgroundSize: 'contain',
	},
}));

export default function ForgotPasswordComp(props) {
	const classes = useStyles();

	const [values, setValues] = React.useState({
		username: '',
		error: '',
	});

	const [status, setStatusBase] = React.useState({
		show: false,
		message: '',
		variant: 'info',
	});

	const dispatch = useDispatch();

	const fnShowErrorMessage = () => {
		setStatusBase({ show: false, message: '', variant: 'info' });
	};

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const fnForgotPassword = (info) => {
		const userDetails = {
			username: info.variables.username,
		};

		axios.post('/api/forgotpassword', userDetails).then(
			(response) => {
				dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

				if (response.data[0].status === 'password sent successfully') {
					setStatusBase({
						show: true,
						message: 'New password sent to your registered email address.',
						variant: 'info',
					});

					setTimeout(() => {
						props.history.push('/login');
					}, 5000);
				} else if (response.data[0].status === 'no user') {
					setStatusBase({ show: true, message: 'User not found.' });
				} else {
					setStatusBase({ show: true, message: 'Error. Please contact administrator.', variant: 'error' });
				}
			},
			(error) => {
				// console.log(error);
				dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });
				setStatusBase({ show: true, message: 'Error. Please contact administrator.', variant: 'error' });
			}
		);
	};

	const fnGoToLogin = () => {
		props.history.push('/login');
	};

	return (
		<React.Fragment>
			<Grid container direction="column" justify="space-around" alignItems="center">
				<Grid item>
					<Paper className={classes.login}>
						<ValidatorForm
							onSubmit={() => {
								dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

								fnForgotPassword({
									variables: {
										username: values.username,
									},
								});
							}}
							onError={(errors) => console.log(errors)}
						>
							<Grid container direction="column" justify="center" alignItems="stretch">
								<Grid item>
									<CardMedia image={LoginImage} className={classes.loginimage}></CardMedia>
								</Grid>
								<Grid item>
									<TextValidator
										margin="normal"
										fullWidth
										label="User Name"
										onChange={handleChange('username')}
										name="username"
										value={values.username || ''}
										validators={['required']}
										errorMessages={['Required']}
										inputProps={{
											maxLength: 25,
										}}
									/>
								</Grid>

								<Grid item>
									<Button type="submit" color="primary" variant="contained">
										Recover Password
									</Button>
								</Grid>
								<Grid item>
									<Button size="small" onClick={() => fnGoToLogin()}>
										Go To Login
									</Button>
								</Grid>
							</Grid>
						</ValidatorForm>
					</Paper>
				</Grid>
			</Grid>
			<Snackbar
				autoHideDuration={6000}
				open={status.show}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={() => fnShowErrorMessage()}
			>
				<AlertNotificationComp
					variant={status.variant}
					onClose={() => fnShowErrorMessage()}
					message={status.message}
				></AlertNotificationComp>
			</Snackbar>
		</React.Fragment>
	);
}
