import * as actionType from 'redux/actions/actionType';

let initialState = {
	username: '',
	token: '',
	usercode: '',
	userrole: '',
};

function userReducer(state = initialState, action) {
	switch (action.type) {
		case actionType.ADD_USERDETAILS:
			state.username = action.payload.username;
			state.token = action.payload.token;
			state.usercode = action.payload.usercode;
			state.userrole = action.payload.userrole;
			return state;

		default:
			return state;
	}
}

export default userReducer;
