import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';

export default function ConfirmationDialogComp(props) {
	return (
		<React.Fragment>
			<Dialog
				open={props.display}
				onClose={props.handleNoDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{props.message}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={props.handleNoDialog} color="primary">
						No
					</Button>
					<Button onClick={props.handleYesDialog} color="primary" autoFocus>
						yes
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}

//Sample code 

// cosnt[(confirmDialog, setConfirmDialog)] = React.useState({
// 	display: false,
// 	message: '',
// });

// const handleNoDialog = () => {
// 	setConfirmDialog({ display: false, message: '' });
// };

// const handleYesDialog = () => {
// 	//Achieve something and reset the state values
// 	setConfirmDialog({ display: false, message: '' });
// };

// <ConfirmationDialogComp
// 	message={confirmDialog.message}
// 	display={confirmDialog.display}
// 	handleNoDialog={() => handleNoDialog()}
// 	handleYesDialog={() => handleYesDialog()}
// ></ConfirmationDialogComp>;
