import { combineReducers } from 'redux';

import userReducer from './userReducer';
import navigationReducer from './navigationReducer';
import spinnerReducer from './spinnerReducer';
import customizationReducer from './customizationReducer';
import searchReducer from './searchReducer';
import operatorsReducer from './operatorsReducer';
import operatortypesReducer from './operatortypesReducer';
import citynamesReducer from './citynamesReducer';
import countynamesReducer from './countynamesReducer';
import statenamesReducer from './statenamesReducer';
import zipcodesReducer from './zipcodesReducer';
import basinsReducer from './basinsReducer';
import refineryproductReducer from './refineryproductReducer';
import commodityhandledReducer from './commodityhandledReducer';
import facilitiescountReducer from './facilitiescountReducer';

const naveenApp = combineReducers({
	userReducer,
	navigationReducer,
	spinnerReducer,
	customizationReducer,
	searchReducer,
	operatorsReducer,
	operatortypesReducer,
	citynamesReducer,
	countynamesReducer,
	statenamesReducer,
	zipcodesReducer,
	basinsReducer,
	refineryproductReducer,
	facilitiescountReducer,
	commodityhandledReducer,
});

export default naveenApp;
