import React, { useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import AlertNotificationComp from 'components/custom/AlertNotificationComp';

import PipelineUpdateComp from './PipelineUpdateComp';
import PipelineSectionUpdateComp from './PipelineSectionUpdateComp';

//linq
import linq from 'linq';

//Redux imports
import { useSelector } from 'react-redux';

//Api
import axios from 'axios';

//Redux imports
import { useDispatch } from 'react-redux';
import * as actionType from 'redux/actions/actionType';

import { withRouter } from 'react-router-dom';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`,
	};
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
	operatordatacontainer: {
		margin: 10,
		width: '100%',
		'& .MuiPaper-root': {
			width: '100%',
		},
	},
	formcontainer: {
		'& .MuiFormControl-root': {
			width: '100%',
		},
		'& .MuiGrid-container': {
			marginBottom: 3.5,
		},
	},

	operatorlist: {
		width: '100%',
		position: 'relative',
		overflow: 'auto',
		maxHeight: 700,
		minHeight: 700,
	},

	updatebutton: {
		width: '200px',
	},
	tabsstyle: {
		'& .MuiTab-root': {
			textTransform: 'none',
		},
	},
}));

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

function PipelineSubComp(props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const token = useSelector((state) => state.userReducer.token);

	const pipelinedataRef = useRef(null);

	const [selectedindex, setSelectedIndex] = React.useState('');

	const [status, setStatusBase] = React.useState({
		show: false,
		message: '',
		variant: 'error',
	});

	const [tabvalue, setTabValue] = React.useState(0);

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	const [pipelinedata, setPipelineData] = React.useState([]);
	const [pipelineid, setPipelineId] = React.useState(0);

	const fnShowErrorMessage = () => {
		setStatusBase({ show: false, message: '', variant: 'info' });
	};

	const fnFetchPipelineData = () => {
		dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

		const data = {
			fetchtype: 'pipelines',
			fetchquery: props.searchpipelinequery,
		};

		const options = {
			headers: {
				authorization: token ? `Bearer ${token}` : '',
			},
		};

		axios.post('/api/fetchindividualdata', data, options).then(
			(response) => {
				dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

				if (response.data[0].status.code === undefined) {
					if (response.data[0].status === 'error') {
						setStatusBase({ show: true, message: 'Error while fetching records', variant: 'error' });
					} else if (response.data[0].status == 'no records found') {
						setStatusBase({ show: true, message: 'Pipeline data not found', variant: 'info' });
					} else if (response.data[0].data.length > 0) {
						setPipelineData(response.data[0].data);

						scrollToRef(pipelinedataRef);
					}
				} else {
					setStatusBase({
						show: true,
						message: 'Error while fetching pipeline data. Please try again.',
						variant: 'error',
					});
				}
			},
			(error) => {
				dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

				if (error.request.status === 500) {
					setStatusBase({ show: true, message: 'Time out', variant: 'error' });
					props.history.push('/login');
				} else {
					setStatusBase({ show: true, message: 'Error while fetching pipeline data', variant: 'error' });
				}
			}
		);
	};

	const fnLoadPipelineData = (id, index) => {
		setSelectedIndex(index);
		setPipelineId(id);
	};

	useEffect(() => {
		if (props.searchpipelinequery !== '' && props.searchpipelinequery !== undefined) {
			setSelectedIndex('');
			fnFetchPipelineData();
		}
	}, [props.searchpipelinequery]);

	return (
		<>
			<Grid className={classes.operatordatacontainer} container>
				{pipelinedata.length > 0 ? (
					<Grid
						container
						ref={pipelinedataRef}
						direction="row"
						justify="flex-start"
						alignItems="flex-start"
						spacing="1"
					>
						<Grid item xs={3}>
							<Paper>
								<List
									component="nav"
									className={classes.operatorlist}
									aria-label="secondary mailbox folders"
								>
									{pipelinedata.map((item, index) => {
										return (
											<>
												<ListItem
													button
													key={item.key}
													selected={selectedindex === index}
													onClick={() => fnLoadPipelineData(item.key, index)}
												>
													<ListItemText primary={item.PipelineName} />
												</ListItem>
												<Divider light />
											</>
										);
									})}
								</List>
							</Paper>
						</Grid>
						<Grid item xs={9}>
							{selectedindex !== '' ? (
								<Paper square>
									<Tabs
										className={classes.tabsstyle}
										value={tabvalue}
										indicatorColor="primary"
										textColor="primary"
										onChange={handleTabChange}
										aria-label="disabled tabs example"
									>
										<Tab label="Header" />
										<Tab label="Section" />
									</Tabs>
									<TabPanel value={tabvalue} index={0}>
										<PipelineUpdateComp id={pipelineid}></PipelineUpdateComp>
									</TabPanel>
									<TabPanel value={tabvalue} index={1}>
										<PipelineSectionUpdateComp id={pipelineid}></PipelineSectionUpdateComp>
									</TabPanel>
								</Paper>
							) : null}
						</Grid>
					</Grid>
				) : null}
			</Grid>

			<Snackbar
				autoHideDuration={6000}
				open={status.show}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={() => fnShowErrorMessage()}
			>
				<AlertNotificationComp
					variant={status.variant}
					onClose={() => fnShowErrorMessage()}
					message={status.message}
				></AlertNotificationComp>
			</Snackbar>
		</>
	);
}

export default withRouter(PipelineSubComp);
