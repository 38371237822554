import React from 'react';
import Grid from '@material-ui/core/Grid';

import ForgotPasswordComp from 'components/user/ForgotPasswordComp';

const loginStyles = {
	login: {
		height: '70%',
	},
};

export default class ChangePassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<React.Fragment>
				<Grid container style={loginStyles.login} direction="column" justify="center" alignItems="center">
					<Grid item align="center">
						<ForgotPasswordComp history={this.props.history}></ForgotPasswordComp>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	}
}