import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Paper, Grid, Button, FormControlLabel, Snackbar } from '@material-ui/core';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import AlertNotificationComp from 'components/custom/AlertNotificationComp';

//Redux imports
import { useDispatch, useSelector } from 'react-redux';
import * as actionType from 'redux/actions/actionType';

//Api
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3, 2),
		width: 450,
		marginTop: 50,
	},
	margin: {
		marginTop: theme.spacing(3),
	},
}));

export default function ChangePasswordComp(props) {
	const classes = useStyles();

	const [values, setValues] = React.useState({
		newpassword: '',
		retypepassword: '',
		error: '',
	});

	const [status, setStatusBase] = React.useState({
		show: false,
		message: '',
		type: 'info',
	});

	const dispatch = useDispatch();

	const token = useSelector((state) => state.userReducer.token);
	const username = useSelector((state) => state.userReducer.username);

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const fnShowErrorMessage = () => {
		setStatusBase({ show: false, message: '', type: 'info' });
	};

	const fnChangePassword = (info) => {
		const passwordDetails = {
			password: info.variables.password,
			username: username,
		};

		const options = {
			headers: {
				authorization: token ? `Bearer ${token}` : '',
			},
		};

		axios.post('/api/changepassword', passwordDetails, options).then(
			(response) => {
				dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

				setStatusBase({ show: true, message: 'Password change successfully', type: 'info' });
			},
			(error) => {
				dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });
				setStatusBase({ show: true, message: 'Error occured while changing password', type: 'error' });
			}
		);
	};

	useEffect(() => {
		ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
			if (value !== values.newpassword) {
				return false;
			}
			return true;
		});
	});

	return (
		<React.Fragment>
			<Paper className={classes.root}>
				<ValidatorForm
					onSubmit={() => {
						dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

						fnChangePassword({
							variables: {
								password: values.newpassword,
							},
						});
					}}
					onError={(errors) => console.log(errors)}
				>
					<TextValidator
						margin="normal"
						fullWidth
						label="New Password"
						onChange={handleChange('newpassword')}
						name="newpassword"
						type="password"
						value={values.newpassword || ''}
						validators={['required']}
						errorMessages={['Required']}
						inputProps={{
							maxLength: 25,
						}}
					/>
					<TextValidator
						margin="normal"
						fullWidth
						label="Re-Type Password"
						onChange={handleChange('retypepassword')}
						name="retypepassword"
						type="password"
						value={values.retypepassword || ''}
						validators={['isPasswordMatch', 'required']}
						errorMessages={['Required']}
						inputProps={{
							maxLength: 25,
						}}
					/>
					<Button type="submit" color="primary" variant="contained">
						Change Password
					</Button>
				</ValidatorForm>
			</Paper>
			<Snackbar
				autoHideDuration={6000}
				open={status.show}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={() => fnShowErrorMessage()}
			>
				<AlertNotificationComp
					variant={status.type}
					onClose={() => fnShowErrorMessage()}
					message="Change Password Successfully"
				></AlertNotificationComp>
			</Snackbar>
		</React.Fragment>
	);
}
