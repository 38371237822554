import React, { Suspense, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import Snackbar from "@material-ui/core/Snackbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import LinearProgress from "@material-ui/core/LinearProgress";

import { makeStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";

import LookupInsertComp from "components/common/LookupInsertComp";
import AlertNotificationComp from "components/custom/AlertNotificationComp";

//date
import moment from "moment";

//Api
import axios from "axios";

//linq
import linq from "linq";

//Redux imports
import { useSelector, useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import { plantstatus } from "lookuptables/global";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },
  operatoraddcontainer: {
    "& .MuiPaper-root": {
      width: "100%",
      padding: 10,
      margin: 10,
    },
  },
  formcontainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-container": {
      // marginBottom: 10,
    },
  },
  updatebutton: {
    width: "100%",
  },
}));

function AddGasPlantComp(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const [gasplants, setGasPlants] = React.useState([]);

  const citylookupvalues = useSelector(
    (state) => state.citynamesReducer.CityName
  );
  const countylookupvalues = useSelector(
    (state) => state.countynamesReducer.CountyName
  );
  const statelookupvalues = useSelector(
    (state) => state.statenamesReducer.StateName
  );
  const zipcodelookupvalues = useSelector(
    (state) => state.zipcodesReducer.Zipcode
  );
  const operatorlookupvalues = useSelector(
    (state) => state.operatorsReducer.OperatorName
  );
  const basinlookupvalues = useSelector(
    (state) => state.basinsReducer.BasinName
  );

  const [statecounties, setStateCounties] = React.useState([]);

  const [selectedcity, setSelectedCity] = React.useState({});
  const [selectedcounty, setSelectedCounty] = React.useState({});
  const [selectedstate, setSelectedState] = React.useState({});
  const [selectedzipcode, setSelectedZipcode] = React.useState({});
  const [selectedbasin, setSelectedBasin] = React.useState({});
  const [selectedowner, setSelectedOwner] = React.useState({});
  const [selectedoperator, setSelectedOperator] = React.useState({});
  const [selectedStatus, setSelectedStatus] = React.useState({});

  const [pipelinelocationcode, setPipelineLocationCode] = React.useState("");
  const [selectedpipeline, setSelectedPipeline] = React.useState({});

  const gasplantinitialstate = {
    GasPlantName: "",
    GovtGasPlantId: "",
    GasPlantAddress: "",
    CityName: null,
    CityId: null,
    CountyName: null,
    CountyId: null,
    StateName: null,
    StateNameId: null,
    Zipcode: null,
    ZipcodeId: null,
    Basin: null,
    BasinId: null,
    OperatorName: null,
    OperatorId: null,
    Owner: null,
    OwnerId: null,
    Latitude: "",
    Longitude: "",
    GasProcessingCapacityMMSCFD: "",
    GasStorageCapacityMMSCF: "",
    NGLStorageCapacityBbl: "",
    BTUContentGasSoldJOULES: "",
    PlantCommissioned: null,
    NAICSCode: "",
    NAICSDesc: "",
    RMPID: "",
    EPAID: "",
    Treatment: 0,
    NGLExtraction: 0,
    Fractionation: 0,
    StatusId: null,
    ContactName: "",
    ContactNumber: "",
    ContactEmail: "",

    EmissionLastReportedYear: "",
    EmissionGHGRPID: "",
    EquivalentCO2Tons: "",
    key: 0,
  };

  const [gasplantvalues, setGasPlantValues] =
    React.useState(gasplantinitialstate);

  const [pipelinenames, setPipelineNames] = React.useState([]);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "error",
  });

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "error" });
  };

  const fnHandleChange = (prop) => (event) => {
    if (prop === "PipelineLocationCode") {
      setPipelineLocationCode(event.target.value);
    } else {
      setGasPlantValues({ ...gasplantvalues, [prop]: event.target.value });
    }
  };

  const fnOnChange = (newvalue, type, typeid) => {
    if (newvalue !== null && newvalue !== undefined) {
      if (type === "Owner" || type === "Operator") {
        setGasPlantValues({
          ...gasplantvalues,
          [type]: newvalue["OperatorName"],
          [typeid]: newvalue["OperatorId"],
        });
      } else {
        setGasPlantValues({
          ...gasplantvalues,
          [type]: newvalue[type],
          [typeid]: newvalue[typeid],
        });
      }

      switch (type) {
        case "CityName":
          setSelectedCity(newvalue);
          break;
        case "CountyName":
          setSelectedCounty(newvalue);
          break;
        case "StateName":
          setSelectedState(newvalue);
          setSelectedCounty({});
          var filtercounties = linq
            .from(countylookupvalues)
            .where((a) => a.StateNameId == newvalue.StateNameId)
            .toArray();
          setStateCounties(filtercounties);
          break;
        case "Zipcode":
          setSelectedZipcode(newvalue);
          break;
        case "Operator":
          setSelectedOperator(newvalue);
          break;
        case "Owner":
          setSelectedOwner(newvalue);
          break;
        case "BasinName":
          setSelectedBasin(newvalue);
          break;
        case "Status":
          setSelectedStatus(newvalue);
      }
    }
  };

  const fnHandlePlantCommissionedDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setGasPlantValues({
        ...gasplantvalues,
        PlantCommissioned: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setGasPlantValues({ ...gasplantvalues, PlantCommissioned: null });
    }
  };

  const fnHandleCheckChange = (prop) => (event) => {
    setGasPlantValues({
      ...gasplantvalues,
      [prop]: event.target.checked ? 1 : 0,
    });
  };

  const fnResetStateValues = () => {
    // let cloneobj = JSON.parse(JSON.stringify(operatorinitialstate));

    // setOperatorValues(cloneobj);
    setSelectedCity({});
    setSelectedCounty({});
    setSelectedState({});
    setSelectedZipcode({});
    setSelectedBasin({});
    setSelectedOperator({});
    setSelectedOwner({});
    setSelectedPipeline({});
    setPipelineLocationCode("");
  };

  const fnInsertGasPlantData = () => {
    var gasplantkeys = [
      "GasPlantName",
      "GovtGasPlantId",
      "GasPlantAddress",
      "CityId",
      "CountyId",
      "StateNameId",
      "BasinId",
      "ZipcodeId",
      "OperatorId",
      "OwnerId",
      "Latitude",
      "Longitude",
      "GasProcessingCapacityMMSCFD",
      "GasStorageCapacityMMSCF",
      "NGLStorageCapacityBbl",
      "BTUContentGasSoldJOULES",
      "PlantCommissioned",
      "NAICSCode",
      "NAICSDesc",
      "RMPID",
      "EPAID",
      "Treatment",
      "NGLExtraction",
      "Fractionation",
      "StatusId",
      "ContactName",
      "ContactNumber",
      "ContactEmail",
      "EmissionLastReportedYear",
      "EmissionGHGRPID",
      "EquivalentCO2Tons",
    ];

    var insertvalues = [];
    gasplantkeys.forEach((element) => {
      if (
        gasplantvalues[element] !== undefined &&
        gasplantvalues[element] !== null &&
        gasplantvalues[element] !== ""
      ) {
        if (element.indexOf("Date") > -1) {
          insertvalues.push(
            element +
              " = '" +
              moment(gasplantvalues[element]).format("YYYY-MM-DD") +
              "'"
          );
        } else {
          if (typeof gasplantvalues[element] === "string") {
            insertvalues.push("'" + gasplantvalues[element] + "'");
          } else {
            insertvalues.push(gasplantvalues[element]);
          }
        }
      } else {
        insertvalues.push("NULL");
      }
    });

    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      inserttype: "Gas Plant",
      insertvalues: insertvalues.join(","),
      facname: gasplantvalues.GasPlantName,
      factype: "Gas Plant",
      operator: gasplantvalues.OperatorId === null ? "" : gasplantvalues.Operator,
      owner: gasplantvalues.Owner === null ? "" : gasplantvalues.Owner,
      latitude: gasplantvalues.Latitude,
      longitude: gasplantvalues.Longitude,
      pipelinelocationcode:
        pipelinelocationcode !== "" ? pipelinelocationcode : null,
      pipelineheaderid:
        selectedpipeline.PipelineHeaderId !== undefined
          ? selectedpipeline.PipelineHeaderId
          : null,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/insertdatarecord", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error while inserting gas plant record",
            variant: "error",
          });
        } else if (response.data[0].status === "inserted") {
          setStatusBase({
            show: true,
            message: "Record inserted successfully",
            variant: "info",
          });

          window.location.reload();
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting record",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting gasplant data",
            variant: "error",
          });
        }
      }
    );
  };

  const fetchGasPlants = () => {
    const query = { columnname: "GasPlantName", tablename: "Gas Plant" };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchsubjectuniquevalues", query, options).then(
      (response) => {
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "no records") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            setGasPlants(response.data[0].data);
          }
        }
      },
      (error) => {
        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
        }
      }
    );
  };

  const fnFetchUniqueValues = () => {
    const data = {
      fetchtype: "PipelineName",
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchuniquevalues", data, options).then(
      (response) => {
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "no records") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            setPipelineNames(response.data[0].data);
          }
        }
      },
      (error) => {
        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
        }
      }
    );
  };

  useEffect(() => {
    fetchGasPlants();
    fnFetchUniqueValues();
  }, []);

  return (
    <>
      <Grid className={classes.operatoraddcontainer} container>
        <Paper>
          <Typography variant="subtitle1">Add Gas Plant Data</Typography>
          <ValidatorForm>
            {gasplants.length > 0 ? (
              <>
                <Grid
                  container
                  spacing="3"
                  direction="column"
                  className={classes.formcontainer}
                >
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <Autocomplete
                          id="gasplantnameId"
                          freeSolo
                          size="small"
                          options={gasplants}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Gas Plant Name"
                              margin="normal"
                              variant="outlined"
                              value={gasplantvalues.GasPlantName}
                              onChange={fnHandleChange("GasPlantName")}
                              error={gasplantvalues.GasPlantName === ""}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="2">
                        <TextField
                          label="Govt GasPlant Id"
                          id="govtgasplantId"
                          value={gasplantvalues.GovtGasPlantId}
                          onChange={fnHandleChange("Govt Gas Plant Id")}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="4">
                        <TextField
                          label="Address"
                          id="gasplantaddressId"
                          value={gasplantvalues.GasPlantAddress}
                          onChange={fnHandleChange("GasPlantAddress")}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        {/* {JSON.stringify(operatorvalues.City)} */}

                        <Autocomplete
                          size="small"
                          options={citylookupvalues}
                          getOptionLabel={(option) =>
                            option.CityName === undefined ? "" : option.CityName
                          }
                          value={selectedcity}
                          onChange={(event, newValue) => {
                            if (newValue != null) {
                              fnOnChange(newValue, "CityName", "CityId");
                            } else {
                              setSelectedCity({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select City"}
                              placeholder="City"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={statelookupvalues}
                          getOptionLabel={(option) =>
                            option.StateName === undefined
                              ? ""
                              : option.StateName
                          }
                          value={selectedstate}
                          onChange={(event, newValue) => {
                            if (newValue != null) {
                              fnOnChange(newValue, "StateName", "StateNameId");
                            } else {
                              setSelectedState({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select State"}
                              placeholder="State"
                              error={selectedstate.StateName === undefined}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={countylookupvalues}
                          getOptionLabel={(option) =>
                            option.CountyName === undefined
                              ? ""
                              : option.CountyName
                          }
                          value={selectedcounty}
                          onChange={(event, newValue) => {
                            if (newValue != null) {
                              fnOnChange(newValue, "CountyName", "CountyId");
                            } else {
                              setSelectedCounty({});
                            }
                          }}
                          disabled={
                            statecounties.length === 0 ||
                            selectedstate.StateName === undefined
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select County"}
                              placeholder="County"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={zipcodelookupvalues}
                          getOptionLabel={(option) =>
                            option.Zipcode === undefined ? "" : option.Zipcode
                          }
                          value={selectedzipcode}
                          onChange={(event, newValue) => {
                            if (newValue != null) {
                              fnOnChange(newValue, "Zipcode", "ZipcodeId");
                            } else {
                              setSelectedZipcode({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Zipcode"}
                              placeholder="Zipcode"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={basinlookupvalues}
                          getOptionLabel={(option) =>
                            option.BasinName === undefined
                              ? ""
                              : option.BasinName
                          }
                          value={selectedbasin}
                          onChange={(event, newValue) => {
                            if (newValue != null) {
                              fnOnChange(newValue, "BasinName", "BasinId");
                            } else {
                              setSelectedBasin({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Basin"}
                              placeholder="Basin"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={operatorlookupvalues}
                          getOptionLabel={(option) =>
                            option.OperatorName === undefined
                              ? ""
                              : option.OperatorName
                          }
                          value={selectedoperator}
                          onChange={(event, newValue) => {
                            if (newValue != null) {
                              fnOnChange(newValue, "Operator", "OperatorId");
                            } else {
                              setSelectedOperator({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Operator"}
                              placeholder="Operator"
                              error={
                                selectedoperator.OperatorName === undefined
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={operatorlookupvalues}
                          getOptionLabel={(option) =>
                            option.OperatorName === undefined
                              ? ""
                              : option.OperatorName
                          }
                          value={selectedowner}
                          onChange={(event, newValue) => {
                            if (newValue != null) {
                              fnOnChange(newValue, "Owner", "OwnerId");
                            } else {
                              setSelectedOwner({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Owner"}
                              placeholder="Owner"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            format="yyyy/MM/dd"
                            margin="normal"
                            id="plantcommissionedId"
                            label="Plant Commissioned"
                            variant="outlined"
                            value={gasplantvalues.PlantCommissioned}
                            onChange={fnHandlePlantCommissionedDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "Plant Commissioned",
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={plantstatus}
                          getOptionLabel={(option) =>
                            option.Status === undefined ? "" : option.Status
                          }
                          value={selectedStatus}
                          onChange={(event, newValue) => {
                            if (newValue != null) {
                              fnOnChange(newValue, "Status", "StatusId");
                            } else {
                              setSelectedStatus({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Status"}
                              placeholder="Status"
                              error={selectedStatus.Status === undefined}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <TextField
                          label="Latitude"
                          id="latitudeId"
                          onChange={fnHandleChange("Latitude")}
                          value={gasplantvalues.Latitude}
                          variant="outlined"
                          type="number"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={gasplantvalues.Latitude === ""}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="Longitude"
                          id="longitudeId"
                          onChange={fnHandleChange("Longitude")}
                          value={gasplantvalues.Longitude}
                          variant="outlined"
                          type="number"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={gasplantvalues.Longitude === ""}
                        />
                      </Grid>
                      <Grid item xs="2">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                gasplantvalues.Treatment === 0 ? false : true
                              }
                              onChange={fnHandleCheckChange("Treatment")}
                              name="Treatment"
                              color="primary"
                            />
                          }
                          label="Treatment"
                        />
                      </Grid>
                      <Grid item xs="2">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                gasplantvalues.NGLExtraction === 0
                                  ? false
                                  : true
                              }
                              onChange={fnHandleCheckChange("NGLExtraction")}
                              name="NGL Extraction"
                              color="primary"
                            />
                          }
                          label="NGL Extraction"
                        />
                      </Grid>
                      <Grid item xs="2">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                gasplantvalues.Fractionation === 0
                                  ? false
                                  : true
                              }
                              onChange={fnHandleCheckChange("Fractionation")}
                              name="Fractionation"
                              color="primary"
                            />
                          }
                          label="Fractionation"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <TextField
                          label="GasProcessingCapacityMMSCFD"
                          id="gasprocessingcapacityMMSCFDId"
                          onChange={fnHandleChange(
                            "GasProcessingCapacityMMSCFD"
                          )}
                          value={gasplantvalues.GasProcessingCapacityMMSCFD}
                          variant="outlined"
                          type="number"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="GasStorageCapacityMMSCF"
                          id="gasstoragecapacityMMSCFId"
                          onChange={fnHandleChange("GasStorageCapacityMMSCF")}
                          value={gasplantvalues.GasStorageCapacityMMSCF}
                          variant="outlined"
                          type="number"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="NGLStorageCapacityBbl"
                          id="nglstoragecapacityBblId"
                          onChange={fnHandleChange("NGLStorageCapacityBbl")}
                          value={gasplantvalues.NGLStorageCapacityBbl}
                          variant="outlined"
                          type="number"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="BTUContentGasSoldJOULES"
                          id="btucontentgassoldJOULESId"
                          onChange={fnHandleChange("BTUContentGasSoldJOULES")}
                          value={gasplantvalues.BTUContentGasSoldJOULES}
                          variant="outlined"
                          type="number"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="2">
                        <TextField
                          label="RMPID"
                          id="rmpidId"
                          onChange={fnHandleChange("RMPID")}
                          value={gasplantvalues.RMPID}
                          variant="outlined"
                          type="number"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="2">
                        <TextField
                          label="EPAID"
                          id="epaidId"
                          onChange={fnHandleChange("EPAID")}
                          value={gasplantvalues.EPAID}
                          variant="outlined"
                          type="number"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="2">
                        <TextField
                          label="NAICSCode"
                          id="naicscodeId"
                          onChange={fnHandleChange("NAICSCode")}
                          value={gasplantvalues.NAICSCode}
                          variant="outlined"
                          type="number"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="5">
                        <TextField
                          label="NAICSDesc"
                          id="naicsdescId"
                          onChange={fnHandleChange("NAICSDesc")}
                          value={gasplantvalues.NAICSDesc}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="5">
                        <TextField
                          label="Contact Name"
                          id="contactnameId"
                          onChange={fnHandleChange("ContactName")}
                          value={gasplantvalues.ContactName}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs="3">
                        <TextField
                          label="Contact Number"
                          onChange={fnHandleChange("ContactNumber")}
                          value={gasplantvalues.ContactNumber}
                          id="contactnumberId"
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs="4">
                        <TextField
                          label="Contact Email"
                          id="contactemailId"
                          onChange={fnHandleChange("ContactEmail")}
                          value={gasplantvalues.ContactEmail}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="3"></Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <TextField
                          label="EmissionLastReportedYear"
                          id="EmissionLastReportedYearId"
                          value={gasplantvalues.EmissionLastReportedYear}
                          onChange={fnHandleChange("EmissionLastReportedYear")}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="EmissionGHGRPID"
                          id="EmissionGHGRPIDId"
                          value={gasplantvalues.EmissionGHGRPID}
                          onChange={fnHandleChange("EmissionGHGRPID")}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <TextField
                          label="EquivalentCO2Tons"
                          id="EquivalentCO2TonsId"
                          value={gasplantvalues.EquivalentCO2Tons}
                          onChange={fnHandleChange("EquivalentCO2Tons")}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="2">
                        <TextField
                          label="PipelineLocationCode"
                          id="PipelineLocationCodeId"
                          onChange={fnHandleChange("PipelineLocationCode")}
                          value={pipelinelocationcode}
                          variant="outlined"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={pipelinenames}
                          value={selectedpipeline}
                          disabled={pipelinelocationcode === ""}
                          getOptionLabel={(option) =>
                            option.PipelineName === undefined
                              ? ""
                              : option.PipelineName
                          }
                          
                          onChange={(event, newValue) => {
                            if (newValue === null) {
                              setSelectedPipeline({});
                            } else {
                              setSelectedPipeline(newValue);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Search By Pipeline Name"
                              placeholder="Pipelines"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs="7"></Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Grid item>
                        <Button
                          type="button"
                          color="primary"
                          variant="contained"
                          className={classes.updatebutton}
                          disabled={
                            gasplantvalues.Latitude === "" ||
                            gasplantvalues.GasPlantName === "" ||
                            gasplantvalues.Longitude === "" ||
                            gasplantvalues.StateName === null ||
                            gasplantvalues.StatusId === null ||
                            (pipelinelocationcode !== "" &&
                              selectedpipeline.PipelineHeaderId === undefined)
                          }
                          onClick={() => fnInsertGasPlantData()}
                        >
                          Insert GasPlant
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </ValidatorForm>
        </Paper>
      </Grid>
      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(AddGasPlantComp);
