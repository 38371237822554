import * as actionType from 'redux/actions/actionType';

let initialState = {
	Zipcode: [],
};

function zipcodesReducer(state = initialState, action) {
	switch (action.type) {
		case actionType.ADD_ZIPCODES:
			state.Zipcode = action.payload.Zipcode;
			return state;

		default:
			return state;
	}
}

export default zipcodesReducer;
