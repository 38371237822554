import * as actionType from 'redux/actions/actionType';

let initialState = {
	CityName: [],
};

function citynamesReducer(state = initialState, action) {
	switch (action.type) {
		case actionType.ADD_CITYNAMES:
			state.CityName = action.payload.CityName;
			return state;

		default:
			return state;
	}
}

export default citynamesReducer;
