export const LOADINGSPINNER = 'LOADINGSPINNER';
export const RESETPROJECT_STATE_PARAMS = 'RESETPROJECT_STATE_PARAMS';
export const ADD_USERDETAILS = 'ADD_USERDETAILS';
export const ADD_NAVIGATION = 'ADD_NAVIGATION';
export const REMOVE_NAVIGATION = 'REMOVE_NAVIGATION';
export const SET_THEME = 'SET_THEME';
export const ADD_SEARCHDETAILS = 'ADD_SEARCHDETAILS';
export const ADD_OPERATORS = 'ADD_OPERATORS';
export const ADD_CITYNAMES = 'ADD_CITYNAMES';
export const ADD_STATENAMES = 'ADD_STATENAMES';
export const ADD_COUNTYNAMES = 'ADD_COUNTYNAMES';
export const ADD_OPERATORTYPES = 'ADD_OPERATORTYPES';
export const ADD_ZIPCODES = 'ADD_ZIPCODES';
export const ADD_BASINS = 'ADD_BASINS';
export const ADD_REFINERYPRODUCT = 'ADD_REFINERYPRODUCT';
export const ADD_COMMODITYHANDLED = 'ADD_COMMODITYHANDLED';
export const FACILITIES_COUNT = 'FACILITIES_COUNT';
