export const plantstatus = [
  {
    StatusId: 1,
    Status: "Planned",
  },
  {
    StatusId: 2,
    Status: "Under Construction",
  },
  {
    StatusId: 3,
    Status: "Active",
  },
  {
    StatusId: 4,
    Status: "Shutdown",
  },
  {
    StatusId: 5,
    Status: "Cancelled",
  },
  {
    StatusId: 6,
    Status: "Inactive",
  },
  {
    StatusId: 7,
    Status: "Idle",
  },
  {
    StatusId: 8,
    Status: "Questionable",
  },
  {
    StatusId: 9,
    Status: "Engineering",
  },
];

export const reservoirtypes = [
  {
    ReservoirTypeId: 2,
    ReservoirType: "Aquifer Reservoir",
  },
  {
    ReservoirTypeId: 1,
    ReservoirType: "Depleted Field",
  },
  {
    ReservoirTypeId: 4,
    ReservoirType: "Hydrocarbon Reservoir",
  },
  {
    ReservoirTypeId: 6,
    ReservoirType: "Other: Bedded Salt Cavern",
  },
  {
    ReservoirTypeId: 5,
    ReservoirType: "Other: Monitory Well",
  },
  {
    ReservoirTypeId: 3,
    ReservoirType: "Salt Cavern",
  },
];

export const terminaltypes = [
  {
    TerminalTypeId: 1,
    TerminalType: "AFB Terminal",
  },
  {
    TerminalTypeId: 2,
    TerminalType: "Airport Terminal",
  },
  {
    TerminalTypeId: 3,
    TerminalType: "Asphalt Terminal",
  },
  {
    TerminalTypeId: 4,
    TerminalType: "Biodiesel Terminal",
  },
  {
    TerminalTypeId: 5,
    TerminalType: "Breakout Terminal",
  },
  {
    TerminalTypeId: 6,
    TerminalType: "Bulk Terminal",
  },
  {
    TerminalTypeId: 7,
    TerminalType: "Crude Terminal",
  },
  {
    TerminalTypeId: 8,
    TerminalType: "Ethanol Terminal",
  },
  {
    TerminalTypeId: 9,
    TerminalType: "Exchange Terminal",
  },
  {
    TerminalTypeId: 10,
    TerminalType: "LNG Terminal",
  },
  {
    TerminalTypeId: 11,
    TerminalType: "LPG Terminal",
  },
  {
    TerminalTypeId: 12,
    TerminalType: "Marine Terminal",
  },
  {
    TerminalTypeId: 13,
    TerminalType: "NGL Terminal",
  },
  {
    TerminalTypeId: 14,
    TerminalType: "Petrochemical Terminal",
  },
  {
    TerminalTypeId: 15,
    TerminalType: "Petroleum Product Terminal",
  },
  {
    TerminalTypeId: 16,
    TerminalType: "Refinery Terminal",
  },
  {
    TerminalTypeId: 17,
    TerminalType: "Others",
  },
  {
    TerminalTypeId: 18,
    TerminalType: "Condensate Terminal",
  },
];

export const commodityhandled = [
  {
    CommodityHandledId: 1,
    CommodityHandled: "Asphalt",
  },
  {
    CommodityHandledId: 2,
    CommodityHandled: "BioDiesel",
  },
  {
    CommodityHandledId: 3,
    CommodityHandled: "LPG",
  },
  {
    CommodityHandledId: 4,
    CommodityHandled: "NGL",
  },
  {
    CommodityHandledId: 5,
    CommodityHandled: "Crude Oil",
  },
  {
    CommodityHandledId: 6,
    CommodityHandled: "LNG",
  },
  {
    CommodityHandledId: 7,
    CommodityHandled: "Chemical Petroleum Product",
  },
  {
    CommodityHandledId: 8,
    CommodityHandled: "Others",
  },
  {
    CommodityHandledId: 9,
    CommodityHandled: "Chemicals",
  },
  {
    CommodityHandledId: 10,
    CommodityHandled: "Refined Products",
  },
  {
    CommodityHandledId: 11,
    CommodityHandled: "Natural Gas",
  },
  {
    CommodityHandledId: 12,
    CommodityHandled: "Ethanol",
  },
  {
    CommodityHandledId: 13,
    CommodityHandled: "Methanol",
  },
  {
    CommodityHandledId: 14,
    CommodityHandled: "Carbondioxide",
  },
  {
    CommodityHandledId: 15,
    CommodityHandled: "Hydrogen",
  },
];

export const directions = [
  {
    DirectionId: 1,
    Direction: "Uni-Directional",
  },
  {
    DirectionId: 2,
    Direction: "Bi-Directional",
  },
];

export const tariffstatus = [
  {
    LiquidTariffStatusId: 1,
    LiquidTariffStatus: "Effective",
  },
  {
    LiquidTariffStatusId: 2,
    LiquidTariffStatus: "Cancelled",
  },
  {
    LiquidTariffStatusId: 3,
    LiquidTariffStatus: "Pending",
  },
];

export const tarifffueltype = [
  {
    LiquidTariffFuelTypeId: 1,
    LiquidTariffFuelType: "Light Crude Petroleum",
  },
  {
    LiquidTariffFuelTypeId: 2,
    LiquidTariffFuelType: "Medium Crude Petroleum",
  },
  {
    LiquidTariffFuelTypeId: 3,
    LiquidTariffFuelType: "Heavy Crude Petroleum",
  },
  {
    LiquidTariffFuelTypeId: 4,
    LiquidTariffFuelType: "Super Heavy Petroleum",
  },
  {
    LiquidTariffFuelTypeId: 5,
    LiquidTariffFuelType: "Petroleum Products",
  },
  {
    LiquidTariffFuelTypeId: 6,
    LiquidTariffFuelType: "Gasoline",
  },
  {
    LiquidTariffFuelTypeId: 7,
    LiquidTariffFuelType: "Diesel",
  },
  {
    LiquidTariffFuelTypeId: 8,
    LiquidTariffFuelType: "Light Fuel Oil",
  },
  {
    LiquidTariffFuelTypeId: 9,
    LiquidTariffFuelType: "Aviation Fuel",
  },
  {
    LiquidTariffFuelTypeId: 10,
    LiquidTariffFuelType: "Heavy Fuel Oil",
  },
  {
    LiquidTariffFuelTypeId: 11,
    LiquidTariffFuelType: "Propane",
  },
  {
    LiquidTariffFuelTypeId: 12,
    LiquidTariffFuelType: "Butane",
  },
  {
    LiquidTariffFuelTypeId: 13,
    LiquidTariffFuelType: "Y-Grade",
  },
  {
    LiquidTariffFuelTypeId: 14,
    LiquidTariffFuelType: "NGL",
  },
  {
    LiquidTariffFuelTypeId: 15,
    LiquidTariffFuelType: "Ethane",
  },
  {
    LiquidTariffFuelTypeId: 16,
    LiquidTariffFuelType: "Isobutane",
  },
  {
    LiquidTariffFuelTypeId: 17,
    LiquidTariffFuelType: "Crude",
  },
];

export const tariffratetype = [
  {
    LiquidTariffRateTypeId: 1,
    LiquidTariffRateType: "Priority Rate",
  },
  {
    LiquidTariffRateTypeId: 2,
    LiquidTariffRateType: "Uncommitted Rate",
  },
  {
    LiquidTariffRateTypeId: 3,
    LiquidTariffRateType: "Uncommitted Rate - Volume",
  },
  {
    LiquidTariffRateTypeId: 4,
    LiquidTariffRateType: "Uncommitted Rate - Acreage",
  },
  {
    LiquidTariffRateTypeId: 5,
    LiquidTariffRateType: "Committed Rate",
  },
  {
    LiquidTariffRateTypeId: 6,
    LiquidTariffRateType: "Committed Rate - Volume",
  },
  {
    LiquidTariffRateTypeId: 7,
    LiquidTariffRateType: "Committed Rate - Term",
  },
  {
    LiquidTariffRateTypeId: 8,
    LiquidTariffRateType: "Committed Rate - Volume & Term",
  },
  {
    LiquidTariffRateTypeId: 9,
    LiquidTariffRateType: "Committed Rate - Acreage & Term",
  },
  {
    LiquidTariffRateTypeId: 10,
    LiquidTariffRateType: "Incentive Rate - Volume & Term",
  },
  {
    LiquidTariffRateTypeId: 11,
    LiquidTariffRateType: "Incentive Rate - Volume",
  },
  {
    LiquidTariffRateTypeId: 12,
    LiquidTariffRateType: "Firm Rate",
  },
  {
    LiquidTariffRateTypeId: 13,
    LiquidTariffRateType: "Non-Incentive Rates",
  },
  {
    LiquidTariffRateTypeId: 14,
    LiquidTariffRateType: "Incentive Rate - Term",
  },
  {
    LiquidTariffRateTypeId: 15,
    LiquidTariffRateType: "Priority Rate - Term",
  },
];

export const deviceterminaltypes = [
  // 'County Point',
  // 'State Point',
  "Refinery",
  "Terminal",
  "GasPlant",
  "GasStorage",
  "Liquefaction",
  "CompressorStation",
  "PumpingStation",
  "PipelineAssembly",
];

export const locationcodefacilitytypes = [
  "Refinery",
  "Terminal",
  "GasPlant",
  "GasStorage",
  "Liquefaction",
  "CompressorStation",
  "PumpingStation",
  "IndustrialPlant",
  "Regulatory Station",
  "Metering Station Delivery",
  "Rural Tap",
  "Field Gathering Station",
  "Gas Oil Seperation Point",
  "Tee Junction",
  "Dehydration Equipment",
  "Tank",
  "Town Border Station",
  "Metering Station Receipt",
  "Metering Station Bidirectional",
  "LDC",
  "Interconnect",
  "Pooling Point",
  "CO2 Production",
];

export const lengthmilestype = ["C", "R"];

export const assettypes = [
  {
    AssetTypeId: 1,
    AssetType: "Regulatory Station",
  },
  {
    AssetTypeId: 2,
    AssetType: "Metering Station Delivery",
  },
  {
    AssetTypeId: 10,
    AssetType: "Metering Station Receipt",
  },
  {
    AssetTypeId: 11,
    AssetType: "Metering Station Bidirectional",
  },
  {
    AssetTypeId: 3,
    AssetType: "Rural Tap",
  },
  {
    AssetTypeId: 4,
    AssetType: "Field Gathering Station",
  },
  {
    AssetTypeId: 5,
    AssetType: "Gas Oil Seperation Point",
  },
  {
    AssetTypeId: 6,
    AssetType: "Tee Junction",
  },
  {
    AssetTypeId: 7,
    AssetType: "Dehydration Equipment",
  },
  {
    AssetTypeId: 8,
    AssetType: "Tank",
  },
  {
    AssetTypeId: 9,
    AssetType: "Town Border Station",
  },
  {
    AssetTypeId: 12,
    AssetType: "LDC",
  },
  {
    AssetTypeId: 13,
    AssetType: "Interconnect",
  },
  {
    AssetTypeId: 14,
    AssetType: "Pooling Point",
  },
  {
    AssetTypeId: 15,
    AssetType: "CO2 Production",
  },
];

export const regulatorytypes = [
  {
    RegulatoryTypeId: 1,
    RegulatoryType: "Unknown",
  },
  {
    RegulatoryTypeId: 2,
    RegulatoryType: "Distribution",
  },
  {
    RegulatoryTypeId: 3,
    RegulatoryType: "Gathering",
  },
  {
    RegulatoryTypeId: 4,
    RegulatoryType: "Transmission",
  },
  {
    RegulatoryTypeId: 5,
    RegulatoryType: "Offshore Gathering",
  },
  {
    RegulatoryTypeId: 6,
    RegulatoryType: "Offshore Transmission",
  },
];

export const monthsvalues = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
