import React, { useEffect, useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import AlertNotificationComp from "components/custom/AlertNotificationComp";

import { customizedDateFormat } from "constants/utils";

//date
import moment from "moment";

//linq
import linq from "linq";

//Redux imports
import { useSelector } from "react-redux";

//Api
import axios from "axios";

//Redux imports
import { useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  operatordatacontainer: {
    margin: 10,
    width: "100%",
    "& .MuiPaper-root": {
      width: "100%",
    },
  },
  formcontainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-container": {
      marginBottom: 3.5,
    },
  },

  operatorlist: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    maxHeight: 500,
    minHeight: 500,
  },
  operatordata: {
    padding: 10,
    height: 500,
    maxHeight: 500,
  },
  updatebutton: {
    width: "200px",
  },
}));

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

function UpdateOwnerByOperatorComp(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const operatorlookupvalues = useSelector(
    (state) => state.operatorsReducer.OperatorName
  );

  const operatordataRef = useRef(null);

  const [selectedindex, setSelectedIndex] = React.useState("");

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "error",
  });

  const [selectedoperator, setSelectedOperator] = React.useState([]);
  const [updateowner, setUpdateOwner] = React.useState([]);
  const [updateownerstatus, setUpdateOwnerStatus] = React.useState([]);

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "error" });
  };

  const fnOnChange = (newvalue, type, typeid) => {
    if (newvalue !== null && newvalue !== undefined) {
      switch (type) {
        case "OperatorName":
          setSelectedOperator(newvalue);
          break;
        case "UpdateOwnerName":
          setUpdateOwner(newvalue);
          break;
      }

      setUpdateOwnerStatus([]);
    }
  };

  const fnUpdateOwnerData = () => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    let facilities = [
      "energyinfrastructure.ccus",
      "energyinfrastructure.co2storageheader",
      "energyinfrastructure.compressorstation",
      "energyinfrastructure.gasplantheader",
      "energyinfrastructure.gasstorageheader",
      "energyinfrastructure.hydrogenplantheader",
      "energyinfrastructure.industrialplantheader",
      "energyinfrastructure.liquefactionplantheader",
      "energyinfrastructure.powerplantheader",
      "energyinfrastructure.pipelineheader",
      "energyinfrastructure.pumpingstation",
      "energyinfrastructure.refineryheader",
      "energyinfrastructure.terminalheader",
      "energyinfrastructure.wellheader",
      "energyinfrastructure.permitsheader",
      "energyinfrastructure.pipelineassembly",
    ];

    facilities.forEach(async (element) => {
      const data = {
        operator: selectedoperator.OperatorId,
        owner: updateowner.OperatorId,
        table: element,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : "",
        },
      };

      await axios.post("/api/updateownerdatabyoperator", data, options).then(
        (response) => {
          if (response.data[0].status === "error") {
          } else if (response.data[0].status === "success") {
            setUpdateOwnerStatus((updateownerstatus) => [
              ...updateownerstatus,
              `${response.data[0].tablename} affected with Operator : ${response.data[0].operator}, Owner : ${response.data[0].owner}`,
            ]);
          } else {
          }
        },
        (error) => {
          if (error.request.status === 500) {
            props.history.push("/login");
          } else {
          }
        }
      );
    });

    dispatch({
      type: actionType.LOADINGSPINNER,
      payload: { loading: false },
    });
  };

  const fnFetchLookupValues = async () => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const lookups = [{ name: "OperatorName", id: "OperatorId" }];

    const actions = {
      OperatorName: "ADD_OPERATORS",
    };

    let promises = [];

    lookups.forEach((table) => {
      const data = {
        fetchtype: table.name,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : "",
        },
      };

      axios.post("/api/fetchuniquevalues", data, options).then(
        (response) => {
          dispatch({
            type: actionType.LOADINGSPINNER,
            payload: { loading: false },
          });
          // console.log(response.data[0]);
          if (response.data[0].status === "error") {
          } else if (response.data[0].status === "success") {
            if (response.data[0].data.length > 0) {
              var data = [];

              response.data[0].data.forEach((element) => {
                data.push({
                  [table.name]: element[table.name],
                  [table.id]: element[table.id],
                });
              });

              dispatch({
                type: actions[table.name],
                payload: {
                  [table.name]: data,
                },
              });
            } else {
            }
          }
        },
        (error) => {
          dispatch({
            type: actionType.LOADINGSPINNER,
            payload: { loading: false },
          });
        }
      );
    });
  };

  return (
    <>
      <Grid className={classes.operatordatacontainer} container>
        <Grid
          container
          ref={operatordataRef}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing="1"
        >
          <Paper className={classes.operatordata}>
            {/* {JSON.stringify(operatorvalues)} */}

            <Grid
              container
              spacing="1"
              direction="column"
              className={classes.formcontainer}
            >
              <Grid item xs="4">
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  className={classes.updatebutton}
                  onClick={() => fnFetchLookupValues()}
                >
                  Refresh Operator
                </Button>
                <Autocomplete
                  size="small"
                  options={operatorlookupvalues}
                  getOptionLabel={(option) =>
                    option.OperatorName === undefined ? "" : option.OperatorName
                  }
                  value={selectedoperator}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      fnOnChange(newValue, "OperatorName", "OperatorId");
                    } else {
                      setSelectedOperator({});
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={"Select Operator"}
                      placeholder="Select Operator"
                      error={selectedoperator.OperatorName === undefined}
                    />
                  )}
                />
              </Grid>
              <Grid item xs="1"></Grid>
              <Grid item xs="4">
                <Autocomplete
                  size="small"
                  options={operatorlookupvalues}
                  getOptionLabel={(option) =>
                    option.OperatorName === undefined ? "" : option.OperatorName
                  }
                  value={updateowner}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      fnOnChange(newValue, "UpdateOwnerName", "OperatorId");
                    } else {
                      setUpdateOwner({});
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={"Select Owner"}
                      placeholder="Select Owner"
                      error={updateowner.OperatorName === undefined}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  disabled={
                    selectedoperator.OperatorName === undefined ||
                    updateowner.OperatorName === undefined ||
                    updateowner.OperatorName === selectedoperator.OperatorName
                  }
                  className={classes.updatebutton}
                  onClick={() => fnUpdateOwnerData()}
                >
                  Update Owner
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              spacing="3"
              direction="column"
              className={classes.formcontainer}
            >
              <Grid item>
                {updateownerstatus.length > 0 &&
                  updateownerstatus.map((item, index) => {
                    return (
                      <>
                        <div>{item}</div>
                      </>
                    );
                  })}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(UpdateOwnerByOperatorComp);
