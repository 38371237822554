import React, { Suspense, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import Snackbar from "@material-ui/core/Snackbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import LinearProgress from "@material-ui/core/LinearProgress";

import { makeStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";

import LookupInsertComp from "components/common/LookupInsertComp";
import AlertNotificationComp from "components/custom/AlertNotificationComp";



//date
import moment from "moment";

//Api
import axios from "axios";

//Redux imports
import { useSelector, useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import { locationcodefacilitytypes } from "lookuptables/global";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        display: "flex",
    },
    operatoraddcontainer: {
        "& .MuiPaper-root": {
            width: "100%",
            padding: 10,
            margin: 10,
        },
    },
    formcontainer: {
        "& .MuiFormControl-root": {
            width: "100%",
        },
        "& .MuiGrid-container": {
            // marginBottom: 10,
        },
    },
    updatebutton: {
        width: "100%",
    },
}));

function MapLocationCodesComp(props) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const token = useSelector((state) => state.userReducer.token);



    const [status, setStatusBase] = React.useState({
        show: false,
        message: "",
        variant: "info",
    });

    const fnShowErrorMessage = () => {
        setStatusBase({ show: false, message: "", variant: "error" });
    };


    const [pipelines, setPipelines] = React.useState([]);
    const fetchPipelines = () => {

        dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

        const query = { columnname: "PipelineName", tablename: "Pipeline" };

        const options = {
            headers: {
                authorization: token ? `Bearer ${token}` : "",
            },
        };

        axios.post("/api/fetchlocationcodepipelines", query, options).then(
            (response) => {

                dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

                if (response.data[0].status === "error") {
                } else if (response.data[0].status === "no records") {
                } else if (response.data[0].status === "success") {
                    if (response.data[0].data.length > 0) {
                        setPipelines(response.data[0].data);
                    }
                }
            },
            (error) => {

                dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

                if (error.request.status === 500) {
                    props.history.push("/login");
                } else {
                }
            }
        );
    };

    const [selectedpipeline, setSelectedPipeline] = React.useState({})
    const [locationcodes, setLocationCodes] = React.useState([]);
    const fnFetchVirtualPoints = () => {

        dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

        const query = { pipeline: selectedpipeline.PipelineName };

        const options = {
            headers: {
                authorization: token ? `Bearer ${token}` : "",
            },
        };

        axios.post("/api/fetchvirtualpointsbypipeline", query, options).then(
            (response) => {

                dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

                if (response.data[0].status === "error") {
                } else if (response.data[0].status === "no record found") {
                    setStatusBase({ show: true, message: "Location Codes Not Found", variant: "info" });
                } else if (response.data[0].status === "success") {
                    if (response.data[0].data.length > 0) {
                        setLocationCodes(response.data[0].data);
                    }
                }
            },
            (error) => {

                dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

                if (error.request.status === 500) {
                    props.history.push("/login");
                } else {
                    setStatusBase({ show: true, message: "Error occured while fetching location codes", variant: "error" });
                }
            }
        );
    }

    const [selectedlocationcode, setSelectedLocationCode] = React.useState("");
    const [selectedfacilitytype, setSelectedFacilityType] = React.useState("");
    const [selectedfacility, setSelectedFacility] = React.useState({});
    const [facilities, setFacilities] = React.useState([]);
    const fnFetchFacilities = (facilitytype) => {
        dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

        const query = { facilitytype: facilitytype };

        const options = {
            headers: {
                authorization: token ? `Bearer ${token}` : "",
            },
        };

        axios.post("/api/fetchfacilitiesbyfacilitytype", query, options).then(
            (response) => {

                dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

                if (response.data[0].status === "error") {
                } else if (response.data[0].status === "no record found") {
                    setStatusBase({ show: true, message: "Facilities Not Found", variant: "info" });
                } else if (response.data[0].status === "success") {
                    if (response.data[0].data.length > 0) {
                        setFacilities(response.data[0].data);
                    }
                }
            },
            (error) => {

                dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

                if (error.request.status === 500) {
                    props.history.push("/login");
                } else {
                    setStatusBase({ show: true, message: "Error occured while fetching facilities", variant: "error" });
                }
            }
        );
    }

    const fnUpdateLocationCodeFacility = () => {
        dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

        const query = {
            pipelineid: selectedpipeline.PipelineHeaderId,
            locationcode: selectedlocationcode,
            facilitytype: selectedfacilitytype,
            facilityid: selectedfacility.FacilityId
        };

        const options = {
            headers: {
                authorization: token ? `Bearer ${token}` : "",
            },
        };

        axios.post("/api/updatelocationcodefacility", query, options).then(
            (response) => {

                dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

                if (response.data[0].status === "error") {
                } else if (response.data[0].status === "no record found") {
                    setStatusBase({ show: true, message: "Facilities Not Found", variant: "info" });
                } else if (response.data[0].status === "success") {
                    setStatusBase({ show: true, message: "Location updated successfully", variant: "info" });

                    setSelectedFacilityType("");
                    setSelectedLocationCode("");
                    setSelectedFacility({});
                }
            },
            (error) => {

                dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

                if (error.request.status === 500) {
                    props.history.push("/login");
                } else {
                    setStatusBase({ show: true, message: "Error occured while fetching facilities", variant: "error" });
                }
            }
        );
    }


    useEffect(() => {

        dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: false } });

        fetchPipelines();
    }, []);

    return (
        <>
            <Grid className={classes.operatoraddcontainer} container>
                <Paper>
                    <Typography variant="subtitle1">Map Location Codes with Facilities</Typography>

                    {pipelines.length > 0 ? (
                        <>
                            <Grid
                                container
                                spacing="3"
                                direction="column"
                                className={classes.formcontainer}
                            >
                                <Grid item>
                                    <Grid
                                        container
                                        spacing="3"
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                    >
                                        <Grid item xs="5">
                                            <Autocomplete
                                                id="pipelinenameId"
                                                freeSolo
                                                size="small"
                                                options={pipelines}
                                                getOptionLabel={(option) => option.PipelineName}
                                                onChange={(event, newValue) => {

                                                    setLocationCodes([]);
                                                    if (newValue !== null) {
                                                        setSelectedPipeline(newValue);

                                                    } else {
                                                        setSelectedPipeline({});

                                                    }
                                                }}
                                                value={selectedpipeline}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Pipeline Name"
                                                        margin="normal"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs="3">
                                            <Button
                                                type="button"
                                                color="primary"
                                                variant="contained"
                                                disabled={Object.keys(selectedpipeline).length === 0}
                                                className={classes.addbutton}
                                                onClick={() => fnFetchVirtualPoints()}
                                            >
                                                Fetch Virtual Points
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {locationcodes.length > 0 && (<>
                                    <Typography style={{ paddingLeft: "50px" }} >{locationcodes.length} Virtual Points to be mapped to Facilities</Typography>
                                    <Grid item>
                                        <Grid
                                            container
                                            spacing="3"
                                            direction="row"
                                            justify="flex-start"
                                            alignItems="center"
                                        >
                                            <Grid item xs="3">
                                                <Autocomplete
                                                    id="locationcodesId"
                                                    freeSolo
                                                    size="small"
                                                    options={locationcodes}
                                                    onChange={(event, newValue) => {

                                                        if (newValue !== null) {
                                                            setSelectedLocationCode(newValue);

                                                        } else {
                                                            setSelectedLocationCode("");

                                                        }
                                                    }}
                                                    value={selectedlocationcode}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Location Codes"
                                                            margin="normal"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs="3">
                                                <Autocomplete
                                                    size="small"
                                                    options={locationcodefacilitytypes}
                                                    onChange={(event, newValue) => {
                                                        setSelectedFacility({});
                                                        if (newValue !== null) {
                                                            setSelectedFacilityType(newValue);
                                                            fnFetchFacilities(newValue);

                                                        } else {
                                                            setSelectedFacilityType("");
                                                        }
                                                    }}
                                                    value={selectedfacilitytype}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label={'Select Facility Type'}
                                                            placeholder="Select Facility Type"

                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            {facilities.length > 0 && (
                                                <Grid item xs="3">
                                                    <Autocomplete
                                                        size="small"
                                                        options={facilities}
                                                        getOptionLabel={(option) => option.FacilityName}
                                                        value={selectedfacility}
                                                        onChange={(event, newValue) => {

                                                            if (newValue !== null) {
                                                                setSelectedFacility(newValue);


                                                            } else {
                                                                setSelectedFacility({});
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                label={'Select Facility'}
                                                                placeholder="Select Facility"

                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            )}
                                            <Grid item xs="3">
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    variant="contained"
                                                    className={classes.addbutton}
                                                    disabled={selectedfacilitytype === "" || selectedlocationcode === "" || Object.keys(selectedfacilitytype).length === 0}

                                                    onClick={() => fnUpdateLocationCodeFacility()}
                                                >
                                                    Update Location Code Details
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {selectedpipeline.PipelineHeaderId} : {selectedlocationcode} : {selectedfacilitytype} : {selectedfacility.FacilityId}
                                </>
                                )}
                            </Grid>
                        </>
                    ) : null}

                </Paper>
            </Grid>
            <Snackbar
                autoHideDuration={6000}
                open={status.show}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                onClose={() => fnShowErrorMessage()}
            >
                <AlertNotificationComp
                    variant={status.variant}
                    onClose={() => fnShowErrorMessage()}
                    message={status.message}
                ></AlertNotificationComp>
            </Snackbar>
        </>
    );
}

export default withRouter(MapLocationCodesComp);
