import React, { Suspense } from 'react';

import Grid from '@material-ui/core/Grid';

import LinearProgress from '@material-ui/core/LinearProgress';

import { makeStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router-dom';

import SideDrawerComp from './subcomponents/SideDrawerComp';

const OperatorDataComponent = React.lazy(
	() => new Promise((resolve, reject) => setTimeout(() => resolve(import('./subcomponents/OperatorDataComp')), 100))
);

const OperatorSearchComponent = React.lazy(
	() => new Promise((resolve, reject) => setTimeout(() => resolve(import('./subcomponents/OperatorSearchComp')), 100))
);

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
	},
}));

function OperatorComp(props) {
	const classes = useStyles();

	const [searchoperatorquery, setSearchOperatorQuery] = React.useState('');

	return (
		<>
			{/* <SideDrawerComp /> */}
			<Grid container>
				<Suspense
					fallback={
						<>
							<LinearProgress />
						</>
					}
				>
					<OperatorSearchComponent setSearchOperatorQuery={setSearchOperatorQuery} />
				</Suspense>
			</Grid>
			<Grid container>
				<Suspense
					fallback={
						<>
							<LinearProgress />
						</>
					}
				>
					<OperatorDataComponent searchoperatorquery={searchoperatorquery} />
				</Suspense>
			</Grid>
		</>
	);
}

export default withRouter(OperatorComp);
