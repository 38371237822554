import React, { useEffect } from "react";

import {
  CardMedia,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Menu,
  MenuItem,
  Avatar,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";



import BreadcrumbRouterComp from "./BreadcrumbRouterComp";

import { withRouter } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import IdleTimer from "react-idle-timer";

//Images
import LogoImage from "assets/images/multiply.png";

import * as actionType from "redux/actions/actionType";

const ITEM_HEIGHT = 48;

const options = ["Change Password", "Logout"];



const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1 },

  title: {
    flexGrow: 1,
  },

  headerStyling: {
    background: "white",
    paddingLeft: "14px",
    paddingRight: "0",
    minHeight: "50px",
  },

  logoimage: {
    height: 40,
    width: 105,
    backgroundSize: "contain",
    cursor: "pointer",
  },
  shortname: {
    color: "#ffffff",
    backgroundColor: "#74b9ff",
    margin: "5px 10px 5px 10px",
  },

  toolbarComponentsHeight: {
    height: "50px",
  },

  borderStyling: {
    borderLeft: "1px solid #b2bec3",
  },
  formControl: {
    "& .MuiOutlinedInput-input": {
      paddingTop: "15px",
      paddingBottom: "15px",
    },
    MuiOutlinedInputInput: { paddingTop: "10px", paddingBottom: "10px" },
    minWidth: 50,
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      borderTop: "none",
      borderBottom: "none",
    },
  },

  countrytype: {
    borderRadius: 0,
  },
  settingsicon: {
    marginRight: "5px",
    marginLeft: "5px",
  },
  csvdownloadButton: {
    paddingLeft: "10px",
    paddingRight: "10px",
    borderLeft: "1px solid rgba(0, 0, 0, 0.23)",
    paddingTop: 7,
    paddingBottom: 7,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

function HeaderComp(props) {
  const classes = useStyles();

  const username = useSelector((state) => state.userReducer.username);
  const usercode = useSelector((state) => state.userReducer.usercode);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const dispatch = useDispatch();

  const [values, setValues] = React.useState({
    searchcountry: "usa",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (usercode === "") {
      props.history.push("/");
    }
  }, [usercode]);

  const handleMenuItemClick = (event) => {
    switch (event.currentTarget.innerText) {
      case "Logout":
        props.history.push("/");
        break;
      case "Change Password":
        props.history.push("/changepassword");
    }
  };

  const GoToHome = () => {
    props.history.push("/home");
  };

  const SessionTimeout = () => {
    props.history.push("/");
  };

  

  return (
    <IdleTimer timeout={3600000} onIdle={SessionTimeout}>
      <div className={classes.root}>
        <AppBar position="static" elevation={1} className={classes.appBar}>
          <Toolbar className={classes.headerStyling}>
            <Grid
              container
              className={classes.toolbarComponentsHeight}
              direction="row"
              justify="flex-start"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item xs={1}>
                <CardMedia
                  image={LogoImage}
                  onClick={GoToHome}
                  className={classes.logoimage}
                ></CardMedia>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="column">
                  <Grid item>
                    <BreadcrumbRouterComp></BreadcrumbRouterComp>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <Grid
                    className={
                      (classes.toolbarComponentsHeight, classes.borderStyling)
                    }
                    item
                  >
                    <Avatar onClick={handleClick} className={classes.shortname}>
                      <Typography>{usercode}</Typography>
                    </Avatar>

                    {/* <IconButton
										aria-label="more"
										aria-controls="long-menu"
										aria-haspopup="true"
										onClick={handleClick}
									>
										<MenuIcon />
									</IconButton>*/}
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: "20ch",
                        },
                      }}
                    >
                      {options.map((option) => (
                        <MenuItem
                          key={option}
                          selected={option === "Pyxis"}
                          onClick={(handleClose, handleMenuItemClick)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    </IdleTimer>
  );
}

export default withRouter(HeaderComp);
