import React from 'react';
import clsx from 'clsx';

import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import { makeStyles } from '@material-ui/core/styles';
import { SnackbarContent, IconButton } from '@material-ui/core';
import { getThemeVariablesByName } from 'constants/colors';

const defaulttheme = getThemeVariablesByName();

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
};

const useStyles = makeStyles((theme) => ({
	success: {
		backgroundColor: defaulttheme.SUCCESSCOLOR,
	},
	error: {
		backgroundColor: defaulttheme.ERRORCOLOR,
	},
	info: {
		backgroundColor: defaulttheme.BACKGROUNDCOLOR,
	},
	warning: {
		backgroundColor: defaulttheme.WARNINGCOLOR,
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1),
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
}));

export default function AlertNotificationComp(props) {
	const classes = useStyles();
	const { className, message, onClose, variant, ...other } = props;
	const Icon = variantIcon[variant];

	return (
		<SnackbarContent
			className={clsx(classes[variant], className)}
			aria-describedby="client-snackbar"
			role="alert"
			message={
				<span id="client-snackbar" className={classes.message}>
					<Icon className={clsx(classes.icon, classes.iconVariant)} />
					{message}
				</span>
			}
			action={[
				<IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
					<CloseIcon className={classes.icon} />
				</IconButton>,
			]}
			{...other}
		/>
	);
}
