import React, { Suspense, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import Snackbar from "@material-ui/core/Snackbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import LinearProgress from "@material-ui/core/LinearProgress";

import { makeStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";

import LookupInsertComp from "components/common/LookupInsertComp";
import AlertNotificationComp from "components/custom/AlertNotificationComp";

//date
import moment from "moment";

//Api
import axios from "axios";

//Redux imports
import { useSelector, useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import { plantstatus } from "lookuptables/global";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },
  operatoraddcontainer: {
    "& .MuiPaper-root": {
      width: "100%",
      padding: 10,
      margin: 10,
    },
  },
  formcontainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-container": {
      // marginBottom: 10,
    },
  },
  updatebutton: {
    width: "100%",
  },
}));

function AddWellComp(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const [refineries, setRefineries] = React.useState([]);

  const [holedirections, setHoleDirections] = React.useState([]);
  const [classificationnames, setClassificationNames] = React.useState([]);
  const [wellstatusnames, setWellStatusNames] = React.useState([]);

  const citylookupvalues = useSelector(
    (state) => state.citynamesReducer.CityName
  );
  const countylookupvalues = useSelector(
    (state) => state.countynamesReducer.CountyName
  );
  const statelookupvalues = useSelector(
    (state) => state.statenamesReducer.StateName
  );
  const zipcodelookupvalues = useSelector(
    (state) => state.zipcodesReducer.Zipcode
  );
  const operatorlookupvalues = useSelector(
    (state) => state.operatorsReducer.OperatorName
  );
  const basinlookupvalues = useSelector(
    (state) => state.basinsReducer.BasinName
  );

  const [selectedcity, setSelectedCity] = React.useState({});
  const [selectedcounty, setSelectedCounty] = React.useState({});
  const [selectedstate, setSelectedState] = React.useState({});
  const [selectedzipcode, setSelectedZipcode] = React.useState({});
  const [selectedbasin, setSelectedBasin] = React.useState({});
  const [selectedoperator, setSelectedOperator] = React.useState({});
  const [selectedholedirection, setSelectedHoleDirection] = React.useState({});
  const [selectedwellclassification, setSelectedWellClassification] =
    React.useState({});
  const [selectedwellstatuscode, setSelectedWellStatusCode] = React.useState(
    {}
  );

  const wellinitialstate = {
    APINumber: "",
    CityName: null,
    CityId: null,
    CountyName: null,
    CountyId: null,
    StateName: null,
    StateNameId: null,
    Zipcode: null,
    ZipcodeId: null,
    Basin: null,
    BasinId: null,
    OperatorName: null,
    OperatorId: null,
    HoleDirection: null,
    HoleDirectionId: null,
    ClassificationName: null,
    WellClassificationId: null,
    WellStatusName: null,
    WellStatusCodeId: null,
    Latitude: "",
    Longitude: "",
    GovtOperatorNumber: "",
    WellName: "",
    WellNumber: "",
    LeaseName: "",
    LeaseNumber: "",
    FieldName: "",
    Section: "",
    Township: "",
    Range: "",
    Qtr_Qtr: "",
    Footages: "",
    Elevation: "",
    BSec: "",
    BTwp: "",
    BTDir: "",
    BRge: "",
    BRDir: "",
    BQtr1: "",
    BQtr2: "",
    BFoot1: "",
    BFoot2: "",
    BottomHoleLatitude: "",
    BottomHoleLongitude: "",
    BottomFormation: "",
    TD: "",
    MDMax: "",
    TVDMax: "",
    ReservoirName: "",
    CoalBed: "",
    StatusDate: null,
    FirstSpudDate: null,
    FirstCompletionDate: null,
    PermitDate: null,
    key: 0,
  };

  const [wellvalues, setWellValues] = React.useState(wellinitialstate);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "error",
  });

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "error" });
  };

  const fnHandleChange = (prop) => (event) => {
    setWellValues({ ...wellvalues, [prop]: event.target.value });
  };

  const fnHandleStatusDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setWellValues({
        ...wellvalues,
        StatusDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setWellValues({ ...wellvalues, StatusDate: null });
    }
  };

  const fnHandleFirstSpudDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setWellValues({
        ...wellvalues,
        FirstSpudDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setWellValues({ ...wellvalues, FirstSpudDate: null });
    }
  };

  const fnHandleFirstCompletionDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setWellValues({
        ...wellvalues,
        FirstCompletionDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setWellValues({ ...wellvalues, FirstCompletionDate: null });
    }
  };

  const fnHandlePermitDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setWellValues({
        ...wellvalues,
        PermitDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setWellValues({ ...wellvalues, PermitDate: null });
    }
  };

  const fnOnChange = (newvalue, type, typeid) => {
    if (newvalue !== null && newvalue !== undefined) {
      if (type === "Owner" || type === "Operator") {
        setWellValues({
          ...wellvalues,
          [type]: newvalue["OperatorName"],
          [typeid]: newvalue["OperatorId"],
        });
      } else {
        setWellValues({
          ...wellvalues,
          [type]: newvalue[type],
          [typeid]: newvalue[typeid],
        });
      }

      switch (type) {
        case "CityName":
          setSelectedCity(newvalue);
          break;
        case "CountyName":
          setSelectedCounty(newvalue);
          break;
        case "StateName":
          setSelectedState(newvalue);
          break;
        case "Zipcode":
          setSelectedZipcode(newvalue);
          break;
        case "Operator":
          setSelectedOperator(newvalue);
          break;

        case "BasinName":
          setSelectedBasin(newvalue);
          break;
        case "HoleDirection":
          setSelectedHoleDirection(newvalue);
          break;
        case "WellClassification":
          setSelectedWellClassification(newvalue);
          break;
        case "WellStatusCode":
          setSelectedWellStatusCode(newvalue);
          break;
      }
    }
  };

  const fnInsertWellData = () => {
    var wellkeys = [
      "APINumber",
      "OperatorId",
      "CountryId",
      "CountyId",
      "StateNameId",
      "BasinId",
      "HoleDirectionId",
      "Latitude",
      "Longitude",
      "GovtOperatorNumber",
      "WellName",
      "WellNumber",
      "LeaseName",
      "LeaseNumber",
      "FieldName",
      "Section",
      "Township",
      "Range",
      "Qtr_Qtr",
      "Footages",
      "Elevation",
      "BSec",
      "BTwp",
      "BTDir",
      "BRge",
      "BRDir",
      "BQtr1",
      "BQtr2",
      "BFoot1",
      "BFoot2",
      "BottomHoleLatitude",
      "BottomHoleLongitude",
      "BottomFormation",
      "WellClassificationId",
      "TD",
      "MDMax",
      "TVDMax",
      "ReservoirName",
      "CoalBed",
      "WellStatusCodeId",
      "StatusDate",
      "FirstSpudDate",
      "FirstCompletionDate",
      "PermitDate",
    ];

    var insertvalues = [];
    wellkeys.forEach((element) => {
      if (
        wellvalues[element] !== undefined &&
        wellvalues[element] !== null &&
        wellvalues[element] !== ""
      ) {
        if (element.indexOf("Date") > -1) {
          insertvalues.push(
            element +
              " = '" +
              moment(wellvalues[element]).format("YYYY-MM-DD") +
              "'"
          );
        } else {
          if (typeof wellvalues[element] === "string") {
            insertvalues.push("'" + wellvalues[element] + "'");
          } else {
            insertvalues.push(wellvalues[element]);
          }
        }
      } else {
        insertvalues.push("NULL");
      }
    });

    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      inserttype: "Well",
      insertvalues: insertvalues.join(","),
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/insertdatarecord", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error while inserting well record",
            variant: "error",
          });
        } else if (response.data[0].status === "inserted") {
          setStatusBase({
            show: true,
            message: "Record inserted successfully",
            variant: "info",
          });

          window.location.reload();
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting record",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting well data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnFetchLookupValues = (fetchtype) => {
    const data = {
      fetchtype: fetchtype,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchuniquevalues", data, options).then(
      (response) => {
        // console.log(response.data[0]);
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            var data = [];

            if (fetchtype === "HoleDirection") {
              response.data[0].data.forEach((element) => {
                data.push({
                  HoleDirection: element["HoleDirection"],
                  HoleDirectionId: element["HoleDirectionId"],
                });
              });

              setHoleDirections(data);
            } else if (fetchtype === "ClassificationName") {
              response.data[0].data.forEach((element) => {
                data.push({
                  ClassificationName: element["ClassificationName"],
                  WellClassificationId: element["WellClassificationId"],
                });
              });

              setClassificationNames(data);
            } else if (fetchtype === "WellStatusName") {
              response.data[0].data.forEach((element) => {
                data.push({
                  WellStatusName: element["WellStatusName"],
                  WellStatusCodeId: element["WellStatusCodeId"],
                });
              });

              setWellStatusNames(data);
            }
          }
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    fnFetchLookupValues("ClassificationName");
    fnFetchLookupValues("WellStatusName");
    fnFetchLookupValues("HoleDirection");
  }, []);

  return (
    <>
      <Grid className={classes.operatoraddcontainer} container>
        <Paper>
          <Typography variant="subtitle1">Add Well Data</Typography>
          <ValidatorForm>
            <>
              <Grid
                container
                spacing="3"
                direction="column"
                className={classes.formcontainer}
              >
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      <TextField
                        label="API Number"
                        id="APINumberId"
                        value={wellvalues.APINumber}
                        onChange={fnHandleChange("APINumber")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={wellvalues.APINumber === ""}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={holedirections}
                        getOptionLabel={(option) =>
                          option.HoleDirection === undefined
                            ? ""
                            : option.HoleDirection
                        }
                        value={selectedholedirection}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            fnOnChange(
                              newValue,
                              "HoleDirection",
                              "HoleDirectionId"
                            );
                          } else {
                            setSelectedHoleDirection({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select Hole Direction"}
                            placeholder="HoleDirection"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      {/* {JSON.stringify(operatorvalues.City)} */}

                      <Autocomplete
                        size="small"
                        options={citylookupvalues}
                        getOptionLabel={(option) =>
                          option.CityName === undefined ? "" : option.CityName
                        }
                        value={selectedcity}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            fnOnChange(newValue, "CityName", "CityId");
                          } else {
                            setSelectedCity({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select City"}
                            placeholder="City"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={countylookupvalues}
                        getOptionLabel={(option) =>
                          option.CountyName === undefined
                            ? ""
                            : option.CountyName
                        }
                        value={selectedcounty}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            fnOnChange(newValue, "CountyName", "CountyId");
                          } else {
                            setSelectedCounty({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select County"}
                            placeholder="County"
                            error={selectedcounty.CountyName === undefined}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={statelookupvalues}
                        getOptionLabel={(option) =>
                          option.StateName === undefined ? "" : option.StateName
                        }
                        value={selectedstate}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            fnOnChange(newValue, "StateName", "StateNameId");
                          } else {
                            setSelectedState({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select State"}
                            placeholder="State"
                            error={selectedstate.StateName === undefined}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={zipcodelookupvalues}
                        getOptionLabel={(option) =>
                          option.Zipcode === undefined ? "" : option.Zipcode
                        }
                        value={selectedzipcode}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            fnOnChange(newValue, "Zipcode", "ZipcodeId");
                          } else {
                            setSelectedZipcode({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select Zipcode"}
                            placeholder="Zipcode"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={basinlookupvalues}
                        getOptionLabel={(option) =>
                          option.BasinName === undefined ? "" : option.BasinName
                        }
                        value={selectedbasin}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            fnOnChange(newValue, "BasinName", "BasinId");
                          } else {
                            setSelectedBasin({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select Basin"}
                            placeholder="Basin"
                            error={selectedbasin.BasinName === undefined}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={operatorlookupvalues}
                        getOptionLabel={(option) =>
                          option.OperatorName === undefined
                            ? ""
                            : option.OperatorName
                        }
                        value={selectedoperator}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            fnOnChange(newValue, "Operator", "OperatorId");
                          } else {
                            setSelectedOperator({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select Operator"}
                            placeholder="Operator"
                            error={selectedoperator.OperatorName === undefined}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={wellstatusnames}
                        getOptionLabel={(option) =>
                          option.WellStatusName === undefined
                            ? ""
                            : option.WellStatusName
                        }
                        value={selectedwellstatuscode}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            fnOnChange(
                              newValue,
                              "WellStatusName",
                              "WellStatusCodeId"
                            );
                          } else {
                            setSelectedWellStatusCode({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select Well Status Name"}
                            placeholder="WellStatusName"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={classificationnames}
                        getOptionLabel={(option) =>
                          option.ClassificationName === undefined
                            ? ""
                            : option.ClassificationName
                        }
                        value={selectedwellclassification}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            fnOnChange(
                              newValue,
                              "ClassificationName",
                              "WellClassificationId"
                            );
                          } else {
                            setSelectedWellClassification({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select Well Classification Name"}
                            placeholder="ClassificationName"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs="3"></Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="2">
                      <TextField
                        label="Latitude"
                        id="latitudeId"
                        onChange={fnHandleChange("Latitude")}
                        value={wellvalues.Latitude}
                        variant="outlined"
                        size="small"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={wellvalues.Latitude === ""}
                      />
                    </Grid>
                    <Grid item xs="2">
                      <TextField
                        label="Longitude"
                        id="longitudeId"
                        onChange={fnHandleChange("Longitude")}
                        value={wellvalues.Longitude}
                        variant="outlined"
                        size="small"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={wellvalues.Longitude === ""}
                      />
                    </Grid>
                    <Grid item xs="2">
                      <Link
                        href={
                          "https://www.google.com/maps/search/?api=1&query=" +
                          wellvalues.Latitude +
                          "," +
                          wellvalues.Longitude
                        }
                        to="Google Maps"
                        target="_blank"
                      >
                        Google Maps Link
                      </Link>
                    </Grid>
                    <Grid item xs="2">
                      <TextField
                        label="Govt Operator Number"
                        id="GovtOperatorNumberId"
                        value={wellvalues.GovtOperatorNumber}
                        onChange={fnHandleChange("GovtOperatorNumber")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="2">
                      <TextField
                        label="Well Name"
                        id="WellNameId"
                        value={wellvalues.WellName}
                        onChange={fnHandleChange("WellName")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="2">
                      <TextField
                        label="WellNumber"
                        id="WellNumberId"
                        value={wellvalues.WellNumber}
                        onChange={fnHandleChange("WellNumber")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      <TextField
                        label="LeaseName"
                        id="LeaseNameId"
                        value={wellvalues.LeaseName}
                        onChange={fnHandleChange("LeaseName")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="LeaseNumber"
                        id="LeaseNumberId"
                        value={wellvalues.LeaseNumber}
                        onChange={fnHandleChange("LeaseNumber")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="FieldName"
                        id="FieldNameId"
                        value={wellvalues.FieldName}
                        onChange={fnHandleChange("FieldName")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="Section"
                        id="SectionId"
                        value={wellvalues.Section}
                        onChange={fnHandleChange("Section")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      <TextField
                        label="Township"
                        id="TownshipId"
                        value={wellvalues.Township}
                        onChange={fnHandleChange("Township")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="Range"
                        id="RangeId"
                        value={wellvalues.Range}
                        onChange={fnHandleChange("Range")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="Qtr_Qtr"
                        id="Qtr_QtrId"
                        value={wellvalues.Qtr_Qtr}
                        onChange={fnHandleChange("Qtr_Qtr")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="Footages"
                        id="FootagesId"
                        value={wellvalues.Footages}
                        onChange={fnHandleChange("Footages")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      <TextField
                        label="Elevation"
                        id="ElevationId"
                        value={wellvalues.Elevation}
                        onChange={fnHandleChange("Elevation")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="BSec"
                        id="BSecId"
                        value={wellvalues.BSec}
                        onChange={fnHandleChange("BSec")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="BTwp"
                        id="BTwpId"
                        value={wellvalues.BTwp}
                        onChange={fnHandleChange("BTwp")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="BTDir"
                        id="BTDirId"
                        value={wellvalues.BTDir}
                        onChange={fnHandleChange("BTDir")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      <TextField
                        label="BRge"
                        id="BRgeId"
                        value={wellvalues.BRge}
                        onChange={fnHandleChange("BRge")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="BRDir"
                        id="BRDirId"
                        value={wellvalues.BRDir}
                        onChange={fnHandleChange("BRDir")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="BQtr1"
                        id="BQtr1Id"
                        value={wellvalues.BQtr1}
                        onChange={fnHandleChange("BQtr1")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="BQtr2"
                        id="BQtr2Id"
                        value={wellvalues.BQtr2}
                        onChange={fnHandleChange("BQtr2")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      <TextField
                        label="BFoot1"
                        id="BFoot1Id"
                        value={wellvalues.BFoot1}
                        onChange={fnHandleChange("BFoot1")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="BFoot2"
                        id="BFoot2Id"
                        value={wellvalues.BFoot2}
                        onChange={fnHandleChange("BFoot2")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="BottomHoleLatitude"
                        id="BottomHoleLatitudeId"
                        value={wellvalues.BottomHoleLatitude}
                        onChange={fnHandleChange("BottomHoleLatitude")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="BottomHoleLongitude"
                        id="BottomHoleLongitudeId"
                        value={wellvalues.BottomHoleLongitude}
                        onChange={fnHandleChange("BottomHoleLongitude")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      <TextField
                        label="BottomFormation"
                        id="BottomFormationId"
                        value={wellvalues.BottomFormation}
                        onChange={fnHandleChange("BottomFormation")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="TD"
                        id="TDId"
                        value={wellvalues.TD}
                        onChange={fnHandleChange("TD")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="MDMax"
                        id="MDMaxId"
                        value={wellvalues.MDMax}
                        onChange={fnHandleChange("MDMax")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3"></Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      <TextField
                        label="TVDMax"
                        id="TVDMaxId"
                        value={wellvalues.TVDMax}
                        onChange={fnHandleChange("TVDMax")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="ReservoirName"
                        id="ReservoirNameId"
                        value={wellvalues.ReservoirName}
                        onChange={fnHandleChange("ReservoirName")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="CoalBed"
                        id="CoalBedId"
                        value={wellvalues.CoalBed}
                        onChange={fnHandleChange("CoalBed")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3"></Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          format="yyyy/MM/dd"
                          margin="normal"
                          id="statusdateId"
                          label="Status Date"
                          variant="outlined"
                          value={wellvalues.StatusDate}
                          onChange={fnHandleStatusDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "Status Date",
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs="3">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          format="yyyy/MM/dd"
                          margin="normal"
                          id="firstspuddateId"
                          label="First Spud Date"
                          variant="outlined"
                          value={wellvalues.FirstSpudDate}
                          onChange={fnHandleFirstSpudDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "First Spud Date",
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs="3">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          format="yyyy/MM/dd"
                          margin="normal"
                          id="firstcompletiondateId"
                          label="First Completion Date"
                          variant="outlined"
                          value={wellvalues.FirstCompletionDate}
                          onChange={fnHandleFirstCompletionDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "First Completion Date",
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs="3">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          format="yyyy/MM/dd"
                          margin="normal"
                          id="permitdateId"
                          label="Permit Date"
                          variant="outlined"
                          value={wellvalues.PermitDate}
                          onChange={fnHandlePermitDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "Permit Date",
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid item>
                      <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        className={classes.updatebutton}
                        disabled={
                          wellvalues.Latitude === "" ||
                          wellvalues.APINumber === "" ||
                          wellvalues.Longitude === "" ||
                          wellvalues.StateName === null ||
                          wellvalues.OperatorName === null
                        }
                        onClick={() => fnInsertWellData()}
                      >
                        Insert Well
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          </ValidatorForm>
        </Paper>
      </Grid>
      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(AddWellComp);
