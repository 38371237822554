import React, { Suspense } from 'react';

import Grid from '@material-ui/core/Grid';

import LinearProgress from '@material-ui/core/LinearProgress';

import { makeStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router-dom';

import SideDrawerComp from './subcomponents/SideDrawerComp';

const OwnerUpdateDataComponent = React.lazy(
	() =>
		new Promise((resolve, reject) =>
			setTimeout(() => resolve(import('./subcomponents/OwnerUpdateDataComp')), 100)
		)
);



const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
	},
}));

function UpdateOperatorComp(props) {
	const classes = useStyles();


	return (
		<>
			<Grid container>
				<Suspense
					fallback={
						<>
							<LinearProgress />
						</>
					}
				>
					<OwnerUpdateDataComponent />
				</Suspense>
			</Grid>
		</>
	);
}

export default withRouter(UpdateOperatorComp);
