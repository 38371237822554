import React, { Suspense } from "react";

import Grid from "@material-ui/core/Grid";

import LinearProgress from "@material-ui/core/LinearProgress";

import { makeStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";

const TariffSearchComponent = React.lazy(
  () =>
    new Promise((resolve, reject) =>
      setTimeout(() => resolve(import("./subcomponents/TariffSearchComp")), 100)
    )
);

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },
}));

function TariffComp(props) {
  const classes = useStyles();

  const [searchtariffquery, setSearchTariffQuery] = React.useState("");

  return (
    <>
      <Grid container>
        <Suspense
          fallback={
            <>
              <LinearProgress />
            </>
          }
        >
          <TariffSearchComponent setSearchTariffQuery={setSearchTariffQuery} />
        </Suspense>
      </Grid>
    </>
  );
}

export default withRouter(TariffComp);
