import React, { Suspense } from 'react';

import Grid from '@material-ui/core/Grid';

import LinearProgress from '@material-ui/core/LinearProgress';

import { makeStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router-dom';

const RefinerySearchComponent = React.lazy(
	() =>
		new Promise((resolve, reject) => setTimeout(() => resolve(import('./subcomponents/RefinerySearchComp')), 100))
);

const RefinerySubComp = React.lazy(
	() =>
		new Promise((resolve, reject) => setTimeout(() => resolve(import('./subcomponents/RefinerySubComp')), 100))
);

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
	},
}));

function RefineryComp(props) {
	const classes = useStyles();

	const [searchrefineryquery, setSearchRefineryQuery] = React.useState('');

	return (
		<>
			<Grid container>
				<Suspense
					fallback={
						<>
							<LinearProgress />
						</>
					}
				>
					<RefinerySearchComponent setSearchRefineryQuery={setSearchRefineryQuery} />
				</Suspense>
			</Grid>
			<Grid container>
				<Suspense
					fallback={
						<>
							<LinearProgress />
						</>
					}
				>
					<RefinerySubComp searchrefineryquery={searchrefineryquery} />
				</Suspense>
			</Grid>
		</>
	);
}

export default withRouter(RefineryComp);
