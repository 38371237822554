import React, { useEffect, useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import AlertNotificationComp from "components/custom/AlertNotificationComp";

import { customizedDateFormat } from "constants/utils";

import { directions } from "lookuptables/global";

//date
import moment from "moment";

//linq
import linq from "linq";

//Redux imports
import { useSelector } from "react-redux";

//Api
import axios from "axios";

//Redux imports
import { useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  operatordatacontainer: {
    margin: 10,
    width: "100%",
    "& .MuiPaper-root": {
      width: "100%",
    },
  },
  formcontainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-container": {
      marginBottom: 3.5,
    },
  },

  operatorlist: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    maxHeight: 700,
    minHeight: 700,
  },
  operatordata: {
    padding: 10,
    height: 700,
    maxHeight: 700,
  },
  updatebutton: {
    width: "200px",
  },
}));

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

function PipelineSectionUpdateComp(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const operatorlookupvalues = useSelector(
    (state) => state.operatorsReducer.OperatorName
  );
  const basinlookupvalues = useSelector(
    (state) => state.basinsReducer.BasinName
  );
  const statelookupvalues = useSelector(
    (state) => state.statenamesReducer.StateName
  );
  const countylookupvalues = useSelector(
    (state) => state.countynamesReducer.CountyName
  );
  const citylookupvalues = useSelector(
    (state) => state.citynamesReducer.CityName
  );

  const [pipelines, setPipelines] = React.useState([]);
  const [fromdeviceterminals, setFromDeviceTerminals] = React.useState([]);
  const [todeviceterminals, setToDeviceTerminals] = React.useState([]);
  const [fromcounties, setFromCounties] = React.useState([]);
  const [tocounties, setToCounties] = React.useState([]);

  const pipelinesectiondataRef = useRef(null);

  const [selectedpipelinesection, setSelectedPipelineSection] = React.useState(
    []
  );

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "error",
  });

  const [fromselectedbasin, setFromSelectedBasin] = React.useState({});
  const [fromselectedstate, setFromSelectedState] = React.useState({});
  const [fromselectedcounty, setFromSelectedCounty] = React.useState({});
  const [fromselectedcity, setFromSelectedCity] = React.useState({});
  const [toselectedbasin, setToSelectedBasin] = React.useState({});
  const [toselectedstate, setToSelectedState] = React.useState({});
  const [toselectedcounty, setToSelectedCounty] = React.useState({});
  const [toselectedcity, setToSelectedCity] = React.useState({});
  const [selecteddirection, setSelectedDirection] = React.useState({});
  const [selectedpipelineheader, setSelectedPipelineHeader] = React.useState(
    {}
  );
  const [selectedoperator, setSelectedOperator] = React.useState({});

  const pipelinesectioninitialstate = {
    PipelineName: null,
    PipelineHeaderId: null,
    PipelineSectionName: "",
    SegmentId: "",
    SegmentStatus: "",
    SectionLengthMiles: "",
    PipelineSectionGid: "",
    SectionGovtId: "",
    SectionFromDeviceTerminalType: "",
    SectionFromDeviceTerminal: "",
    SectionFromDeviceTerminalId: "",
    SectionToDeviceTerminalType: "",
    SectionToDeviceTerminal: "",
    SectionToDeviceTerminalId: "",
    SectionMeasuredLengthMiles: "",
    SectionPipesurfaceArea: "",
    ToFromSectionCapacityMMBTU: "",
    FromToSectionCapacityMMBTU: "",
    SectionInstallationDate: null,
    SectionInservicedate: null,
    SectionNominalDiameterInch: "",
    SectionOperatingPressure: "",
    SectionLengthSource: "",
    SectionManufacturer: "",
    SectionPipevolume: "",
    SectionActualInternalDiameter: "",
    SectionDateRetired: null,
    CriticalIndicator: "",
    SectionStubbed: "",
    PressureUnits: "",
    LengthUnits: "",
    DiameterUnits: "",
    PipesurfaceAreaUnits: "",
    PipeVolumeUnits: "",
    WallThickness: "",
    FromCountyName: null,
    FromCountyId: null,
    FromStateName: null,
    FromStateNameId: null,
    FromBasin: null,
    FromBasinId: null,
    FromCityName: null,
    FromCityId: null,
    ToCountyName: null,
    ToCountyId: null,
    ToStateName: null,
    ToStateNameId: null,
    ToBasin: null,
    ToBasinId: null,
    ToCityName: null,
    ToCityId: null,
    DirectionId: null,
    Direction: null,
    OperatorName: null,
    OperatorId: null,
    key: 0,
  };

  const [pipelinesectionvalues, setPipelineSectionValues] = React.useState({});

  const [pipelinesectiondata, setPipelineSectionData] = React.useState([]);

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "info" });
  };

  const fnCheckSegmentIdExists = (segmentid, headerid) => {
    const data = {
      segmentid: segmentid,
      pipelineheaderid: headerid,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchpipelinesegmentidexists", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
          } else if (response.data[0].status == "no records found") {
          } else if (response.data[0].data.length > 0) {
            setStatusBase({
              show: true,
              message:
                "Segment Id is used for - " +
                response.data[0].data[0].PipelineSectionName,
              variant: "error",
            });
          }
        } else {
        }
      },
      (error) => {
        if (error.request.status === 500) {
        } else {
        }
      }
    );
  };

  const fnHandleChange = (prop) => (event) => {
    setPipelineSectionValues({
      ...pipelinesectionvalues,
      [prop]: event.target.value,
    });

    if (prop === "SegmentId" && event.target.value > 0) {
      fnCheckSegmentIdExists(
        event.target.value,
        pipelinesectionvalues.PipelineHeaderId
      );
    }
  };

  const fnHandleCheckChange = (prop) => (event) => {
    setPipelineSectionValues({
      ...pipelinesectionvalues,
      [prop]: event.target.checked ? 1 : 0,
    });
  };

  const fnOnChange = (newvalue, type, typeid) => {
    if (newvalue !== null && newvalue !== undefined) {
      if (type === "Owner" || type === "Operator") {
        setPipelineSectionValues({
          ...pipelinesectionvalues,
          [type]: newvalue["OperatorName"],
          [typeid]: newvalue["OperatorId"],
        });
      } else if (type === "PipelineName" || type === "Direction") {
        setPipelineSectionValues({
          ...pipelinesectionvalues,
          [type]: newvalue[type],
          [typeid]: newvalue[typeid],
        });
      } else {
        var keys = Object.keys(newvalue);

        setPipelineSectionValues({
          ...pipelinesectionvalues,
          [type]: newvalue[keys[0]],
          [typeid]: newvalue[keys[1]],
        });
      }

      switch (type) {
        case "Operator":
          setSelectedOperator(newvalue);
          break;
        case "FromCountyName":
          setFromSelectedCounty(newvalue);
          break;
        case "ToCountyName":
          setToSelectedCounty(newvalue);
          break;
        case "FromStateName":
          setFromSelectedState(newvalue);
          setFromSelectedCounty({});
          var filtercounties = linq
            .from(countylookupvalues)
            .where((a) => a.StateNameId == newvalue.StateNameId)
            .toArray();
          setFromCounties(filtercounties);

          break;
        case "ToStateName":
          setToSelectedState(newvalue);
          setToSelectedCounty({});
          var filtercounties = linq
            .from(countylookupvalues)
            .where((a) => a.StateNameId == newvalue.StateNameId)
            .toArray();
          setToCounties(filtercounties);
          break;
        case "FromBasinName":
          setFromSelectedBasin(newvalue);
          break;
        case "ToBasinName":
          setToSelectedBasin(newvalue);
          break;
        case "FromCityName":
          setFromSelectedCity(newvalue);
          break;
        case "ToCityName":
          setToSelectedCity(newvalue);
          break;
        case "PipelineName":
          setSelectedPipelineHeader(newvalue);
          break;
        case "Direction":
          setSelectedDirection(newvalue);
      }
    } else {
      switch (type) {
        case "Operator":
          setSelectedOperator({});
          break;
        case "FromBasinName":
          setFromSelectedBasin({});
          break;
        case "ToBasinName":
          setToSelectedBasin({});
          break;
        case "FromCityName":
          setFromSelectedCity({});
          break;
        case "ToCityName":
          setToSelectedCity({});
          break;
      }
    }
  };

  const tablenamemapping = {
    Refinery: "RefineryName",
    Terminal: "TerminalName",
    GasPlant: "GasPlantName",
    GasStorage: "GasStorageName",
    Liquefaction: "LiquefactionPlantName",
    CompressorStation: "CompressorStationName",
    PumpingStation: "PumpingStationName",
  };

  const fnHandleDDChange = (prop) => (event) => {
    if (prop === "SectionFromDeviceTerminalType") {
      fetchTableData(
        tablenamemapping[event.target.textContent],
        event.target.textContent,
        "SectionFromDeviceTerminalType"
      );
    } else if (prop === "SectionToDeviceTerminalType") {
      fetchTableData(
        tablenamemapping[event.target.textContent],
        event.target.textContent,
        "SectionToDeviceTerminalType"
      );
    }

    setPipelineSectionValues({
      ...pipelinesectionvalues,
      [prop]: event.target.textContent,
    });
  };

  const fnFetchPipelineSectionData = () => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      fetchtype: "pipelinesections",
      fetchquery: props.id,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchindividualdata", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while fetching records",
              variant: "error",
            });
          } else if (response.data[0].status == "no records found") {
            setStatusBase({
              show: true,
              message: "Pipeline section data not found",
              variant: "info",
            });
          } else if (response.data[0].data.length > 0) {
            setPipelineSectionData(response.data[0].data);

            // scrollToRef(pipelinesectiondataRef);
          }
        } else {
          setStatusBase({
            show: true,
            message:
              "Error while fetching pipeline section data. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching pipeline section data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnLoadPipelineSectionData = (id) => {
    // setSelectedIndex(index);

    fnResetStateValues();

    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      fetchtype: "pipelinesection",
      fetchid: id,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchsinglerecord", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while fetching record",
              variant: "error",
            });
          } else if (response.data[0].status == "no record found") {
            setStatusBase({
              show: true,
              message: "Operator data not found",
              variant: "info",
            });
          } else if (response.data[0].data !== undefined) {
            var resultobj = JSON.parse(
              JSON.stringify(pipelinesectioninitialstate)
            );

            Object.keys(response.data[0].data).forEach((element) => {
              if (
                response.data[0].data[element] !== null &&
                response.data[0].data[element] !== undefined
              ) {
                if (element === "OperatorName") {
                  var selectedvalue = linq
                    .from(operatorlookupvalues)
                    .where(
                      (a) => a.OperatorName == response.data[0].data[element]
                    )
                    .toArray();

                  setSelectedOperator({
                    OperatorName: selectedvalue[0].OperatorName,
                    OperatorId: selectedvalue[0].OperatorId,
                  });

                  resultobj.OperatorId = selectedvalue[0].OperatorId;
                }

                if (element === "FromCityName") {
                  var selectedvalue = linq
                    .from(citylookupvalues)
                    .where((a) => a.CityName == response.data[0].data[element])
                    .toArray();

                  setFromSelectedCity({
                    CityName: selectedvalue[0].CityName,
                    CityId: selectedvalue[0].CityId,
                  });

                  resultobj.FromCityId = selectedvalue[0].CityId;
                }

                if (element === "ToCityName") {
                  var selectedvalue = linq
                    .from(citylookupvalues)
                    .where((a) => a.CityName == response.data[0].data[element])
                    .toArray();

                  setToSelectedCity({
                    CityName: selectedvalue[0].CityName,
                    CityId: selectedvalue[0].CityId,
                  });

                  resultobj.ToCityId = selectedvalue[0].CityId;
                }

                if (element === "FromCountyName") {
                  var selectedvalue = linq
                    .from(countylookupvalues)
                    .where(
                      (a) => a.CountyName == response.data[0].data[element]
                    )
                    .toArray();

                  setFromSelectedCounty({
                    CountyName: selectedvalue[0].CountyName,
                    CountyId: selectedvalue[0].CountyId,
                  });

                  resultobj.FromCountyId = selectedvalue[0].CountyId;
                }

                if (element === "ToCountyName") {
                  var selectedvalue = linq
                    .from(countylookupvalues)
                    .where(
                      (a) => a.CountyName == response.data[0].data[element]
                    )
                    .toArray();

                  setToSelectedCounty({
                    CountyName: selectedvalue[0].CountyName,
                    CountyId: selectedvalue[0].CountyId,
                  });

                  resultobj.ToCountyId = selectedvalue[0].CountyId;
                }

                if (element === "FromStateName") {
                  var selectedvalue = linq
                    .from(statelookupvalues)
                    .where((a) => a.StateName == response.data[0].data[element])
                    .toArray();

                  setFromSelectedState({
                    StateName: selectedvalue[0].StateName,
                    StateNameId: selectedvalue[0].StateNameId,
                  });

                  var filtercounties = linq
                    .from(countylookupvalues)
                    .where((a) => a.StateNameId == selectedvalue[0].StateNameId)
                    .toArray();
                  setFromCounties(filtercounties);

                  resultobj.FromStateNameId = selectedvalue[0].StateNameId;
                }

                if (element === "ToStateName") {
                  var selectedvalue = linq
                    .from(statelookupvalues)
                    .where((a) => a.StateName == response.data[0].data[element])
                    .toArray();

                  setToSelectedState({
                    StateName: selectedvalue[0].StateName,
                    StateNameId: selectedvalue[0].StateNameId,
                  });

                  var filtercounties = linq
                    .from(countylookupvalues)
                    .where((a) => a.StateNameId == selectedvalue[0].StateNameId)
                    .toArray();
                  setToCounties(filtercounties);

                  resultobj.ToStateNameId = selectedvalue[0].StateNameId;
                }

                if (element === "FromBasinId") {
                  var selectedvalue = linq
                    .from(basinlookupvalues)
                    .where((a) => a.BasinId == response.data[0].data[element])
                    .toArray();

                  setFromSelectedBasin({
                    BasinName: selectedvalue[0].BasinName,
                    BasinId: selectedvalue[0].BasinId,
                  });

                  resultobj.FromBasinId = selectedvalue[0].BasinId;
                }

                if (element === "ToBasinId") {
                  var selectedvalue = linq
                    .from(basinlookupvalues)
                    .where((a) => a.BasinId == response.data[0].data[element])
                    .toArray();

                  setToSelectedBasin({
                    BasinName: selectedvalue[0].BasinName,
                    BasinId: selectedvalue[0].BasinId,
                  });

                  resultobj.ToBasinId = selectedvalue[0].BasinId;
                }

                if (element === "DirectionId") {
                  var selectedvalue = linq
                    .from(directions)
                    .where(
                      (a) => a.DirectionId == response.data[0].data[element]
                    )
                    .toArray();

                  setSelectedDirection({
                    Direction: selectedvalue[0].Direction,
                    DirectionId: selectedvalue[0].DirectionId,
                  });

                  resultobj.DirectionId = selectedvalue[0].DirectionId;
                }

                if (element === "PipelineName") {
                  var selectedvalue = linq
                    .from(pipelines)
                    .where(
                      (a) => a.PipelineName == response.data[0].data[element]
                    )
                    .toArray();

                  setSelectedPipelineHeader({
                    PipelineName: selectedvalue[0].PipelineName,
                    PipelineHeaderId: selectedvalue[0].PipelineHeaderId,
                  });

                  resultobj.PipelineHeaderId =
                    selectedvalue[0].PipelineHeaderId;
                }

                resultobj[element] = response.data[0].data[element];
              }
            });

            setPipelineSectionValues(resultobj);
          }
        } else {
          setStatusBase({
            show: true,
            message:
              "Error while fetching pipeline section data. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while fetching pipeline section data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnResetStateValues = () => {
    // let cloneobj = JSON.parse(JSON.stringify(operatorinitialstate));

    setFromSelectedCounty({});
    setFromSelectedState({});
    setFromSelectedBasin({});
    setFromSelectedCity({});
    setToSelectedCounty({});
    setToSelectedState({});
    setToSelectedBasin({});
    setToSelectedCity({});
    setSelectedDirection({});
    setSelectedPipelineHeader({});
  };

  const fnUpdatePipelineSectionData = () => {
    var pipelinesectionkeys = [
      // 'PipelineHeaderId',
      // 'PipelineSectionName',
      // 'PipelineSectionGid',
      // 'SectionGovtId',
      // 'SectionFromDeviceTerminalType',
      // 'SectionFromDeviceTerminal',
      // 'SectionFromDeviceTerminalId',
      // 'SectionToDeviceTerminalType',
      // 'SectionToDeviceTerminal',
      // 'SectionToDeviceTerminalId',
      "SegmentId",
      "SegmentStatus",
      "SectionLengthMiles",
      "SectionMeasuredLengthMiles",
      "FromToSectionCapacityMMBTU",
      "ToFromSectionCapacityMMBTU",
      "SectionPipesurfaceArea",
      "SectionInstallationDate",
      "SectionInservicedate",
      "SectionNominalDiameterInch",
      "SectionOperatingPressure",
      "SectionLengthSource",
      "SectionManufacturer",
      "SectionPipevolume",
      "SectionActualInternalDiameter",
      "SectionDateRetired",
      "CriticalIndicator",
      "SectionStubbed",
      "PressureUnits",
      "LengthUnits",
      "DiameterUnits",
      "PipesurfaceAreaUnits",
      "PipeVolumeUnits",
      "WallThickness",
      "FromCountyId",
      "FromStateNameId",
      "FromBasinId",
      "FromCityId",
      "ToCountyId",
      "ToStateNameId",
      "ToBasinId",
      "ToCityId",
      "OperatorId",
      "DirectionId",
    ];

    var updatevalues = [];
    pipelinesectionkeys.forEach((element) => {
      if (
        pipelinesectionvalues[element] !== undefined &&
        pipelinesectionvalues[element] !== null &&
        pipelinesectionvalues[element] !== ""
      ) {
        if (element.indexOf("Date") > -1) {
          updatevalues.push(
            element +
              " = '" +
              moment(pipelinesectionvalues[element]).format("YYYY-MM-DD") +
              "'"
          );
        } else {
          if (typeof pipelinesectionvalues[element] === "string") {
            updatevalues.push(
              element + " = '" + pipelinesectionvalues[element] + "'"
            );
          } else {
            updatevalues.push(element + " = " + pipelinesectionvalues[element]);
          }
        }
      } else {
        updatevalues.push(element + " = null");
      }
    });

    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      tablename: "PipelineSection",
      query: updatevalues.join(","),
      key: pipelinesectionvalues.key,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/updateheaderdata", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while updating",
              variant: "error",
            });
          } else if (response.data[0].status === "success") {
            setStatusBase({
              show: true,
              message: "Data updated successfully",
              variant: "info",
            });
          }
        } else {
          setStatusBase({
            show: true,
            message:
              "Error while updating pipeline section data. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while updating pipeline section data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnHandleInstallationDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setPipelineSectionValues({
        ...pipelinesectionvalues,
        InstallationDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setPipelineSectionValues({
        ...pipelinesectionvalues,
        InstallationDate: null,
      });
    }
  };

  const fnHandleInserviceDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setPipelineSectionValues({
        ...pipelinesectionvalues,
        InserviceDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setPipelineSectionValues({
        ...pipelinesectionvalues,
        InserviceDate: null,
      });
    }
  };

  const fnHandleDateRetiredChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setPipelineSectionValues({
        ...pipelinesectionvalues,
        DateRetired: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setPipelineSectionValues({ ...pipelinesectionvalues, DateRetired: null });
    }
  };

  const fnFetchLookupValues = () => {
    const data = {
      fetchtype: "PipelineName",
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchuniquevalues", data, options).then(
      (response) => {
        // console.log(response.data[0]);
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            var data = [];
            response.data[0].data.forEach((element) => {
              data.push({
                PipelineName: element["PipelineName"],
                PipelineHeaderId: element["PipelineHeaderId"],
              });
            });

            setPipelines(data);
          } else {
          }
        }
      },
      (error) => {}
    );
  };

  const fetchTableData = (columnname, tablename, type) => {
    const query = { columnname: columnname, tablename: tablename };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchsubjectuniquevalues", query, options).then(
      (response) => {
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "no records") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            if (type === "SectionFromDeviceTerminalType") {
              setFromDeviceTerminals(response.data[0].data);
            } else if (type === "SectionToDeviceTerminalType") {
              setToDeviceTerminals(response.data[0].data);
            }
          }
        }
      },
      (error) => {
        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
        }
      }
    );
  };

  useEffect(() => {
    if (props.id > 0) {
      fnFetchPipelineSectionData();
    }
  }, [props.id]);

  useEffect(() => {
    fnFetchLookupValues();
  }, []);

  useEffect(() => {
    if (selectedpipelinesection.key !== undefined) {
      fnLoadPipelineSectionData(selectedpipelinesection.key);
    }
  }, [selectedpipelinesection]);

  return (
    <>
      <Grid className={classes.operatordatacontainer} container>
        <Grid
          container
          spacing="3"
          direction="column"
          className={classes.formcontainer}
          style={{ marginBottom: "10px" }}
        >
          <Grid item>
            <Autocomplete
              size="small"
              options={pipelinesectiondata}
              getOptionLabel={(option) =>
                option.PipelineSectionName === undefined
                  ? ""
                  : option.PipelineSectionName
              }
              value={selectedpipelinesection}
              onChange={(event, newValue) =>
                setSelectedPipelineSection(newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={"Select Pipeline Section"}
                  placeholder="Pipeline Section"
                />
              )}
            />
          </Grid>
        </Grid>

        {selectedpipelinesection.key !== undefined ? (
          <Grid
            container
            spacing="3"
            direction="column"
            className={classes.formcontainer}
          >
            <Grid item>
              <Grid
                container
                spacing="3"
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs="3">
                  <TextField
                    label="Pipeline Name"
                    id="pipelinenameId"
                    value={pipelinesectionvalues.PipelineName}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="Pipeline Section Name"
                    id="pipelinesectionnameId"
                    value={pipelinesectionvalues.PipelineSectionName}
                    // onChange={fnHandleChange('PipelineSectionName')}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />
                </Grid>

                <Grid item xs="1">
                  <TextField
                    label="Gid"
                    id="gidId"
                    value={pipelinesectionvalues.PipelineSectionGid}
                    // onChange={fnHandleChange('PipelineSectionName')}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs="2">
                  <TextField
                    label="Govt Id"
                    id="govtidId"
                    value={pipelinesectionvalues.SectionGovtId}
                    // onChange={fnHandleChange('PipelineSectionName')}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />
                </Grid>

                <Grid item xs="3">
                  <TextField
                    label="SectionMeasuredLengthMiles"
                    id="sectionMeasuredlengthId"
                    onChange={fnHandleChange("SectionMeasuredLengthMiles")}
                    value={pipelinesectionvalues.SectionMeasuredLengthMiles}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs="3"></Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing="3"
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs="2">
                  <TextField
                    label="From Terminal Type"
                    id="fromterminaltypeId"
                    value={pipelinesectionvalues.SectionFromDeviceTerminalType}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="From Terminal"
                    id="fromterminalId"
                    value={pipelinesectionvalues.SectionFromDeviceTerminal}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs="1">
                  <TextField
                    label="From Id"
                    id="fromterminalidId"
                    value={pipelinesectionvalues.SectionFromDeviceTerminalId}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs="2">
                  <TextField
                    label="To Terminal Type"
                    id="toterminaltypeId"
                    value={pipelinesectionvalues.SectionToDeviceTerminalType}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="To Terminal"
                    id="toterminalId"
                    value={pipelinesectionvalues.SectionToDeviceTerminal}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />
                </Grid>
                <Grid item xs="1">
                  <TextField
                    label="To Id"
                    id="toterminalidId"
                    value={pipelinesectionvalues.SectionToDeviceTerminalId}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing="3"
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs="3">
                  <Autocomplete
                    size="small"
                    options={statelookupvalues}
                    getOptionLabel={(option) =>
                      option.StateName === undefined ? "" : option.StateName
                    }
                    value={fromselectedstate}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        fnOnChange(
                          newValue,
                          "FromStateName",
                          "FromStateNameId"
                        );
                      } else {
                        setFromSelectedState({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select From State"}
                        placeholder="From State"
                        error={fromselectedstate.StateName === undefined}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs="3">
                  <Autocomplete
                    size="small"
                    options={fromcounties}
                    getOptionLabel={(option) =>
                      option.CountyName === undefined ? "" : option.CountyName
                    }
                    value={fromselectedcounty}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        fnOnChange(newValue, "FromCountyName", "FromCountyId");
                      } else {
                        setFromSelectedCounty({});
                      }
                    }}
                    disabled={fromcounties.length === 0}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select From County"}
                        placeholder="From County"
                        error={fromselectedcounty.CountyName === undefined}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs="3">
                  <Autocomplete
                    size="small"
                    options={statelookupvalues}
                    getOptionLabel={(option) =>
                      option.StateName === undefined ? "" : option.StateName
                    }
                    value={toselectedstate}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        fnOnChange(newValue, "ToStateName", "ToStateNameId");
                      } else {
                        setToSelectedState({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select To State"}
                        placeholder="to State"
                        error={toselectedstate.StateName === undefined}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs="3">
                  <Autocomplete
                    size="small"
                    options={tocounties}
                    getOptionLabel={(option) =>
                      option.CountyName === undefined ? "" : option.CountyName
                    }
                    value={toselectedcounty}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        fnOnChange(newValue, "ToCountyName", "ToCountyId");
                      } else {
                        setToSelectedCounty({});
                      }
                    }}
                    disabled={tocounties.length === 0}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select To County"}
                        placeholder="To County"
                        error={toselectedcounty.CountyName === undefined}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing="3"
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs="3">
                  <Autocomplete
                    size="small"
                    options={operatorlookupvalues}
                    getOptionLabel={(option) =>
                      option.OperatorName === undefined
                        ? ""
                        : option.OperatorName
                    }
                    value={selectedoperator}
                    onChange={(event, newValue) => {
                      if (newValue != null) {
                        fnOnChange(newValue, "Operator", "OperatorId");
                      } else {
                        setSelectedOperator({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select Operator"}
                        placeholder="Operator"
                        error={selectedoperator.OperatorName === undefined}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="SegmentId"
                    id="segmentidId"
                    onChange={fnHandleChange("SegmentId")}
                    value={pipelinesectionvalues.SegmentId}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinesectionvalues.SegmentId === ""}
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="SegmentStatus"
                    id="SegmentStatusId"
                    onChange={fnHandleChange("SegmentStatus")}
                    value={pipelinesectionvalues.SegmentStatus}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                    error={pipelinesectionvalues.SegmentStatus === ""}
                  />
                </Grid>
                <Grid item xs="3">
                <TextField
                    label="SectionLengthMiles"
                    id="SectionLengthMilesId"
                    onChange={fnHandleChange("SectionLengthMiles")}
                    value={pipelinesectionvalues.SectionLengthMiles}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing="3"
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs="3">
                  <Autocomplete
                    size="small"
                    options={citylookupvalues}
                    getOptionLabel={(option) =>
                      option.CityName === undefined ? "" : option.CityName
                    }
                    value={fromselectedcity}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        fnOnChange(newValue, "FromCityName", "FromCityId");
                      } else {
                        setFromSelectedCity({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select From City"}
                        placeholder="From City"
                        error={fromselectedcity.CityName === undefined}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs="3">
                  <Autocomplete
                    size="small"
                    options={citylookupvalues}
                    getOptionLabel={(option) =>
                      option.CityName === undefined ? "" : option.CityName
                    }
                    value={toselectedcity}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        fnOnChange(newValue, "ToCityName", "ToCityId");
                      } else {
                        setToSelectedCity({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select To City"}
                        placeholder="To City"
                        error={toselectedcity.CityName === undefined}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs="3">
                  <Autocomplete
                    size="small"
                    options={basinlookupvalues}
                    getOptionLabel={(option) =>
                      option.BasinName === undefined ? "" : option.BasinName
                    }
                    value={fromselectedbasin}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        fnOnChange(newValue, "FromBasinName", "FromBasinId");
                      } else {
                        setFromSelectedBasin({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select From Basin"}
                        placeholder="From Basin"
                        error={fromselectedbasin.BasinName === undefined}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs="3">
                  <Autocomplete
                    size="small"
                    options={basinlookupvalues}
                    getOptionLabel={(option) =>
                      option.BasinName === undefined ? "" : option.BasinName
                    }
                    value={toselectedbasin}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        fnOnChange(newValue, "ToBasinName", "ToBasinId");
                      } else {
                        setToSelectedBasin({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select To Basin"}
                        placeholder="to Basin"
                        error={toselectedbasin.BasinName === undefined}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing="3"
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs="3">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      format="yyyy/MM/dd"
                      margin="normal"
                      id="installationdateId"
                      label="Section Installation Date"
                      variant="outlined"
                      value={pipelinesectionvalues.SectionInstallationDate}
                      onChange={fnHandleInstallationDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "Section Installation Date",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={
                        pipelinesectionvalues.SectionInstallationDate ===
                        undefined
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs="3">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      format="yyyy/MM/dd"
                      margin="normal"
                      id="inservicedateId"
                      label="Section Inservice Date"
                      variant="outlined"
                      value={pipelinesectionvalues.SectionInservicedate}
                      onChange={fnHandleInserviceDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "Section Inservice Date",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={
                        pipelinesectionvalues.SectionInservicedate === undefined
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs="2">
                  <Autocomplete
                    size="small"
                    options={directions}
                    getOptionLabel={(option) =>
                      option.Direction === undefined ? "" : option.Direction
                    }
                    value={selecteddirection}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        fnOnChange(newValue, "Direction", "DirectionId");
                      } else {
                        setSelectedDirection({});
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select Direction"}
                        placeholder="Direction"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs="2">
                  <TextField
                    label="FromToSectionCapacityMMBTU"
                    id="FromToSectionCapacityMMBTUId"
                    onChange={fnHandleChange("FromToSectionCapacityMMBTU")}
                    value={pipelinesectionvalues.FromToSectionCapacityMMBTU}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={
                      pipelinesectionvalues.FromToSectionCapacityMMBTU === ""
                    }
                  />
                </Grid>
                <Grid item xs="2">
                  <TextField
                    label="ToFromSectionCapacityMMBTU"
                    id="ToFromSectionCapacityMMBTUId"
                    onChange={fnHandleChange("ToFromSectionCapacityMMBTU")}
                    value={pipelinesectionvalues.ToFromSectionCapacityMMBTU}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={
                      pipelinesectionvalues.ToFromSectionCapacityMMBTU === ""
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                spacing="3"
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs="3">
                  <TextField
                    label="SectionPipesurfaceArea"
                    id="sectionpipesurfaceareaId"
                    onChange={fnHandleChange("SectionPipesurfaceArea")}
                    value={pipelinesectionvalues.SectionPipesurfaceArea}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinesectionvalues.SectionPipesurfaceArea === ""}
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="SectionNominalDiameterInch"
                    id="sectionnominaldiameterinchId"
                    onChange={fnHandleChange("SectionNominalDiameterInch")}
                    value={pipelinesectionvalues.SectionNominalDiameterInch}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={
                      pipelinesectionvalues.SectionNominalDiameterInch === ""
                    }
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="SectionOperatingPressure"
                    id="sectionoperatingpressureId"
                    onChange={fnHandleChange("SectionOperatingPressure")}
                    value={pipelinesectionvalues.SectionOperatingPressure}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={
                      pipelinesectionvalues.SectionOperatingPressure === ""
                    }
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="SectionActualInternalDiameter"
                    id="sectionactualinternaldiameterId"
                    onChange={fnHandleChange("SectionActualInternalDiameter")}
                    value={pipelinesectionvalues.SectionActualInternalDiameter}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={
                      pipelinesectionvalues.SectionActualInternalDiameter === ""
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                spacing="3"
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs="3">
                  <TextField
                    label="SectionLengthSource"
                    id="sectionlengthsourceId"
                    onChange={fnHandleChange("SectionLengthSource")}
                    value={pipelinesectionvalues.SectionLengthSource}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinesectionvalues.SectionLengthSource === ""}
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="SectionManufacturer"
                    id="sectionmanufacturerId"
                    onChange={fnHandleChange("SectionManufacturer")}
                    value={pipelinesectionvalues.SectionManufacturer}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinesectionvalues.SectionManufacturer === ""}
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="SectionPipevolume"
                    id="sectionpipevolumeId"
                    onChange={fnHandleChange("SectionPipevolume")}
                    value={pipelinesectionvalues.SectionPipevolume}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinesectionvalues.SectionPipevolume === ""}
                  />
                </Grid>
                <Grid item xs="3">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      format="yyyy/MM/dd"
                      margin="normal"
                      id="sectiondateretiredId"
                      label="SectionDateRetired"
                      variant="outlined"
                      value={pipelinesectionvalues.SectionDateRetired}
                      onChange={fnHandleDateRetiredChange}
                      KeyboardButtonProps={{
                        "aria-label": "Section Date Retired",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={
                        pipelinesectionvalues.SectionDateRetired === undefined
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                spacing="3"
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs="3">
                  <TextField
                    label="CriticalIndicator"
                    id="criticalindicatorId"
                    onChange={fnHandleChange("CriticalIndicator")}
                    value={pipelinesectionvalues.CriticalIndicator}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinesectionvalues.CriticalIndicator === ""}
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="SectionStubbed"
                    id="sectionstubbedId"
                    onChange={fnHandleChange("SectionStubbed")}
                    value={pipelinesectionvalues.SectionStubbed}
                    variant="outlined"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinesectionvalues.SectionStubbed === ""}
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="PressureUnits"
                    id="pressureunitsId"
                    onChange={fnHandleChange("PressureUnits")}
                    value={pipelinesectionvalues.PressureUnits}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinesectionvalues.PressureUnits === ""}
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="LengthUnits"
                    id="lengthunitsId"
                    onChange={fnHandleChange("LengthUnits")}
                    value={pipelinesectionvalues.LengthUnits}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinesectionvalues.LengthUnits === ""}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                spacing="3"
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs="3"></Grid>
                <Grid item xs="3">
                  <TextField
                    label="PipesurfaceAreaUnits"
                    id="pipesurfaceareaunitsId"
                    onChange={fnHandleChange("PipesurfaceAreaUnits")}
                    value={pipelinesectionvalues.PipesurfaceAreaUnits}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinesectionvalues.PipesurfaceAreaUnits === ""}
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="PipeVolumeUnits"
                    id="pipevolumeunitsId"
                    onChange={fnHandleChange("PipeVolumeUnits")}
                    value={pipelinesectionvalues.PipeVolumeUnits}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinesectionvalues.PipeVolumeUnits === ""}
                  />
                </Grid>
                <Grid item xs="3">
                  <TextField
                    label="WallThickness"
                    id="wallthicknessId"
                    onChange={fnHandleChange("WallThickness")}
                    value={pipelinesectionvalues.WallThickness}
                    variant="outlined"
                    size="small"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={pipelinesectionvalues.WallThickness === ""}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    className={classes.updatebutton}
                    disabled={
                      pipelinesectionvalues.ToStateNameId === null ||
                      pipelinesectionvalues.ToCountyId === null ||
                      pipelinesectionvalues.FromStateNameId === null ||
                      pipelinesectionvalues.FromCountyId === null ||
                      pipelinesectionvalues.SegmentId === "" ||
                      pipelinesectionvalues.SegmentId === 0 ||
                      pipelinesectionvalues.SegmentId < 1
                    }
                    onClick={() => fnUpdatePipelineSectionData()}
                  >
                    Update Pipeline Section {pipelinesectionvalues.SegmentId}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(PipelineSectionUpdateComp);
