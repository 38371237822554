import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const PrivateRoute = ({ component: Component, auth, children, ...rest }) => {
	const token = useSelector((state) => state.userReducer.token);
	const isadmin = useSelector((state) => state.userReducer.isadmin);

	const dispatch = useDispatch();

	const validateRoute = (props) => {

		// if (props.location.pathname !== '/admin' && isadmin) {
		// 	return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
		// } else if (props.location.pathname === '/admin' && !isadmin) {
		// 	return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
		// } else {
			
		// }

		return <Component {...props} />;
	};

	return (
		<Route
			{...rest}
			render={(props) =>
				token ? validateRoute(props) : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
			}
		/>
	);
};

export default PrivateRoute;
