import React, { Suspense } from 'react';

import Grid from '@material-ui/core/Grid';

import LinearProgress from '@material-ui/core/LinearProgress';

import { makeStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router-dom';

const IndustrialPlantSearchComponent = React.lazy(
	() =>
		new Promise((resolve, reject) =>
			setTimeout(() => resolve(import('./subcomponents/IndustrialPlantSearchComp')), 100)
		)
);

const IndustrialPlantSubComp = React.lazy(
	() =>
		new Promise((resolve, reject) =>
			setTimeout(() => resolve(import('./subcomponents/IndustrialPlantSubComp')), 100)
		)
);

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
	},
}));

function IndustrialPlantComp(props) {
	const classes = useStyles();

	const [searchindustrialplantquery, setSearchIndustrialPlantQuery] = React.useState('');

	return (
		<>
			<Grid container>
				<Suspense
					fallback={
						<>
							<LinearProgress />
						</>
					}
				>
					<IndustrialPlantSearchComponent
						setSearchIndustrialPlantQuery={setSearchIndustrialPlantQuery}
					/>
				</Suspense>
			</Grid>
			<Grid container>
				<Suspense
					fallback={
						<>
							<LinearProgress />
						</>
					}
				>
					<IndustrialPlantSubComp searchindustrialplantquery={searchindustrialplantquery} />
				</Suspense>
			</Grid>
		</>
	);
}

export default withRouter(IndustrialPlantComp);
