import React, { useEffect } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';

import 'antd/dist/antd.css';

import { Select } from 'antd';

import AlertNotificationComp from 'components/custom/AlertNotificationComp';

//linq
import linq from 'linq';

//Redux imports
import { useSelector } from 'react-redux';

//Api
import axios from 'axios';

import { withRouter } from 'react-router-dom';

const { Option } = Select;

function LookupComp(props) {
	const token = useSelector((state) => state.userReducer.token);

	const [status, setStatusBase] = React.useState({
		show: false,
		message: '',
	});

	const mappingReducers = {
		OperatorName: 'operatorsReducer',
		CityName: 'citynamesReducer',
		CountyName: 'countynamesReducer',
		StateName: 'statenamesReducer',
		OperatorType: 'operatortypesReducer',
		Zipcode: 'zipcodesReducer',
	};

	const [values, setValues] = React.useState([]);
	const [selectedvalue, setSelectedValue] = React.useState('');

	var dbtype =
		props.lookuptype === 'Company1' || props.lookuptype === 'Company2' || props.lookuptype === 'Company3'
			? 'OperatorName'
			: props.lookuptype;

	console.log('dbtype ' + dbtype);

	var reduxvalues = useSelector((state) => state[mappingReducers[dbtype]][dbtype]);

	const onChange = (value) => {
		// console.log(`selected ${value}`);
		setSelectedValue(value);

		props.fnSetValues(props.lookuptype, props.lookupid, value[0], value[1]);
	};

	const fnShowErrorMessage = () => {
		setStatusBase({ show: false, message: '' });
	};

	const fnFetchLookupValues = () => {
		const data = {
			fetchtype: dbtype,
		};

		const options = {
			headers: {
				authorization: token ? `Bearer ${token}` : '',
			},
		};

		axios.post('/api/fetchuniquevalues', data, options).then(
			(response) => {
				if (response.data[0].status === 'error') {
					setStatusBase({
						show: true,
						message: `Error while fetching ${props.lookuptype}`,
						variant: 'error',
					});
				} else if (response.data[0].data.length > 0) {
					if (
						props.lookuptype === 'Company1' ||
						props.lookuptype === 'Company2' ||
						props.lookuptype === 'Company3'
					) {
						var data = [];
						response.data[0].data.forEach((element) => {
							data.push({
								[props.lookuptype]: element.OperatorName,
								[props.lookupid]: element.OperatorId,
							});
						});
						setValues(data);
					} else {
						setValues(response.data[0].data);
					}
				} else {
					setStatusBase({ show: true, message: `${props.lookuptype}s not found`, variant: 'error' });
				}
			},
			(error) => {
				if (error.request.status === 500) {
					setStatusBase({ show: true, message: 'Time out', variant: 'error' });
					props.history.push('/login');
				} else {
					setStatusBase({
						show: true,
						message: `Error while fetching ${props.lookuptype}s`,
						variant: 'error',
					});
				}
			}
		);
	};

	useEffect(() => {
		if (reduxvalues !== undefined && reduxvalues.length > 0) {
			// console.log('dbtype ' + dbtype);
			// console.log('effect' + reduxvalues);

			if (props.lookuptype === 'Company1' || props.lookuptype === 'Company2' || props.lookuptype === 'Company3') {
				var data = [];
				reduxvalues.forEach((element) => {
					data.push({
						[props.lookuptype]: element.OperatorName,
						[props.lookupid]: element.OperatorId,
					});
				});
				setValues(data);
			} else {
				setValues(reduxvalues);
			}
		} else {
			fnFetchLookupValues();
		}
	}, []);

	useEffect(() => {
		if (values.length > 0 && props.lookupvalue !== undefined) {
			setSelectedValue(props.lookupvalue);
		}
	}, [values]);

	return (
		<>
			{values.length > 0 ? (
				<Grid>
					<Select
						showSearch
						style={{ minWidth: 100 }}
						placeholder="Select"
						optionFilterProp="children"
						onChange={onChange}
						value={selectedvalue}
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						<Option key="-1" value="">
							select
						</Option>
						{values.map((item, index) => (
							<Option key={item[props.lookupid]} value={[item[props.lookuptype], item[props.lookupid]]}>
								{item[props.lookuptype]}
							</Option>
						))}
					</Select>
				</Grid>
			) : null}

			<Snackbar
				autoHideDuration={6000}
				open={status.show}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={() => fnShowErrorMessage()}
			>
				<AlertNotificationComp
					variant="error"
					onClose={() => fnShowErrorMessage()}
					message="Error"
				></AlertNotificationComp>
			</Snackbar>
		</>
	);
}

export default withRouter(LookupComp);
