import React, { Suspense } from 'react';

import Grid from '@material-ui/core/Grid';

import LinearProgress from '@material-ui/core/LinearProgress';

import { makeStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router-dom';

const PowerPlantSearchComponent = React.lazy(
	() =>
		new Promise((resolve, reject) =>
			setTimeout(() => resolve(import('./subcomponents/PowerPlantSearchComp')), 100)
		)
);

const PowerPlantSubComp = React.lazy(
	() =>
		new Promise((resolve, reject) =>
			setTimeout(() => resolve(import('./subcomponents/PowerPlantSubComp')), 100)
		)
);

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
	},
}));

function PowerPlantComp(props) {
	const classes = useStyles();

	const [searchpowerplantquery, setSearchPowerPlantQuery] = React.useState('');

	return (
		<>
			<Grid container>
				<Suspense
					fallback={
						<>
							<LinearProgress />
						</>
					}
				>
					<PowerPlantSearchComponent
						setSearchPowerPlantQuery={setSearchPowerPlantQuery}
					/>
				</Suspense>
			</Grid>
			<Grid container>
				<Suspense
					fallback={
						<>
							<LinearProgress />
						</>
					}
				>
					<PowerPlantSubComp searchpowerplantquery={searchpowerplantquery} />
				</Suspense>
			</Grid>
		</>
	);
}

export default withRouter(PowerPlantComp);
