import React, { useEffect, useRef } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import AlertNotificationComp from "components/custom/AlertNotificationComp";

import { customizedDateFormat } from "constants/utils";

import { plantstatus } from "lookuptables/global";

//date
import moment from "moment";

//linq
import linq from "linq";

//Redux imports
import { useSelector } from "react-redux";

//Api
import axios from "axios";

//Redux imports
import { useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },
  operatoraddcontainer: {
    "& .MuiPaper-root": {
      width: "100%",
      padding: 10,
      margin: 10,
    },
  },
  formcontainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-container": {
      marginBottom: 3.5,
    },
  },

  operatorlist: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    maxHeight: 700,
    minHeight: 700,
  },
  operatordata: {
    padding: 10,
    height: 700,
    maxHeight: 700,
  },
  updatebutton: {
    width: "300px",
    marginTop: "15px",
  },
}));

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

function AddTerminalTransportComp(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const [terminals, setTerminals] = React.useState([]);
  const [selectedterminal, setSelectedTerminal] = React.useState({});

  const [terminaltransportdata, setTerminalTransportData] = React.useState([
    {
      TerminalHeaderId: 1,
      ModeOfTransportId: 4,
      ModeOfTransport: "Rail",
      In: "False",
      Out: "False",
    },
    {
      TerminalHeaderId: 1,
      ModeOfTransportId: 1,
      ModeOfTransport: "Truck",
      In: "False",
      Out: "False",
    },
    {
      TerminalHeaderId: 1,
      ModeOfTransportId: 3,
      ModeOfTransport: "Marine",
      In: "False",
      Out: "False",
    },
    {
      TerminalHeaderId: 1,
      ModeOfTransportId: 2,
      ModeOfTransport: "Pipeline",
      In: "False",
      Out: "False",
    },
  ]);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "error",
  });

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "info" });
  };

  const fnCheckChange = (event, optionindex) => {
    setTerminalTransportData((terminaltransportdata) => {
      return terminaltransportdata.map((x, index) => {
        if (index !== optionindex) return x;
        return {
          ...x,
          [event.target.name]: event.target.checked ? "True" : "False",
        };
      });
    });
  };

  const fnOnChange = (newvalue, type, typeid) => {
    if (newvalue !== null && newvalue !== undefined) {
      switch (type) {
        case "TerminalName":
          setSelectedTerminal(newvalue);
          break;
      }
    }
  };

  const fnInsertTerminalTransport = () => {
    const data = {
      RailIn: 0,
      RailOut: 0,
      TruckIn: 0,
      TruckOut: 0,
      MarineIn: 0,
      MarineOut: 0,
      PipelineIn: 0,
      PipelineOut: 0,
      TerminalHeaderId: selectedterminal.TerminalHeaderId,
    };

    terminaltransportdata.forEach((element) => {
      switch (element.ModeOfTransport) {
        case "Rail":
          data.RailIn = element.In === "True" ? 1 : 0;
          data.RailOut = element.Out === "True" ? 1 : 0;

          break;
        case "Truck":
          data.TruckIn = element.In === "True" ? 1 : 0;
          data.TruckOut = element.Out === "True" ? 1 : 0;

          break;
        case "Marine":
          data.MarineIn = element.In === "True" ? 1 : 0;
          data.MarineOut = element.Out === "True" ? 1 : 0;

          break;
        case "Pipeline":
          data.PipelineIn = element.In === "True" ? 1 : 0;
          data.PipelineOut = element.Out === "True" ? 1 : 0;

          break;
      }
    });

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/insertterminaltransport", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while inserting",
              variant: "error",
            });
          } else if (response.data[0].status === "success") {
            setStatusBase({
              show: true,
              message: "Data inserted successfully",
              variant: "info",
            });
          }
        } else {
          setStatusBase({
            show: true,
            message:
              "Error while inserting terminal transport data. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting terminal transport data",
            variant: "error",
          });
        }
      }
    );
  };

  const fnFetchLookupValues = (fetchtype) => {
    const data = {
      fetchtype: fetchtype,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchuniquevalues", data, options).then(
      (response) => {
        // console.log(response.data[0]);
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            var data = [];

            if (fetchtype === "TerminalName") {
              response.data[0].data.forEach((element) => {
                data.push({
                  TerminalName: element["TerminalName"],
                  TerminalHeaderId: element["TerminalHeaderId"],
                });
              });

              setTerminals(data);
            }
          } else {
          }
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    fnFetchLookupValues("TerminalName");
  }, []);

  return (
    <>
      <Grid className={classes.operatoraddcontainer} container>
        <Paper>
          <Typography variant="subtitle1">Add Terminal Unit Data</Typography>
          <ValidatorForm>
            {terminaltransportdata.length > 0 && (
              <>
                <Grid
                  container
                  spacing="3"
                  direction="column"
                  className={classes.formcontainer}
                >
                  <Grid item>
                    <Grid
                      container
                      spacing="3"
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs="3">
                        <Autocomplete
                          size="small"
                          options={terminals}
                          getOptionLabel={(option) =>
                            option.TerminalName === undefined
                              ? ""
                              : option.TerminalName
                          }
                          value={selectedterminal}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              fnOnChange(
                                newValue,
                                "TerminalName",
                                "TerminalHeaderId"
                              );
                            } else {
                              setSelectedTerminal({});
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label={"Select Terminal"}
                              placeholder="Terminal"
                              error={
                                selectedterminal.TerminalName === undefined
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  {/* {JSON.stringify(terminaltransportdata)} */}
                  {terminaltransportdata.map((item, index) => {
                    return (
                      <>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Grid item xs={4}>
                            {item.ModeOfTransport}
                          </Grid>
                          <Grid item xs={2}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={item.In === "False" ? false : true}
                                  onChange={(e) => fnCheckChange(e, index)}
                                  name="In"
                                  color="primary"
                                />
                              }
                              label="In"
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={item.Out === "False" ? false : true}
                                  onChange={(e) => fnCheckChange(e, index)}
                                  name="Out"
                                  color="primary"
                                />
                              }
                              label="Out"
                            />
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid item>
                      <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        className={classes.updatebutton}
                        onClick={() => fnInsertTerminalTransport()}
                        disabled={selectedterminal.TerminalName === undefined}
                      >
                        Insert Terminal Transport
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </ValidatorForm>
        </Paper>
      </Grid>
      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(AddTerminalTransportComp);
