import React from 'react';
import Grid from '@material-ui/core/Grid';
import { clear } from 'redux-localstorage-simple';

import LoginComp from 'components/user/LoginComp';

const loginStyles = {
	login: {
		height: '100%',
	},
};

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		localStorage.clear();
		clear();
	}

	render() {
		return (
			<React.Fragment>
				<Grid container style={loginStyles.login} direction="column" justify="center" alignItems="center">
					<Grid item align="center">
						<LoginComp history={this.props.history}></LoginComp>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	}
}

export default Login;
