import React, { Suspense, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import Snackbar from "@material-ui/core/Snackbar";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import { Space, Table } from "antd";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import MUIDataTable from "mui-datatables";

import LinearProgress from "@material-ui/core/LinearProgress";

import { makeStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";

import LookupInsertComp from "components/common/LookupInsertComp";
import AlertNotificationComp from "components/custom/AlertNotificationComp";

//date
import moment from "moment";

//linq
import linq from "linq";

//Api
import axios from "axios";

//Redux imports
import { useSelector, useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import {
    tariffstatus,
    tariffratetype,
    tarifffueltype,
} from "lookuptables/global";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        display: "flex",
    },
    operatoraddcontainer: {
        "& .MuiPaper-root": {
            width: "100%",
            padding: 10,
            margin: 10,
        },
    },
    formcontainer: {
        "& .MuiFormControl-root": {
            width: "100%",
        },
        "& .MuiGrid-container": {
            // marginBottom: 10,
        },
    },
    updatebutton: {
        width: "100%",
    },
    operatortable: {
        width: "100%",
        "& .ant-table-title": {
            textTransform: "capitalize",
        },
        "& .editable-row .ant-form-item-explain": {
            position: "absolute",
            top: "100%",
        },
    },
    operatordatacontainer: {
        margin: 10,
        width: "98.5%",
        "& .MuiPaper-root": {
            width: "100%",
        },
    },
}));

function EditTariffDetailComp(props) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const token = useSelector((state) => state.userReducer.token);

    const [status, setStatusBase] = React.useState({
        show: false,
        message: "",
        variant: "error",
    });

    const fnShowErrorMessage = () => {
        setStatusBase({ show: false, message: "", variant: "error" });
    };

    const tariffdetailinitialstate = {
        LiquidTariffDetailsId: "",
        LiquidTariffNumber: "",
        EffectiveDate: null,
        EndDate: null,
        LiquidTariffStatusId: "",
        LiquidTariffStatus: "",
        LiquidTariffRateTypeId: "",
        LiquidTariffRateType: "",
        TermYear: "",
        BPDMin: "",
        BPDMax: "",
        AcreageDedicationMinAcres: "",
        AcreageDedicationMaxAcres: "",
        LiquidRateCentsPerBbl: "",
        SurchargeCentsPerBbl: "",
        LiquidTariffFuelTypeId: "",
        LiquidTariffFuelType: "",
    };

    const [tariffdetailvalues, setTariffDetailValues] = React.useState(
        tariffdetailinitialstate
    );

    const [selectedtarifffueltype, setSelectedTariffFuelType] = React.useState({});

    const [selectedtariffstatus, setSelectedTariffStatus] = React.useState({});

    const [selectedtariffratetype, setSelectedTariffRateType] = React.useState({});

    const fnHandleDetailsChange = (prop) => (event) => {
        setTariffDetailValues({
            ...tariffdetailvalues,
            [prop]: event.target.value,
        });
    };

    const fnHandleEffectiveDateChange = (date) => {
        if (date !== null) {
            let modifieddate = moment(date);
            setTariffDetailValues({
                ...tariffdetailvalues,
                EffectiveDate: modifieddate.format("YYYY-MM-DD"),
            });
        } else {
            setTariffDetailValues({
                ...tariffdetailvalues,
                EffectiveDate: null,
            });
        }
    };

    const fnHandleEndDateChange = (date) => {
        if (date !== null) {
            let modifieddate = moment(date);
            setTariffDetailValues({
                ...tariffdetailvalues,
                EndDate: modifieddate.format("YYYY-MM-DD"),
            });
        } else {
            setTariffDetailValues({
                ...tariffdetailvalues,
                EndDate: null,
            });
        }
    };

    const fnOnChange = (newvalue, type, typeid) => {
        if (newvalue !== null && newvalue !== undefined) {
            switch (type) {

                case "LiquidTariffStatus":
                    setSelectedTariffStatus(newvalue);
                    setTariffDetailValues({
                        ...tariffdetailvalues,
                        ["LiquidTariffStatusId"]: newvalue.LiquidTariffStatusId,
                        ["LiquidTariffStatus"]: newvalue.LiquidTariffStatus,
                    });
                    break;
                case "LiquidTariffRateType":
                    setSelectedTariffRateType(newvalue);
                    setTariffDetailValues({
                        ...tariffdetailvalues,
                        ["LiquidTariffRateTypeId"]: newvalue.LiquidTariffRateTypeId,
                        ["LiquidTariffRateType"]: newvalue.LiquidTariffRateType,
                    });
                    break;
                case "LiquidTariffFuelType":
                    setSelectedTariffFuelType(newvalue);
                    setTariffDetailValues({
                        ...tariffdetailvalues,
                        ["LiquidTariffFuelTypeId"]: newvalue.LiquidTariffFuelTypeId,
                        ["LiquidTariffFuelType"]: newvalue.LiquidTariffFuelType,
                    });
                    break;
            }
        }
    };

    const fnUpdateTariffDetailData = () => {

        dispatch({
            type: actionType.LOADINGSPINNER,
            payload: { loading: true },
        });

        var tariffdetailkeys = Object.keys(tariffdetailinitialstate);

        var updatevalues = [];
        tariffdetailkeys.forEach((element) => {

            if (element !== "LiquidTariffStatus" &&
                element !== "LiquidTariffRateType" &&
                element !== "LiquidTariffFuelType" &&
                element !== "LiquidTariffDetailsId") {


                if (
                    tariffdetailvalues[element] !== undefined &&
                    tariffdetailvalues[element] !== null &&
                    tariffdetailvalues[element] !== ""
                ) {
                    if (element.indexOf("Date") > -1) {
                        updatevalues.push(
                            element +
                            " = '" +
                            moment(tariffdetailvalues[element]).format("YYYY-MM-DD") +
                            "'"
                        );
                    } else {
                        if (typeof tariffdetailvalues[element] === "string") {
                            updatevalues.push(
                                element + " = '" + tariffdetailvalues[element] + "'"
                            );
                        } else {
                            updatevalues.push(element + " = " + tariffdetailvalues[element]);
                        }
                    }
                } else {
                    updatevalues.push(element + " = null");
                }
            }
        });



        

        const data = {
            tablename: "LiquidTariffDetails",
            query: updatevalues.join(","),
            key: tariffdetailvalues.LiquidTariffDetailsId
        };

        const options = {
            headers: {
                authorization: token ? `Bearer ${token}` : "",
            },
        };

        axios.post("/api/updateheaderdata", data, options).then(
            (response) => {
                dispatch({
                    type: actionType.LOADINGSPINNER,
                    payload: { loading: false },
                });

                if (response.data[0].status.code === undefined) {
                    if (response.data[0].status === "error") {
                        setStatusBase({
                            show: true,
                            message: "Error while updating",
                            variant: "error",
                        });
                    } else if (response.data[0].status === "success") {
                        setStatusBase({
                            show: true,
                            message: "Data updated successfully",
                            variant: "info",
                        });

                        props.fnclosedetaileditdialog();
                    }


                } else {
                    setStatusBase({
                        show: true,
                        message:
                            "Error while updating tariff detail data. Please try again.",
                        variant: "error",
                    });
                }
            },
            (error) => {
                dispatch({
                    type: actionType.LOADINGSPINNER,
                    payload: { loading: false },
                });

                if (error.request.status === 500) {
                    setStatusBase({ show: true, message: "Time out", variant: "error" });
                    props.history.push("/login");
                } else {
                    setStatusBase({
                        show: true,
                        message: "Error while updating tariff detail data",
                        variant: "error",
                    });
                }
            }
        );

    }

    useEffect(() => {

        let gastariffdata = props.data;

        var resultobj = JSON.parse(
            JSON.stringify(tariffdetailinitialstate)
        );

        Object.keys(gastariffdata).forEach((element) => {
            if (
                gastariffdata[element] !== null &&
                gastariffdata[element] !== undefined
            ) {



                if (element === "LiquidTariffStatus") {
                    var selectedvalue = linq
                        .from(tariffstatus)
                        .where((a) => a.LiquidTariffStatus == gastariffdata[element])
                        .toArray();

                    setSelectedTariffStatus({
                        LiquidTariffStatus: selectedvalue[0].LiquidTariffStatus,
                        LiquidTariffStatusId: selectedvalue[0].LiquidTariffStatusId,
                    });

                    resultobj.LiquidTariffStatusId = selectedvalue[0].LiquidTariffStatusId;


                }

                if (element === "LiquidTariffRateType") {
                    var selectedvalue = linq
                        .from(tariffratetype)
                        .where((a) => a.LiquidTariffRateType == gastariffdata[element])
                        .toArray();

                    setSelectedTariffRateType({
                        LiquidTariffRateType: selectedvalue[0].LiquidTariffRateType,
                        LiquidTariffRateTypeId: selectedvalue[0].LiquidTariffRateTypeId,
                    });

                    resultobj.LiquidTariffRateTypeId = selectedvalue[0].LiquidTariffRateTypeId;
                }

                if (element === "LiquidTariffFuelType") {
                    var selectedvalue = linq
                        .from(tarifffueltype)
                        .where((a) => a.LiquidTariffFuelType == gastariffdata[element])
                        .toArray();

                    setSelectedTariffFuelType({
                        LiquidTariffFuelType: selectedvalue[0].LiquidTariffFuelType,
                        LiquidTariffFuelTypeId: selectedvalue[0].LiquidTariffFuelTypeId,
                    });

                    resultobj.LiquidTariffFuelTypeId = selectedvalue[0].LiquidTariffFuelTypeId;
                }

                resultobj[element] = gastariffdata[element];
            }
        });

        setTariffDetailValues(resultobj);
    }, [])

    return (
        <>
            <Grid className={classes.operatoraddcontainer} container>
                <Paper>

                    <ValidatorForm>
                        <>
                            <Grid
                                container
                                spacing="3"
                                direction="column"
                                className={classes.formcontainer}
                            >
                                <Grid item>
                                    <Grid
                                        container
                                        spacing="3"
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                    >
                                        <Grid item xs="3">
                                            <TextField
                                                label="TariffNumber"
                                                id="TariffNumberId"
                                                onChange={fnHandleDetailsChange(
                                                    "LiquidTariffNumber"
                                                )}
                                                value={tariffdetailvalues.LiquidTariffNumber}
                                                variant="outlined"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs="3">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    format="yyyy/MM/dd"
                                                    margin="normal"
                                                    id="EffectiveDateId"
                                                    label="EffectiveDate"
                                                    variant="outlined"
                                                    value={tariffdetailvalues.EffectiveDate}
                                                    onChange={fnHandleEffectiveDateChange}
                                                    KeyboardButtonProps={{
                                                        "aria-label": "EffectiveDate",
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs="3">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    format="yyyy/MM/dd"
                                                    margin="normal"
                                                    id="EndDateId"
                                                    label="EndDate"
                                                    variant="outlined"
                                                    value={tariffdetailvalues.EndDate}
                                                    onChange={fnHandleEndDateChange}
                                                    KeyboardButtonProps={{
                                                        "aria-label": "EndDate",
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs="3">
                                            <Autocomplete
                                                size="small"
                                                options={tariffstatus}
                                                getOptionLabel={(option) =>
                                                    option.LiquidTariffStatus === undefined
                                                        ? ""
                                                        : option.LiquidTariffStatus
                                                }
                                                value={selectedtariffstatus}
                                                onChange={(event, newValue) => {
                                                    if (newValue !== null) {
                                                        fnOnChange(
                                                            newValue,
                                                            "LiquidTariffStatus",
                                                            "LiquidTariffStatusId"
                                                        );
                                                    } else {
                                                        setSelectedTariffStatus({});
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label={"Select TariffStatus"}
                                                        placeholder="TariffStatus"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        spacing="3"
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                    >
                                        <Grid item xs="3">
                                            <Autocomplete
                                                size="small"
                                                options={tariffratetype}
                                                getOptionLabel={(option) =>
                                                    option.LiquidTariffRateType === undefined
                                                        ? ""
                                                        : option.LiquidTariffRateType
                                                }
                                                value={selectedtariffratetype}
                                                onChange={(event, newValue) => {
                                                    if (newValue !== null) {
                                                        fnOnChange(
                                                            newValue,
                                                            "LiquidTariffRateType",
                                                            "LiquidTariffRateTypeId"
                                                        );
                                                    } else {
                                                        setSelectedTariffRateType({});
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label={"Select TariffRateType"}
                                                        placeholder="TariffRateType"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs="3">
                                            <TextField
                                                label="TermYear"
                                                id="TermYearId"
                                                onChange={fnHandleDetailsChange("TermYear")}
                                                value={tariffdetailvalues.TermYear}
                                                variant="outlined"
                                                size="small"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs="3">
                                            <TextField
                                                label="BPDMin"
                                                id="BPDMinId"
                                                onChange={fnHandleDetailsChange("BPDMin")}
                                                value={tariffdetailvalues.BPDMin}
                                                variant="outlined"
                                                size="small"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs="3">
                                            <TextField
                                                label="BPDMax"
                                                id="BPDMaxId"
                                                type="number"
                                                onChange={fnHandleDetailsChange("BPDMax")}
                                                value={tariffdetailvalues.BPDMax}
                                                variant="outlined"
                                                size="small"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        spacing="3"
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                    >
                                        <Grid item xs="3">
                                            <TextField
                                                label="AcreageDedicationMinAcres"
                                                id="AcreageDedicationMinAcresId"
                                                onChange={fnHandleDetailsChange(
                                                    "AcreageDedicationMinAcres"
                                                )}
                                                value={tariffdetailvalues.AcreageDedicationMinAcres}
                                                variant="outlined"
                                                size="small"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs="3">
                                            <TextField
                                                label="AcreageDedicationMaxAcres"
                                                id="AcreageDedicationMaxAcresId"
                                                onChange={fnHandleDetailsChange(
                                                    "AcreageDedicationMaxAcres"
                                                )}
                                                value={tariffdetailvalues.AcreageDedicationMaxAcres}
                                                variant="outlined"
                                                size="small"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        spacing="3"
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                    >
                                        <Grid item xs="3">
                                            <TextField
                                                label="LiquidRateCentsPerBbl"
                                                id="LiquidRateCentsPerBblId"
                                                onChange={fnHandleDetailsChange(
                                                    "LiquidRateCentsPerBbl"
                                                )}
                                                value={tariffdetailvalues.LiquidRateCentsPerBbl}
                                                variant="outlined"
                                                size="small"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs="3">
                                            <TextField
                                                label="SurchargeCentsPerBbl"
                                                id="SurchargeCentsPerBblId"
                                                onChange={fnHandleDetailsChange(
                                                    "SurchargeCentsPerBbl"
                                                )}
                                                value={tariffdetailvalues.SurchargeCentsPerBbl}
                                                variant="outlined"
                                                size="small"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs="3">
                                            <Autocomplete
                                                size="small"
                                                options={tarifffueltype}
                                                getOptionLabel={(option) =>
                                                    option.LiquidTariffFuelType === undefined
                                                        ? ""
                                                        : option.LiquidTariffFuelType
                                                }
                                                value={selectedtarifffueltype}
                                                onChange={(event, newValue) => {
                                                    if (newValue !== null) {
                                                        fnOnChange(
                                                            newValue,
                                                            "LiquidTariffFuelType",
                                                            "LiquidTariffFuelTypeId"
                                                        );
                                                    } else {
                                                        setSelectedTariffFuelType({});
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label={"Select TariffFuelType"}
                                                        placeholder="TariffFuelType"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs="3">
                                            <Button
                                                type="button"
                                                color="primary"
                                                variant="contained"
                                                className={classes.updatebutton}
                                                disabled={
                                                    tariffdetailvalues.LiquidTariffRateType ===
                                                    "" ||
                                                    tariffdetailvalues.LiquidTariffStatus === "" ||
                                                    tariffdetailvalues.LiquidTariffNumber === ""
                                                }
                                                onClick={() => fnUpdateTariffDetailData()}
                                            >
                                                Update Tariff Details
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    </ValidatorForm>

                </Paper>
            </Grid>

            <Snackbar
                autoHideDuration={6000}
                open={status.show}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                onClose={() => fnShowErrorMessage()}
            >
                <AlertNotificationComp
                    variant={status.variant}
                    onClose={() => fnShowErrorMessage()}
                    message={status.message}
                ></AlertNotificationComp>
            </Snackbar>
        </>
    );
}

export default withRouter(EditTariffDetailComp);
