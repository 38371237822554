import React from 'react';

import { useSelector } from 'react-redux';

import 'assets/styles/customloader.css';

const loaderStyles = {
	root: {
		backgroundColor: 'rgba(171, 205, 239, 0.5)',
	},
};

function LoadingSpinnerComp() {
	const isLoading = useSelector((state) => state.spinnerReducer.loading);

	return (
		<React.Fragment>
			{isLoading ? (
				<div className="loader-wrapper" style={loaderStyles.root}>
					<div className="loader"></div>
				</div>
			) : null}
		</React.Fragment>
	);
}

export default LoadingSpinnerComp;