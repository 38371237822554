import React, { Suspense, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import Snackbar from "@material-ui/core/Snackbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import LinearProgress from "@material-ui/core/LinearProgress";

import { makeStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";

import LookupInsertComp from "components/common/LookupInsertComp";
import AlertNotificationComp from "components/custom/AlertNotificationComp";

//date
import moment from "moment";

//linq
import linq from "linq";

//Api
import axios from "axios";

//Redux imports
import { useSelector, useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import { plantstatus } from "lookuptables/global";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },
  operatoraddcontainer: {
    "& .MuiPaper-root": {
      width: "100%",
      padding: 10,
      margin: 10,
    },
  },
  formcontainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-container": {
      // marginBottom: 10,
    },
  },
  updatebutton: {
    width: "100%",
  },
}));

function AddCCUSComp(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const [ccuss, setCCUSs] = React.useState([]);

  const citylookupvalues = useSelector(
    (state) => state.citynamesReducer.CityName
  );
  const countylookupvalues = useSelector(
    (state) => state.countynamesReducer.CountyName
  );
  const statelookupvalues = useSelector(
    (state) => state.statenamesReducer.StateName
  );
  const zipcodelookupvalues = useSelector(
    (state) => state.zipcodesReducer.Zipcode
  );
  const operatorlookupvalues = useSelector(
    (state) => state.operatorsReducer.OperatorName
  );
  const basinlookupvalues = useSelector(
    (state) => state.basinsReducer.BasinName
  );

  const [statecounties, setStateCounties] = React.useState([]);

  const [pipelinenames, setPipelineNames] = React.useState([]);

  const [selectedcity, setSelectedCity] = React.useState({});
  const [selectedcounty, setSelectedCounty] = React.useState({});
  const [selectedstate, setSelectedState] = React.useState({});
  const [selectedzipcode, setSelectedZipcode] = React.useState({});
  const [selectedbasin, setSelectedBasin] = React.useState({});
  const [selectedowner, setSelectedOwner] = React.useState({});
  const [selectedoperator, setSelectedOperator] = React.useState({});
  const [selectedStatus, setSelectedStatus] = React.useState({});
  const [selectedccustype, setSelectedCCUSType] = React.useState({});

  const [pipelinelocationcode, setPipelineLocationCode] = React.useState("");
  const [selectedpipeline, setSelectedPipeline] = React.useState({});

  const ccusinitialstate = {
    CCUSName: "",
    CityName: null,
    CityId: null,
    CountyName: null,
    CountyId: null,
    StateName: null,
    StateNameId: null,
    Zipcode: null,
    ZipcodeId: null,
    Basin: null,
    BasinId: null,
    OperatorName: null,
    OperatorId: null,
    Owner: null,
    OwnerId: null,
    Latitude: "",
    Longitude: "",
    CCUSFunction: null,
    CCUSFunctionId: null,
    CaptureType: null,
    CaptureTypeId: null,
    CaptureTechnology: "",
    CO2EndUse: null,
    CO2EndUseId: null,
    CO2CaptureStoreMMTPA: "",
    InceptionDate: null,
    LocationQualifier: null,
    LocationQualifierId: null,
    CO2TransportMode: null,
    CO2TransportModeId: null,
    FacilityId: null,
    FacilityType: null,
    FacilityTypeId: null,
    EPCContractor: "",
    EmissionGHGRPID: "",
    Remarks: "",
    key: 0,
  };

  const [ccusvalues, setCCUSValues] = React.useState(ccusinitialstate);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "error",
  });

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "error" });
  };

  const fnHandleChange = (prop) => (event) => {
    if (prop === "PipelineLocationCode") {
      setPipelineLocationCode(event.target.value);
    } else {
      setCCUSValues({
        ...ccusvalues,
        [prop]: event.target.value,
      });
    }
  };

  const fnOnChange = (newvalue, type, typeid) => {
    if (newvalue !== null && newvalue !== undefined) {
      if (type === "Owner" || type === "Operator") {
        setCCUSValues({
          ...ccusvalues,
          [type]: newvalue["OperatorName"],
          [typeid]: newvalue["OperatorId"],
        });
      } else {
        setCCUSValues({
          ...ccusvalues,
          [type]: newvalue[type],
          [typeid]: newvalue[typeid],
        });
      }

      switch (type) {
        case "CityName":
          setSelectedCity(newvalue);
          break;
        case "CountyName":
          setSelectedCounty(newvalue);
          break;
        case "StateName":
          setSelectedState(newvalue);
          setSelectedCounty({});
          var filtercounties = linq
            .from(countylookupvalues)
            .where((a) => a.StateNameId == newvalue.StateNameId)
            .toArray();
          setStateCounties(filtercounties);
          break;
        case "Zipcode":
          setSelectedZipcode(newvalue);
          break;
        case "Operator":
          setSelectedOperator(newvalue);
          break;
        case "Owner":
          setSelectedOwner(newvalue);
          break;
        case "BasinName":
          setSelectedBasin(newvalue);
          break;
        case "Status":
          setSelectedStatus(newvalue);
          break;
        case "CCUSFunction":
          setSelectedCCUSFunction(newvalue);
          break;
        case "CaptureType":
          setSelectedCaptureType(newvalue);
          break;
        case "CO2EndUse":
          setSelectedCO2EndUse(newvalue);
          break;
        case "LocationQualifier":
          setSelectedLocationQualifier(newvalue);
          break;
        case "CO2TransportMode":
          setSelectedCO2TransportMode(newvalue);
          break;
        case "FacilityType":
          setSelectedFacilityType(newvalue);
          setSelectedFacility({});
          fnFetchLookupValues("Facility", newvalue.FacilityType);

          break;
        case "Facility":
          setSelectedFacility(newvalue);
          break;
      }
    }
  };

  const fnInsertCCUSData = () => {
    var ccuskeys = [
      "CCUSName",
      "CityId",
      "StateNameId",
      "ZipcodeId",
      "StatusId",
      "CountyId",
      "BasinId",
      "Latitude",
      "Longitude",
      "OperatorId",
      "OwnerId",
      "CCUSFunctionId",
      "CaptureTypeId",
      "CaptureTechnology",
      "CO2EndUseId",
      "CO2CaptureStoreMMTPA",
      "InceptionDate",
      "LocationQualifierId",
      "CO2TransportModeId",
      "FacilityTypeId",
      "FacilityId",
      "EPCContractor",
      "EmissionGHGRPID",
      "Remarks"
    ];

    var insertvalues = [];
    ccuskeys.forEach((element) => {
      if (
        ccusvalues[element] !== undefined &&
        ccusvalues[element] !== null &&
        ccusvalues[element] !== ""
      ) {
        if (element.indexOf("Date") > -1) {
          insertvalues.push(
            "'" + moment(ccusvalues[element]).format("YYYY-MM-DD") + "'"
          );
        } else {
          if (typeof ccusvalues[element] === "string") {
            insertvalues.push("'" + ccusvalues[element] + "'");
          } else {
            insertvalues.push(ccusvalues[element]);
          }
        }
      } else {
        insertvalues.push("NULL");
      }
    });

    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      inserttype: "CCUS",
      insertvalues: insertvalues.join(","),
      facname: ccusvalues.CCUSName,
      factype: "CCUS",
      operator: ccusvalues.Operator === null ? "" : ccusvalues.Operator,
      owner: ccusvalues.Owner === null ? "" : ccusvalues.Owner,
      latitude: ccusvalues.Latitude,
      longitude: ccusvalues.Longitude,
      pipelinelocationcode:
        pipelinelocationcode !== "" ? pipelinelocationcode : null,
      pipelineheaderid:
        selectedpipeline.PipelineHeaderId !== undefined
          ? selectedpipeline.PipelineHeaderId
          : null,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/insertdatarecord", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error while inserting ccus record",
            variant: "error",
          });
        } else if (response.data[0].status === "inserted") {
          setStatusBase({
            show: true,
            message: "Record inserted successfully",
            variant: "info",
          });

          window.location.reload();
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting record",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting ccus data",
            variant: "error",
          });
        }
      }
    );
  };

  const fetchCCUSs = () => {
    const query = {
      columnname: "CCUSName",
      tablename: "CCUS",
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchsubjectuniquevalues", query, options).then(
      (response) => {
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "no records") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            setCCUSs(response.data[0].data);
          }
        }
      },
      (error) => {
        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
        }
      }
    );
  };

  const fnFetchUniqueValues = () => {
    const data = {
      fetchtype: "PipelineName",
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchuniquevalues", data, options).then(
      (response) => {
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "no records") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            setPipelineNames(response.data[0].data);
          }
        }
      },
      (error) => {
        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
        }
      }
    );
  };

  const fnHandleInceptionDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setCCUSValues({
        ...ccusvalues,
        InceptionDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setCCUSValues({ ...ccusvalues, InceptionDate: null });
    }
  };

  const [ccusfunction, setCCUSFunction] = React.useState([]);
  const [selectedccusfunction, setSelectedCCUSFunction] = React.useState({});

  const [co2enduses, setCO2EndUses] = React.useState([]);
  const [selectedco2enduse, setSelectedCO2EndUse] = React.useState({});

  const [locationqualifiers, setLocationQualifiers] = React.useState([]);
  const [selectedlocationqualifier, setSelectedLocationQualifier] =
    React.useState({});

  const [capturetypes, setCaptureTypes] = React.useState([]);
  const [selectedcapturetype, setSelectedCaptureType] = React.useState({});

  const [co2transportmodes, setCO2TransportModes] = React.useState([]);
  const [selectedco2transportmode, setSelectedCO2TransportMode] =
    React.useState({});

  const [facilitytypes, setFacilityTypes] = React.useState([]);
  const [selectedfacilitytype, setSelectedFacilityType] = React.useState({});

  const [facilities, setFacilities] = React.useState([]);
  const [selectedfacility, setSelectedFacility] = React.useState({});
  const [facilityid, setFacilityId] = React.useState(null);

  const fnFetchLookupValues = (fetchType, facilitytype) => {
    const data = {
      fetchtype: fetchType,
      facilitytype: facilitytype,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchuniquevalues", data, options).then(
      (response) => {
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "no records") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            if (fetchType === "CO2EndUse") {
              setCO2EndUses(response.data[0].data);
            } else if (fetchType === "LocationQualifier") {
              setLocationQualifiers(response.data[0].data);
            } else if (fetchType === "CCUSFunction") {
              setCCUSFunction(response.data[0].data);
            } else if (fetchType === "FacilityType") {
              setFacilityTypes(response.data[0].data);
            } else if (fetchType === "Facility") {
              setFacilities(response.data[0].data);
            } else if (fetchType === "CO2TransportMode") {
              setCO2TransportModes(response.data[0].data);
            } else if (fetchType === "CaptureType") {
              setCaptureTypes(response.data[0].data);
            }
          }
        }
      },
      (error) => {
        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
        }
      }
    );
  };

  useEffect(() => {
    fetchCCUSs();
    fnFetchUniqueValues();

    fnFetchLookupValues("CO2EndUse", null);
    fnFetchLookupValues("LocationQualifier", null);
    fnFetchLookupValues("CCUSFunction", null);
    fnFetchLookupValues("CO2TransportMode", null);
    fnFetchLookupValues("FacilityType", null);
    fnFetchLookupValues("CaptureType", null);
  }, []);

  return (
    <>
      <Grid className={classes.operatoraddcontainer} container>
        <Paper>
          <Typography variant="subtitle1">Add CCUS Data</Typography>
          <ValidatorForm>
            <>
              <Grid
                container
                spacing="3"
                direction="column"
                className={classes.formcontainer}
              >
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      <TextField
                        label="CCUS Name"
                        id="ccusnameId"
                        value={ccusvalues.CCUSName}
                        onChange={fnHandleChange("CCUSName")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={ccusvalues.CCUSName === ""}
                      />
                    </Grid>

                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={citylookupvalues}
                        getOptionLabel={(option) =>
                          option.CityName === undefined ? "" : option.CityName
                        }
                        value={selectedcity}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "CityName", "CityId");
                          } else {
                            setSelectedCity({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select City"}
                            placeholder="City"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={statelookupvalues}
                        getOptionLabel={(option) =>
                          option.StateName === undefined ? "" : option.StateName
                        }
                        value={selectedstate}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "StateName", "StateNameId");
                          } else {
                            setSelectedState({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select State"}
                            placeholder="State"
                            error={selectedstate.StateName === undefined}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={statecounties}
                        getOptionLabel={(option) =>
                          option.CountyName === undefined
                            ? ""
                            : option.CountyName
                        }
                        value={selectedcounty}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "CountyName", "CountyId");
                          } else {
                            setSelectedCounty({});
                          }
                        }}
                        disabled={
                          statecounties.length === 0 ||
                          selectedstate.StateName === undefined
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select County"}
                            placeholder="County"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={zipcodelookupvalues}
                        getOptionLabel={(option) =>
                          option.Zipcode === undefined ? "" : option.Zipcode
                        }
                        value={selectedzipcode}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "Zipcode", "ZipcodeId");
                          } else {
                            setSelectedZipcode({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select Zipcode"}
                            placeholder="Zipcode"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={basinlookupvalues}
                        getOptionLabel={(option) =>
                          option.BasinName === undefined ? "" : option.BasinName
                        }
                        value={selectedbasin}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "BasinName", "BasinId");
                          } else {
                            setSelectedBasin({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select Basin"}
                            placeholder="Basin"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={operatorlookupvalues}
                        getOptionLabel={(option) =>
                          option.OperatorName === undefined
                            ? ""
                            : option.OperatorName
                        }
                        value={selectedoperator}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "Operator", "OperatorId");
                          } else {
                            setSelectedOperator({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select Operator"}
                            placeholder="Operator"
                            error={selectedoperator.OperatorName === undefined}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={operatorlookupvalues}
                        getOptionLabel={(option) =>
                          option.OperatorName === undefined
                            ? ""
                            : option.OperatorName
                        }
                        value={selectedowner}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "Owner", "OwnerId");
                          } else {
                            setSelectedOwner({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select Owner"}
                            placeholder="Owner"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={plantstatus}
                        getOptionLabel={(option) =>
                          option.Status === undefined ? "" : option.Status
                        }
                        value={selectedStatus}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "Status", "StatusId");
                          } else {
                            setSelectedStatus({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select Status"}
                            placeholder="Status"
                            error={selectedStatus.Status === undefined}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={ccusfunction}
                        getOptionLabel={(option) =>
                          option.CCUSFunction === undefined
                            ? ""
                            : option.CCUSFunction
                        }
                        value={selectedccusfunction}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(
                              newValue,
                              "CCUSFunction",
                              "CCUSFunctionId"
                            );
                          } else {
                            setSelectedCCUSFunction({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select CCUSFunction"}
                            placeholder="CCUSFunction"
                            error={
                              selectedccusfunction.CCUSFunction === undefined
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      {/* {JSON.stringify(operatorvalues.City)} */}

                      <Autocomplete
                        size="small"
                        options={co2enduses}
                        getOptionLabel={(option) =>
                          option.CO2EndUse === undefined ? "" : option.CO2EndUse
                        }
                        value={selectedco2enduse}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "CO2EndUse", "CO2EndUseId");
                          } else {
                            setSelectedCO2EndUse({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select CO2EndUse"}
                            placeholder="CO2EndUse"
                            error={selectedco2enduse.CO2EndUse === undefined}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={locationqualifiers}
                        getOptionLabel={(option) =>
                          option.LocationQualifier === undefined
                            ? ""
                            : option.LocationQualifier
                        }
                        value={selectedlocationqualifier}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(
                              newValue,
                              "LocationQualifier",
                              "LocationQualifierId"
                            );
                          } else {
                            setSelectedLocationQualifier({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select Location Qualifier"}
                            placeholder="Location Qualifier"
                            error={
                              selectedlocationqualifier.LocationQualifier ===
                              undefined
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="2">
                      <TextField
                        label="Latitude"
                        id="latitudeId"
                        onChange={fnHandleChange("Latitude")}
                        value={ccusvalues.Latitude}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={ccusvalues.Latitude === ""}
                      />
                    </Grid>
                    <Grid item xs="2">
                      <TextField
                        label="Longitude"
                        id="longitudeId"
                        onChange={fnHandleChange("Longitude")}
                        value={ccusvalues.Longitude}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={ccusvalues.Longitude === ""}
                      />
                    </Grid>
                    <Grid item xs="2">
                      <Link
                        href={
                          "https://www.google.com/maps/search/?api=1&query=" +
                          ccusvalues.Latitude +
                          "," +
                          ccusvalues.Longitude
                        }
                        to="Google Maps"
                        target="_blank"
                      >
                        Google Maps Link
                      </Link>
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={co2transportmodes}
                        getOptionLabel={(option) =>
                          option.CO2TransportMode === undefined
                            ? ""
                            : option.CO2TransportMode
                        }
                        value={selectedco2transportmode}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(
                              newValue,
                              "CO2TransportMode",
                              "CO2TransportModeId"
                            );
                          } else {
                            setSelectedCO2TransportMode({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select CO2TransportMode"}
                            placeholder="CO2TransportMode"
                            error={
                              selectedco2transportmode.CO2TransportMode ===
                              undefined
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={capturetypes}
                        getOptionLabel={(option) =>
                          option.CaptureType === undefined
                            ? ""
                            : option.CaptureType
                        }
                        value={selectedcapturetype}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(
                              newValue,
                              "CaptureType",
                              "CaptureTypeId"
                            );
                          } else {
                            setSelectedCaptureType({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select CaptureType"}
                            placeholder="CaptureType"
                            error={
                              selectedcapturetype.CaptureType === undefined
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          format="yyyy/MM/dd"
                          margin="normal"
                          id="InceptionDateId"
                          label="Inception Date"
                          variant="outlined"
                          value={ccusvalues.InceptionDate}
                          onChange={fnHandleInceptionDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "Inception Date",
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={ccusvalues.InceptionDate === undefined}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="EPC Contractor"
                        id="EPCContractorId"
                        value={ccusvalues.EPCContractor}
                        onChange={fnHandleChange("EPCContractor")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={ccusvalues.EPCContractor === ""}
                      />
                    </Grid>
                    <Grid item xs="2">
                      <TextField
                        label="EmissionGHGRPID"
                        id="EmissionGHGRPIDId"
                        value={ccusvalues.EmissionGHGRPID}
                        onChange={fnHandleChange("EmissionGHGRPID")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="2">
                      <TextField
                        label="CO2CaptureStoreMMTPA"
                        id="CO2CaptureStoreMMTPAId"
                        value={ccusvalues.CO2CaptureStoreMMTPA}
                        onChange={fnHandleChange("CO2CaptureStoreMMTPA")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={ccusvalues.CO2CaptureStoreMMTPA === ""}
                      />
                    </Grid>
                    <Grid item xs="2">
                      <TextField
                        label="Capture Technology"
                        id="capturetechnologyId"
                        value={ccusvalues.CaptureTechnology}
                        onChange={fnHandleChange("CaptureTechnology")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={ccusvalues.CaptureTechnology === ""}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="2">
                      <TextField
                        label="PipelineLocationCode"
                        id="PipelineLocationCodeId"
                        onChange={fnHandleChange("PipelineLocationCode")}
                        value={pipelinelocationcode}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={pipelinenames}
                        value={selectedpipeline}
                        getOptionLabel={(option) =>
                          option.PipelineName === undefined
                            ? ""
                            : option.PipelineName
                        }
                        disabled={pipelinelocationcode === ""}
                        onChange={(event, newValue) => {
                          if (newValue === null) {
                            setSelectedPipeline({});
                          } else {
                            setSelectedPipeline(newValue);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Search By Pipeline Name"
                            placeholder="Pipelines"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={facilitytypes}
                        getOptionLabel={(option) =>
                          option.FacilityType === undefined
                            ? ""
                            : option.FacilityType
                        }
                        value={selectedfacilitytype}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(
                              newValue,
                              "FacilityType",
                              "FacilityTypeId"
                            );
                          } else {
                            setSelectedFacilityType({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select FacilityType"}
                            placeholder="FacilityType"
                            error={
                              selectedfacilitytype.FacilityType === undefined
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={facilities}
                        getOptionLabel={(option) =>
                          option.Facility === undefined ? "" : option.Facility
                        }
                        value={selectedfacility}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "Facility", "FacilityId");
                          } else {
                            setSelectedFacility({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select Facility"}
                            placeholder="Facility"
                            error={selectedfacility.Facility === undefined}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="6">
                      <TextField
                        label="Remarks"
                        id="remarksId"
                        value={ccusvalues.Remarks}
                        onChange={fnHandleChange("Remarks")}
                        variant="outlined"
                        size="small"
                        multiline
                        rows={4}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid item>
                      <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        className={classes.updatebutton}
                        disabled={
                          ccusvalues.Latitude === "" ||
                          ccusvalues.CCUSName === "" ||
                          ccusvalues.Longitude === "" ||
                          ccusvalues.StateName === null ||
                          ccusvalues.Status === null
                        }
                        onClick={() => fnInsertCCUSData()}
                      >
                        Insert CCUS
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          </ValidatorForm>
        </Paper>
      </Grid>
      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(AddCCUSComp);
