import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import CardMedia from '@material-ui/core/CardMedia';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ListSubheader from '@material-ui/core/ListSubheader';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';

//MapTypeImages
import darkgray from 'assets/images/maptypes/dark-gray.jpg';
import gray from 'assets/images/maptypes/gray.jpg';
import grayvector from 'assets/images/maptypes/gray-vector.jpg';
import hybrid from 'assets/images/maptypes/hybrid.jpg';
import nationalgeographic from 'assets/images/maptypes/national-geographic.jpg';
import osm from 'assets/images/maptypes/osm.jpg';
import satellite from 'assets/images/maptypes/satellite.jpg';
import streets from 'assets/images/maptypes/streets.jpg';
import streetsnavigation from 'assets/images/maptypes/streets-navigation.jpg';
import streetsnight from 'assets/images/maptypes/streets-night.jpg';
import streetsvector from 'assets/images/maptypes/streets-vector.jpg';
import terrain from 'assets/images/maptypes/terrain.jpg';
import topo from 'assets/images/maptypes/topo.jpg';
import topovector from 'assets/images/maptypes/topo-vector.jpg';

const useStyles = makeStyles((theme) => ({
	mapcontrol: {
		minWidth: '200px',
		marginLeft: 10,
		'& .MuiSelect-root': {
			paddingTop: 10,
			paddingBottom: 10,
		},
	},
	mapicon: { width: '100px', height: '100px' },
	menulist: { maxHeight: '326px', overflow: 'auto' },
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		backgroundColor: theme.palette.background.paper,
	},
	gridList: {
		width: 500,
		height: 300,
	},
	infogridList: {
		width: 500,
		height: 300,
	},
}));

function BaseMapsComp(props) {
	const classes = useStyles();

	const [selectedmap, setSelectedMap] = React.useState('');

	//Source for names : https://developers.arcgis.com/javascript/3/jsapi/esri.basemaps-amd.html
	const baseMaps = [
		{
			name: 'Dark Gray',
			value: 'dark-gray',
			image: darkgray,
		},
		{
			name: 'Light Gray',
			value: 'gray',
			image: gray,
		},
		// {
		// 	name: 'Light Gray Canvas [v2]',
		// 	value: 'gray-vector',
		// 	image: grayvector,
		// },
		{
			name: 'World Imagery',
			value: 'hybrid',
			image: hybrid,
		},
		{
			name: 'National Geographic',
			value: 'national-geographic',
			image: nationalgeographic,
		},
		{
			name: 'OpenStreetMap',
			value: 'osm',
			image: osm,
		},
		{
			name: 'World Imagery',
			value: 'satellite',
			image: satellite,
		},
		{
			name: 'Streets',
			value: 'streets',
			image: streets,
		},
		// {
		// 	name: 'World Navigation',
		// 	value: 'streets-navigation-vector',
		// 	image: streetsnavigation,
		// },
		{
			name: 'World Street Map (Night)',
			value: 'streets-night-vector',
			image: streetsnight,
		},
		{
			name: 'Terrain with Labels',
			value: 'terrain',
			image: terrain,
		},
		{
			name: 'Topographic Map',
			value: 'topo',
			image: topo,
		},
	];

	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);

	const [infoopen, setInfoOpen] = React.useState(false);
	const infoanchorRef = React.useRef(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	const infohandleToggle = () => {
		setInfoOpen((prevOpen) => !prevOpen);
	};

	const infohandleClose = (event) => {
		if (infoanchorRef.current && infoanchorRef.current.contains(event.target)) {
			return;
		}

		setInfoOpen(false);
	};

	const fnHandleItemClick = (event) => {
		const { myValue } = event.currentTarget.dataset;
		props.fnHandleBaseMapChange(myValue);
		setOpen(false);
	};

	return (
		<>
			<Button
				ref={anchorRef}
				aria-controls={open ? 'menu-list-grow' : undefined}
				aria-haspopup="true"
				onClick={handleToggle}
			>
				Base Maps
			</Button>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				style={{ zIndex: 1 }}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
					>
						<Paper className={classes.root}>
							<ClickAwayListener onClickAway={handleClose}>
								<GridList cellHeight={150} className={classes.gridList} cols={4}>
									<GridListTile key="Subheader" cols={4} style={{ height: 'auto' }}>
										<ListSubheader component="div">Base Maps</ListSubheader>
									</GridListTile>
									{baseMaps.map((val, index) => (
										<GridListTile key={index} style={{ cursor: 'pointer' }}>
											<Grid container direction="column" justify="center" alignItems="center">
												<Grid item>
													<CardMedia
														className={classes.mapicon}
														image={val.image}
														title={val.value}
													/>
												</Grid>
												<Grid item>
													<Button
														color="primary"
														onClick={fnHandleItemClick}
														data-my-value={val.value}
														size="small"
													>
														{val.name}
													</Button>
												</Grid>
											</Grid>
										</GridListTile>
									))}
								</GridList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
			<IconButton
				ref={infoanchorRef}
				aria-label="info"
				aria-haspopup="true"
				aria-controls={infoopen ? 'menu-list-grow' : undefined}
				className={classes.margin}
				onClick={infohandleToggle}
			>
				<InfoIcon fontSize="small" />
			</IconButton>
			<Popper
				open={infoopen}
				anchorEl={infoanchorRef.current}
				role={undefined}
				style={{ zIndex: 1 }}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
					>
						<Paper className={classes.root}>
							<ClickAwayListener onClickAway={infohandleClose}>
								<GridList cellHeight={65} className={classes.infogridList} cols={4}>
									<GridListTile key="Subheader" cols={4} style={{ height: 'auto' }}>
										<ListSubheader component="div">Product(s) Colors</ListSubheader>
									</GridListTile>

									<GridListTile>
										<Grid container direction="column" justify="center" alignItems="center">
											<Grid item>
												<span>Crude</span>
											</Grid>
											<Grid item>
												<span
													style={{
														height: '15px',
														width: '15px',
														backgroundColor: '#1d9d00',
														borderRadius: '50%',
														display: 'inline-block',
													}}
												></span>
											</Grid>
										</Grid>
									</GridListTile>

									<GridListTile>
										<Grid container direction="column" justify="center" alignItems="center">
											<Grid item>
												<span>Refined Product</span>
											</Grid>
											<Grid item>
												<span
													style={{
														height: '15px',
														width: '15px',
														backgroundColor: '#0984e3',
														borderRadius: '50%',
														display: 'inline-block',
													}}
												></span>
											</Grid>
										</Grid>
									</GridListTile>
									<GridListTile>
										<Grid container direction="column" justify="center" alignItems="center">
											<Grid item>
												<span>Chemicals</span>
											</Grid>
											<Grid item>
												<span
													style={{
														height: '15px',
														width: '15px',
														backgroundColor: '#636e72',
														borderRadius: '50%',
														display: 'inline-block',
													}}
												></span>
											</Grid>
										</Grid>
									</GridListTile>
									<GridListTile>
										<Grid container direction="column" justify="center" alignItems="center">
											<Grid item>
												<span>LNG</span>
											</Grid>
											<Grid item>
												<span
													style={{
														height: '15px',
														width: '15px',
														backgroundColor: '#ff357d',
														borderRadius: '50%',
														display: 'inline-block',
													}}
												></span>
											</Grid>
										</Grid>
									</GridListTile>
									<GridListTile key="Subheader" cols={4} style={{ height: 'auto' }}>
										<ListSubheader component="div">Status Colors</ListSubheader>
									</GridListTile>

									<GridListTile>
										<Grid container direction="column" justify="center" alignItems="center">
											<Grid item>
												<span>Active</span>
											</Grid>
											<Grid item>
												<span
													style={{
														height: '15px',
														width: '15px',
														backgroundColor: '#607d8b',
														borderRadius: '50%',
														display: 'inline-block',
													}}
												></span>
											</Grid>
										</Grid>
									</GridListTile>
									<GridListTile>
										<Grid container direction="column" justify="center" alignItems="center">
											<Grid item>
												<span>Expired</span>
											</Grid>
											<Grid item>
												<span
													style={{
														height: '15px',
														width: '15px',
														backgroundColor: '#d63031',
														borderRadius: '50%',
														display: 'inline-block',
													}}
												></span>
											</Grid>
										</Grid>
									</GridListTile>
									<GridListTile>
										<Grid container direction="column" justify="center" alignItems="center">
											<Grid item>
												<span>Denied</span>
											</Grid>
											<Grid item>
												<span
													style={{
														height: '15px',
														width: '15px',
														backgroundColor: '#ff7675',
														borderRadius: '50%',
														display: 'inline-block',
													}}
												></span>
											</Grid>
										</Grid>
									</GridListTile>
									<GridListTile>
										<Grid container direction="column" justify="center" alignItems="center">
											<Grid item>
												<span>Waiting</span>
											</Grid>
											<Grid item>
												<span
													style={{
														height: '15px',
														width: '15px',
														backgroundColor: '#fdcb6e',
														borderRadius: '50%',
														display: 'inline-block',
													}}
												></span>
											</Grid>
										</Grid>
									</GridListTile>
								</GridList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
}

export default BaseMapsComp;
