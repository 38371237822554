import React, { Suspense, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import Snackbar from "@material-ui/core/Snackbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import LinearProgress from "@material-ui/core/LinearProgress";

import { makeStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";

import LookupInsertComp from "components/common/LookupInsertComp";
import AlertNotificationComp from "components/custom/AlertNotificationComp";

//date
import moment from "moment";

//linq
import linq from "linq";

//Api
import axios from "axios";

//Redux imports
import { useSelector, useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

import { plantstatus } from "lookuptables/global";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },
  operatoraddcontainer: {
    "& .MuiPaper-root": {
      width: "100%",
      padding: 10,
      margin: 10,
    },
  },
  formcontainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-container": {
      // marginBottom: 10,
    },
  },
  updatebutton: {
    width: "100%",
  },
}));

function AddPowerPlantComp(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const [powerplants, setPowerPlants] = React.useState([]);

  const citylookupvalues = useSelector(
    (state) => state.citynamesReducer.CityName
  );
  const countylookupvalues = useSelector(
    (state) => state.countynamesReducer.CountyName
  );
  const statelookupvalues = useSelector(
    (state) => state.statenamesReducer.StateName
  );
  const zipcodelookupvalues = useSelector(
    (state) => state.zipcodesReducer.Zipcode
  );
  const operatorlookupvalues = useSelector(
    (state) => state.operatorsReducer.OperatorName
  );
  const basinlookupvalues = useSelector(
    (state) => state.basinsReducer.BasinName
  );

  const [statecounties, setStateCounties] = React.useState([]);

  const [pipelinenames, setPipelineNames] = React.useState([]);

  const [selectedcity, setSelectedCity] = React.useState({});
  const [selectedcounty, setSelectedCounty] = React.useState({});
  const [selectedstate, setSelectedState] = React.useState({});
  const [selectedzipcode, setSelectedZipcode] = React.useState({});
  const [selectedbasin, setSelectedBasin] = React.useState({});
  const [selectedowner, setSelectedOwner] = React.useState({});
  const [selectedoperator, setSelectedOperator] = React.useState({});
  const [selectedStatus, setSelectedStatus] = React.useState({});
  const [selectedpowerplanttype, setSelectedPowerPlantType] = React.useState(
    {}
  );

  const [pipelinelocationcode, setPipelineLocationCode] = React.useState("");
  const [selectedpipeline, setSelectedPipeline] = React.useState({});

  const powerplantinitialstate = {
    PowerPlantName: "",
    EIAId: "",
    CityName: null,
    CityId: null,
    CountyName: null,
    CountyId: null,
    StateName: null,
    StateNameId: null,
    Zipcode: null,
    ZipcodeId: null,
    Basin: null,
    BasinId: null,
    OperatorName: null,
    OperatorId: null,
    Owner: null,
    OwnerId: null,
    Latitude: "",
    Longitude: "",
    EmissionLastReportedYear: "",
    EmissionGHGRPID: "",
    EquivalentCO2Tons: "",
    key: 0,
  };

  const [powerplantvalues, setPowerPlantValues] = React.useState(
    powerplantinitialstate
  );

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "error",
  });

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "error" });
  };

  const fnHandleChange = (prop) => (event) => {
    if (prop === "PipelineLocationCode") {
      setPipelineLocationCode(event.target.value);
    } else {
      setPowerPlantValues({ ...powerplantvalues, [prop]: event.target.value });
    }
  };

  const fnOnChange = (newvalue, type, typeid) => {
    if (newvalue !== null && newvalue !== undefined) {
      if (type === "Owner" || type === "Operator") {
        setPowerPlantValues({
          ...powerplantvalues,
          [type]: newvalue["OperatorName"],
          [typeid]: newvalue["OperatorId"],
        });
      } else {
        setPowerPlantValues({
          ...powerplantvalues,
          [type]: newvalue[type],
          [typeid]: newvalue[typeid],
        });
      }

      switch (type) {
        case "CityName":
          setSelectedCity(newvalue);
          break;
        case "CountyName":
          setSelectedCounty(newvalue);
          break;
        case "StateName":
          setSelectedState(newvalue);
          setSelectedCounty({});
          var filtercounties = linq
            .from(countylookupvalues)
            .where((a) => a.StateNameId == newvalue.StateNameId)
            .toArray();
          setStateCounties(filtercounties);
          break;
        case "Zipcode":
          setSelectedZipcode(newvalue);
          break;
        case "Operator":
          setSelectedOperator(newvalue);
          break;
        case "Owner":
          setSelectedOwner(newvalue);
          break;
        case "BasinName":
          setSelectedBasin(newvalue);
          break;
        case "Status":
          setSelectedStatus(newvalue);
      }
    }
  };

  const fnInsertPowerPlantData = () => {
    var powerplantkeys = [
      "PowerPlantName",
      "EIAId",
      "CityId",
      "StateNameId",
      "ZipcodeId",
      "StatusId",
      "CountyId",
      "BasinId",
      "Latitude",
      "Longitude",
      "OperatorId",
      "OwnerId",
      "EmissionLastReportedYear",
      "EmissionGHGRPID",
      "EquivalentCO2Tons",
    ];

    var insertvalues = [];
    powerplantkeys.forEach((element) => {
      if (
        powerplantvalues[element] !== undefined &&
        powerplantvalues[element] !== null &&
        powerplantvalues[element] !== ""
      ) {
        if (element.indexOf("Date") > -1) {
          insertvalues.push(
            element +
              " = '" +
              moment(powerplantvalues[element]).format("YYYY-MM-DD") +
              "'"
          );
        } else {
          if (typeof powerplantvalues[element] === "string") {
            insertvalues.push("'" + powerplantvalues[element] + "'");
          } else {
            insertvalues.push(powerplantvalues[element]);
          }
        }
      } else {
        insertvalues.push("NULL");
      }
    });

    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      inserttype: "PowerPlant",
      insertvalues: insertvalues.join(","),
      facname: powerplantvalues.PowerPlantName,
      factype: "PowerPlant",
      operator:
        powerplantvalues.Operator === null ? "" : powerplantvalues.Operator,
      owner: powerplantvalues.Owner === null ? "" : powerplantvalues.Owner,
      latitude: powerplantvalues.Latitude,
      longitude: powerplantvalues.Longitude,
      pipelinelocationcode:
        pipelinelocationcode !== "" ? pipelinelocationcode : null,
      pipelineheaderid:
        selectedpipeline.PipelineHeaderId !== undefined
          ? selectedpipeline.PipelineHeaderId
          : null,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/insertdatarecord", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status === "error") {
          setStatusBase({
            show: true,
            message: "Error while inserting powerplant record",
            variant: "error",
          });
        } else if (response.data[0].status === "inserted") {
          setStatusBase({
            show: true,
            message: "Record inserted successfully",
            variant: "info",
          });

          window.location.reload();
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting record",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while inserting powerplant data",
            variant: "error",
          });
        }
      }
    );
  };

  const fetchPowerPlants = () => {
    const query = { columnname: "PowerPlantName", tablename: "PowerPlant" };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchsubjectuniquevalues", query, options).then(
      (response) => {
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "no records") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            setPowerPlants(response.data[0].data);
          }
        }
      },
      (error) => {
        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
        }
      }
    );
  };

  const fnFetchUniqueValues = () => {
    const data = {
      fetchtype: "PipelineName",
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/fetchuniquevalues", data, options).then(
      (response) => {
        if (response.data[0].status === "error") {
        } else if (response.data[0].status === "no records") {
        } else if (response.data[0].status === "success") {
          if (response.data[0].data.length > 0) {
            setPipelineNames(response.data[0].data);
          }
        }
      },
      (error) => {
        if (error.request.status === 500) {
          props.history.push("/login");
        } else {
        }
      }
    );
  };

  const fnHandleInstallationDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setPowerPlantValues({
        ...powerplantvalues,
        InstallationDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setPowerPlantValues({ ...powerplantvalues, InstallationDate: null });
    }
  };

  const fnHandleInserviceDateChange = (date) => {
    if (date !== null) {
      let modifieddate = moment(date);
      setPowerPlantValues({
        ...powerplantvalues,
        InserviceDate: modifieddate.format("YYYY-MM-DD"),
      });
    } else {
      setPowerPlantValues({ ...powerplantvalues, InserviceDate: null });
    }
  };

  useEffect(() => {
    fetchPowerPlants();
    fnFetchUniqueValues();
  }, []);

  return (
    <>
      <Grid className={classes.operatoraddcontainer} container>
        <Paper>
          <Typography variant="subtitle1">Add PowerPlant Data</Typography>
          <ValidatorForm>
            <>
              <Grid
                container
                spacing="3"
                direction="column"
                className={classes.formcontainer}
              >
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      <TextField
                        label="PowerPlant Name"
                        id="powerplantnameId"
                        value={powerplantvalues.PowerPlantName}
                        onChange={fnHandleChange("PowerPlantName")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={powerplantvalues.PowerPlantName === ""}
                      />
                    </Grid>
                    <Grid item xs="2"></Grid>
                    <Grid item xs="4">
                      <TextField
                        label="EIAId"
                        id="EIAIdId"
                        value={powerplantvalues.EIAId}
                        onChange={fnHandleChange("EIAId")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      {/* {JSON.stringify(operatorvalues.City)} */}

                      <Autocomplete
                        size="small"
                        options={citylookupvalues}
                        getOptionLabel={(option) =>
                          option.CityName === undefined ? "" : option.CityName
                        }
                        value={selectedcity}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "CityName", "CityId");
                          } else {
                            setSelectedCity({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select City"}
                            placeholder="City"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={statelookupvalues}
                        getOptionLabel={(option) =>
                          option.StateName === undefined ? "" : option.StateName
                        }
                        value={selectedstate}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "StateName", "StateNameId");
                          } else {
                            setSelectedState({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select State"}
                            placeholder="State"
                            error={selectedstate.StateName === undefined}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={statecounties}
                        getOptionLabel={(option) =>
                          option.CountyName === undefined
                            ? ""
                            : option.CountyName
                        }
                        value={selectedcounty}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "CountyName", "CountyId");
                          } else {
                            setSelectedCounty({});
                          }
                        }}
                        disabled={
                          statecounties.length === 0 ||
                          selectedstate.StateName === undefined
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select County"}
                            placeholder="County"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={zipcodelookupvalues}
                        getOptionLabel={(option) =>
                          option.Zipcode === undefined ? "" : option.Zipcode
                        }
                        value={selectedzipcode}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "Zipcode", "ZipcodeId");
                          } else {
                            setSelectedZipcode({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select Zipcode"}
                            placeholder="Zipcode"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={basinlookupvalues}
                        getOptionLabel={(option) =>
                          option.BasinName === undefined ? "" : option.BasinName
                        }
                        value={selectedbasin}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "BasinName", "BasinId");
                          } else {
                            setSelectedBasin({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select Basin"}
                            placeholder="Basin"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={operatorlookupvalues}
                        getOptionLabel={(option) =>
                          option.OperatorName === undefined
                            ? ""
                            : option.OperatorName
                        }
                        value={selectedoperator}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "Operator", "OperatorId");
                          } else {
                            setSelectedOperator({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select Operator"}
                            placeholder="Operator"
                            error={selectedoperator.OperatorName === undefined}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={operatorlookupvalues}
                        getOptionLabel={(option) =>
                          option.OperatorName === undefined
                            ? ""
                            : option.OperatorName
                        }
                        value={selectedowner}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "Owner", "OwnerId");
                          } else {
                            setSelectedOwner({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select Owner"}
                            placeholder="Owner"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="3"></Grid>

                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={plantstatus}
                        getOptionLabel={(option) =>
                          option.Status === undefined ? "" : option.Status
                        }
                        value={selectedStatus}
                        onChange={(event, newValue) => {
                          if (newValue != null) {
                            fnOnChange(newValue, "Status", "StatusId");
                          } else {
                            setSelectedStatus({});
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label={"Select Status"}
                            placeholder="Status"
                            error={selectedStatus.Status === undefined}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="2">
                      <TextField
                        label="Latitude"
                        id="latitudeId"
                        onChange={fnHandleChange("Latitude")}
                        value={powerplantvalues.Latitude}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={powerplantvalues.Latitude === ""}
                      />
                    </Grid>
                    <Grid item xs="2">
                      <TextField
                        label="Longitude"
                        id="longitudeId"
                        onChange={fnHandleChange("Longitude")}
                        value={powerplantvalues.Longitude}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={powerplantvalues.Longitude === ""}
                      />
                    </Grid>
                    <Grid item xs="2">
                      <Link
                        href={
                          "https://www.google.com/maps/search/?api=1&query=" +
                          powerplantvalues.Latitude +
                          "," +
                          powerplantvalues.Longitude
                        }
                        to="Google Maps"
                        target="_blank"
                      >
                        Google Maps Link
                      </Link>
                    </Grid>
                    <Grid item xs="3"></Grid>
                    <Grid item xs="3"></Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs="3">
                      <TextField
                        label="EmissionLastReportedYear"
                        id="EmissionLastReportedYearId"
                        value={powerplantvalues.EmissionLastReportedYear}
                        onChange={fnHandleChange("EmissionLastReportedYear")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="EmissionGHGRPID"
                        id="EmissionGHGRPIDId"
                        value={powerplantvalues.EmissionGHGRPID}
                        onChange={fnHandleChange("EmissionGHGRPID")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <TextField
                        label="EquivalentCO2Tons"
                        id="EquivalentCO2TonsId"
                        value={powerplantvalues.EquivalentCO2Tons}
                        onChange={fnHandleChange("EquivalentCO2Tons")}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3"></Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing="3"
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs="2">
                      <TextField
                        label="PipelineLocationCode"
                        id="PipelineLocationCodeId"
                        onChange={fnHandleChange("PipelineLocationCode")}
                        value={pipelinelocationcode}
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs="3">
                      <Autocomplete
                        size="small"
                        options={pipelinenames}
                        value={selectedpipeline}
                        getOptionLabel={(option) =>
                          option.PipelineName === undefined
                            ? ""
                            : option.PipelineName
                        }
                        disabled={pipelinelocationcode === ""}
                        onChange={(event, newValue) => {
                          if (newValue === null) {
                            setSelectedPipeline({});
                          } else {
                            setSelectedPipeline(newValue);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Search By Pipeline Name"
                            placeholder="Pipelines"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs="7"></Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid item>
                      <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        className={classes.updatebutton}
                        disabled={
                          powerplantvalues.Latitude === "" ||
                          powerplantvalues.PowerPlantName === "" ||
                          powerplantvalues.Longitude === "" ||
                          powerplantvalues.StateName === null ||
                          powerplantvalues.Status === null ||
                          (pipelinelocationcode !== "" &&
                            selectedpipeline.PipelineHeaderId === undefined)
                        }
                        onClick={() => fnInsertPowerPlantData()}
                      >
                        Insert PowerPlant
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          </ValidatorForm>
        </Paper>
      </Grid>
      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(AddPowerPlantComp);
