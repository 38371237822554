import React, { Suspense } from 'react';

import Grid from '@material-ui/core/Grid';

import LinearProgress from '@material-ui/core/LinearProgress';

import { makeStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router-dom';

import SideDrawerComp from './subcomponents/SideDrawerComp';

const OperatorUpdateDataComponent = React.lazy(
	() =>
		new Promise((resolve, reject) =>
			setTimeout(() => resolve(import('./subcomponents/OperatorUpdateDataComp')), 100)
		)
);

const OperatorSearchComponent = React.lazy(
	() => new Promise((resolve, reject) => setTimeout(() => resolve(import('./subcomponents/OperatorSearchComp')), 100))
);

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
	},
}));

function UpdateOperatorComp(props) {
	const classes = useStyles();

	const [searchoperatorquery, setSearchOperatorQuery] = React.useState('');

	return (
		<>
			{/* <SideDrawerComp /> */}
			<Grid container>
				<Suspense
					fallback={
						<>
							<LinearProgress />
						</>
					}
				>
					<OperatorSearchComponent setSearchOperatorQuery={setSearchOperatorQuery} />
				</Suspense>
			</Grid>
			<Grid container>
				<Suspense
					fallback={
						<>
							<LinearProgress />
						</>
					}
				>
					<OperatorUpdateDataComponent searchoperatorquery={searchoperatorquery} />
				</Suspense>
			</Grid>
		</>
	);
}

export default withRouter(UpdateOperatorComp);
