import React, { useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Snackbar from "@material-ui/core/Snackbar";

import ReactJson from "react-json-view";

import { makeStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";

import AlertNotificationComp from "./AlertNotificationComp";

//Api
import axios from "axios";

//Redux imports
import { useSelector, useDispatch } from "react-redux";
import * as actionType from "redux/actions/actionType";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
  },
  operatoraddcontainer: {
    width: "100%",
    padding: 10,
    margin: 10,
  },
  formcontainer: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiGrid-container": {
      // marginBottom: 10,
    },
  },
  updatebutton: {
    width: "100%",
    marginTop: "10px",
  },
}));

function PipelineLocationCodeComp(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const token = useSelector((state) => state.userReducer.token);

  const [pipelinelocationcode, setPipelineLocationCode] = React.useState("");
  const [facilitydata, setFacilityData] = React.useState([]);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "error",
  });

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: "", variant: "error" });
  };

  const fnHandleChange = (prop) => (event) => {
    if (prop === "PipelineLocationCode") {
      setPipelineLocationCode(event.target.value);
      setFacilityData([]);
    }
  };

  const fnSearchPLC = () => {
    dispatch({ type: actionType.LOADINGSPINNER, payload: { loading: true } });

    const data = {
      pipelinelocationcode: pipelinelocationcode,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    axios.post("/api/searchplcfacility", data, options).then(
      (response) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (response.data[0].status.code === undefined) {
          if (response.data[0].status === "error") {
            setStatusBase({
              show: true,
              message: "Error while searching",
              variant: "error",
            });
          } else if (response.data[0].status === "no records found") {
            setStatusBase({
              show: true,
              message: "Facility not found",
              variant: "info",
            });
          } else if (response.data[0].status === "success") {
            setFacilityData(response.data[0].data);
          }
        } else {
          setStatusBase({
            show: true,
            message: "Error while searching. Please try again.",
            variant: "error",
          });
        }
      },
      (error) => {
        dispatch({
          type: actionType.LOADINGSPINNER,
          payload: { loading: false },
        });

        if (error.request.status === 500) {
          setStatusBase({ show: true, message: "Time out", variant: "error" });
          props.history.push("/login");
        } else {
          setStatusBase({
            show: true,
            message: "Error while searching data",
            variant: "error",
          });
        }
      }
    );
  };

  useEffect(() => {}, []);

  return (
    <>
      <Grid className={classes.operatoraddcontainer} container spacing={2}>
        <Grid xs={12} item>
          <Grid
            container
            spacing="3"
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs="5">
              <TextField
                label="PipelineLocationCode"
                id="PipelineLocationCodeId"
                onChange={fnHandleChange("PipelineLocationCode")}
                value={pipelinelocationcode}
                variant="outlined"
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item>
              <Button
                type="button"
                color="primary"
                variant="contained"
                disabled={pipelinelocationcode === ""}
                className={classes.updatebutton}
                onClick={() => fnSearchPLC()}
              >
                Search Pipeline Location Code
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {facilitydata.length > 0 && (
          <Grid xs={12} item>
            <ReactJson src={facilitydata} displayDataTypes={false}></ReactJson>
          </Grid>
        )}
      </Grid>
      <Snackbar
        autoHideDuration={6000}
        open={status.show}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => fnShowErrorMessage()}
      >
        <AlertNotificationComp
          variant={status.variant}
          onClose={() => fnShowErrorMessage()}
          message={status.message}
        ></AlertNotificationComp>
      </Snackbar>
    </>
  );
}

export default withRouter(PipelineLocationCodeComp);
