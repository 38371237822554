import React, { useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import 'antd/dist/antd.css';

import { Table, Form, Input } from 'antd';

import AlertNotificationComp from 'components/custom/AlertNotificationComp';
import ConfirmationDialogComp from 'components/custom/ConfirmationDialogComp';

import LookupComp from 'components/common/LookupComp';

//Redux imports
import { useSelector } from 'react-redux';

//Api
import axios from 'axios';

//Redux imports
import { useDispatch } from 'react-redux';
import * as actionType from 'redux/actions/actionType';

import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	editabletable: {
		width: '100%',
		'& .ant-table-title': {
			textTransform: 'capitalize',
		},
		'& .editable-row .ant-form-item-explain': {
			position: 'absolute',
			top: '100%',
		},
	},
	editabledatacontainer: {
		margin: 10,
		width: '98.5%',
		'& .MuiPaper-root': {
			width: '100%',
		},
	},
	editablerow: {
		height: '60px',
	},
}));

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

function FacilityDataComp(props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const token = useSelector((state) => state.userReducer.token);

	const editabledataRef = useRef(null);

	const [status, setStatusBase] = React.useState({
		show: false,
		message: '',
		variant: 'error',
	});

	const [confirmDialog, setConfirmDialog] = React.useState({
		display: false,
		message: '',
	});

	const [editabledata, setEditableData] = React.useState(props.editabledata);

	const [editingKey, setEditingKey] = React.useState('');

	var columns = [
		{
			title: 'Id',
			dataIndex: 'Id',
			editable: false,
		},
		{
			title: 'FacilityName',
			dataIndex: 'FacilityName',
			editable: true,
		},
		{
			title: 'Facilitytype',
			dataIndex: 'Facilitytype',
			editable: false,
		},
		{
			title: 'Operator',
			dataIndex: 'Operator',
			editable: true,
		},
		{
			title: 'Owner',
			dataIndex: 'Owner',
			editable: true,
		},
		{
			title: 'Latitude',
			dataIndex: 'Latitude',
			editable: true,
		},
		{
			title: 'Longitude',
			dataIndex: 'Longitude',
			editable: true,
		},
		{
			title: 'StateName',
			dataIndex: 'statename',
			editable: true,
		},
		{
			title: 'CountyName',
			dataIndex: 'CountyName',
			editable: true,
		},
		{
			title: 'BasinName',
			dataIndex: 'BasinName',
			editable: true,
		},
		{
			title: 'Zipcode',
			dataIndex: 'Zipcode',
			editable: true,
		},
		// {
		// 	title: 'Action',
		// 	dataIndex: 'Action',
		// 	width: 150,
		// 	render: (_, record, index) => {
		// 		const editable = isEditing(record);
		// 		return editable ? (
		// 			<Grid container>
		// 				<Grid item>
		// 					<Button size="small" onClick={() => fnSaveData(record.key)} color="primary">
		// 						Save
		// 					</Button>
		// 				</Grid>
		// 				<Grid item>
		// 					<Button size="small" onClick={() => fnCancelSave()} color="primary">
		// 						Cancel
		// 					</Button>
		// 				</Grid>
		// 			</Grid>
		// 		) : (
		// 			<Button size="small" onClick={() => fnEditRecord(record)} color="primary">
		// 				Edit
		// 			</Button>
		// 		);
		// 	},
		// },
	];

	const MappingColumnId = {
		CountyName: 'CountyId',
		BasinName: 'BasinId',
		StateName: 'StateNameId',
		Zipcode: 'ZipcodeId',
	};

	const MappingQueryColumnId = {
		CountyName: 'CountyId',
		BasinName: 'BasinId',
		StateName: 'StateNameId',
		Zipcode: 'ZipcodeId',
	};

	const [form] = Form.useForm();
	let IdValues = {
		CountyId: '',
		BasinId: '',
		StateNameId: '',
		ZipcodeId: '',
	};

	const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
		if (title === 'CountyName' || title === 'BasinName' || title === 'StateName' || title === 'Zipcode') {
			return (
				<td {...restProps} className={classes.editablerow}>
					{editing ? (
						<Form.Item
							name={dataIndex}
							style={{
								margin: 0,
							}}
						>
							<LookupComp
								fnSetValues={fnSetValues}
								lookuptype={title}
								lookupid={MappingColumnId[title]}
								lookupvalue={children[1]}
							></LookupComp>
						</Form.Item>
					) : (
						children
					)}
				</td>
			);
		} else {
			return (
				<td {...restProps} className={classes.editablerow}>
					{editing ? (
						<Form.Item
							name={dataIndex}
							style={{
								margin: 0,
							}}
						>
							<Input />
						</Form.Item>
					) : (
						children
					)}
				</td>
			);
		}
	};

	const fnHandleNoDialog = () => {
		setConfirmDialog({ display: false, message: '' });
	};

	const fnHandleYesDialog = () => {
		//Save operator data
		fnSaveData();
		setConfirmDialog({ display: false, message: '' });
	};

	const fnSetValues = (key, keyid, keyvalue, keyidvalue) => {
		var idcolumn = MappingQueryColumnId[key];
		IdValues[idcolumn] = keyidvalue;
		form.setFieldsValue({ [key]: keyvalue });
	};

	const isEditing = (record) => record.key === editingKey;

	const fnSaveData = async (key) => {
		// console.log('key ' + editingKey);
		const row = await form.validateFields();

		const newdata = [...editabledata];
		const index = newdata.findIndex((item) => key === item.key);

		if (index > -1) {
			const item = newdata[index];

			var keys = Object.keys(item);

			var queries = [];

			keys.forEach((element) => {
				// console.log(row);
				if (
					row[element] !== undefined &&
					row[element] !== null &&
					row[element] !== '' &&
					row[element] !== item[element]
				) {
					if (
						element === 'CountyName' ||
						element === 'StateName' ||
						element === 'Zipcode' ||
						element === 'BasinName'
					) {
						var idcolumn = MappingQueryColumnId[element];
						queries.push(idcolumn + '=' + IdValues[idcolumn]);
					} else {
						queries.push(element + "='" + row[element] + "'");
					}
				}
			});

			if (queries.length > 0) {
				const data = {
					updatetype: 'operator', //set subject type
					updatequery: queries.toString(','),
					queryid: editingKey,
				};

				const options = {
					headers: {
						authorization: token ? `Bearer ${token}` : '',
					},
				};

				axios.post('/api/updatedatarecord', data, options).then(
					(response) => {
						if (response.data[0].status === 'error') {
							setStatusBase({
								show: true,
								message: 'Error while updating record',
								variant: 'error',
							});
						} else if (response.data[0].status === 'updated') {
							setStatusBase({ show: true, message: 'Record updated successfully', variant: 'info' });
						} else {
							setStatusBase({ show: true, message: 'Error while updating record', variant: 'info' });
						}

						newdata.splice(index, 1, { ...item, ...row });
						setEditableData(newdata);
						setEditingKey('');
					},
					(error) => {
						if (error.request.status === 500) {
							setStatusBase({ show: true, message: 'Time out', variant: 'error' });
							props.history.push('/login');
						} else {
							setStatusBase({
								show: true,
								message: 'Error while updating data',
								variant: 'error',
							});
						}
					}
				);
			} else {
				setEditingKey('');
			}
		} else {
			newdata.push(row);
			setEditableData(newdata);
			setEditingKey('');
		}
	};

	const fnEditRecord = (record) => {
		form.setFieldsValue({
			Id: '',
			FacilityName: '',
			Facilitytype: '',
			Latitude: '',
			Longitude: '',
			StateName: '',
			CountyName: '',
			BasinName: '',
			Zipcode: '',
			...record,
		});

		setEditingKey(record.key);
	};

	const fnCancelSave = () => {
		setEditingKey('');
	};

	const fnShowErrorMessage = () => {
		setStatusBase({ show: false, message: '', variant: 'error' });
	};

	const mergedColumns = columns.map((col) => {
		if (!col.editable) {
			return col;
		}

		return {
			...col,
			onCell: (record) => ({
				record,
				inputType: 'text',
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record),
			}),
		};
	});

	useEffect(() => {
		setEditableData(props.editabledata);
	}, [props.editabledata]);

	return (
		<>
			<Grid className={classes.editabledatacontainer} container>
				{editabledata.length > 0 ? (
					<Paper ref={editabledataRef}>
						<Form form={form} component={false}>
							<Table
								components={{
									body: {
										cell: EditableCell,
									},
								}}
								columns={mergedColumns}
								dataSource={editabledata}
								className={classes.editabletable}
								bordered
								title={() => {
									return `${editabledata.length} Records Found`;
								}}
								// pagination={false}
								size="middle"
								scroll={{ x: 1500, y: 500 }}
							/>
						</Form>
					</Paper>
				) : null}
			</Grid>

			<Snackbar
				autoHideDuration={6000}
				open={status.show}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={() => fnShowErrorMessage()}
			>
				<AlertNotificationComp
					variant={status.variant}
					onClose={() => fnShowErrorMessage()}
					message={status.message}
				></AlertNotificationComp>
			</Snackbar>
			<ConfirmationDialogComp
				message={confirmDialog.message}
				display={confirmDialog.display}
				handleNoDialog={() => fnHandleNoDialog()}
				handleYesDialog={() => fnHandleYesDialog()}
			></ConfirmationDialogComp>
		</>
	);
}

export default withRouter(FacilityDataComp);
