import * as actionType from 'redux/actions/actionType';

let initialState = {
	OperatorType: [],
};

function operatortypesReducer(state = initialState, action) {
	switch (action.type) {
		case actionType.ADD_OPERATORTYPES:
			state.OperatorType = action.payload.OperatorType;
			return state;

		default:
			return state;
	}
}

export default operatortypesReducer;
