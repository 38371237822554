import React, { Suspense } from 'react';

import Grid from '@material-ui/core/Grid';

import LinearProgress from '@material-ui/core/LinearProgress';

import { makeStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router-dom';

const CCUSSearchComponent = React.lazy(
	() =>
		new Promise((resolve, reject) =>
			setTimeout(() => resolve(import('./subcomponents/CCUSSearchComp')), 100)
		)
);

const CCUSSubComp = React.lazy(
	() =>
		new Promise((resolve, reject) =>
			setTimeout(() => resolve(import('./subcomponents/CCUSSubComp')), 100)
		)
);

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
	},
}));

function CCUSComp(props) {
	const classes = useStyles();

	const [searchccusquery, setSearchCCUSQuery] = React.useState('');

	return (
		<>
			<Grid container>
				<Suspense
					fallback={
						<>
							<LinearProgress />
						</>
					}
				>
					<CCUSSearchComponent
						setSearchCCUSQuery={setSearchCCUSQuery}
					/>
				</Suspense>
			</Grid>
			<Grid container>
				<Suspense
					fallback={
						<>
							<LinearProgress />
						</>
					}
				>
					<CCUSSubComp searchccusquery={searchccusquery} />
				</Suspense>
			</Grid>
		</>
	);
}

export default withRouter(CCUSComp);
