import React, { useEffect } from 'react';
import { loadModules } from 'esri-loader';
import shortid from 'shortid';

import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import BaseMapsComp from './subcomponents/BaseMapsComp';
import AlertNotificationComp from 'components/custom/AlertNotificationComp';
import FacilityDataComp from './subcomponents/FacilityDataComp';

import { withRouter } from 'react-router-dom';

//Redux imports
import { useDispatch, useSelector } from 'react-redux';
import * as actionType from 'redux/actions/actionType';

//Api
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '500px',

		'& .MuiTypography-subtitle1': {
			paddingLeft: 10,
			paddingTop: 5,
		},
	},

	toptiles: {
		// width: '100%',
		height: '100%',
	},
	toppaper: {
		height: '100%',
		margin: 10,
	},
	mappaper: {
		height: '100%',
		marginTop: 10,
		marginBottom: 10,
	},
	filtercontrols: {
		marginTop: 10,
	},
	filtercontrol: {
		borderTop: '1px solid rgba(0, 0, 0, 0.12)',
		padding: 10,
		paddingRight: 7,
		'& .MuiFormControlLabel-root': {
			marginRight: 0,
		},
	},
	rowcontrols: {
		marginTop: 20,
	},
}));

function SearchFacilityComp(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	let mapRef = React.createRef();

	const [view, setView] = React.useState({});

	const [status, setStatusBase] = React.useState({
		show: false,
		message: '',
		variant: 'error',
	});

	const statelookupvalues = useSelector((state) => state.statenamesReducer.StateName);

	const acstatenames = [];
	const [selectedstatenames, setSelectedStateNames] = React.useState([...acstatenames]);

	const [county, setCounty] = React.useState([]);

	const accountynames = [];
	const [selectedcountynames, setSelectedCountyNames] = React.useState([...accountynames]);

	const [companyname, setCompanyName] = React.useState('');

	const [facilitylayer, setFacilityLayer] = React.useState({
		properties: {},
		binded: false,
	});

	const [facilitylabel, setFacilityLabel] = React.useState({
		symbol: {
			type: 'text',
			color: '#000000',
			// haloColor: '#d83435',
			// haloSize: '1px',
			font: {
				size: '12px',
				family: 'Arial',
				// style: 'italic',
				weight: 'normal',
			},
		},
		labelPlacement: 'above-center',
		labelExpressionInfo: {
			expression: '$feature.Facility',
		},
	});

	const [countfetched, setCountFetched] = React.useState(false);

	const [mapdata, setMapData] = React.useState([]);

	const [searchby, setSearchBy] = React.useState('location');

	const [latlong, setLatLongValues] = React.useState({
		Latitude: '',
		Longitude: '',
	});

	const token = useSelector((state) => state.userReducer.token);

	const fnShowErrorMessage = () => {
		setStatusBase({ show: false, message: '', variant: 'info' });
	};

	const fnHandleSearchByChange = (event) => {
		setSearchBy(event.target.value);
	};

	const fnHandleChange = (prop) => (event) => {
		setLatLongValues({ ...latlong, [prop]: event.target.value });
	};

	const fnHandleBaseMapChange = (value) => {
		view.map.basemap = value;
	};

	const fnLoadMapView = () => {
		loadModules(
			[
				'esri/Map',
				'esri/views/MapView',
				'esri/Graphic',
				'esri/layers/FeatureLayer',
				'esri/widgets/Feature',
				'esri/widgets/BasemapGallery',
				'dojo/on',
			],
			{
				css: true,
			}
		).then(([ArcGISMap, MapView, Graphic, FeatureLayer, Feature, BasemapGallery, on]) => {
			var mapview = new MapView({
				container: 'multiplywebmapId',
				map: new ArcGISMap({
					basemap: 'gray',
					// layers: [housingLayer] // layers can be added as an array to the map's constructor
				}),
				center: [-111, 38],
				zoom: 3,
				// center: [-118.3, 34.2],
				// zoom: 11,
			});

			mapview.when(
				function () {
					// All the resources in the MapView and the map have loaded. Now execute additional processes
				},
				function (error) {
					// Use the errback function to handle when the view doesn't load properly
					console.log("The view's resources failed to load: ", error);
				}
			);

			// mapview.popup.on('trigger-action', fnHandlePopupAction);

			setView(mapview);
		});
	};

	const fnFetchCountyValues = () => {
		var query = '';
		if (selectedstatenames.length > 0) {
			selectedstatenames.forEach((element) => {
				query = query + ' StateNameId = ' + element.StateNameId;
			});
		}

		const data = {
			columns: ' CountyId, CountyName ',
			table: 'energyinfrastructure.county',
			query: query,
		};

		const options = {
			headers: {
				authorization: token ? `Bearer ${token}` : '',
			},
		};

		axios.post('/api/fetchgenericquery', data, options).then(
			(response) => {
				if (response.data[0].status === 'error') {
				} else if (response.data[0].status === 'success') {
					if (response.data[0].data.length > 0) {
						var data = [];

						response.data[0].data.forEach((element) => {
							data.push({
								CountyName: element.CountyName,
								CountyId: element.CountyId,
							});
						});

						setCounty(data);
					} else {
						setStatusBase({ show: false, message: 'County not found', variant: 'error' });
					}
				}
			},
			(error) => {
				setStatusBase({ show: false, message: 'Server error. Please try again.', variant: 'error' });
			}
		);
	};

	const fnFetchMapData = () => {
		setCountFetched(true);

		var wherequery = '';

		if (searchby === 'company') {
			wherequery = `ow.OperatorName LIKE '%${companyname}%' OR o.OperatorName LIKE '%${companyname}%' `;
		} else {
			var start = '(';
			var end = ')';

			var statefilters = [];

			selectedstatenames.forEach((element) => {
				statefilters.push(`statename = '${element.StateName}'`);
			});

			if (statefilters.length > 0) {
				wherequery = wherequery + start + statefilters.join(' OR ') + end;
			} else {
				wherequery = wherequery + statefilters.join(' OR ');
			}

			var countyfilters = [];

			if (selectedcountynames.length > 0) {
				selectedcountynames.forEach((element) => {
					countyfilters.push(`CountyName = '${element.CountyName}'`);
				});

				if (countyfilters.length > 0) {
					wherequery = wherequery + ' AND ' + start + countyfilters.join(' OR ') + end;
				} else {
					wherequery = wherequery + ' AND ' + countyfilters.join(' OR ');
				}
			}
		}

		const searchQuery = {
			query: wherequery,
		};

		const options = {
			headers: {
				authorization: token ? `Bearer ${token}` : '',
			},
		};

		axios.post('/api/fetchmapsearchdata', searchQuery, options).then(
			(response) => {
				if (response.data[0].status === 'error') {
					setCountFetched(false);
					setStatusBase({ show: true, message: 'Error occured while fetching data', variant: 'error' });
				} else if (response.data[0].status === 'no records') {
					setCountFetched(false);
				} else if (response.data[0].status === 'success') {
					setMapData(response.data[0].data);
					fnAddFacilityLayer(response.data[0].data);
				}
			},
			(error) => {
				setCountFetched(false);
				if (error.request.status === 500) {
				} else {
					setStatusBase({ show: true, message: 'Error occured while fetching data', variant: 'error' });
				}
			}
		);
	};

	const fnFetchLatLongMapData = () => {
		setCountFetched(true);

		const searchQuery = latlong;

		const options = {
			headers: {
				authorization: token ? `Bearer ${token}` : '',
			},
		};

		axios.post('/api/fetchmaplatlongsearchdata', searchQuery, options).then(
			(response) => {
				if (response.data[0].status === 'error') {
					setCountFetched(false);
					setStatusBase({ show: true, message: 'Error occured while fetching data', variant: 'error' });
				} else if (response.data[0].status === 'no records') {
					setCountFetched(false);
				} else if (response.data[0].status === 'success') {
					var overalldata = response.data[0].data;
					overalldata.push({
						Id: 0,
						FacilityName: 'Search',
						Facilitytype: 'Search',
						Latitude: latlong.Latitude,
						Longitude: latlong.Longitude,
						statename: '',
						CountyName: '',
						BasinName: '',
						Zipcode: '',
						Operator: '',
						Owner: '',
					});

					setMapData(overalldata);

					fnAddFacilityLayer(overalldata);
				}
			},
			(error) => {
				setCountFetched(false);
				if (error.request.status === 500) {
				} else {
					setStatusBase({ show: true, message: 'Error occured while fetching data', variant: 'error' });
				}
			}
		);
	};

	const fnAddFacilityLayer = (data) => {
		loadModules(['esri/Graphic', 'esri/layers/FeatureLayer', 'esri/PopupTemplate'], { css: true }).then(
			([Graphic, FeatureLayer, PopupTemplate]) => {
				var moreinfoAction = {
					title: 'More Info',
					id: 'more-info',
				};

				var popupTemplate = new PopupTemplate({
					title: '{Facility}',
					outFields: ['*'],
					content: facilityChange,
					fieldInfos: [
						{
							fieldName: 'Facility',
							format: {
								digitSeparator: true,
								places: 0,
							},
						},
						{
							fieldName: 'FacilityType',
							format: {
								digitSeparator: true,
								places: 0,
							},
						},
						{
							fieldName: 'Operator',
							format: {
								digitSeparator: true,
								places: 0,
							},
						},
						{
							fieldName: 'Owner',
							format: {
								digitSeparator: true,
								places: 0,
							},
						},
						{
							fieldName: 'Longitude',
							format: {
								digitSeparator: true,
								places: 0,
							},
						},
						{
							fieldName: 'Latitude',
							format: {
								digitSeparator: true,
								places: 0,
							},
						},
						{
							fieldName: 'Id',
							format: {
								digitSeparator: true,
								places: 0,
							},
						},
						{
							fieldName: 'StateName',
							format: {
								digitSeparator: true,
								places: 0,
							},
						},
						{
							fieldName: 'CountyName',
							format: {
								digitSeparator: true,
								places: 0,
							},
						},
						{
							fieldName: 'BasinName',
							format: {
								digitSeparator: true,
								places: 0,
							},
						},
						{
							fieldName: 'Zipcode',
							format: {
								digitSeparator: true,
								places: 0,
							},
						},
					],
					actions: [moreinfoAction],
				});

				function facilityChange(feature) {
					var div = document.createElement('div');

					div.innerHTML =
						'Facility Name : <b>' +
						feature.graphic.attributes.Facility +
						'</b> </br> Type : <b>' +
						feature.graphic.attributes.FacilityType +
						'</b> </br> Operator : <b>' +
						feature.graphic.attributes.Operator +
						'</b> </br> Owner : <b>' +
						feature.graphic.attributes.Owner +
						'</b> </br> Latitude : <b>' +
						feature.graphic.attributes.Latitude +
						'</b> </br> Longitude : <b>' +
						feature.graphic.attributes.Longitude +
						'</b> </br> StateName : <b>' +
						feature.graphic.attributes.StateName +
						'</b> </br> CountyName : <b>' +
						feature.graphic.attributes.CountyName +
						'</b> </br> BasinName : <b>' +
						feature.graphic.attributes.BasinName +
						'</b> </br> Zipcode : <b>' +
						feature.graphic.attributes.Zipcode +
						'</b>';
					return div;
				}

				var facilitygraphics = data.map(function (facility) {
					return new Graphic({
						attributes: {
							ObjectId: shortid.generate(),
							Facility: facility.FacilityName,
							FacilityType: facility.Facilitytype,
							Operator: facility.Operator,
							Owner: facility.Owner,
							Latitude: facility.Latitude,
							Longitude: facility.Longitude,
							Id: facility.Id,
							StateName: facility.statename,
							CountyName: facility.CountyName,
							BasinName: facility.BasinName,
							Zipcode: facility.Zipcode,
						},
						geometry: {
							latitude: facility.Latitude,
							longitude: facility.Longitude,
							type: 'point',
						},
					});
				});

				var renderer = {
					type: 'unique-value', // autocasts as new UniqueValueRenderer()
					field: 'FacilityType',
					// defaultSymbol: {
					// 	type: 'simple', // autocasts as new SimpleRenderer()
					// 	symbol: {
					// 		type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
					// 		size: 7,
					// 		color: '#74b9ff',
					// 	},
					// },
					uniqueValueInfos: [
						{
							// All features with value of "North" will be blue
							value: 'Search',
							symbol: {
								type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
								size: 12,
								color: '#00ffe7d9',
							},
						},
						{
							// All features with value of "North" will be blue
							value: 'Terminal',
							symbol: {
								type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
								size: 7,
								color: '#ffffff',
							},
						},
						{
							// All features with value of "North" will be blue
							value: 'GasStorage',
							symbol: {
								type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
								size: 7,
								color: '#ff7d7e',
							},
						},
						{
							// All features with value of "North" will be blue
							value: 'GasPlant',
							symbol: {
								type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
								size: 7,
								color: '#d63031',
							},
						},
						{
							// All features with value of "North" will be blue
							value: 'Liquefaction',
							symbol: {
								type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
								size: 7,
								color: '#930001',
							},
						},
						{
							// All features with value of "North" will be blue
							value: 'Refinery',
							symbol: {
								type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
								size: 7,
								color: '#74b9ff',
							},
						},
						{
							// All features with value of "North" will be blue
							value: 'Compressor Station',
							symbol: {
								type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
								size: 7,
								color: '#32a852',
							},
						},
						{
							// All features with value of "North" will be blue
							value: 'Pumping Station',
							symbol: {
								type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
								size: 7,
								color: '#5332a8',
							},
						},
					],
				};

				var facilitylayer = new FeatureLayer({
					source: facilitygraphics,
					objectIdField: 'ObjectId', // This must be defined when creating a layer from `Graphic` objects
					fields: [
						{
							name: 'ObjectId',
							type: 'oid',
						},
						{
							name: 'Facility',
							type: 'string',
						},
						{
							name: 'FacilityType',
							type: 'string',
						},
						{
							name: 'Operator',
							type: 'string',
						},
						{
							name: 'Owner',
							type: 'string',
						},
						{
							name: 'Latitude',
							type: 'double',
						},
						{
							name: 'Longitude',
							type: 'double',
						},
						{
							name: 'Id',
							type: 'integer',
						},
						{
							name: 'StateName',
							type: 'string',
						},
						{
							name: 'CountyName',
							type: 'string',
						},
						{
							name: 'BasinName',
							type: 'string',
						},
						{
							name: 'Zipcode',
							type: 'string',
						},
					],

					popupTemplate: popupTemplate,
					renderer: renderer,
					// labelingInfo: [facilitylabel],
					outFields: ['*'],
				});

				setFacilityLayer({ properties: facilitylayer, binded: false });
			}
		);
	};

	useEffect(() => {
		if (facilitylayer.binded === false && facilitylayer.properties.visible !== undefined) {
			view.map.removeAll();
			view.map.add(facilitylayer.properties);

			setFacilityLayer({ ...facilitylayer, binded: true });

			setTimeout(() => {
				setCountFetched(false);
			}, 2000);
		}
	}, [facilitylayer]);

	useEffect(() => {
		if (selectedstatenames.length > 0) {
			fnFetchCountyValues();
		} else {
			setCounty([]);
		}
	}, [selectedstatenames]);

	useEffect(() => {
		fnLoadMapView();
	}, []);

	return (
		<>
			<Grid container className={classes.root}>
				<Grid item xs={12}>
					<Paper>
						<Grid
							container
							spacing="2"
							style={{ padding: '5px' }}
							justify="flex-start"
							alignItems="flex-end"
						>
							<Grid item xs={4}>
								<FormControl component="fieldset">
									<FormLabel component="legend">Search By</FormLabel>
									<RadioGroup
										aria-label="Search By"
										name="search by"
										value={searchby}
										onChange={fnHandleSearchByChange}
										row
									>
										<FormControlLabel
											value="location"
											control={<Radio color="primary" />}
											label="Location"
										/>
										<FormControlLabel
											value="company"
											control={<Radio color="primary" />}
											label="Operator/Owner"
										/>
										<FormControlLabel
											value="latlong"
											control={<Radio color="primary" />}
											label="Lat/Long"
										/>
									</RadioGroup>
								</FormControl>
							</Grid>
							<Grid item xs={3}>
								{searchby === 'location' ? (
									<>
										{statelookupvalues.length > 0 ? (
											<Autocomplete
												multiple
												size="small"
												id="statenameslistId"
												options={statelookupvalues}
												getOptionLabel={(option) => option.StateName}
												filterSelectedOptions
												defaultValue={selectedstatenames}
												onChange={(event, newValue) => {
													setSelectedStateNames([
														...acstatenames,
														...newValue.filter(
															(option) => acstatenames.indexOf(option) === -1
														),
													]);
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														variant="outlined"
														label="Select StateName(s)"
														placeholder="StateNames"
													/>
												)}
											/>
										) : null}
									</>
								) : null}
								{searchby === 'latlong' ? (
									<>
										<TextField
											label="Latitude"
											id="latitudeid"
											onChange={fnHandleChange('Latitude')}
											value={latlong.Latitude}
											variant="outlined"
											size="small"
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</>
								) : null}
								{searchby === 'company' ? (
									<>
										<TextField
											label="Company"
											id="companyid"
											onChange={(e) => setCompanyName(e.target.value)}
											value={companyname}
											variant="outlined"
											size="small"
											fullWidth
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</>
								) : null}
							</Grid>

							<Grid item xs={3}>
								{searchby === 'location' ? (
									<>
										{county.length > 0 ? (
											<Autocomplete
												multiple
												size="small"
												id="countylistId"
												options={county}
												getOptionLabel={(option) => option.CountyName}
												filterSelectedOptions
												defaultValue={selectedcountynames}
												onChange={(event, newValue) => {
													setSelectedCountyNames([
														...accountynames,
														...newValue.filter(
															(option) => accountynames.indexOf(option) === -1
														),
													]);
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														variant="outlined"
														label="Select CountyName(s)"
														placeholder="CountyNames"
													/>
												)}
											/>
										) : null}
									</>
								) : null}
								{searchby === 'latlong' ? (
									<>
										<TextField
											label="Longitude"
											id="longitudeid"
											onChange={fnHandleChange('Longitude')}
											value={latlong.Longitude}
											variant="outlined"
											size="small"
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</>
								) : null}
							</Grid>
							<Grid item xs={2}>
								{searchby === 'location' ? (
									<>
										<Button
											type="button"
											color="primary"
											variant="contained"
											className={classes.updatebutton}
											disabled={selectedstatenames.length === 0}
											onClick={() => fnFetchMapData()}
										>
											Search Facilities
										</Button>
									</>
								) : null}
								{searchby === 'latlong' ? (
									<>
										<Button
											type="button"
											color="primary"
											variant="contained"
											className={classes.updatebutton}
											disabled={latlong.Latitude === '' || latlong.Longitude === ''}
											onClick={() => fnFetchLatLongMapData()}
										>
											Search Facilities
										</Button>
									</>
								) : null}
								{searchby === 'company' ? (
									<>
										<Button
											type="button"
											color="primary"
											variant="contained"
											className={classes.updatebutton}
											disabled={companyname === ''}
											onClick={() => fnFetchMapData()}
										>
											Search Facilities
										</Button>
									</>
								) : null}
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Grid item xs={12} className={classes.toptiles}>
					<Paper className={classes.mappaper}>
						<Grid container direction="row" justify="space-between" alignItems="center">
							<Grid item>
								<Typography variant="subtitle1">Maps</Typography>
							</Grid>
							<Grid item>
								<Grid container direction="row" alignItems="center" justify="flex-end">
									<Grid item>
										<BaseMapsComp fnHandleBaseMapChange={fnHandleBaseMapChange}></BaseMapsComp>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						{countfetched ? <LinearProgress /> : false}
						<div className="webmap" id="multiplywebmapId" style={{ height: '92%' }} ref={mapRef} />
					</Paper>
				</Grid>

				{mapdata.length > 0 ? (
					<Grid item xs={12} className={classes.toptiles}>
						<Paper>
							<FacilityDataComp editabledata={mapdata}></FacilityDataComp>
						</Paper>
					</Grid>
				) : null}
			</Grid>
			<Snackbar
				autoHideDuration={6000}
				open={status.show}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={() => fnShowErrorMessage()}
			>
				<AlertNotificationComp
					variant={status.variant}
					onClose={() => fnShowErrorMessage()}
					message={status.message}
				></AlertNotificationComp>
			</Snackbar>
		</>
	);
}

export default withRouter(SearchFacilityComp);
